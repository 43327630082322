<h1>Details</h1>
<h3>{{dialogData.id}} - {{dialogData.cpi}}</h3>

<table class="table" aria-label="Details">
    <tbody>
        <tr>
            <th>CPI</th>
            <td>{{dialogData.cpi}}</td>
        </tr>
        <tr>
            <th>Letzte Verbindung</th>
            <td>{{dialogData.tst | date:"full"}} ({{dialogData.tst | age:MAX_SAFE_INTEGER}})</td>
        </tr>
        <tr>
            <th>Remote IP</th>
            <td>{{dialogData.remoteIp}}</td>
        </tr>
        <tr>
            <th>Anzahl Connector IDs</th>
            <td *ngIf="!isMinus1(dialogData.connectorIdCount); else unknown">{{dialogData.connectorIdCount}}</td>
            <ng-template #unknown>
                <td>Wurde nicht automatisch erkannt</td>
            </ng-template>
        </tr>
        <tr>
            <th>Flotte</th>
            <td>ID: {{dialogData.fleetId || "-"}} Name: {{fleetName}}</td>
        </tr>
        <tr>
            <th>Boot Notification</th>
            <td><pre>{{tryFormatJson(dialogData.bootNotification)}}</pre></td>
        </tr>
        <tr>
            <th>HTTP Header</th>
            <td><pre>{{dialogData.httpHeader}}</pre></td>
        </tr>
        <tr>
            <th>OCPP Config</th>
            <td><pre>{{tryFormatJson(dialogData.ocppConfig)}}</pre></td>
        </tr>
    </tbody>
</table>
