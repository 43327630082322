<div *ngIf="(vehicles | async) as vehicles; else loading" class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>E-Mail</mat-label>
            <input matInput type="email" [(ngModel)]="data.mail">
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Passwort</mat-label>
            <input matInput type="password" [(ngModel)]="data.password" placeholder="Leer lassen, um das Passwort nicht zu ändern.">
        </mat-form-field>
    </div>
    <button mat-raised-button color="primary" (click)="loadVins()" [disabled]="loadingVins || !data.mail || !data.password">VIN-Liste laden</button>
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill" class="relative flex-grow-1">
            <mat-spinner *ngIf="loadingVins"></mat-spinner>
            <mat-label>VIN</mat-label>
            <mat-select [(value)]="data.vin">
                <mat-option [value]="">Nicht zugeordnet</mat-option>
                <mat-option *ngFor="let v of vins" [value]="v.vin" [class.outdated]="now > v.endDate">
                    {{v.vin}}
                    <span class="info">{{prettyString(v.endDate)}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Fahrzeug</mat-label>
            <mat-select [(value)]="data.vehicle">
                <mat-option [value]="null">Keinem Fahrzeug zuordnen</mat-option>
                <mat-option *ngFor="let v of vehicles" [value]="v.id">{{v.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-hint>
        Hinweis: Das Passwort muss aus technischen Gründen für die Verwendung der Free2Move
        Dienste in IO-ELON gespeichert werden. Aus Sicherheitsgründen wird es aber nicht mehr
        in der Anzeige dargestellt. Wenn es sich nicht geändert hat, kann das Feld beim
        bearbeiten leer gelassen werden.
        Für die Abfrage der VIN-Liste muss das Passwort beim
        bearbeiten erneut eingegeben werden, um unberechtigten Zugriff zu vermeiden.
    </mat-hint>
</div>
<ng-template #loading>Loading...</ng-template>
