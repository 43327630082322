<div *ngIf="success" class="success frame">
    <img src="/assets/img/iod-logo.svg" width="200px" alt="Logo">
    <h1>Tesla Account verbunden.</h1>

    <p>
        Ihr Tesla Account wurde erfolgreich mit IO-ELON verbunden. <br>
        Zusätzlich müssen sie nun IO-Elon in ihrer Tesla App freischalten. <br>

        Besuchen sie dazu <a href="https://www.tesla.com/_ak/vehicle.io-elon.de" target="_blank">tesla.com</a>
        oder scannen sie direkt folgenden QR-Code mit ihrem Smartphone.
        <Img width="300px" src="assets/img/tesla_api_qr_code.svg">
    </p>
    <p>
        <a [href]="link">Weiter zu IO-ELON</a>
    </p>

</div>
<div *ngIf="!success" class="fail frame">
    <img src="/assets/img/iod-logo.svg" width="200px" alt="Logo">
    <h1>Verbindung fehlgeschlagen.</h1>

    <p>
        Es ist ein Problem aufgetreten. Ihr Tesla Account wurde nicht korrekt mit IO-ELON verbunden.
    </p>
    <p>
        <a [href]="link">Weiter zu IO-ELON</a>
    </p>
</div>
