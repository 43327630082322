import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-relative-time',
    templateUrl: './relative-time.component.html',
    styleUrls: ['./relative-time.component.scss']
})
export class RelativeTimeComponent implements OnInit {

    @Input() time!: number;

    constructor(
        private readonly datePipe: DatePipe
    ) {
    }

    ngOnInit(): void {
    }


    public getAbsoluteTime(): string {
        return this.datePipe.transform(this.time) + " " + this.datePipe.transform(this.time, "mediumTime");
    }
}
