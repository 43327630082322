import {Component, ElementRef, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ApiService} from '../../../../services/api-handlers/api.service';
import {VehicleService} from '../../service/vehicle.service';
import {
    AbstractHistoryGraph,
    BackgroundLegend,
    GraphRange,
    HistoryCellData
} from '../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import {CHARGING_COLOR, PLUGGED_COLOR} from '../vehicle-history-graph/vehicle-history-graph.component';
import Annotation = dygraphs.Annotation;
import Options = dygraphs.Options;
import {EventLogEntry} from '@io-elon-common/frontend-api';
import {ApiHandler} from "../../../../services/api-handlers/api-handler";

const HOUR = 3600 * 1000;
const DAY = 24 * HOUR;
const SHOW_EVENT_MAX_TIME = 3 * DAY;
const NET_VOLTAGE = 235;


export interface IU12GraphData {
    data: Array<Array<HistoryCellData>>;
    pluggedSlots: GraphRange[];
    chargingSlots: GraphRange[];
    events: Annotation[];
}

@Component({
  selector: 'app-u12-history-graph',
  templateUrl: './u12-history-graph.component.html',
  styleUrls: ['./u12-history-graph.component.scss']
})
export class U12HistoryGraphComponent extends AbstractHistoryGraph<IU12GraphData> implements OnInit {
    @Input() vehicleId!: number;
    @Input() autoReload!: boolean;
    @Output() autoReloadChange = new EventEmitter<boolean>();
    private maxY2?: Promise<number>;

    constructor(
        private readonly element: ElementRef,
        private readonly apiService: ApiService,
        private readonly vehicleService: VehicleService
    ) {
        super();
    }

    public getBackgroundLegend(): BackgroundLegend[] {
        return [
            {
                name: 'Eingesteckt',
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawHatched(canvas, x, y, w, h, PLUGGED_COLOR)
            }, {
                name: 'Laden',
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawHatched(canvas, x, y, w, h, CHARGING_COLOR)
            }, {
                name: 'Jetzt',
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawNowMarker(canvas, x + w / 2 - 1, y, 2, h)
            }
        ];
    }

    protected async getMaxY2(): Promise<number> {
        if (!this.maxY2) {
            this.maxY2 = this.vehicleService.getPromise(this.vehicleId).then(v => ((v.maxAmps || 32) * NET_VOLTAGE * v.numPhases) * 1.2);
        }
        return this.maxY2;
    }

    protected async loadData(start: number, end: number, showAlerts = true): Promise<IU12GraphData> {
        const historyRequest = this.vehicleService.getHistoryOfVehicle(this.vehicleId, start, end);
        let eventsRequest;
        if(end - start < SHOW_EVENT_MAX_TIME) {
            eventsRequest = this.apiService.getVehicleEvents(showAlerts, this.vehicleId, start, end, undefined, undefined, ApiHandler.customerId).toPromise();
        } else {
            // #1181 No events if zoomed out.
            eventsRequest = Promise.resolve({
                start,
                end,
                events: ([] as EventLogEntry[])
            });
        }
        const data = await historyRequest;
        const eventLog = await eventsRequest;

        const gapDistance = (end - start) / 2;

        const u12Data = this.mapToArray(data.data.u12Meas, 'time', 'val', 'min', 'max', 0, 2, undefined, gapDistance);
        const eventLogSeries = this.mapToArray(eventLog.events, 'tst', 'id', undefined, undefined, 1, 2, () => 0, 0);

        const pluggedSlots = this.boolHistoryToAreas(data.data.plugged as any);
        const chargingSlots = this.boolHistoryToAreas(data.data.charging as any);

        const dataMapped = u12Data.concat(eventLogSeries)

        // @ts-ignore
        dataMapped.sort((a, b) => a[0] - b[0]);
        const dataJoined = this.joinLines(dataMapped);

        const events: Annotation[] = eventLog.events.map((e) => {
            return {
                series: 'Events',
                x: e.tst,
                shortText: e.key,
                text: e.key + ': ' + e.message,
                // attachAtBottom: true,
                tickHeight: -2,
                icon: '/img/marker.png',
                height: 16,
                width: 16
            };
        });

        return {
            data: dataJoined,
            pluggedSlots,
            chargingSlots,
            events
        };
    }

    protected async getConfig(): Promise<Options> {
        return {
            errorBars: true,
            customBars: true,
            axes: {
                y: {
                    valueRange: [9, 16],
                    axisLabelFormatter: (v: number | Date) => {
                        return '<span style="color: #FF0000">' + v + ' V</span>';
                    }
                }
            },
            labels: ['x',
                'Spannung U12 (V)',
                'Events'
            ],
            colors: [
                '#FF0000',
                '#000000'
            ],
            series: {
                'Spannung U12 (V)': {
                    axis: 'y1',
                    stepPlot: false
                },
                Events: {drawPoints: false, strokeWidth: 0}
            },
            underlayCallback: (canvas, area, graph) => {
                this.drawAreaRangesHatched(canvas, area, graph, this.data.pluggedSlots, PLUGGED_COLOR);
                this.drawAreaRangesHatched(canvas, area, graph, this.data.chargingSlots, CHARGING_COLOR);
                const nowX = graph.toDomXCoord(Date.now());
                if (nowX > -10 && nowX < area.w) {
                    this.drawNowMarker(canvas, nowX, area.y, 2, area.h);
                }
            },
        };
    }

    async ngOnInit(): Promise<void> {
        await this.init(this.element);
    }
}
