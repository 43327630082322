import {Pipe, PipeTransform} from '@angular/core';
import {
    NOTIFICATION_LEVEL_ERROR,
    NOTIFICATION_LEVEL_INFO,
    NOTIFICATION_LEVEL_WARN
} from '../../services/api-handlers/notification.service';
import {NotificationEntry} from '@io-elon-common/frontend-api';

@Pipe({
  name: 'notificationIconStyle'
})
export class NotificationIconStylePipe implements PipeTransform {

  transform(entry: NotificationEntry): { background: string, color: string, 'border-radius': '50%' } {
      if(entry.level <= NOTIFICATION_LEVEL_ERROR) {
          return {
              background: 'red',
              color: 'white',
              'border-radius': '50%'
          };
      }
      if(entry.level <= NOTIFICATION_LEVEL_WARN) {
          return {
              background: 'orange',
              color: 'black',
              'border-radius': '50%'
          };
      }
      if(entry.level <= NOTIFICATION_LEVEL_INFO) {
          return {
              background: 'lightblue',
              color: 'black',
              'border-radius': '50%'
          };
      }
      return {
          background: 'white',
          color: 'black',
          'border-radius': '50%'
      };
  }
}
