<div class="position-relative">
    <h1>Formel Last Simulation</h1>
    <app-help-box-icon [iconType]="DialogType.HELP" #helpIcon></app-help-box-icon>
</div>

<app-help-box [helpIcon]="helpIcon" key="FORMEL_LOAD_SIMULATION" [dialogType]="DialogType.HELP">
    Mit diesem Modul ist es möglich, formelbasierte dynamische Lasten zu testen und verschiedene Einstellungen zu
    vergleichen. Details zu der Anforderung können in #4380 sowie #4362 (Redmine) gefunden werden.
    <br><br>
    Diese Simulation sendet dazu eine Konfiguration an das Backend, und führt dann mit dieser Einstellung eine
    Simulation auf Basis der aufgezeichneten Daten (Rohdaten) von EVSEs und Zählern aus. Da die historischen Daten
    für Lasten nicht zuverlässig zur Verfügung stehen, werden diese in der Simulation nicht unterstützt.
    <br><br>
    Auf dieser Seite kann eine bestehende Last in den Editor geladen werden, indem sie links ausgewählt wird.
    Alle Änderungen, die auf dieser Seite gemacht werden, werden nicht gespeichert. Es hat auch keinen Einfluss auf
    das Verhalten von IO-ELON, was hier konfiguriert wird, da die hier konfigurierte Last niemals von IO-ELON verwendet
    wird.
    <br><br>
    <strong>Warnung: </strong> Diese Simulation muss je nach gewähltem Zeitraum sehr viele Daten aus der Datenbank laden
    und im Arbeitsspeicher halten. Es ist empfohlen, dass darauf verzichtet wird, mehrere Anfragen gleichzeitig zu
    machen. Ebenso sollte der Zeitraum nicht zu groß gewählt werden.
</app-help-box>

<div class="d-flex gap-3">
    <div class="d-flex flex-column">
        @if(loads | async; as loads) {
            <mat-form-field appearance="fill">
                <mat-label>Vorauswahl</mat-label>
                <mat-select [(ngModel)]="id" (ngModelChange)="idUpdate()">
                    @for(l of loads; track l.id) {
                        <mat-option  [value]="l.id" [disabled]="l.type !== 'FormulaLoad'">{{l.id}} - {{l.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        } @else {
            <mat-form-field appearance="fill">
                <mat-label>Laden...</mat-label>
                <mat-select [disabled]="true">
                    <mat-option>Daten werden geladen</mat-option>
                </mat-select>
            </mat-form-field>
        }
        <app-help-box [closable]="false" [dialogType]="DialogType.HELP">
            Diese Werte der Last werden<br>
            nur in den Editor kopiert.<br>
            Die originale Last wird<br>
            nicht bearbeitet
        </app-help-box>
    </div>
    <div class="edit-dialog">
        @if(powerSupplies | async; as powerSupplies) {
            @if(drawEditor) {
                <app-edit-load-dialog [loadId]="id" [edit]="true" [data]="data" [possiblePowerSupplies]="powerSupplies"></app-edit-load-dialog>
            }
        }
    </div>
</div>
<hr>
<div class="d-flex gap-2">
    <mat-form-field appearance="fill">
        <mat-label>Simulation Start</mat-label>
        <input [ngModel]="start | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="start = parseDate($event)" matInput type="datetime-local">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Simulation Ende</mat-label>
        <input [ngModel]="end | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="end = parseDate($event)" matInput type="datetime-local">
    </mat-form-field>
    <button mat-button mat-raised-button [color]="dateWarning ? 'warn' : 'primary'" [disabled]="loading" (click)="simulate()">Simulation </button>
    <div style="max-width: 250px">{{dateWarning}}</div>
    <div *ngIf="loading">Loading... <i class="fas fa-circle-notch fa-spin"></i></div>
</div>
<hr>
@if(result) {
    <app-load-formula-simulation-result [result]="result"></app-load-formula-simulation-result>
}
