import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

@Component({
    selector: 'app-date-time-form',
    templateUrl: './date-time-form.component.html',
    styleUrls: ['./date-time-form.component.scss']
})
export class DateTimeFormComponent implements OnInit {

    @Input()
    public dateTime!: number;
    @Input()
    public label!: string;
    @Output()
    public dateTimeChange = new EventEmitter<number>();
    public date!: FormControl<moment.Moment | null>;

    constructor() {
    }

    ngOnInit(): void {
        this.date = new FormControl(moment(this.dateTime - this.dateTime % DAY));
    }

    public timeChange(newVal: string) {
        this.time = newVal;
    }

    public dateChange() {
        this.dateMillis = this.date.value!.valueOf();
    }

    public get millis(): number {
        return this.dateTime + this.utcOffsetMillis;
    }

    public set millis(val: number) {
        this.dateTime = val - this.utcOffsetMillis;
        this.date.setValue(moment(this.dateTime).startOf('day'));
        this.dateTimeChange.emit(this.dateTime);
    }

    public get minuteMillis(): number {
        return this.millis % HOUR;
    }

    public set minuteMillis(val: number) {
        this.millis = this.millis - this.minuteMillis + val;
    }

    public get hourMillis(): number {
        return this.millis % DAY - this.minuteMillis;
    }

    public set hourMillis(val: number) {
        this.millis = this.millis - this.hourMillis + val;
    }

    public get dateMillis(): number {
        return this.millis - this.hourMillis - this.minuteMillis;
    }

    public set dateMillis(val: number) {
        this.millis = this.millis - this.dateMillis + val;
    }

    public get minute(): number {
        return Math.floor(this.minuteMillis / MINUTE)
    }

    public set minute(val: number) {
        this.minuteMillis = val * MINUTE;
    }

    public get hour(): number {
        return this.hourMillis / HOUR;
    }

    public set hour(val: number) {
        this.hourMillis = val * HOUR
    }

    public get time(): string {
        return this.hour + ":" + this.minute;
    }

    public set time(val: string) {
        const split = val.split(':');
        this.hour = parseInt(split[0], 10);
        this.minute = parseInt(split[1], 10);
    }

    public get utcOffsetMillis(): number {
        return moment().utcOffset() * MINUTE
    }
}
