import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

export type ZoomEvent = {
    direction: number,
    clientX: number,
    clientY: number
};

@Directive({
    selector: '[appZoomHelper]'
})
export class ZoomHelperDirective implements OnInit{

    @Output()
    public appOnZoom = new EventEmitter<ZoomEvent>();

    private distance: number | undefined = undefined;

    constructor(
        private readonly elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {

        this.elementRef.nativeElement.addEventListener("touchmove", (event: TouchEvent) => {
            if(event.touches.length === 2) {
                const dx = event.touches[0].clientX - event.touches[1].clientX;
                const dy = event.touches[0].clientY - event.touches[1].clientY;

                const newDistance = Math.sqrt(dx * dx + dy * dy);

                const cx = event.touches[0].clientX - dx / 2
                const cy = event.touches[0].clientY - dy / 2


                if (this.distance !== undefined && Math.abs(this.distance - newDistance) > 2) {

                    this.appOnZoom.emit({
                        direction: -Math.sign(newDistance - this.distance),
                        clientX: cx,
                        clientY: cy
                    });
                }
                this.distance = newDistance;

            }
                event.preventDefault();
                event.stopPropagation();
        }, {
            passive: false,
            capture: true
        });
    }

    @HostListener("wheel", ['$event'])
    public onWheel(event: WheelEvent) {
        if(event.altKey || event.shiftKey || event.ctrlKey) {
            event.preventDefault();
            this.appOnZoom.emit({
                direction: Math.sign(event.deltaY) as (1 | -1),
                clientX: event.clientX,
                clientY: event.clientY
            });
        }
    }
}
