import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";


@Injectable()
export class TitleService {

  private dataSource = new BehaviorSubject<any>('');
  title = this.dataSource.asObservable();

  constructor() { }

  updatedTitle(title: string) {
    this.dataSource.next(title);
  }
}
