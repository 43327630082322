import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SystemInfoSupportedEvseActions} from '@io-elon-common/frontend-api/lib/model/systemInfoSupportedEvseActions';
import {AuthService} from '../../../../shared/guards/auth.service';

export interface ActionWithToolTip {
    action: SystemInfoSupportedEvseActions,
    tooltip: string
}

@Component({
  selector: 'app-evse-actions-dropdown',
  templateUrl: './evse-actions-dropdown.component.html',
  styleUrl: './evse-actions-dropdown.component.scss'
})

export class EvseActionsDropdownComponent {
    @Input()
    private actions: SystemInfoSupportedEvseActions[] = [];
    @Output()
    private updateActionSelection = new EventEmitter();
    private actionWithToolTips: ActionWithToolTip[] = [];

    @Input()
    public width: string = "200px";
    public selectedAction: SystemInfoSupportedEvseActions | undefined;

    constructor(
        private readonly authService: AuthService
    ) { }

    public updateSelectableAction() {
        this.updateActionSelection.emit(this.selectedAction);
    }

    public get isDev(): boolean {
        return this.authService.isDeveloper();
    }

    public get devActions(): SystemInfoSupportedEvseActions[] {
        return this.actions.filter(a => a.isDevOnly);
    }

    public get userHiddenActions(): SystemInfoSupportedEvseActions[] {
        return this.actions.filter(a => !a.isDevOnly && !a.isVisibleToUsers);
    }
    public get userActions(): SystemInfoSupportedEvseActions[] {
        return this.actions.filter(a => !a.isDevOnly && a.isVisibleToUsers);
    }

    public getTooltip(action: SystemInfoSupportedEvseActions): string {
        const result = this.actionWithToolTips.find(a => a.action === action);
        if (result) {
            return result.tooltip;
        } else {
            const tooltip = action.description?.replace(/<br\s*\/?>/g, '\n') ?? "";
            this.actionWithToolTips.push({
                action: action,
                tooltip: tooltip
            });
            return tooltip;
        }
    }
}
