<table mat-table [dataSource]="dataSource" class="full-width-table" matSort>

    <!-- id Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
        </td>
    </ng-container>


    <!-- Type Column -->
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="col-username"> Art der Berechtigung</th>
        <td mat-cell *matCellDef="let element" class="col-username">
            {{element.type.displayName}}
        </td>
    </ng-container>


    <!-- Impl Column -->
    <ng-container matColumnDef="impl">
        <th mat-header-cell *matHeaderCellDef class="col-name"> Typ</th>
        <td mat-cell *matCellDef="let element" class="col-name">
            {{element.impl}}
        </td>
    </ng-container>

    <!-- Element Column -->
    <ng-container matColumnDef="object">
        <th mat-header-cell *matHeaderCellDef class="col-roles"> Objekt</th>
        <td mat-cell *matCellDef="let element" class="col-roles" [ngSwitch]="element.impl">
            <div *ngSwitchCase="'GLOBAL'"></div>
            <div *ngSwitchCase="'EVSE'">{{element.evse | evseName}}</div>
            <div *ngSwitchCase="'FLEET'">{{element.fleet | fleetName}}</div>
            <div *ngSwitchCase="'RESERVATION'">{{element.reservation | reservationName}}</div>
            <div *ngSwitchCase="'VEHICLE'">{{element.vehicle | vehicleName}}</div>
        </td>
    </ng-container>

    <!-- actions -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button [disabled]="!element.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon fontSet="material-icons">edit</mat-icon></button>
            <button [disabled]="!element.canDelete" (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon fontSet="material-icons">delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div *ngIf="dataSource?.data.length>10">
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
</div>
