import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SystemService} from "../../services/api-handlers/system.service";
export interface keyValuePair {
    key: string,
    value: string
}

@Injectable({
    providedIn: 'root'
})
export class SearchDataConfig {

    constructor(private readonly systemInfo: SystemService) {
    }

    private ignoreSearchVariable = ['user.permissionGroups', 'user.vehicleCommands'];

    public getActorArgDisplayName(key: string) {
        const supportedEvses = this.systemInfo.getSystemInfoSync()?.supportedEvses ?? [];
        for(const evse of supportedEvses) {
            for(const actor of evse.actors) {
                for(const arg of actor.args) {
                    if(arg.key === key) {
                        return arg.name;
                    }
                }
            }
        }
        return key;
    }

    public getDisplayName(path: string): string | null {
        const pathWithoutIdx = path.replace(/\.\d+\./, ".X.");

        switch (pathWithoutIdx) {
            case "evse.actorType": return "Typ der Anbindung";
            case "evse.guestMinAmps": return "Minimaler Strom für Gastfahrzeuge";
            case "evse.id": return "ID";
            case "evse.liveData.evseCosPhi1.val": return "Cosinus Phi (Leistungsfaktor 1)";
            case "evse.liveData.evseCosPhi2.val": return "Cosinus Phi (Leistungsfaktor 2)";
            case "evse.liveData.evseCosPhi3.val": return "Cosinus Phi (Leistungsfaktor 3)";
            case "evse.liveData.evseI1.val": return "Strom (I1)";
            case "evse.liveData.evseI2.val": return "Strom (I2)";
            case "evse.liveData.evseI3.val": return "Strom (I3)";
            case "evse.liveData.evseP1.val": return "Leistung (P1)";
            case "evse.liveData.evseP2.val": return "Leistung (P2)";
            case "evse.liveData.evseP3.val": return "Leistung (P3)";
            case "evse.liveData.evseU1.val": return "Leistung (U1)";
            case "evse.liveData.evseU2.val": return "Leistung (U2)";
            case "evse.liveData.evseU3.val": return "Leistung (U3)";
            case "evse.liveData.evseDetectedMaxAmps.val": return "Maxmialstrom (Erkannt)";
            case "evse.liveData.evseFirmwareVersion.val": return "Firmware Version";
            case "evse.liveData.evseIecStatus.val": return "IEC Status";
            case "evse.liveData.evseLastBootnotification.val": return "Letzte BootNotification (OCPP)";
            case "evse.liveData.evseModel.val": return "Model";
            case "evse.liveData.evseRfidToken.val": return "RFID Token";
            case "evse.liveData.evseSerialNumber.val": return "Seriennummer";
            case "evse.liveData.evseTargetI1.val": return "Zielstrom";
            case "evse.liveData.healthStatus.val": return "Status";
            case "evse.liveData.ocppRemoteType.source": return "Quelle für Erkannten OCPP Typ";
            case "evse.liveData.ocppRemoteType.val": return "Erkannter OCPP Typ";
            case "evse.liveData.validationResult.X.ruleDisplayName": return "Config Checker Regel";
            case "evse.liveData.vehicle.dataSource.displayName": return "Name der Telemetrie/API des verbundenen Fahrzeuges";
            case "evse.liveData.vehicle.dataSource.id": return "ID der Telemetrie/API des verbundenen Fahrzeuges";
            case "evse.liveData.vehicle.id": return "ID des angeschlossenen Fahrzeuges";
            case "evse.liveData.vehicle.localId": return "Lokale ID des angeschlossenen Fahrzeuges";
            case "evse.liveData.vehicle.name": return "Name des angeschlossenen Fahrzeuges";
            case "evse.liveData.vehicle.numberPlate": return "Nummernschild des angeschlossenen Fahrzeuges";
            case "evse.liveData.vehicle.type": return "Typ des angeschlossenen Fahrzeuges";
            case "evse.liveData.vehicle.vehicleClass": return "Art des angeschlossenen Fahrzeuges";
            case "evse.localId": return "Lokale ID";
            case "evse.maintenanceInitiator.firstname": return "Name des Wartungsmodus-Initiators";
            case "evse.maintenanceInitiator.lastname": return "Name des Wartungsmodus-Initiators";
            case "evse.maintenanceInitiator.name": return "Name des wartungsmodus Initiators";
            case "evse.maintenanceReason": return "Grund der Wartung";
            case "evse.maxI": return "Maximaler Strom";
            case "evse.modelMaxAmps": return "Maximaler Strom (Bauart)";
            case "evse.phaseRotation": return "Phasenrotation";
            case "evse.typeKey": return "Typ";
            case "evse.type": return "Typ";
            case "evse.name": return "Name";
            case "load.formula": return "Formel";
            case "load.id": return "ID";
            case "load.name": return "Name";
            case "load.type": return "Typ";
            case "load.meterId": return "ID des zugeordneten Smartmeters";
            case "load.powerSupplyId": return "ID der zugeordneten Unterverteilung";
            case "load.zone": return "Zone";
            case "meter.hostname": return "Hostname / IP-Adresse";
            case "meter.id": return "ID";
            case "meter.modbusId": return "Modbus ID";
            case "meter.name": return "Name";
            case "meter.port": return "Port";
            case "meter.type": return "Typ";
            case "powerSupply.id": return "ID";
            case "powerSupply.maxAmps": return "Maximaler Strom";
            case "powerSupply.name": return "Name";
            case "powerSupply.userDefinedPeak": return "Benutzerdefinierter Peak";
            case "rfid.id": return "ID";
            case "rfid.invoiceReceiver": return "Kostenstelle";
            case "rfid.name": return "Name";
            case "rfid.token": return "Token";
            case "rfid.vehicle.id": return "ID des zugeordneten Fahrzeuges";
            case "rfid.vehicle.localId": return "Lokale ID des zugeordneten Fahrzeuges";
            case "rfid.vehicle.name": return "Name des zugeordneten Fahrzeuges";
            case "rfid.vehicle.numberPlate": return "Nummernschild des zugeordneten Fahrzeuges";
            case "user.email": return "E-Mail";
            case "user.id": return "ID";
            case "user.firstname": return "Vorname";
            case "user.lastname": return "Nachname";
            case "user.name": return "Benutzername";
            case "vehicle.confMaxAmps": return "Maximaler Strom (Config)";
            case "vehicle.confMinAmps": return "Minimaler Strom (Config)";
            case "vehicle.dataSource.displayName": return "Name der Telematik/API";
            case "vehicle.dataSource.id": return "ID der Telematik/API";
            case "vehicle.dataSource.vendorVehicleId": return "Kennung des Herstellers für die Telematik/API";
            case "vehicle.defaultTargetSoc": return "Ziel Ladestand";
            case "vehicle.id": return "ID";
            case "vehicle.liveData.i1.val": return "Strom (I1)";
            case "vehicle.liveData.i2.val": return "Strom (I2)";
            case "vehicle.liveData.i3.val": return "Strom (I3)";
            case "vehicle.liveData.p1.val": return "Leistung (P1)";
            case "vehicle.liveData.p2.val": return "Leistung (P2)";
            case "vehicle.liveData.p3.val": return "Leistung (P3)";
            case "vehicle.liveData.u1.val": return "Spannung (U1)";
            case "vehicle.liveData.u2.val": return "Spannung (U2)";
            case "vehicle.liveData.u3.val": return "Spannung (U3)";
            case "vehicle.liveData.u12Meas.val": return "Spannung (12V Batterie)";
            case "vehicle.liveData.availState": return "Verfügbarkeitsstatus";
            case "vehicle.liveData.errorState": return "Fehlerstatus";
            case "vehicle.liveData.estimatedSocR.val": return "Geschätzer Ladestand (Real)";
            case "vehicle.liveData.estimatedSocU.val": return "Geschätzer Ladestand";
            case "vehicle.liveData.i.val": return "Strom";
            case "vehicle.liveData.iCp.val": return "Zielstrom";
            case "vehicle.liveData.odometer.val": return "Kilimeterzähler";
            case "vehicle.liveData.p.val": return "Leistung";
            case "vehicle.liveData.pwrState.val": return "Status";
            case "vehicle.liveData.pwrText": return "Status";
            case "vehicle.liveData.rangeFull.val": return "Reichweite bei 100%";
            case "vehicle.liveData.rangeNow.val": return "Reichweite";
            case "vehicle.liveData.rssi.val": return "Signalstärke (RSSI)";
            case "vehicle.liveData.socLimit.val": return "Ladestand Limit";
            case "vehicle.liveData.socR.val": return "Ladestand (Real)";
            case "vehicle.liveData.socU.val": return "Ladestand";
            case "vehicle.liveData.soh.val": return "Batteriegesundheit";
            case "vehicle.liveData.targetSocR": return "Zielladestand (Real)";
            case "vehicle.liveData.targetSocU": return "Zielladestand";
            case "vehicle.liveData.u.val": return "Spannung";
            case "vehicle.maxAmps": return "Maximaler Stromg";
            case "vehicle.numPhases": return "Anzahl Phasen";
            case "vehicle.localId": return "Lokale ID";
            case "vehicle.name": return "Name";
            case "vehicle.numberPlate": return "Nummernschild";
            case "vehicle.type": return "Typ";
            case "vehicle.vehicleClass": return "Fahrzeugart";
            default: return null;
        }
    }

    public getRoutingLinkReference(variable: string, object: any): keyValuePair[] | undefined {
        const result = [];
        switch (variable) {
            case 'vehicle': {
                if (object.evse) {
                    result.push({key: object.evse.name, value: this.getRouterLink("evse", object.evse.id) })
                }
                break;
            }
            case 'evse': {
                if (object.liveData && object.liveData.vehicle) {
                    result.push({key: object.liveData.vehicle.name, value: this.getRouterLink("vehicle", object.liveData.vehicle.id)})
                }
                break;
            }
            case 'rfid': {
                if (object.vehicle) {
                    result.push({key: object.vehicle.name, value: this.getRouterLink("vehicle", object.vehicle.id)})
                }
                if (object.user) {
                    result.push({key: object.user.name, value: this.getRouterLink("user", undefined)})
                }
                break;
            }
            default: return undefined;
        }
        return result;
    }

    public getRouterLink(resultType: string, id: string | undefined): string {
        switch (resultType) {
            case 'evse': return  "/evses/evse/" + id;
            case 'meter': return  "/meters/meter/" + id;
            case 'powerSupply': return  "/basis";
            case 'rfid': return  "/rfid";
            case 'vehicle': return  "/fleet/vehicle/" + id;
            case 'load': return  "/loads/";
            case 'solarSystem': return  "/solar/";
            case 'user': return  "/user/";
            default: return "Fehler";
        }
    }

    public getTitle(resultType: string, object: any): string {
        switch (resultType) {
            case 'vehicle': return `Fahrzeug: ${object.name} (${object.type})`;
            case 'evse': return `Ladepunkt: ${object.name} (${object.type})`;
            case 'rfid': return `RFID: ${object.name} (${object.token})` + (object.Kostenstelle ? ", Kostenstelle: " + object.Kostenstelle : "");
            case 'meter': return `Zähler: ${object.name} (${object.type})`;
            case 'powerSupply': return "Untervereilung: " + object.name;
            case 'load': return `Last: ${object.name} (${object.type})`;
            case 'solarSystem': return `Solaranlage: ${object.name} (via ${object.solarPredictionProvider})`;
            case 'user': return `Benutzer: ${object.name} (${object.name} / ${object.firstname} ${object.lastname})`;
            default: return "Fehler";
        }
    }

    isFieldIgnored(fieldName: string): boolean {
        return this.ignoreSearchVariable.includes(fieldName);
    }
}
