<mat-dialog-content class="form-field-container">
    <h2 class="mat-h2">{{config.head}}</h2>
    {{config.msg}}
</mat-dialog-content>
<mat-dialog-actions class="d-flex flex-row justify-content-end gap-1">
    <button *ngFor="let btn of config.options"
        mat-raised-button [color]="btn.color"
        type="submit"
        (click)="onButton(btn)"
    >
        {{btn.text}}
    </button>
</mat-dialog-actions>
