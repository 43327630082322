import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {SystemService} from '../../../services/api-handlers/system.service';
import {EntityManagerDetails} from '@io-elon-common/frontend-api';

@Component({
  selector: 'app-entity-manager-debugger',
  templateUrl: './entity-manager-debugger.component.html',
  styleUrls: ['./entity-manager-debugger.component.scss']
})
export class EntityManagerDebuggerComponent implements OnInit {

    public entityManagers!: BehaviorSubject<EntityManagerDetails[] | undefined>;

    public sortByKey: keyof EntityManagerDetails = "name"
    public onlyOpen = true;

    constructor(
        private systemService: SystemService
    ) {
    }

    ngOnInit(): void {
        this.entityManagers = this.systemService.getEntityManagers();
    }

    public openCount(list: EntityManagerDetails[]): number {
        return list.filter(e => e.open).length;
    }

    public sortBy(list: EntityManagerDetails[], key: keyof EntityManagerDetails): EntityManagerDetails[] {
        if(this.onlyOpen) {
            list = list.filter(e => e.open)
        }
        return list.sort((a,b) => {
            const aVal = a[key];
            const bVal = b[key];

            if(aVal === bVal) {
                return 0;
            }

            if(aVal === null) {
                return -1;
            }

            if(bVal === null) {
                return 1;
            }

            if(typeof aVal === typeof bVal) {
                if(typeof aVal === 'string') {
                    return aVal.localeCompare(bVal as unknown as string);
                }
                if(typeof aVal === 'number') {
                    return aVal - (bVal as unknown as number);
                }
                return 0;
            }

            if(typeof aVal === "string") {
                return 1;
            }
            return -1;
        });
    }
}
