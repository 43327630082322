<h1>Zähler Live Daten</h1>
<div *ngIf="(meterLiveData | async) as meterConnections; else loading">

    <table class="table table-striped">
        <thead>
        <tr>
            <th>Id</th>
            <th>Daten</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let meterConnection of meterConnections.list">
            <td>{{meterConnection.meterId}}</td>
            <td>
                <table class="table">
                    <tbody>
                        <tr *ngFor="let data of meterConnection.fields | keyvalue">
                            <th>{{data.key}}</th>
                            <td>{{data.value.val}} {{data.value.unit}}</td>
                            <td>{{data.value.tst | date:"dd.MM.yyyy HH:mm:ss"}} ({{data.value.tst | age}})</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </table>
</div>
<ng-template #loading>
    Loading...
</ng-template>
