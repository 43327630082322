<div *ngIf="!uploadInProgress(); else newUserTable">
    <div *ngIf="(users | async) as users; else loading">
        <div>
            <div>
                <div class="with-background">
                    <app-user-table [users]="users"></app-user-table>
                </div>
            </div>
        </div>
        <div *ngIf="allowedToAddUsers()" class="buttons d-flex flex-row justify-content-end gap-3">
            <div class="with-background mat-elevation-z4 d-flex flex-row gap-1" style="border-radius: 6px">
                <button mat-button color="primary" (click)="downloadTemplate()" matTooltip="Download der auszufüllenden Excel Vorlage, zum hinzufügen mehrerer Benutzer">
                    <mat-icon>download</mat-icon>
                </button>
                <button mat-button color="primary" (click)="fileInput.click()" matTooltip="Hochladen der zuvor ausgefüllten Excel Vorlage, zum importieren von Benutzern">
                    <mat-icon>upload</mat-icon>
                </button>
                <input hidden (change)="handleFileInput($event.target.files)" #fileInput type="file" id="file">
            </div>
            <button mat-raised-button color="primary" (click)="newUser()">Benutzer hinzufügen</button>
        </div>
    </div>
</div>
<ng-template #newUserTable>
    <div>
        <div>
            <div class="with-background">
                <app-new-user-table [users]="newUsers"></app-new-user-table>
            </div>
        </div>
    </div>
    <div class="buttons d-flex justify-content-end">
        <button mat-raised-button color="accent" (click)="cancelUpload()">Abbrechen</button>
    </div>
</ng-template>
<ng-template #loading>
    Loading...
</ng-template>
