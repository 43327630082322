import {Component, Input, OnInit} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {RfidService, RfidUploadData, UploadStatus} from '../../service/rfid.service';
import {User, Vehicle} from '@io-elon-common/frontend-api';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {UserService} from '../../../users/service/user.service';

@Component({
  selector: 'app-rfid-upload-dialog',
  templateUrl: './rfid-upload-dialog.component.html',
  styleUrls: ['./rfid-upload-dialog.component.scss']
})
export class RfidUploadDialogComponent implements OnInit, IEditForm<RfidUploadData[]> {

    @Input()
    public data!: RfidUploadData[];

    public users: User[] | undefined;

    public vehicles: Vehicle[] | undefined;

    public isAllSelected = true;

    public uploadStatus = UploadStatus;
    validate(): string[] {
        const result: string[] = [];
        this.data.forEach(rfid => {
            if (rfid.status === this.uploadStatus.PENDING) {
                if (rfid.userRequired && rfid.rfid.userId === undefined) {
                    result.push("Benutzer nicht ausgewählt für " + rfid.rfid.name + "<br>");
                }

                if (rfid.vehicleRequired && rfid.rfid.vehicleId === undefined) {
                    result.push("Fahrzeug nicht ausgewählt für " + rfid.rfid.name + "<br>");
                }
            }
        });
        return result;
    }

    constructor(private readonly rfidService: RfidService,
                private readonly vehicleService: VehicleService,
                private readonly userService: UserService
                ) {}

    ngOnInit(): void {
        this.users = this.userService.getAll().getValue();
        this.vehicles = this.vehicleService.getAll().getValue();
    }

    public updateAllSelected() {
        this.data.forEach(rfid => {
            if (rfid.status !== UploadStatus.SUCCESS) {
                rfid.status = this.isAllSelected ? this.uploadStatus.PENDING : this.uploadStatus.DISCARDED;
            }
        });
    }

    public updatedStatus(elem: MatCheckboxChange, rfid: RfidUploadData) {
        rfid.status = elem.checked ? this.uploadStatus.PENDING : this.uploadStatus.DISCARDED;
    }

    public checkIfSelected(rfid: RfidUploadData): boolean {
        return rfid.status === this.uploadStatus.PENDING || rfid.status === this.uploadStatus.SUCCESS;
    }
}
