import {Component, Input, OnInit} from '@angular/core';
import {AlgoV2Config} from '@io-elon-common/frontend-api';
import {AlgoV2ConfigService} from '../../service/algoV2Config.service';


@Component({
    selector: 'app-algo-v2-config-tile',
    templateUrl: './algo-v2-config-tile.component.html',
    styleUrls: ['./algo-v2-config-tile.component.scss']
})
export class AlgoV2ConfigTileComponent implements OnInit {

    @Input()
    public config!: AlgoV2Config;

    constructor(
        private readonly algoV2ConfigService: AlgoV2ConfigService,
    ) {
    }

    ngOnInit(): void {
    }

    public async edit(): Promise<void> {
        await this.algoV2ConfigService.showEditDialog(this.config);
    }
    public async copy(): Promise<void> {
        await this.algoV2ConfigService.showCloneDialog(this.config);
    }
    public async delete(): Promise<void> {
        await this.algoV2ConfigService.showDeleteDialog(this.config, {});
    }

}
