import {Component, Input, OnInit} from '@angular/core';
import {ExecuteReservationAddOrUpdate, User, VehicleTeaser} from '@io-elon-common/frontend-api';
import {DateRange} from '@angular/material/datepicker';
import * as moment from 'moment';
import {Moment} from 'moment';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {UserService} from '../../../users/service/user.service';
import {num} from '../../../../shared/helper/util-functions';

@Component({
    selector: 'app-edit-reservation-dialog',
    templateUrl: './edit-reservation-dialog.component.html',
    styleUrls: ['./edit-reservation-dialog.component.scss']
})
export class EditReservationDialogComponent implements OnInit, IEditForm<ExecuteReservationAddOrUpdate> {
    @Input()
    data!: ExecuteReservationAddOrUpdate;
    @Input()
    vehicles!: VehicleTeaser[];
    @Input()
    mode: ExecuteReservationAddOrUpdate.EditModeEnum = 'ALL';
    @Input()
    min?: Moment;

    public users!: User[];
    public isValidUserSocDistance = true;
    private rangeCache?: DateRange<Moment>;

    //#2242
    private userSetDistance?: number;
    private userSetSoc?: number;

    constructor(
        private readonly userService: UserService
    ) {}

    public async ngOnInit(): Promise<void> {
        this.users = (await this.userService.getAllPromise());
        if (this.calTargetSoc() > 100) {
            this.targetSoc = '100';
        } else {
            this.targetDistance = '' + this.data.distance;
        }
    }

    public toggleDay(day: ExecuteReservationAddOrUpdate.DayOptionsEnum) {
        const index = this.data.dayOptions.indexOf(day);
        if(index !== -1) {
            this.data.dayOptions.splice(index, 1);
        } else {
            this.data.dayOptions.push(day);
        }
    }

    public isDayChecked(day: ExecuteReservationAddOrUpdate.DayOptionsEnum) {
        const index = this.data.dayOptions.indexOf(day);
        return index !== -1;
    }

    public get range(): DateRange<Moment> {
        if (this.rangeCache &&
            this.rangeCache.start?.valueOf() === this.data.departureTime &&
            this.rangeCache.end?.valueOf() === this.data.returnTime) {
            return this.rangeCache;
        }
        this.rangeCache = new DateRange<Moment>(moment(this.data.departureTime), moment(this.data.returnTime));
        return this.rangeCache;
    }

    public set range(range: DateRange<Moment>) {
        this.data.departureTime = range.start?.valueOf() || 0;
        this.data.returnTime = range.end?.valueOf() || 0;
    }

    public get repeat(): boolean {
        return this.data.freq === 'DAYS'
    }

    public set repeat(repeat: boolean) {
        this.data.freq = repeat ? 'DAYS' : undefined;
        if(repeat) {
            this.asap = false;
        }
    }

    public get asap(): boolean {
        return this.data.asap;
    }

    public set asap(asap: boolean) {
        this.data.asap = asap;
        if(asap) {
            this.repeat = false;
        }
    }

    public get vehicle(): VehicleTeaser | undefined {
        return this.vehicles.filter(v => v.id === this.data.vehicleId)[0];
    }

    public set vehicle(vehicle: VehicleTeaser | undefined) {
        this.data.vehicleId = vehicle?.id || -1;
    }

    public get allowSoc(): boolean {
        return typeof this.vehicle?.liveData.rangeFull?.val === 'number';
    }

    public get targetDistance(): string {
        if(this.userSetDistance) {
            return this.userSetDistance.toFixed(0);
        }
        return this.data.distance.toFixed(0);
    }

    public set targetDistance(val: string) {
        this.data.distance = Number.parseFloat(val);
        this.userSetDistance = this.data.distance;
        const soc = this.calTargetSoc();
        this.userSetSoc = isNaN(soc) ? undefined : soc;
        this.updateValidUserSocDistance();
    }

    public get targetSoc(): string {
        if(this.userSetSoc) {
            return this.userSetSoc.toFixed(0);
        }

        const soc = this.calTargetSoc();
        return isNaN(soc) ? "---" : soc.toFixed(0)
    }

    public set targetSoc(val: string) {
        const fullRange = this.vehicle?.liveData.rangeFull?.val;
        const numVal = Number.parseFloat(val);
        if (typeof fullRange === 'number' && !isNaN(numVal)) {
            this.data.distance = Math.round(numVal / 100 * num(fullRange));
            this.userSetSoc = numVal;
            this.userSetDistance = this.data.distance;
        }
        this.updateValidUserSocDistance();
    }

    public getUserSetDistanceHint(): string {
        if (this.vehicle &&
            this.vehicle.liveData &&
            this.vehicle.liveData.rangeFull) {
            return "Maximal " + this.vehicle.liveData.rangeFull.val + " km";
        }
        return "" ;
    }

    validate(): string[] {
        const ret: string[] = []
        if (!(this.isValidSoc() && this.isValidDistance())) {
            ret.push("Überschreitet Fahrzeugreichweite")
        }

        return ret;
    }

    updateValidUserSocDistance() {
        this.isValidUserSocDistance = this.isValidSoc() && this.isValidDistance();
    }

    isValidSoc(): boolean {
        return this.userSetSoc !== undefined && this.userSetSoc >= 0 && this.userSetSoc <= 100;
    }

    isValidDistance(): boolean {
        return this.userSetDistance !== undefined && this.vehicle !== undefined &&
                this.vehicle.liveData !== undefined && this.vehicle.liveData.rangeFull !== undefined &&
                this.userSetDistance >= 0 && this.userSetDistance <= this.vehicle.liveData.rangeFull.val;
    }

    calTargetSoc() {
        const fullRange = this.vehicle?.liveData.rangeFull?.val;
        if (typeof fullRange === 'number') {
            return this.data.distance * 100 / num(fullRange);
        }
        return NaN;
    }
}
