import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Box} from '@io-elon-common/frontend-api';

@Component({
    selector: 'app-boxes',
    templateUrl: './boxes.component.html',
    styleUrls: ['./boxes.component.scss']
})
export class BoxesComponent implements OnInit, OnChanges {

    @Input() boxes!: (Box & { link?: string })[];
    public boxSize!: number;

    public trackByIdx = (idx: number) => idx;

    public constructor() {
    }

    public ngOnInit(): void {
        if (this.boxes) {
            this.boxSize = 100 / this.boxes.length;
        }
    }

    public ngOnChanges() {
        this.ngOnInit();
    }
}
