<table>
    <tr>
        <th>Phase 1</th>
        <th>Phase 2</th>
        <th>Phase 3</th>
        <th>Summe 3 Phasen</th>
        <th>Zähler</th>
        <th>Zähler seit Boot</th>
    </tr>
    <tr>
        <td><mat-form-field>
            <mat-label>Spannung (V)</mat-label>
            <input matInput [(ngModel)]="data.u1">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Spannung (V)</mat-label>
            <input matInput [(ngModel)]="data.u2">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Spannung (V)</mat-label>
            <input matInput [(ngModel)]="data.u3">
        </mat-form-field></td>
        <td></td>
        <td><mat-form-field>
            <mat-label>Gesamt (kWh)</mat-label>
            <input matInput [(ngModel)]="data.meterValueTotal">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Gesamt (kWh)</mat-label>
            <input matInput [(ngModel)]="data.meterValueSinceBoot">
        </mat-form-field></td>
    </tr>
    <tr>
        <td><mat-form-field>
            <mat-label>Strom (A)</mat-label>
            <input matInput [(ngModel)]="data.i1">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Strom (A)</mat-label>
            <input matInput [(ngModel)]="data.i2">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Strom (A)</mat-label>
            <input matInput [(ngModel)]="data.i3">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Strom (A)</mat-label>
            <input matInput [(ngModel)]="data.iSum">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Bezogen (kWh)</mat-label>
            <input matInput [(ngModel)]="data.meterValueTotalIn">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Bezogen (kWh)</mat-label>
            <input matInput [(ngModel)]="data.meterValueSinceBootIn">
        </mat-form-field></td>
    </tr>
    <tr>
        <td><mat-form-field>
            <mat-label>Leistung (kW)</mat-label>
            <input matInput [(ngModel)]="data.p1">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Leistung (kW)</mat-label>
            <input matInput [(ngModel)]="data.p2">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Leistung (kW)</mat-label>
            <input matInput [(ngModel)]="data.p3">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Leistung (kW)</mat-label>
            <input matInput [(ngModel)]="data.pSum">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Eingespeist (kWh)</mat-label>
            <input matInput [(ngModel)]="data.meterValueTotalOut">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Eingespeist (kWh)</mat-label>
            <input matInput [(ngModel)]="data.meterValueSinceBootOut">
        </mat-form-field></td>
    </tr>
    <tr>
        <td><mat-form-field>
            <mat-label>Leistungsfaktor</mat-label>
            <input matInput [(ngModel)]="data.cosPhi1">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Leistungsfaktor</mat-label>
            <input matInput [(ngModel)]="data.cosPhi2">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Leistungsfaktor</mat-label>
            <input matInput [(ngModel)]="data.cosPhi3">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Leistungsfaktor</mat-label>
            <input matInput [(ngModel)]="data.cosPhiSum">
        </mat-form-field></td>
        <td><mat-form-field>
            <mat-label>Peak (kW)</mat-label>
            <input matInput [(ngModel)]="data.peak">
        </mat-form-field></td>
        <td></td>
    </tr>
</table>
<ng-template #loading>Loading...</ng-template>
