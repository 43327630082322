<table mat-table [dataSource]="dataSource" class="full-width-table" matSort>

    <!-- Icon Column -->
    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef class="col-icon">Fahrzeug</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            <div class="btn-car me-2">
                <mat-icon *ngIf="element.giveOutTime === null" class="iblack">event_available</mat-icon>
                <mat-icon *ngIf="element.giveOutTime !== null" class="iblack">event_busy</mat-icon>
            </div>
        </td>
    </ng-container>

    <!-- Vehicle Column -->
    <ng-container matColumnDef="vehicle">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle"> </th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            <!--suppress AngularInvalidExpressionResultType -->
            <app-vehicle-link [vehicle]="element.vehicle"></app-vehicle-link>
        </td>
    </ng-container>

    <!-- Current Location Column -->
    <ng-container matColumnDef="currentLocation">
        <th mat-header-cell *matHeaderCellDef class="col-name">Aktueller Ort</th>
        <td mat-cell *matCellDef="let element" class="col-name">
            {{element.currentLocation}}
        </td>
    </ng-container>

    <!-- Current Location User Column -->
    <ng-container matColumnDef="currentLocationUser">
        <th mat-header-cell *matHeaderCellDef class="col-type">Herausgabe an / Rücknahme durch</th>
        <td mat-cell *matCellDef="let element" class="col-type">
            <span *ngIf="element.currentLocationUser">{{element.currentLocationUser | fullName}}</span>
        </td>
    </ng-container>

    <!-- Give Out Time icon -->
    <ng-container matColumnDef="giveOutTime">
        <th mat-header-cell *matHeaderCellDef class="col-plug">Ausgabezeitpunkt</th>
        <td mat-cell *matCellDef="let element" class="col-plug">
            <app-relative-time *ngIf="element.giveOutTime" [time]="element.giveOutTime"></app-relative-time>
            <app-relative-time class="light" *ngIf="!element.giveOutTime && nextReservation(element)" [time]="nextReservation(element)?.start || 0"></app-relative-time><br>
            <span class="light" *ngIf="!element.giveOutTime && nextReservation(element)">an {{nextReservation(element)?.reservation.driver | fullName}}*</span>
        </td>
    </ng-container>

    <!-- RückgabeZeit Column -->
    <ng-container matColumnDef="expectedReturnTime">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Rückgabezeit</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            <app-relative-time *ngIf="element.expectedReturnTime" [time]="element.expectedReturnTime"></app-relative-time>
        </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"></th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button *ngIf="!element.giveOutTime" [disabled]="!element.canEdit || !nextReservation(element)" (click)="handOutKeyAuto(element)" mat-icon-button matTooltip="Schlüssel herausgeben - Daten vom Termin übernehmen"><mat-icon svgIcon="onroad_1"></mat-icon></button>
            <button *ngIf="!element.giveOutTime" [disabled]="!element.canEdit" (click)="handOutKey(element)" mat-icon-button matTooltip="Schlüssel herausgeben - Daten eingeben"><mat-icon svgIcon="onroad_2"></mat-icon></button>
            <button *ngIf="element.giveOutTime" [disabled]="!element.canEdit" (click)="keyReturned(element)" mat-icon-button matTooltip="Schlüssel zurückgegeben"><mat-icon fontSet="material-icons">check</mat-icon></button>
            <button [disabled]="!element.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon fontSet="material-icons">edit</mat-icon></button>
            <button [disabled]="!element.canDelete" (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon fontSet="material-icons">delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        (click)="$event.stopPropagation(); $event ? selectRow(row) : null"
        [ngClass]="{selected: selectedIndex === row.id}">
    </tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
<span class="light">*Nächste Buchung für dieses Fahrzeug</span>
