<div class="container" *ngIf="(connections | async) as connections; else loading">
    <ng-container *ngFor="let c of connections; trackBy: trackByFn">
        <div class="col-12">{{c.caller}}</div>
        <div class="row border-bottom">
            <div class="col-2">
                {{c.host}}:{{c.port}}<br>
                UnitId: {{c.unitId}}<br>
                <br>
                Letzte Aktivität:<br>
                {{c.lastTst | age}}<br>
            </div>
            <div class="col-10">
                <pre>{{c.lastData}}</pre>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #loading>
    Loading...
</ng-template>
