import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {
    AlgoV2Config,
    ExecuteAlgoV2ConfigAddOrUpdate,
    SystemInfoAlgoParamDescriptionItem,
    SystemInfoAlgoStrategies
} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {AlgoV2ConfigService} from '../../service/algoV2Config.service';
import {SystemService} from '../../../../services/api-handlers/system.service';
import {AuthService} from '../../../../shared/guards/auth.service';
import {ChartMeta} from 'chart.js';

declare const Chart: any;

@Component({
    selector: 'app-edit-algo-config-dialog',
    templateUrl: './edit-algo-config-dialog.component.html',
    styleUrls: ['./edit-algo-config-dialog.component.scss']
})
export class EditAlgoConfigDialogComponent implements IEditForm<ExecuteAlgoV2ConfigAddOrUpdate>, OnInit, AfterViewInit {

    @Input()
    public data!: ExecuteAlgoV2ConfigAddOrUpdate | any;
    public algoConfigs!: BehaviorSubject<AlgoV2Config[] | undefined>;
    public algoParams!: Array<SystemInfoAlgoParamDescriptionItem>;
    public strategies: Array<SystemInfoAlgoStrategies> = [];
    public isDev = false;

    private chartAlgo: any = undefined;
    private chartMi: any = undefined;

    private readonly fieldsByStrategy: {[key: string]: Array<keyof AlgoV2Config>};

    public constructor(
        private algoV2ConfigService: AlgoV2ConfigService,
        private systemService: SystemService,
        private authService: AuthService,
        private systemInfoService: SystemService
    ) {
        this.fieldsByStrategy = this.systemService.getAlgoConfigFieldsByStrategyMapping();
    }

    public get visibleAlgoParams(): Array<SystemInfoAlgoParamDescriptionItem> {
        return this.algoParams?.filter(p => p.strategy.some(s => s === this.data.strategy)) || []
    }

    public getAlgoDesc(): string {
        return this.strategies.find(s => s.key === this.data.strategy)?.description || "Hier steht eine Beschreibung der ausgehwählen Strategie" ;
    }

    public async ngOnInit(): Promise<void> {
        if(this.data.fallbackConfigId === null) {
            this.data.fallbackConfigId = -1;
        }
        this.isDev = this.authService.isDeveloper();
        this.algoConfigs = this.algoV2ConfigService.getAll();
        this.algoParams = (await this.systemService.getSystemInfo()).algoParamsDescription;
        this.strategies = this.systemService.getSystemInfoSync()?.algoStrategies || [];
    }

    public strategySelected(newStrategy: string): Promise<void> {
        const info = this.systemInfoService.getSystemInfoSync()
        this.data.params = info?.algoParamsDescription.filter(x => x.strategy.indexOf(newStrategy) !== -1).reduce((b, i) => {b[i.key] = i.initial; return b}, {} as {[key:string]:string});
        return this.updateCharts();
    }

    public validate(): string[] {
        const val = [];
        if (this.data.name === undefined || this.data.name === "") {
            val.push("Name ist leer")
        }
        return val;
    }

    public tickIntervall(param: SystemInfoAlgoParamDescriptionItem): number {
        if (param.min === undefined || param.max === undefined)
            throw new Error("Param has no min/max")
        return (param.max - param.min) / 10
    }
    public step(param: SystemInfoAlgoParamDescriptionItem): number {
        if (param.min === undefined || param.max === undefined)
            throw new Error("Param has no min/max")
        return (param.max - param.min) / 100
    }

    private getConfigToKey(algoParams: SystemInfoAlgoParamDescriptionItem[], key: string): SystemInfoAlgoParamDescriptionItem & {val: number} {
        const param = algoParams.find(p => p.key === key);
        if(param == null) {
            throw new Error("Invalid key: " + key);
        }
        return {
            ...param,
            val: this.data.params[key]
        }
    }

    private transformToGraph(param: SystemInfoAlgoParamDescriptionItem, val: number): number {
        if (param.min === undefined || param.max === undefined)
            throw new Error("Param has no min/max")
        return (val - param.min) / (param.max - param.min) * 100;
    }

    private transformToModel(param: SystemInfoAlgoParamDescriptionItem, val: number): number {
        if (param.min === undefined || param.max === undefined)
            throw new Error("Param has no min/max")
        return val / 100 * (param.max - param.min) + param.min
    }

    private getConfigAlgo1(algoParams: Array<SystemInfoAlgoParamDescriptionItem>): Array<SystemInfoAlgoParamDescriptionItem & {val: number}> {
        return [
            this.getConfigToKey(algoParams, "reservPre"),
            this.getConfigToKey(algoParams, "peakPre"),
            this.getConfigToKey(algoParams, "sellPre"),
            this.getConfigToKey(algoParams, "buyPre"),
            this.getConfigToKey(algoParams, "bestPreBelow"),
            this.getConfigToKey(algoParams, "bestPreAbove"),
            this.getConfigToKey(algoParams, "minPreAbove"),
            this.getConfigToKey(algoParams, "minPreBelow"),
            this.getConfigToKey(algoParams, "changeCost"),
            this.getConfigToKey(algoParams, "planSocLowCost"),
            this.getConfigToKey(algoParams, "planSocHighCost")
        ];
    }

    private getConfigAlgoMi(algoParams: Array<SystemInfoAlgoParamDescriptionItem>): Array<SystemInfoAlgoParamDescriptionItem & {val: number}> {
        return [
            this.getConfigToKey(algoParams, "miCostWrongI"),
            this.getConfigToKey(algoParams, "miCostBufferHigh"),
            this.getConfigToKey(algoParams, "miCostBufferLow"),
            this.getConfigToKey(algoParams, "miCostICpChange"),
            this.getConfigToKey(algoParams, "miCostDistToTotal"),
            this.getConfigToKey(algoParams, "miBufferSize"),
        ];
    }

    public async updateCharts() {
        const algoParams = (await this.systemService.getSystemInfo()).algoParamsDescription;

        const config = this.getConfigAlgo1(algoParams);
        const options = this.getChartOptions(config);

        this.chartAlgo.data = options.data;
        this.chartAlgo.update();

        const config2 = this.getConfigAlgoMi(algoParams);
        const options2 = this.getChartOptions(config2);

        this.chartMi.data = options2.data;
        this.chartMi.update();
    }

    public async ngAfterViewInit(): Promise<void> {
        const algoParams = (await this.systemService.getSystemInfo()).algoParamsDescription;

        const config = this.getConfigAlgo1(algoParams);
        const options = this.getChartOptions(config);


        const ctx = (document.getElementById('chartCanvas') as HTMLCanvasElement).getContext('2d');
        if(ctx) {
            // tslint:disable-next-line:no-unused-expression
            this.chartAlgo = new Chart(ctx, options);
        }

        const config2 = this.getConfigAlgoMi(algoParams);
        const options2 = this.getChartOptions(config2);
        const ctx2 = (document.getElementById('chartCanvasMi') as HTMLCanvasElement).getContext('2d');
        if(ctx2) {
            // tslint:disable-next-line:no-unused-expression
            this.chartMi = new Chart(ctx2, options2);
        }
    }

    private getChartOptions(config: Array<SystemInfoAlgoParamDescriptionItem & { val: number }>): ChartMeta {
        return {
            type: 'radar',
            data: {
                // @ts-ignore
                labels: config.map(l => l.description),
                datasets: [{
                    label: 'Config',
                    data: config.map(l => this.transformToGraph(l, l.val)),
                    pointHitRadius: 25,
                    borderColor: '#00F',
                    backgroundColor: '#9999FF99',
                    tooltipItems: config.map(l => l.hint)
                }]
            },
            options: {
                animation: false,
                responsive: false,
                onHover: (e: any) => {
                    const point = e.chart.getElementsAtEventForMode(e, 'nearest', {intersect: true}, false);
                    if (point.length) e.native.target.style.cursor = 'grab';
                    else e.native.target.style.cursor = 'default';
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    dragData: {
                        round: 0,
                        showTooltip: true,
                        onDragStart: (e: any) => {
                            console.log(e);
                        },
                        onDrag: (e: any, datasetIndex: number, index: number, value: number) => {
                            e.target.style.cursor = 'grabbing';
                            console.log(e, datasetIndex, index, value);
                            this.data.params[config[index].key] = this.transformToModel(config[index], value);
                        },
                        onDragEnd: (e: any, datasetIndex: number, index: number, value: number) => {
                            e.target.style.cursor = 'default';
                            console.log(datasetIndex, index, value);
                            this.data.params[config[index].key] = this.transformToModel(config[index], value);
                        },
                    }
                },
                scales: {
                    r: {
                        max: 100,
                        min: 0,
                        stepSize: 25,
                        ticks: {
                            callback: () => '',
                            showLabelBackdrop: false
                        }
                    }
                }
            }
        };
    }
}
