import {Component, Input, OnInit} from '@angular/core';

export enum EIconType {
    FONT_AWESOME,
    MATERIAL,
    MATERIAL_OUTLINE,
    CUSTOM,
    CUSTOM_WHITE
}

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent {
    public EIconType = EIconType;

    @Input()
    public icon!: string
    @Input()
    public type!: EIconType

    constructor() {
    }
}
