import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Box, Dongle, NoteRelatedObject} from '@io-elon-common/frontend-api';
import {DongleService} from '../../service/dongle.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-dongle-details-view',
  templateUrl: './dongle-details-view.component.html',
  styleUrl: './dongle-details-view.component.scss'
})
export class DongleDetailsViewComponent implements OnInit{
    public dongle!: BehaviorSubject<Dongle | undefined>
    private routerEventSubscription!: Subscription;
    public readonly NoteRelatedObject = NoteRelatedObject;

    ngOnInit(): void {
        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
                console.log(id)
                if (id) {
                    this.dongle = this.dongleService.get(id);
                }
            }
        });
        const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
        this.dongle = this.dongleService.get(id);
    }

    public getBoxes(dongle: Dongle){
        if(dongle !== undefined) {
           const boxes: (Box & {link?: string})[] = [
                {value: dongle.name, description: 'Dongle', icon: 'plug'},
                {value: dongle.vehicle?.name ?? "---", description: 'Fahrzeug', icon: 'car'},

                {value: dongle.dongleVin ?? "--", description: 'Vin', icon: 'id'},
                {value: this.lastContactToHumanReadable(dongle.lastMsg) ?? "--", description: 'Letzter Kontakt', icon: 'verwaltung'},
            ]
            return boxes;
        }
    }

    public lastContactToHumanReadable(lastContact: number | undefined): string | undefined {
       return lastContact ? (this.datePipe.transform(lastContact) +" - " + this.datePipe.transform(lastContact, "shortTime")) : undefined;
    }

    constructor(
    private readonly route: ActivatedRoute,
    private readonly dongleService: DongleService,
    private readonly router: Router,
    private readonly datePipe: DatePipe
    ) {
    }

}
