import Dygraph from 'dygraphs';
import {AbstractHistoryGraph} from './abstract-history-graph';

export class InteractionModel {

    public dblclick: () => void;
    public mousedown: (event: MouseEvent, g: Dygraph, context: any) => void;
    public mousemove: (event: MouseEvent, g: Dygraph, context: any) => void;
    public mouseup: (event: MouseEvent, g: Dygraph, context: any) => void;
    public wheel: (event: WheelEvent, g: Dygraph) => void;
    public touchstart: (event: TouchEvent, g: Dygraph, context: any) => void
    public touchend: (event: TouchEvent, g: Dygraph, context: any) => void
    public touchmove: (event: TouchEvent, g: Dygraph, context: any) => void

    public constructor(
        private readonly graph: AbstractHistoryGraph<any>) {

        // Fix this context
        this.dblclick = () => this._dblclick();
        this.mousedown = (event: MouseEvent, g: Dygraph, context: any) => this._mousedown(event, g, context);
        this.mousemove = (event: MouseEvent, g: Dygraph, context: any) => this._mousemove(event, g, context);
        this.mouseup = (event: MouseEvent, g: Dygraph, context: any) => this._mouseup(event, g, context);
        this.wheel = (event: WheelEvent, g: Dygraph) => this._wheel(event, g);
        this.touchstart = (event: TouchEvent, g: Dygraph, context: any) => this._touchstart(event, g, context);
        this.touchmove = (event: TouchEvent, g: Dygraph, context: any) => this._touchmove(event, g, context);
        this.touchend = (event: TouchEvent, g: Dygraph, context: any) => this._touchend(event, g, context);
    }

    private _touchstart(event: TouchEvent, g: Dygraph, context: any): void {
        Dygraph.defaultInteractionModel.touchstart(event, g, context);
        context.touchDirections.y = false;
    }
    private _touchmove(event: TouchEvent, g: Dygraph, context: any): void {
        Dygraph.defaultInteractionModel.touchmove(event, g, context);
    }
    private _touchend(event: TouchEvent, g: Dygraph, context: any): void {
        Dygraph.defaultInteractionModel.touchend(event, g, context);
        // noinspection JSIgnoredPromiseFromCall
        this.graph.updateToRange(g.xAxisRange()[0], g.xAxisRange()[1]);
    }

    private _dblclick(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.graph.updateToRange(
            this.graph.defaultStart(),
            this.graph.defaultEnd(),
            true,
            true
        )
    }

    private _mousedown(event: MouseEvent, g: Dygraph, context: any): void {
        // @ts-ignore
        context.initializeMouseDown(event, g, context);
        if (event.altKey || event.shiftKey || event.ctrlKey) {
            // @ts-ignore
            Dygraph.startZoom(event, g, context);
            context.dragEndY = context.dragStartY;
        } else {
            // @ts-ignore
            Dygraph.startPan(event, g, context);
        }
    }

    private _mousemove(event: MouseEvent, g: Dygraph, context: any): void {
        context.is2DPan = false;
        if (context.isPanning) {
            // @ts-ignore
            Dygraph.movePan(event, g, context);
        }
        if (context.isZooming) {
            context.dragStartY = context.dragEndY;
            // @ts-ignore
            Dygraph.moveZoom(event, g, context);
        }
    }

    private _mouseup(event: MouseEvent, g: Dygraph, context: any): void {
        // @ts-ignore
        Dygraph.endPan(event, g, context);
        if (context.isPanning) {
            // @ts-ignore
            Dygraph.endPan(event, g, context);
            // noinspection JSIgnoredPromiseFromCall
            this.graph.updateToRange(g.xAxisRange()[0], g.xAxisRange()[1]);
        }
        if (context.isZooming) {
            // @ts-ignore
            Dygraph.endZoom(event, g, context);
        }
        context.isPanning = false;
        context.isZooming = false;
    }

    private _wheel(event: WheelEvent, g: Dygraph): void{
        if (event.altKey || event.shiftKey || event.ctrlKey) {
            const signum = Math.sign(event.deltaY);
            console.log('Zoom: ' + signum);
            event.preventDefault();
            const percentageMouse = g.toPercentXCoord(g.toDataXCoord(g.eventToDomCoords(event)[0]));
            const s = g.xAxisRange()[0];
            const e = g.xAxisRange()[1];
            const w = e - s;
            const newE = e + w * 0.1 * (1 - percentageMouse) * signum;
            const newS = s - w * 0.1 * percentageMouse * signum;
            this.graph.dygraph.updateOptions({dateWindow: [newS, newE]});
            // noinspection JSIgnoredPromiseFromCall
            this.graph.updateToRange(newS, newE);
        }
    }
}
