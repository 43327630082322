import {Component, Input, OnInit} from '@angular/core';
import {IEditForm} from "../../../../shared/components/dialogs/edit-dialog/edit-dialog.component";
import {SmartMeterV1Data} from "@io-elon-common/frontend-api";

@Component({
    selector: 'app-smartmeter-data-v1-dialog',
    templateUrl: './smartmeter-data-v1-dialog.component.html',
    styleUrls: ['./smartmeter-data-v1-dialog.component.scss']
})
export class SmartmeterDataV1DialogComponent implements OnInit, IEditForm<SmartMeterV1Data> {

    constructor() { }

    ngOnInit(): void {
    }

    @Input()
    public data!: SmartMeterV1Data;

    validate(): string[] {
        return [];
    }

}
