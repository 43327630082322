<div class="body d-flex flex-column justify-content-start align-items-stretch">
    <div class="d-flex flex-row justify-content-between gap-3 align-items-start">
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Account</mat-label>
            <mat-select [(ngModel)]="accountId">
                <mat-option *ngFor="let a of accounts" [value]="a.id">
                    <span class="account-info mat-caption">{{ a.id }}</span>
                    <span class="transparent">/</span>
                    {{ a.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Fahrzeug</mat-label>
            <mat-select [disabled]="!vehicles" [(ngModel)]="connection.troId">
                <ng-container *ngIf="vehicles">
                    <mat-optgroup label="Nicht Verwendet" *ngIf="unusedVehicles.length > 0">
                        <ng-container *ngFor="let v of unusedVehicles">
                            <mat-option *ngIf="!v.used" [value]="v.troId">
                                <span class="vehicle-info mat-caption">{{ v.troId }}</span>
                                <span class="transparent">/</span>
                                {{ v.licensePlate || '' }}
                                <span class="transparent">/</span>
                                <span class="mat-caption">IMEI: {{ v.imei }}</span>
                            </mat-option>
                        </ng-container>
                    </mat-optgroup>
                    <mat-optgroup label="Bereits verwendet" *ngIf="usedVehicles.length > 0">
                        <ng-container *ngFor="let v of usedVehicles">
                            <mat-option *ngIf="v.used" [value]="v.troId">
                                <span class="vehicle-info mat-caption">{{ v.troId }}</span>
                                <span class="transparent">/</span>
                                {{ v.licensePlate || '' }}
                                <span class="transparent">/</span>
                                <span class="mat-caption">IMEI: {{ v.imei }}</span>
                            </mat-option>
                        </ng-container>
                    </mat-optgroup>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
    <br>
    <div>
        <mat-checkbox [(ngModel)]="showDetails">Fahrzeugdetails anzeigen</mat-checkbox>
        <div class="details" *ngIf="!vehicle && showDetails">
            <h3>Fahrzeug Info</h3>
            <p>Bitte ein Fahrzeug auswählen, um Details anzuzeigen.</p>
        </div>
        <div class="details" *ngIf="vehicle && showDetails">
            <h3>Fahrzeug Info</h3>
            <table class="table w-auto">
                <thead>
                <tr>
                    <th>Feld</th>
                    <th>Wert</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let vDat of (vehicle.data | keyvalue)">
                    <td>{{ vDat.key }}</td>
                    <td>{{ vDat.value }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
