<table mat-table [dataSource]="dataSource" class="full-width-table" matSort>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
        </td>
    </ng-container>

    <ng-container matColumnDef="fleet">
        <th mat-header-cell *matHeaderCellDef class="col-fleet">Flotte</th>
        <td mat-cell *matCellDef="let element" class="col-fleet">
            <span *ngIf="element.fleetId">{{getFleetName(element)}} ({{element.fleetId}})</span>
            <span *ngIf="!element.fleetId">---</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef class="col-time">Zeit</th>
        <td mat-cell *matCellDef="let element" class="col-time">
            {{element.tst | age:MAX_NUM}}<br>
            <mat-hint>{{element.tst | date:"full"}}</mat-hint>
        </td>
    </ng-container>

    <ng-container matColumnDef="cpi">
        <th mat-header-cell *matHeaderCellDef class="col-cpi">CPI</th>
        <td mat-cell *matCellDef="let element" class="col-cpi">
            {{element.cpi}}
        </td>
    </ng-container>

    <ng-container matColumnDef="remote">
        <th mat-header-cell *matHeaderCellDef class="col-remote">IP-Adresse</th>
        <td mat-cell *matCellDef="let element" class="col-remote">
            {{element.remoteIp}}
        </td>
    </ng-container>

    <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef class="col-model">Model</th>
        <td mat-cell *matCellDef="let element" class="col-model">
            {{getModel(element)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="serial">
        <th mat-header-cell *matHeaderCellDef class="col-serial">Seriennummer</th>
        <td mat-cell *matCellDef="let element" class="col-serial">
            {{getSerial(element)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="firmware">
        <th mat-header-cell *matHeaderCellDef class="col-firmware">Firmware Version</th>
        <td mat-cell *matCellDef="let element" class="col-firmware">
            {{getFirmware(element)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions">Aktionen</th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button (click)="showDetails(element)" mat-icon-button matTooltip="Details anzeigen"><mat-icon>info</mat-icon></button>
            <button (click)="createStation(element)" mat-icon-button matTooltip="Station anlegen"><mat-icon>control_point</mat-icon></button>
            <button (click)="deleteEntry(element)" mat-icon-button matTooltip="Entfernen"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
