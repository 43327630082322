<mat-dialog-content class="form-field-container">
    <h2 class="mat-h2">Aktion ausführen</h2>
    <mat-form-field>
        <mat-label>Aktion</mat-label>
        <mat-select [(ngModel)]="selectedAction" [disabled]="executed">
            <mat-option *ngFor="let action of vehicle.supportedActions" [value]="action">{{action.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field *ngFor="let param of selectedAction?.params; let i = index">
        <mat-label>{{param}}</mat-label>
        <input matInput [(ngModel)]="paramValues[i]" [disabled]="executed">
    </mat-form-field>
    <mat-spinner *ngIf="running" ></mat-spinner>
    <div *ngIf="executed && !running">Befehl wurde gespeichert und wird beim nächsten Kontakt mit dem Fahrzeug ausgeführt</div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex flex-row justify-content-end gap-3" >
    <button
        mat-raised-button color="primary"
        type="submit"
        [disabled]="executed || !selectedAction"
        (click)="execute()"
    >
        Absenden
    </button>
    <button
        mat-raised-button color="accent"
        type="submit"
        (click)="close()"
    >
        Dialog schließen
    </button>
</mat-dialog-actions>
