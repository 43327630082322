import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {PasswordResetTokenValidation, Permission, User} from '@io-elon-common/frontend-api';
import {ApiService} from '../../services/api-handlers/api.service';
import {CacheManager, CacheUpdater} from '../../services/api-handlers/cacheManager';
import {LOGIN_STATE, POLL_INTERVALS} from "../../app.module";

@Injectable()
export class AuthService extends CacheManager{
    private currentUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
    private userCache: CacheUpdater<User, number>

    private routeAfterLogin = "/fleet";

    get currentUserSubj(): BehaviorSubject<User | null> {
        return this.currentUser;
    }

    get user(): User | null {
        return this.currentUser.getValue();
    }

    get permissions(): Permission[] {
        let user = this.user;
        if (user==null || user.permissionGroups == undefined) return [];

        return ([] as Permission[]).concat(...user.permissionGroups?.map(pg=>pg.permissions));
    }

    constructor(
        private router: Router,
        private apiService: ApiService,
        @Inject(LOGIN_STATE) private loggedIn: BehaviorSubject<boolean>
    ) {
        super(POLL_INTERVALS.auth);
        this.userCache = this.createManagedCache(() => true)
        this.userCache
            .getOrCreateGet(0, ()=>this.apiService.getAuth(true).toPromise())
            .data.subscribe(value => {
                if(!value || value.id === -1) {
                    this.currentUser.next(null);
                    if(this.loggedIn.getValue()) {
                        this.loggedIn.next(false);
                    }
                } else {
                    this.currentUser.next(value);
                    if(!this.loggedIn.getValue()) {
                        this.loggedIn.next(true);
                    }
                }
            });

        this.loggedIn.subscribe(value => {
            if(value){
                const urlTree = this.router.parseUrl(this.routeAfterLogin);
                // noinspection JSIgnoredPromiseFromCall
                this.router.navigate([this.routeAfterLogin.split('?')[0]], {
                    queryParams: urlTree.queryParams
                });
            } else if(this.router.url.split('?')[0] !== "/reset") {
                // noinspection JSIgnoredPromiseFromCall
                this.router.navigate(['/login']);
            }
        })
    }


    async login(user: string, pass: string) {
        await this.apiService.postAuth(true,{
            username: user,
            passwordHash: pass
        }).toPromise();
        await this.userCache.update();
    }


    async requestPasswordReset(email: string) {
        await this.apiService.postPasswordForgotten(true, {email: email}).toPromise();
    }

    async resetPassword(token: string, newPassword: string) {
        await this.apiService.postPasswordChange(true, token, {token: token, password: newPassword}).toPromise();
    }

    async validateToken(token: string): Promise<PasswordResetTokenValidation> {
        return this.apiService.postPasswordResetToken(true, {token: token}).toPromise();
    }

    async logout() {
        await this.apiService.postAuth(true, {
            username: "logout",
            passwordHash: "invalid"
        }).toPromise();
        await this.userCache.update();
    }

    setPostLoginUrl(url: string) {
        this.routeAfterLogin = url;
    }

    public isDeveloper(): boolean {
        return this.permissions.some(p => p.type.name === "DEVELOPER_PERMISSION")
    }

    public isAdmin(): boolean {
        return this.permissions.some(p => p.type.name === "ADMIN_PERMISSION" || p.type.name === "DEVELOPER_PERMISSION")
    }

    public hasFleetPermission(permissionType: string, fleetId: number) : boolean {
        if (fleetId === -1) return false;
        return this.permissions.filter(p => p.type.name === permissionType).some(p=>p.fleet?.id===fleetId);
    }

    /* Only looks for Permission Type, no specific object, no permission inheritance*/
    public hasGlobalPermission(permissionType: string): boolean {
        return this.hasOneOfGlobalPermissions(permissionType);
    }

    /* Only looks for Permission Type, no specific object, no permission inheritance*/
    public hasOneOfGlobalPermissions(...permissionTypes: string[]): boolean {
        if(permissionTypes.length === 0) {
            return false;
        }

        if(this.isAdmin()) {
            if(this.isDeveloper()) {
                return true;
            }
            for(const p of permissionTypes) {
                switch (p) {
                    case "VIEW_API":
                        // ViewAPI is special, and not available even to admins if not allowed.
                        const apiAccess = this.permissions.some(p => p.type.name === "VIEW_API");
                        if(apiAccess) {
                            return true;
                        }
                        break;
                    case "DEVELOPER_PERMISSION":
                        // We checked this before
                        break;
                    default:
                        // All other permissions are available to admins
                        return true
                }
            }
            return false;
        }
        const permissionsToCheck = [...permissionTypes]
        return this.permissions.some(p => p.impl === "GLOBAL" && permissionsToCheck.indexOf(p.type.name) !== -1);
    }

    /* Only looks for Permission Type, no specific object, no permission inheritance*/
    public hasAllPermissions(...permissionTypes: string[]): boolean {
        if(permissionTypes.length === 0) {
            return true;
        }

        if(this.isAdmin()){
            if(this.isDeveloper()) {
                return true;
            }
            if(permissionTypes.some(p => p === "VIEW_API")) {
                // ViewAPI is special, and not available even to admins if not allowed.
                return this.permissions.some(p => p.type.name === "VIEW_API");
            }
            return true;
        }
        return permissionTypes.every(pt => this.permissions.some(p => p.type.name === pt));
    }
}
