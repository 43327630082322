<div cdk-overlay-origin #trigger="cdkOverlayOrigin" class="d-flex flex-column gap-2 justify-content-between align-items-start" (mouseenter)="isOpen = true" (mouseleave)="isOpen = false">
    <span>
        <i [class]="getIcon()"></i>
        <span>{{getText()}}</span>
    </span>
    <span class="light">
        {{((reason?.phases.length ?? 3) * reason?.ampsCp * 235 / 1000).toFixed(1)}}kW@switch (reason.limitedBy) {
            @case ("PEAK") {, Begrenzt durch Peak}
            @case ("FUSE") {, Begrenzt durch Sicherung}
            @case ("SLOW_CHARGE_DETECTION") {, Langsam Laden wurde erkannt}
            @case ("EVSE_VEHICLE") {, Maximum}
            @case ("SOLAR") {, Begrenzt durch Solar}
            @case ("BATTERY_HEALTH") {, Akku-schonend}
            @case ("BATTERY_FULL") {, Akku voll}
        }
    </span>
</div>

<ng-template cdk-connected-overlay
             [cdkConnectedOverlayOpen] = "isOpen"
             [cdkConnectedOverlayOrigin]="trigger"
             [cdkConnectedOverlayPositions]="[
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetY: 3
                    }
                 ]"
    ><div class="megatooltip">
        <div class="megatooltip-header">
            <div class="megatooltip-header-item"><div><strong>Geplant</strong></div><div>{{reason.phases.length ?? 3}}x {{reason.ampsCp.toFixed(1)}}A</div><div>&wedgeq; {{((reason.phases.length ?? 3) * reason.ampsCp * 235 / 1000).toFixed(1)}}kW</div></div>
            @if(reason.limitedBy && reason.limitedBy != "FUSE") {
                <div class="megatooltip-header-item">
                    <div><strong>Begrenzend</strong></div>
                    @switch (reason.limitedBy) {
                        @case ("PEAK") {
                            <mat-icon class="iwhite" svgIcon="peak" style="transform: scale(1.5); height: 16px"></mat-icon>
                            <div>Peak</div>
                        }
                        @case ("SLOW_CHARGE_DETECTION") {
                            <mat-icon class="iwhite" style="transform: scale(1.5) translateY(3px); height: 16px; font-size: 1em">filter_list</mat-icon>
                            <div>Langsam Laden erkennen</div>
                        }
                        @case ("EVSE_VEHICLE") {
                            <mat-icon class="iwhite" svgIcon="ladestation" style="transform: scale(1.2) translateY(3px); height: 16px"></mat-icon>
                            <mat-icon class="iwhite" style="transform: scale(1.5) translateY(3px); height: 16px; font-size: 1em">directions_car</mat-icon>
                            <div>Hardware-Maximum</div>
                        }
                        @case ("SOLAR") {
                            <mat-icon class="iwhite" style="transform: scale(1.5) translateY(3px); height: 16px; font-size: 1em">wb_sunny</mat-icon>
                            <div>Solar</div>
                        }
                        @case ("BATTERY_HEALTH") {
                            <mat-icon class="iwhite" svgIcon="battery_health" style="transform: scale(1.5); height: 16px"></mat-icon>
                            <div>Akku-Gesundheit</div>
                        }
                        @case ("BATTERY_FULL") {
                            <mat-icon class="iwhite" svgIcon="battery_4" style="transform: scale(1.5); height: 16px"></mat-icon>
                            <div>Akku voll</div>
                        }
                    }
                </div>
            }
            @if(reason.limitedByFuses?.length > 0 || reason.limitedBy == "FUSE") {
                <div class="megatooltip-header-item">
                    <div><strong>Begrenzend</strong></div>
                    <mat-icon class="iwhite" style="transform: scale(1.5) translateY(3px); height: 16px; font-size: 1em">account_tree</mat-icon>
                    <div>Sicherung {{getFuseNames()}}</div>
                </div>
            }
        </div>
        @for(line of getTooltip(); track line) {
            {{line}}<br>
        }
    </div>
</ng-template>
