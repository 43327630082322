import {Component, Input} from '@angular/core';
import {ExecuteVehicleKeyAddOrUpdate, User, Vehicle} from '@io-elon-common/frontend-api';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {BehaviorSubject} from 'rxjs';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {UserService} from '../../../users/service/user.service';

const HOUR = 1000 * 60 * 60;

@Component({
    selector: 'app-edit-vehicle-key-dialog',
    templateUrl: './edit-vehicle-key-dialog.component.html',
    styleUrls: ['./edit-vehicle-key-dialog.component.scss']
})
export class EditVehicleKeyDialogComponent implements IEditForm<ExecuteVehicleKeyAddOrUpdate>{

    @Input()
    public data!: ExecuteVehicleKeyAddOrUpdate;
    public vehicles: BehaviorSubject<Vehicle[] | undefined>;
    public users: BehaviorSubject<User[] | undefined>;

    constructor(
        private readonly vehicleService: VehicleService,
        private readonly userService: UserService
    ) {
        this.vehicles = this.vehicleService.getAll();
        this.users = this.userService.getAll();
    }

    validate(): string[] {
        const err: string[] = [];
        if(this.data.vehicleId == null) {
            err.push("Fahrzeug muss ausgewählt werden!");
        }
        if(!this.data.currentLocation) {
            err.push("Es muss ein aktueller Ort eingegeben werden!");
        }
        return err;
    }

    public get givenOut(): boolean {
        return this.data.giveOutTime != null;
    }

    public set givenOut(val: boolean) {
        if(val) {
            this.data.giveOutTime = Date.now();
            this.data.expectedReturnTime = Date.now() + 3 * HOUR;
        } else {
            this.data.giveOutTime = undefined;
            this.data.expectedReturnTime = undefined;
        }
    }

    public get hasReturnTime(): boolean {
        return this.data.expectedReturnTime != null
    }

    public set hasReturnTime(val: boolean) {
        if(val) {
            this.data.expectedReturnTime = Date.now() + 3 * HOUR;
        } else {
            this.data.expectedReturnTime = undefined;
        }
    }
}
