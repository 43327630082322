<mat-expansion-panel [expanded]="panelOpenState"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Filter:
        </mat-panel-title>
        <mat-panel-description>
            Datumsbereich: <span class="query-text-value">{{queryText('dateRange')}},</span>
            Standort: <span class="query-text-value">{{queryText('locations')}},</span>
            Ladepunkt: <span class="query-text-value">{{queryText('evses')}},</span>
            Fahrzeug: <span class="query-text-value">{{queryText('vehicles')}},</span>
            RFID: <span class="query-text-value">{{queryText('rfids')}},</span>
            Kostenstelle: <span class="query-text-value">{{queryText('kostenstelle')}}</span>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="input-rows">
        <div>
            <mat-form-field appearance="fill" class="selection-field">
                <mat-label>Schnelle Datumsauswahl</mat-label>
                <mat-select>
                    <mat-option *ngFor="let category of dateRange" [value]="category.value"
                        (click)="changeDateRangeSelection(category.value)">{{category.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="selection-field">
                <mat-label>Datumsbereich</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill" class="selection-field">
                <mat-label>Standort</mat-label>
                <mat-select [(ngModel)]="currentSelectedLocations" multiple>
                    <mat-select-trigger>
                        <span>
                            {{getDropDowntext(currentSelectedLocations, allFilterOptions?.locations)}}
                        </span>
                    </mat-select-trigger>
                    <mat-option [value]="SELECT_ALL" (click)="toggleAllSelection('locations')">Alles</mat-option>
                    <mat-option *ngFor="let location of allFilterOptions?.locations" [value]="location.id"
                                (click)="toggleSelectedOption('locations')">
                        {{location.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="selection-field">
                <mat-label>Ladepunkt</mat-label>
                <mat-select [(ngModel)]="selectedEvses" multiple>
                    <mat-select-trigger>
                        <span>
                            {{getDropDowntext(selectedEvses, allFilterOptions?.evses)}}
                        </span>
                    </mat-select-trigger>
                    <mat-option (click)="toggleAllSelection('evses')" [value]="SELECT_ALL">Alles</mat-option>
                    <mat-option (click)="toggleSelectedOption('evses')" [value]="SELECT_UNDEFINED">Fremde Stationen</mat-option>
                    <mat-optgroup *ngFor="let group of evses" [disabled]="group.disabled">
                        <span style="font-weight: bold">{{ group.name }}</span>
                        <mat-option *ngFor="let option of group.options" [value]="option.id"
                            (click)="toggleSelectedOption('evses')">
                            {{option.name}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="selection-field">
                <mat-label>Fahrzeug</mat-label>
                <mat-select [(ngModel)]="selectedVehicles" multiple>
                    <mat-select-trigger>
                        <span>
                            {{getDropDowntext(selectedVehicles, allFilterOptions?.vehicles)}}
                        </span>
                    </mat-select-trigger>
                    <mat-option (click)="toggleAllSelection('vehicles')" [value]="SELECT_ALL">Alles</mat-option>
                    <mat-option (click)="toggleSelectedOption('vehicles')" [value]="SELECT_UNDEFINED">Gastfahrzeuge</mat-option>
                    <mat-optgroup *ngFor="let group of vehicles" [disabled]="group.disabled">
                        <span style="font-weight: bold">{{ group.name }}</span>
                        <mat-option *ngFor="let option of group.options" [value]="option.id"
                            (click)="toggleSelectedOption('vehicles')">
                            {{option.name}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill" class="selection-field">
                <mat-label>RFID</mat-label>
                <mat-select [(ngModel)]="selectedRfids" multiple>
                    <mat-select-trigger>
                        <span>
                            {{getDropDowntext(selectedRfids, allFilterOptions?.rfids)}}
                        </span>
                    </mat-select-trigger>
                    <mat-option (click)="toggleAllSelection('rfids')" [value]="SELECT_ALL">Alles</mat-option>
                    <mat-option (click)="toggleSelectedOption('rfids')" [value]="SELECT_UNDEFINED">Ohne RFID</mat-option>
                    <mat-option *ngFor="let rfid of allFilterOptions?.rfids" [value]="rfid.id"
                                (click)="toggleSelectedOption('rfids')">
                        {{rfid.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="selection-field">
                <mat-label>Kostenstelle</mat-label>
                <mat-select [(ngModel)]="selectedKostenstelle" multiple>
                    <mat-select-trigger>
                        <span>
                            {{getDropDowntext(selectedKostenstelle, allFilterOptions?.kostenstelle)}}
                        </span>
                    </mat-select-trigger>
                    <mat-option (click)="toggleAllSelection('kostenstelle')" [value]="SELECT_ALL">Alles</mat-option>
                    <mat-option (click)="toggleSelectedOption('kostenstelle')" [value]="SELECT_UNDEFINED">Ohne Kostenstelle</mat-option>
                    <mat-option *ngFor="let kostenstelle of allFilterOptions?.kostenstelle"
                        [value]="kostenstelle.id" (click)="toggleSelectedOption('kostenstelle')">
                        {{kostenstelle.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-expansion-panel>

<div class="d-flex flex-row justify-content-center w-100" style="padding-top: 10px;">
    <button mat-raised-button color="primary" (click)="search()">Suche</button>
</div>


<div class="w-100" *ngIf="filteredData !== undefined" style="padding-top: 30px;">
    <app-charging-session-table class="with-background" [data]="filteredData"></app-charging-session-table>
</div>
