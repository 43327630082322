<h3> Icons </h3>

<table>
    <tr>
        <th>Name</th>
        <th *ngFor="let color of colors" >{{color}}</th>
        <th *ngFor="let color of colors" class="bg-dark">{{color}}</th>
        <th *ngFor="let color of colors" class="bg-blue-1">{{color}}</th>
    </tr>
    <tr *ngFor="let icon of icons">
        <td>{{icon}}</td>
        <td *ngFor="let color of colors">
            <mat-icon [class]="color" [svgIcon]="icon"></mat-icon>
        </td>
        <td *ngFor="let color of colors" class="bg-dark">
            <mat-icon [class]="color" [svgIcon]="icon"></mat-icon>
        </td>
        <td *ngFor="let color of colors" class="bg-blue-1">
            <mat-icon [class]="color" [svgIcon]="icon"></mat-icon>
        </td>
    </tr>
</table>
<hr>
<button *ngIf="!showMaterialIcons" (click)="showMaterialIcons = true" mat-raised-button>Show Material Icons</button>
<table *ngIf="showMaterialIcons">
    <tr>
        <th>Name</th>
        <th *ngFor="let color of colors" >{{color}}</th>
        <th *ngFor="let color of colors" class="bg-dark">{{color}}</th>
        <th *ngFor="let color of colors" class="bg-blue-1">{{color}}</th>
    </tr>
    <tr *ngFor="let icon of matIcons">
        <td>{{icon}}</td>
        <td *ngFor="let color of colors">
            <mat-icon [class]="color">{{icon}}</mat-icon>
        </td>
        <td *ngFor="let color of colors" class="bg-dark">
            <mat-icon [class]="color">{{icon}}</mat-icon>
        </td>
        <td *ngFor="let color of colors" class="bg-blue-1">
            <mat-icon [class]="color">{{icon}}</mat-icon>
        </td>
    </tr>
</table>
