<div *ngIf="!closed">
    <div role="alert" [class]="cssClass">
        <div class="d-flex justify-content-start gap-3">
            <div>
                <mat-icon class="icon">{{icon}}</mat-icon>
            </div>
            <div>
                <ng-content></ng-content>
            </div>
        </div>
            <div [class]="buttonTypeCssClass">
                <mat-icon class="close-button" (click)="close()" *ngIf="closable">close</mat-icon>
            </div>
    </div>
</div>
