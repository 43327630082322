import {Injectable} from '@angular/core';
import {
    ExecuteSolarPanelAddOrUpdate,
    SolarPanel,
    SolarSystem
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {EditSolarPanelDialogComponent} from "../dialogs/edit-solar-panel-dialog/edit-solar-panel-dialog.component";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
    providedIn: 'root'
})


export class SolarPanelService extends DialogHandler<SolarPanel, ExecuteSolarPanelAddOrUpdate, ExecuteSolarPanelAddOrUpdate, {}, SolarSystem[], SolarSystem[]> {

    public constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "SolarPanel", toastr, dialog, dialogService, POLL_INTERVALS.solarPanel);
    }

    // tslint:disable-next-line:max-line-length
    protected getEditConfig(solarPanel: SolarPanel, solarSystems: SolarSystem[]): TDialogOptions<ExecuteSolarPanelAddOrUpdate, EditSolarPanelDialogComponent> {
        return {
            headline: "Solar-Panel bearbeiten",
            component: EditSolarPanelDialogComponent,
            executeCallback: editResult => this.update(solarPanel.id, editResult),
            editElement: {
                solarSystemId: solarPanel.solarSystemId,
                longitude: solarPanel.longitude || 0,
                latitude: solarPanel.latitude || 0,
                installedPower: solarPanel.installedPower || 0,
                tilt: solarPanel.tilt || 0,
                azimuth: solarPanel.azimuth || 0,
                height: solarPanel.height,
            },
            extraParams: {
                possibleSolarSystems: solarSystems
            }
        }
    }

    protected getNewConfig(solarSystems: SolarSystem[]): TDialogOptions<ExecuteSolarPanelAddOrUpdate, EditSolarPanelDialogComponent> {
        return {
            headline: "Solar-Panel hinzufügen",
            component: EditSolarPanelDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                solarSystemId: solarSystems[0].id,
                longitude: solarSystems[0].basis? solarSystems[0].basis.lon : 0.0,
                latitude: solarSystems[0].basis? solarSystems[0].basis.lat : 0.0,
                installedPower: 0,
                tilt: 0.0,
                azimuth: 0.0,
                height: 0
            },
            extraParams: {
                possibleSolarSystems: solarSystems
            }
        }
    }
}
