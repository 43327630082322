<h1>Service Status</h1>

Filter:
<input [(ngModel)]="filter" (keyup.enter)="saveFilter()">
<br>
<mat-hint>Enter Drücken um Filterbegriff zu speichern.</mat-hint><br>
<ng-container *ngFor="let val of log">
    <a class="log-entry" (click)="filter = val" >{{val}}</a><br>
</ng-container>

<div *ngIf="(statusFilter | async) as serviceStatus; else loading">

    <table class="table table-striped">
        <thead>
        <tr>
            <th>Name</th>
            <th colspan="2">Status</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let service of serviceStatus | keyvalue">
            <tr>
                <td class="pb-0">{{service.value?.name}}</td>
                <td class="pb-0" colspan="2">Alive: {{service.value?.alive}}, Restarts: {{service.value?.restartCounter}}</td>
            </tr>
            <tr *ngFor="let i of service.value?.info">
               <td class="p-0"></td>
               <td class="p-0">
                   <span [ngSwitch]="i.status">
                       <span *ngSwitchCase="'ERROR'">🔴</span>
                       <span *ngSwitchCase="'WARN'">🟡</span>
                       <span *ngSwitchCase="'HEALTHY'">🟢</span>
                       <span *ngSwitchCase="'INFO'">🔵</span>
                   </span>
                   {{i.key}}
               </td>
               <td class="p-0">{{i.value}}</td>
            </tr>

        </ng-container>
        </tbody>
    </table>
</div>
<ng-template #loading>
    Loading...
</ng-template>
