import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {FormControl, FormGroup} from '@angular/forms';
import {AbstractHistoryGraph} from '../abstract-history-graph/abstract-history-graph';

@Component({
    selector: 'app-graph-date-picker',
    templateUrl: './graph-date-picker.component.html',
    styleUrls: ['./graph-date-picker.component.scss']
})
export class GraphDatePickerComponent implements OnInit {

    @Input() graph!: AbstractHistoryGraph<any>;

    public range = new FormGroup({
        start: new FormControl(),
        end: new FormControl()
    });

    public today = moment();

    public constructor() {
    }

    public ngOnInit(): void {
        this.update();
    }

    public update(): void {
        this.range.setValue({
            start: moment(this.graph.start),
            end: moment(this.graph.end)
        })
    }

    public refresh(): void {
        this.graph.updateToRange(
            this.range.get("start")?.value.valueOf(),
            this.range.get("end")?.value.clone().endOf('day').valueOf(),
            true,
            true
        );
    }
}
