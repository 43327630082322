<!--<apx-chart
  [series]="chartOptions.series"
  [chart]="chartOptions.chart"
  [plotOptions]="chartOptions.plotOptions"
  [labels]="chartOptions.labels"
  [fill]="chartOptions.fill"
  [stroke]="chartOptions.stroke"
  [colors]="chartOptions.colors"
></apx-chart>-->

<circle-progress [percent]="percent"
                 [title]="value ? num(value).toFixed(fractionDigits) : '--'"
                 [space]="-11"
                 [units]="' ' + unit + (showMax ? '/ ' + max + ' ' + unit : '')"
                 [outerStrokeGradient]="true"
                 [outerStrokeWidth]="11"
                 [innerStrokeWidth]="11"
                 [outerStrokeColor]="outerStrokeColor"
                 [outerStrokeGradientStopColor]="outerStrokeGradientStopColor"
                 [outerStrokeLinecap]="'butt'"
                 [innerStrokeColor]="'#eeeeee'"
                 [animation]="false"
                 [animationDuration]="300"
                 [startFromZero]="false"
                 [subtitle]="label"
                 [subtitleFontSize]="'13'"></circle-progress>
