<h1>Zähler Verbindungsdetails</h1>
<div *ngIf="(meterConnections | async) as meterConnections; else loading">

    <table>
        <tr>
            <th>Id</th>
            <th>Typ</th>
            <th>Abfrage-Intervall</th>
            <th>Nächste Abfrage um</th>
            <th>Abfrage-Status</th>
        </tr>
        <tr *ngFor="let meterConnection of meterConnections.list">
            <td>{{meterConnection.id}}</td>
            <td>{{meterConnection.type}}</td>
            <td>{{meterConnection.interval / 1000}}s</td>
            <td>{{meterConnection.nextPoll | date:'HH:mm:ss.SSSS'}}</td>
            <td>{{meterConnection.requestState}}</td>
        </tr>
    </table>
</div>
<ng-template #loading>
    Loading...
</ng-template>
