<mat-dialog-content>
    <div class="d-flex flex-column gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Zeitzone</mat-label>
            <input #input
                   type="text"
                   matInput
                   [matAutocomplete]="auto"
                   [formControl]="timeZoneFromControl">
            <mat-autocomplete #auto="matAutocomplete">
                @for (timezone of filteredTimeZone | async; track timezone) {
                    <mat-option [value]="timezone">{{timezone}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="buttons d-flex flex-row justify-content-end gap-3">
        <button mat-raised-button color="primary" (click)="cancel()">Abrechen</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="timeZone">OK</button>
    </div>
</mat-dialog-content>


