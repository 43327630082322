import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Fleet, Load} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {LoadService} from '../../service/load.service';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-load-table',
  templateUrl: './load-table.component.html',
  styleUrls: ['./load-table.component.scss']
})
export class LoadTableComponent extends AbstractTableComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() loads!: Load[];

    public displayedColumns: string[] = ['id', 'name', 'power_supply', 'actions'];
    public fleet!: BehaviorSubject<Fleet | undefined>
    private fleetIdSubscription?: Subscription;
    public dataSource = new MatTableDataSource<Load>([]);
    public selection = new SelectionModel<Load>(true, []);

    public constructor(
        private readonly loadService: LoadService,
        private readonly fleetService: FleetService,
        private readonly toastr: ToastrService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.loads);
        this.dataSource.paginator = this.paginator;
        this.fleetIdSubscription = this.fleetService.selectedFleet.subscribe(sf => {
            if(sf !== undefined) {
                this.fleet = this.fleetService.get(sf);
            }
        })
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.loads;
    }

    public ngOnDestroy(): void {
        this.fleetIdSubscription?.unsubscribe();
    }

    public async handleEditLoad(load: Load): Promise<void> {
        const powerSupplies =  this.fleet.getValue()?.base.powerSupplies;
        if(powerSupplies === undefined){
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        await this.loadService.showEditDialog(load, powerSupplies);
    }
    public async handleDeleteLoad(load: Load): Promise<void> {
        await this.loadService.showDeleteDialog(load,{});
    }
}
