import {Component} from '@angular/core';
import {AbstractUserDialogDirective} from '../abstract-user-dialog.directive';


@Component({
    selector: 'app-edit-user-dialog',
    templateUrl: './edit-user-dialog.component.html',
    styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent extends AbstractUserDialogDirective{
}
