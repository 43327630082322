<div class="d-flex flex-column gap-3">
    <mat-form-field appearance="fill" *ngIf="(vehicles | async) as vehicles; else loading">
        <mat-label>Fahrzeug</mat-label>
        <mat-select [(value)]="data.vehicleId">
            <mat-option [value]="null">Keinem Fahrzeug zuordnen</mat-option>
            <mat-option *ngFor="let v of vehicles" [value]="v.id">{{v.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="(accounts | async) as accounts; else loading">
        <mat-label>Tesla Account</mat-label>
        <mat-select [(value)]="data.teslaAccountId" (selectionChange)="updateVehiclesInAccount()">
            <mat-option *ngFor="let a of accounts" [value]="a.id">{{a.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="(vehiclesInAccount | async) as vehiclesInAccount; else selectAccount">
        <mat-label>Fahrzeug im Tesla Account</mat-label>
        <mat-select [(value)]="data.vehicleId">
            <mat-option *ngFor="let v of vehiclesInAccount" [value]="v.teslaVehicleId">{{v.displayName + " " + v.state + " " + v.id + " " + v.teslaVehicleId}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<ng-template #loading>Loading...</ng-template>
<ng-template #selectAccount>Account auswählen...</ng-template>
