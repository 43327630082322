import {Component, Input, OnInit} from '@angular/core';
import {IEditForm} from "../edit-dialog/edit-dialog.component";
import {Customer, ExecuteCustomerUpdate} from "@io-elon-common/frontend-api";
import {CustomerService} from "../../../../services/api-handlers/customer.service";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-edit-customer',
    templateUrl: './edit-customer.component.html',
    styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit, IEditForm<ExecuteCustomerUpdate> {

    @Input()
    data!: ExecuteCustomerUpdate;
    customers: BehaviorSubject<Customer[] | undefined>

    constructor(
        private readonly customerService: CustomerService
    ) {
        this.customers = customerService.getAll();
    }

    ngOnInit(): void {
    }

    validate(): string[] {
        if(!this.data.name) {
            return ["Kein Name eingegeben"];
        }
        return [];
    }
}
