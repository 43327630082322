export class SinglePhaseElectricity {
    private readonly volts: number
    private readonly amps: number
    private readonly phi: number //phase shift in radians

    public static NET_ZERO = new SinglePhaseElectricity(235, 0, 0);

    constructor(volts: number, amps:number, phi:number) {
        this.volts = volts;
        this.amps = amps;
        this.phi = phi
    }

    static fromAmpsAndWatts(amps: number, watts:number): SinglePhaseElectricity { // assumes no phase shift
        const volts = watts / amps;
        return new SinglePhaseElectricity(volts, amps, 0)
    }

    static fromVoltsAndWatts(volts: number, watts: number) {// assumes no phase shift
        if (watts == 0) {
            return this.NET_ZERO;
        }
        const amps = watts / volts;
        return new SinglePhaseElectricity(volts, amps, 0)
    }
    static fromAmpsAndVolts(amps: number, volts:number):SinglePhaseElectricity { // assumes no phase shift
        return new SinglePhaseElectricity(volts, amps, 0)
    }
    static fromAmpsAndVoltsAndCosPhi(amps: number, volts: number, cosPhi: number) { // assumes phase shift between 0 and +180 deg
        return new SinglePhaseElectricity(volts, amps, Math.acos(cosPhi))
    }

    static fromVoltsAndWattsAndVAR(volts: number, watts: number, voltAmpsReactive: number) {
        const phi = Math.atan2(voltAmpsReactive, watts);
        const voltAmps = Math.sqrt(Math.pow(watts, 2) + Math.pow(voltAmpsReactive, 2));
        const amps = voltAmps / volts;
        return new SinglePhaseElectricity(volts, amps, phi);
    }

    getWatts(): number { // true power, german: Wirkleistung
        return this.volts * this.amps * Math.cos(this.phi)
    }
    getVAR(): number { // VAR = Volts-Amps-Reactive, reactive power, german: Blindleistung
        return this.volts * this.amps * Math.sin(this.phi)
    }
    getVA(): number { // VA = Volt-Amps, apparent power, german: Scheinleistung
        return this.volts * this.amps
    }
    getAmps(): number {
        return this.amps
    }
    getVolts(): number {
        return this.volts
    }
    getPhi(): number {
        return this.phi;
    }
    getPhiDeg(): number {
        return this.phi / Math.PI * 180;
    }
    getCosPhi(): number {
        return Math.cos(this.phi)
    }
    getSinPhi(): number {
        return Math.sin(this.phi)
    }

    add(other: SinglePhaseElectricity): SinglePhaseElectricity { //Adds two currents on single phase. Voltage will get averaged and weighted by apparent power
        const watts = this.getWatts() + other.getWatts();
        const voltAmpsReactive = this.getVAR() + other.getVAR();
        const voltAmpsSum = this.getVA() + other.getVA();
        if (voltAmpsSum == 0) {
            return SinglePhaseElectricity.fromVoltsAndWattsAndVAR((this.volts + other.volts) / 2, watts, voltAmpsReactive);
        }
        const volts = this.volts * (this.getVA() / voltAmpsSum) + other.volts * (other.getVA() / voltAmpsSum);
        return SinglePhaseElectricity.fromVoltsAndWattsAndVAR(volts, watts, voltAmpsReactive);
    }
}
export class ThreePhaseElectricity {
    l1: SinglePhaseElectricity
    l2: SinglePhaseElectricity
    l3: SinglePhaseElectricity

    public static NET_ZERO = new ThreePhaseElectricity(SinglePhaseElectricity.NET_ZERO, SinglePhaseElectricity.NET_ZERO, SinglePhaseElectricity.NET_ZERO);

    constructor(l1: SinglePhaseElectricity, l2: SinglePhaseElectricity, l3: SinglePhaseElectricity) {
        this.l1 = l1;
        this.l2 = l2;
        this.l3 = l3;
    }

    getPSum() { // Watts, true power, german: Wirkleistung
        return this.l1.getWatts() + this.l2.getWatts() + this.l3.getWatts();
    }

    getVARSum() { // Volts-Amps-Reactive, reactive power, german: Blindleistung
        return this.l1.getVAR() + this.l2.getVAR() + this.l3.getVAR()
    }
    getPhaseAmps() {
        return new PhaseValues(this.l1.getAmps(), this.l2.getAmps(), this.l3.getAmps())
    }

    add(other: ThreePhaseElectricity) {
        return new ThreePhaseElectricity(
            this.l1.add(other.l1),
            this.l2.add(other.l2),
            this.l3.add(other.l3),
        )
    }
}

export class PhaseValues {
    l1: number
    l2: number
    l3: number

    public static ZERO: PhaseValues = new PhaseValues(0,0,0);

    public static threePhased(singlePhase: number) : PhaseValues {
        return new PhaseValues(singlePhase, singlePhase, singlePhase)
    }

    constructor(l1: number, l2: number, l3: number) {
        this.l1 = l1;
        this.l2 = l2;
        this.l3 = l3;
    }

    public sum() :number {
        return this.l1 + this.l2 + this.l3;
    }

    public max() :number {
        return Math.max(this.l1, this.l2, this.l3);
    }

    public min() :number {
        return Math.min(this.l1, this.l2, this.l3);
    }

    public add(other: PhaseValues): PhaseValues {
        return new PhaseValues(
            this.l1 + other.l1,
            this.l2 + other.l2,
            this.l3 + other.l3
        )
    }
}
