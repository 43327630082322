<div class="d-flex flex-column gap-3 align-items-stretch" [formGroup]="formGroup">
    <div class="d-flex flex-row justify-content-between gap-3">
        <mat-form-field appearance="fill" class="w-50">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
        <div class="w-50 flex-grow-0">
        </div>
    </div>
    <div class="d-flex flex-row justify-content-between gap-3">
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Breitengrad [lat] (°)</mat-label>
            <input matInput type="number" formControlName="lat">
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>L&auml;ngengrad [lng] (°)</mat-label>
            <input matInput type="number" formControlName="lon">
        </mat-form-field>
    </div>
    <div class="d-flex flex-row justify-content-between gap-3">
        <mat-form-field appearance="fill" class="w-50">
            <mat-label>Geofence-Radius(meter)</mat-label>
            <input matInput type="number" formControlName="geoFenceRadius">
        </mat-form-field>
        <div class="w-50 flex-grow-0">
        </div>
    </div>
    <mat-divider></mat-divider>
    <div>
        <mat-checkbox formControlName="dynamicElectricityCostsEnabled">dynamischer Strompreis</mat-checkbox>
    </div>
    <div class="reserveSpace d-flex flex-row gap-3">
        <mat-form-field appearance="fill" class="w-50" *ngIf="!formGroup.get('dynamicElectricityCostsEnabled')?.value">
            <mat-label>Strompreis (€/kWh)</mat-label>
            <input matInput formControlName="energyPrice">
        </mat-form-field>
        <mat-form-field appearance="fill" [hidden]="!formGroup.get('dynamicElectricityCostsEnabled')?.value" class="w-50">
            <mat-label>Konzessionsabgabe (ct/kWh)</mat-label>
            <input matInput type="number" formControlName="konzessionsabgabe">
        </mat-form-field>
        <mat-form-field appearance="fill" [hidden]="!formGroup.get('dynamicElectricityCostsEnabled')?.value" class="w-50">
            <mat-label>Netzentgelt (ct/kWh)</mat-label>
            <input matInput type="number" formControlName="netzentgelt">
        </mat-form-field>
    </div>
    <div>
        <mat-checkbox matTooltip="Nachdem durch das Klicken in unserer Oberfläche ein Ladevorgang vom Benutzer gestoppt wurde, kann eine Fortsetzung unterbunden werden. In den meisten Fällen ist es jedoch sinnvoll das Fortsetzen eines Ladevorganges zu erlauben." formControlName="allowContinue">Manuell gestoppte Ladevorgänge können Fortgesetzt werden (empfohlen)</mat-checkbox>
    </div>
    <!--
    //TODO ist beim Angular Update kaputt gegangen
    <mat-divider fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
            <mat-form-field appearance="fill">
                <mat-label>Icon</mat-label>
                <ngx-mat-file-input formControlName="icon" placeholder="Datei wählen" accept="image/png, image/jpeg"></ngx-mat-file-input>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" class = "icon">
            <img *ngIf="isImageAvailable()" [src]="data.icon" alt="Aktuell ausgewähltes Bild">
        </div>
    </div>
    -->
</div>

