import {Component, OnInit} from '@angular/core';
import {FreeToMoveDataSource} from '@io-elon-common/frontend-api';
import {FreeToMoveService} from '../../service/free-to-move.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-free-to-move-list-view',
    templateUrl: './free-to-move-list-view.component.html',
    styleUrls: ['./free-to-move-list-view.component.scss']
})
export class FreeToMoveListViewComponent implements OnInit {


    public freeToMoves!: BehaviorSubject<FreeToMoveDataSource[] | undefined>;

    constructor(
        private readonly freeToMoveService: FreeToMoveService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.freeToMoves = await this.freeToMoveService.getAll();
    }

    public async handleNewFreeToMove() {
        await this.freeToMoveService.showNewDialog(undefined);
    }
}
