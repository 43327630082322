import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    AbstractHistoryGraph,
    BackgroundLegend,
    GraphRange,
    HistoryCellData
} from '../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import {VehicleService} from '../../service/vehicle.service';
import {ApiService} from '../../../../services/api-handlers/api.service';
import {
    EventLog,
    EventLogEntry,
    ReservationInstance,
    Vehicle,
    VehicleChargingPlan,
    VehicleHistoryData
} from '@io-elon-common/frontend-api';
import {ApiHandler} from "../../../../services/api-handlers/api-handler";
import {PowerUnits} from 'src/app/shared/helper/power-units';
import Annotation = dygraphs.Annotation;
import Options = dygraphs.Options;

const HOUR = 3600 * 1000;
const DAY = 24 * HOUR;
const SHOW_EVENT_MAX_TIME = 3 * DAY;

export const PLUGGED_COLOR = 'rgba(200, 200, 200, 0.5)';
export const CHARGING_COLOR = 'rgba(93, 192, 227, 1)';

interface IVehicleGraphData {
    data: Array<Array<HistoryCellData>>;
    pluggedSlots: GraphRange[];
    chargingSlots: GraphRange[];
    reservations: ReservationInstance[];
    events: Annotation[];
}

@Component({
    selector: 'app-vehicle-history-graph',
    templateUrl: './vehicle-history-graph.component.html',
    styleUrls: ['./vehicle-history-graph.component.scss']
})
export class VehicleHistoryGraphComponent extends AbstractHistoryGraph<IVehicleGraphData> implements OnInit {

    @Input() vehicleId!: number;
    @Input() autoReload!: boolean;
    @Input() selectedUnit!: PowerUnits;
    @Output() autoReloadChange = new EventEmitter<boolean>();

    private maxY2?: Promise<number>;

    constructor(
        private readonly element: ElementRef,
        private readonly apiService: ApiService,
        private readonly vehicleService: VehicleService
    ) {
        super();
    }

    public getBackgroundLegend(): BackgroundLegend[] {
        return [
            {
                name: 'Eingesteckt',
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawHatched(canvas, x, y, w, h, PLUGGED_COLOR)
            }, {
                name: 'Laden',
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawHatched(canvas, x, y, w, h, CHARGING_COLOR)
            }, {
                name: 'Reservierung',
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawReservationBox(canvas, 3, h - 5, w - 6, 5, 5, 5)
            }, {
                name: 'Jetzt',
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawNowMarker(canvas, x + w / 2 - 1, y, 2, h)
            }
        ];
    }

    protected async getMaxY2(): Promise<number> {
        if (!this.maxY2) {
            this.maxY2 = this.vehicleService.getPromise(this.vehicleId).then(v => (v.maxAmps || 32) * 1.2);
        }
        return this.maxY2;
    }

    private createData(start: number, end: number, data: VehicleHistoryData, vehicle: Vehicle, chargeplan: VehicleChargingPlan, filteredLogEvents: Array<EventLogEntry & { multiple?: boolean }>): HistoryCellData[][] {
        const gapDistance = (end - start) / 2;
        let seriesIndex = 0;
        let seriesCount = this.selectedUnit == PowerUnits.W ? 6 : 10;
        let d;
        if (this.selectedUnit == PowerUnits.W) {
            const pDataRaw = this.sumGraphLines(data.p1, data.p2, data.p3);
            d = this.mapToArray(pDataRaw, 'time', 'val', 'min', 'max', 4, seriesCount, undefined, gapDistance);
        } else {
            const i1 = this.mapToArray(data.i1, 'time', 'val', 'min', 'max', 6, seriesCount, undefined, gapDistance);
            const i2 = this.mapToArray(data.i2, 'time', 'val', 'min', 'max', 7, seriesCount, undefined, gapDistance);
            const i3 = this.mapToArray(data.i3, 'time', 'val', 'min', 'max', 8, seriesCount, undefined, gapDistance);
            d = i1.concat(i2).concat(i3);
        }

        // Create a better looking soc/soc-est graph refs #2903
        // Interrupt soc-line where soc-estimations are
        data.socUEst.forEach(socEst => {
            data.socU.push({max: NaN, min: NaN, time: socEst.time, val: NaN});
        });
        // Connect soc-est graph to the reals socs
        data.socU.forEach(soc => {
            if (!isNaN(soc.val)) { //don't use the interruptions we added before
                data.socUEst.push({max: soc.max, min: soc.max, time: soc.time, val: soc.val});
            }
        });

        const socData = this.mapToArray(data.socU, 'time', 'val', 'min', 'max', 0, seriesCount, undefined, gapDistance);
        const planSoc = this.mapToArray(chargeplan.forecastSocU, 'time', 'val', 'min', 'max', 1, seriesCount);
        const planSocEst = this.mapToArray(data.socUEst, 'time', 'val', 'min', 'max', 1, seriesCount, undefined, gapDistance);
        const iCpData = this.mapToArray(data.iCp, 'time', 'val', 'min', 'max', 2, seriesCount, i => this.convertPowerUnits(i, vehicle.numPhases, PowerUnits.A, this.selectedUnit), gapDistance);
        let planGraphs;
        if (this.selectedUnit == PowerUnits.W) {
            planGraphs = this.mapToArray(chargeplan.forecastAmps, 'time', 'val', 'val', 'val', 3, seriesCount, i => this.convertPowerUnits(i.l1 + i.l2 + i.l3, 1, PowerUnits.A, this.selectedUnit));
        } else {
            const planI1 = this.mapToArray(chargeplan.forecastAmps, 'time', 'val', 'val', 'val', 3, seriesCount, i => this.convertPowerUnits(i.l1, 1, PowerUnits.A, this.selectedUnit));
            const planI2 = this.mapToArray(chargeplan.forecastAmps, 'time', 'val', 'val', 'val', 4, seriesCount, i => this.convertPowerUnits(i.l2, 1, PowerUnits.A, this.selectedUnit));
            const planI3 = this.mapToArray(chargeplan.forecastAmps, 'time', 'val', 'val', 'val', 5, seriesCount, i => this.convertPowerUnits(i.l3, 1, PowerUnits.A, this.selectedUnit));
            planGraphs = planI1.concat(planI2).concat(planI3);
        }
        const eventLogSeries = this.mapToArray(filteredLogEvents, 'tst', 'id', undefined, undefined, seriesCount - 1, seriesCount, () => 0, 0);

        return socData.concat(iCpData).concat(d).concat(eventLogSeries).concat(planGraphs).concat(planSoc).concat(planSocEst);
    }

    protected async loadData(start: number, end: number, showAlerts = true): Promise<IVehicleGraphData> {
        const historyRequest = this.vehicleService.getHistoryOfVehicle(this.vehicleId, start, end);
        let eventsRequest: Promise<EventLog>;
        if (end - start < SHOW_EVENT_MAX_TIME) {
            eventsRequest = this.apiService.getVehicleEvents(showAlerts, this.vehicleId, start, end, undefined, undefined, ApiHandler.customerId).toPromise();
        } else {
            // #1181 No events if zoomed out.
            eventsRequest = Promise.resolve({
                start,
                end,
                events: ([] as EventLogEntry[])
            });
        }
        const chargeplanRequest = this.apiService.getVehicleChargePlan(showAlerts, this.vehicleId, undefined, undefined, ApiHandler.customerId).toPromise();
        const reservationsRequest = this.vehicleService.getReservationsByTimePromise(this.vehicleId, start, end, showAlerts);
        const data = await historyRequest;
        const eventLog = await eventsRequest;
        const chargeplan = await chargeplanRequest;
        const reservations = (await reservationsRequest) || [];
        const filteredLogEvents = this.chunkEvents(end, start, eventLog);

        const vehicle = await this.vehicleService.getPromise(this.vehicleId);

        const pluggedSlots = this.boolHistoryToAreas(data.data.plugged as any);
        const chargingSlots = this.boolHistoryToAreas(data.data.charging as any);

        const dataMapped = this.createData(start, end, data.data, vehicle, chargeplan, filteredLogEvents);

        // @ts-ignore
        dataMapped.sort((a, b) => a[0] - b[0]);
        const dataJoined = this.joinLines(dataMapped);

        const events: Annotation[] = filteredLogEvents.map((e) => {
            return {
                series: 'Events',
                x: e.tst,
                shortText: e.key,
                text: e.key + ': ' + e.message,
                // attachAtBottom: true,
                tickHeight: -2,
                icon: e.multiple ? '/img/markerplus.png' : '/img/marker.png',
                height: 16,
                width: 16
            };
        });

        return {
            data: dataJoined,
            pluggedSlots,
            chargingSlots,
            reservations,
            events
        };
    }

    protected async getConfig(): Promise<Options> {
        return {
            errorBars: true,
            customBars: true,
            axes: {
                y2: {
                    valueRange: [0, 106],
                    axisLabelFormatter: (v: number | Date) => '<span style="color: rgb(69,165,198)">' + (v as number).toFixed(0) + ' %</span>',
                    independentTicks: true
                },
                y: {
                    valueRange: [0, Math.ceil(this.convertPowerUnits(await this.getMaxY2(), 3, PowerUnits.A, this.selectedUnit))],
                    axisLabelFormatter: (v: number | Date) => {
                        return '<span style="color: orange">' + this.getYValues(v) + '</span>';
                    },
                    independentTicks: false
                }
            },
            labels: this.getLegendLabels(),
            colors: this.getGraphColors(),
            series: {
                'Batterieladestand (%)': {
                    axis: 'y2',
                    stepPlot: false
                },
                'Batterieladestand Geplant (%)': {
                    axis: 'y2',
                    strokePattern: [2, 2],
                    drawPoints: false,
                    stepPlot: false
                },
                'Leistung Charge Pilot (W)': {
                    axis: 'y1',
                    stepPlot: true
                },
                'Leistung Geplant (W)': {
                    axis: 'y1',
                    strokePattern: [2, 2],
                    drawPoints: false,
                    stepPlot: true
                },
                'Leistung (W)': {
                    axis: 'y1',
                    stepPlot: true
                },
                'Strom Charge Pilot (A)': {
                    axis: 'y1',
                    stepPlot: true
                },
                'Strom Geplant I1 (A)': {
                    axis: 'y1',
                    strokePattern: [2, 2],
                    drawPoints: false,
                    stepPlot: true
                },
                'Strom Geplant I2 (A)': {
                    axis: 'y1',
                    strokePattern: [2, 2],
                    drawPoints: false,
                    stepPlot: true
                },
                'Strom Geplant I3 (A)': {
                    axis: 'y1',
                    strokePattern: [2, 2],
                    drawPoints: false,
                    stepPlot: true
                },
                'Strom I1 (A)': {
                    axis: 'y1',
                    stepPlot: true
                },
                'Strom I2 (A)': {
                    axis: 'y1',
                    stepPlot: true
                },
                'Strom I3 (A)': {
                    axis: 'y1',
                    stepPlot: true
                },
                Events: {
                    drawPoints: false,
                    strokeWidth: 0
                }
            },
            underlayCallback: (canvas, area, graph) => {
                this.drawAreaRangesHatched(canvas, area, graph, this.data.pluggedSlots, PLUGGED_COLOR);
                this.drawAreaRangesHatched(canvas, area, graph, this.data.chargingSlots, CHARGING_COLOR);
                this.data.reservations
                    .filter(r => r.start < graph.xAxisRange()[1] && r.end > graph.xAxisRange()[0])
                    .forEach(r => {
                        const start = Math.max(area.x, graph.toDomXCoord(r.start));
                        const end = Math.min(area.w + area.x, graph.toDomXCoord(r.end));
                        const readyAt = graph.toDomXCoord(r.readyAt || 0);
                        const heightSoc = graph.toDomYCoord(r.reservation.targetSoc, 1);
                        this.drawReservationBox(canvas, start, area.h - 10, end - start, 10, heightSoc, readyAt);
                    });
                const nowX = graph.toDomXCoord(Date.now());
                if (nowX > -10 && nowX < area.w) {
                    this.drawNowMarker(canvas, nowX, area.y, 2, area.h);
                }
            },
        };
    }

    async ngOnInit(): Promise<void> {
        await this.init(this.element);
    }

    private getYValues(v: number | Date): string {
        if (this.selectedUnit === PowerUnits.W && (v as number) > 1000) {
            return ((v as number) / 1000).toFixed(1) + ' kW';
        }

        return (typeof v !== "number" ? v : v.toFixed(1)) + ' ' + PowerUnits[this.selectedUnit];
    }

    private getLegendLabels(): string[] {
        if (this.selectedUnit === PowerUnits.W) {
            return ["x", "Batterieladestand (%)", "Batterieladestand Geplant (%)", "Leistung Charge Pilot (W)", "Leistung Geplant (W)", "Leistung (W)", "Events"];
        } else {
            return ["x", "Batterieladestand (%)", "Batterieladestand Geplant (%)", "Strom Charge Pilot (A)", "Strom Geplant I1 (A)", "Strom Geplant I2 (A)", "Strom Geplant I3 (A)", "Strom I1 (A)", "Strom I2 (A)", "Strom I3 (A)", "Events"];
        }
    }

    private getGraphColors(): string[] {
        if (this.selectedUnit === PowerUnits.W) {
            return [
                '#59B9DA',
                '#59B9DA',
                '#CCCCCC',
                '#ffBB00',
                '#ffAA00',
                '#000000'
            ];
        } else {
            return [
                '#59B9DA',
                '#59B9DA',
                '#CCCCCC',
                '#ffBB00',
                '#ffBB00',
                '#ffBB00',
                '#ffAA00',
                '#ffAA00',
                '#ffAA00',
                '#000000'
            ];
        }
    }
}
