export type CompareFn<T> = (a: T, b: T) => number;

export function createByFieldsComparator<T>(fieldNames: Array<keyof T>): CompareFn<T> {
    const compareFns = fieldNames.map(field => createByFieldComparator(field));

    return (a: T, b: T) => {
        for(const localCompareFn of compareFns) {
            const result = localCompareFn(a, b);
            if(result !== 0) {
                return result;
            }
        }
        return 0;
    }
}

export function createByFieldComparator<T>(fieldName: keyof T): CompareFn<T> {
    return (a: T, b:T) => {
        return compareFn(a[fieldName], b[fieldName]);
    }
}

export function compareFn(a: any, b: any) {
    if(a === b) {
        return 0;
    }
    if(a == null) {
        return 1;
    }
    if(b == null) {
        return -1;
    }
    if(typeof a === 'string' && typeof b === 'string') {
        return compareStrFn(a, b);
    }
    if(typeof a === 'number' && typeof b === 'number') {
        return compareNumFn(a, b);
    }
    return 0;
}

export function compareStrFn(a: string, b: string): number {
    return a.localeCompare(b);
}

export function compareNumFn(a: number, b: number): number {
    return a - b;
}
