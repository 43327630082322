import {Component, OnInit} from '@angular/core';

import {BehaviorSubject} from 'rxjs';
import {RfidService, RfidUploadData, UploadStatus} from '../../service/rfid.service';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {UserService} from '../../../users/service/user.service';
import {CustomerService} from '../../../../services/api-handlers/customer.service';
import {downloadAsset} from '../../../../shared/helper/util-functions';
import {read, utils, WorkBook, WorkSheet} from 'xlsx';
import {Customer, Rfid, User, Vehicle} from '@io-elon-common/frontend-api';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-rfid-list-view',
    templateUrl: './rfid-list-view.component.html',
    styleUrls: ['./rfid-list-view.component.scss']
})
export class RfidListViewComponent implements OnInit {

    public rfids!: BehaviorSubject<Rfid[] | undefined>
    public vehicles!: BehaviorSubject<Vehicle[] | undefined>
    public users!: BehaviorSubject<User[] | undefined>
    public customers!: BehaviorSubject<Customer[] | undefined>
    public rfidCsv: RfidUploadData[] = [];
    public filter: string = ""

    constructor(
        private readonly rfidService: RfidService,
        private readonly vehicleService: VehicleService,
        private readonly userService: UserService,
        private readonly customerService: CustomerService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        this.rfids = this.rfidService.getAll();
        this.vehicles = this.vehicleService.getAll();
        this.users = this.userService.getAll();
        this.customers = this.customerService.getAll();
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((queryParams: any) => {
            if(queryParams.q) {
                this.filter=decodeURIComponent(queryParams.q);
                this.updateUrl();
            }
        });
    }

    updateUrl(): void {
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: { q: encodeURIComponent(this.filter) },
                queryParamsHandling: 'merge'
            }
        );
    }

    public async handleNewRfid(): Promise<void> {
        await this.rfidService.showNewDialog(undefined);
    }

    public downloadTemplate() {
        downloadAsset("io_elon_rfid_template.xlsx", "assets/io_elon_rfid_template.xlsx")
    }

    public handleFileInput(files: FileList) {
        if(files.length !== 1) return;
        const file = files.item(0);
        if(file === null) return;

        file.arrayBuffer().then((ab: ArrayBuffer) => {
            const wb: WorkBook = read(ab);
            const ws: WorkSheet = wb.Sheets[wb.SheetNames[0]];

            const data = (utils.sheet_to_json<Array<any>>(ws, {header: 1}));
            this.rfidCsv = data.slice(2).filter(value => value[1] !== undefined).map((value: Array<any>) => this.xlsxRowToRfid(value));
            this.rfidService.showRfidUploadDialog(this.rfidCsv)
        });
    }

    private xlsxRowToRfid(row: Array<any>): RfidUploadData {
        const rfid = {
            name: row[1] || '',
            token: row[2] || '',
            userId: this.findUserId(row[3]),
            vehicleId: this.findVehicleId(row[4]),
            invoiceReceiver: row[5] || '',
            active: row[6] === "Ja"
        }

        return {
            rfid: rfid,
            error: undefined,
            status: UploadStatus.PENDING,
            userRequired: row[3] !== undefined,
            vehicleRequired: row[4] !== undefined
        }
    }

    private findUserId(keyword: string): number | undefined {
        if (keyword === undefined) {
            return undefined;
        }
        keyword = keyword.trim().toUpperCase();
        const user = this.users.getValue()?.find(user =>
            "" + user.id === "" + keyword
            || (user.firstname + " " + user.lastname).toUpperCase() === keyword
            || (user.lastname + " " + user.firstname).toUpperCase() === keyword
            || user.name.toUpperCase() === keyword
            || (user.firstname.toUpperCase() === keyword && this.users.getValue()?.filter(u => u.firstname === user.firstname).length === 1)
            || (user.lastname.toUpperCase() === keyword && this.users.getValue()?.filter(u => u.lastname === user.lastname).length === 1)
    );
        return user ? user.id : undefined;
    }

    private findVehicleId(keyword: string): number | undefined {
        if (keyword === undefined) {
            return undefined;
        }
        keyword = keyword.trim().toUpperCase();
        const vehicle = this.vehicles.getValue()?.find(vehicle =>
            "" + vehicle.id === "" + keyword
            || vehicle.localId?.toUpperCase() === keyword
            || vehicle.numberPlate?.toUpperCase() === keyword
            || vehicle.name?.toUpperCase() === keyword
        );
        return vehicle ? vehicle.id : undefined;
    }
    public clearFileHistory(target: any) {
        target.value = '';
    }

    public getHelpText() {
        return "Es können mithilfe einer Tabelle mehrere RFID-Karten gleichzeitig im System angelegt werden. Dafür empfehlen wir die Beispieltabelle herunterzuladen. Sobald diese mit Daten gefüllt wurde, kann sie wieder hochgeladen werden. Danach besteht noch die Möglichkeit die eingebenden Daten in einem Dialogfeld zu bestätigen.\n" +
            "Achtung:\n" +
            "Eine RFID-Karte kann entweder einem Benutzer zugeordnet werden oder einem Fahrzeug, nicht beiden zugleich.\n" +
            "Die ersten beiden Zeilen der Tabelle werden ignoriert (Kopfzeile und Beispiel). Deshalb sollte der erste Eintrag in der 3. Zeile eingetragen werden."
    }
}
