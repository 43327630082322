import {Injectable} from '@angular/core';
import {
    ExecuteSolarSystemAddOrUpdate, Meter,
    PowerSupply,
    SolarSystem, SolarSystemHistory
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {EditSolarSystemDialogComponent} from '../dialogs/edit-solar-system-dialog/edit-solar-system-dialog.component';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {CacheUpdater} from "../../../services/api-handlers/cacheManager";
import {POLL_INTERVALS} from "../../../app.module";

export class SolarSystemCreateOrEditArguments {
    public possiblePowerSupplys!: PowerSupply[];
    public possibleMeters!: Meter[];
    public basisId: number | undefined;
}

@Injectable({
    providedIn: 'root'
})
export class SolarSystemService extends DialogHandler<SolarSystem, ExecuteSolarSystemAddOrUpdate, ExecuteSolarSystemAddOrUpdate, {}, SolarSystemCreateOrEditArguments, SolarSystemCreateOrEditArguments> {
    private historyCache: CacheUpdater<SolarSystemHistory, number>;

    public constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "SolarSystem", toastr, dialog, dialogService, POLL_INTERVALS.solarSystem);
        this.historyCache = this.createManagedCache((obj, id) => obj.id === id);
    }

    // tslint:disable-next-line:max-line-length
    protected getEditConfig(solarSystem: SolarSystem, dialogArguments: SolarSystemCreateOrEditArguments): TDialogOptions<ExecuteSolarSystemAddOrUpdate, EditSolarSystemDialogComponent> {
        return {
            headline: "Solar-Anlage bearbeiten",
            component: EditSolarSystemDialogComponent,
            executeCallback: editResult => this.update(solarSystem.id, editResult),
            editElement: {
                name: solarSystem.name ||"",
                powerSupplyId: solarSystem.powerSupplyId,
                meterId: solarSystem.meterId,
                meterFlattenWindowMinutes: solarSystem.meterFlattenWindowMinutes,
                l1: solarSystem.l1,
                l2: solarSystem.l2,
                l3: solarSystem.l3,
                solarPredictionProvider: solarSystem.solarPredictionProvider,
                naecoBlueFileName: solarSystem.naecoBlueFileName,
                forecastSolarKey: solarSystem.forecastSolarKey,
                basisId: solarSystem.basis?.id
            },
            extraParams: {
                possiblePowerSupplies: dialogArguments.possiblePowerSupplys,
                possibleMeters: dialogArguments.possibleMeters
            }
        }
    }

    protected getNewConfig(dialogArguments: SolarSystemCreateOrEditArguments): TDialogOptions<ExecuteSolarSystemAddOrUpdate, EditSolarSystemDialogComponent> {
        return {
            headline: "Solar-Anlage anlegen",
            component: EditSolarSystemDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                name: "Meine Solaranlage",
                powerSupplyId: dialogArguments.possiblePowerSupplys[0].id,
                meterId: undefined,
                meterFlattenWindowMinutes: 15,
                l1: true,
                l2: false,
                l3: false,
                solarPredictionProvider: 'ENERCAST',
                naecoBlueFileName: "",
                forecastSolarKey: "",
                basisId: dialogArguments.basisId
            },
            extraParams: {
                possiblePowerSupplies: dialogArguments.possiblePowerSupplys,
                possibleMeters: dialogArguments.possibleMeters
            }
        }
    }

    // Funktion um dem Backend zu sagen, dass es die Daten von NaecoBlue/Enercast neu abfragen soll
    public async refreshData(showAlerts = true): Promise<void> {
        await this.apiService.triggerSolarSystemReload(showAlerts, undefined, undefined, ApiHandler.customerId);
    }

    public async getSolarSystemHistory(solarSystemId: number, start: number, end: number, showAlerts = true): Promise<SolarSystemHistory> {
        return await this.apiService.getValuesBySolarSystemAndTime(showAlerts, solarSystemId, start, end, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public updateSolarSystemBasis(solarSystem: SolarSystem) {
        return this.update(solarSystem.id, {
            name: solarSystem.name ||"",
            powerSupplyId: solarSystem.powerSupplyId,
            meterId: solarSystem.meterId,
            meterFlattenWindowMinutes: solarSystem.meterFlattenWindowMinutes,
            l1: solarSystem.l1,
            l2: solarSystem.l2,
            l3: solarSystem.l3,
            solarPredictionProvider: solarSystem.solarPredictionProvider,
            naecoBlueFileName: solarSystem.naecoBlueFileName,
            forecastSolarKey: solarSystem.forecastSolarKey,
            basisId: solarSystem.basis?.id
        });
    }

}
