import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-default-buttons',
    templateUrl: './default-buttons.component.html',
    styleUrls: ['./default-buttons.component.scss']
})
export class DefaultButtonsComponent {
    @Input()
    public disabled!: boolean

    @Output()
    public save = new EventEmitter<void>();
    @Output()
    public discard = new EventEmitter<void>();

    constructor() {
    }

    validate(): string[] {
        return [];
    }
}
