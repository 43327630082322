<mat-dialog-content>
    <div class="dialog-head">
        <h2 class="mat-h2">Kommando senden</h2><mat-icon class="close-icon" (click)="close()">close</mat-icon>
        <h4><app-evse-link [evseId]="dialogData.evseId"></app-evse-link></h4>
    </div>
    <div style="width: 640px">
        <div *ngIf="data">
            <div class="full" appearance="fill">
                <app-evse-actions-dropdown [actions]="data" (updateActionSelection)="updateActionSelection($event)" [width]="'640px'"></app-evse-actions-dropdown>
            </div>
            <span class="mat-caption break" *ngIf="selectedAction" innerHTML="{{selectedAction.description}}"></span>
        </div>

        <div *ngIf="selectedAction && selectedAction.arguments && selectedAction.arguments.length > 0">
            <ng-container *ngFor="let arg of selectedAction.arguments">
                <div [ngSwitch]="arg.argumentType" class="half" appearance="fill">
                    <mat-checkbox *ngSwitchCase="argumentType.Checkbox" [checked]="arg.defaultValue==='true'" [(ngModel)]="argumentsValues[arg.name]">
                        {{arg.displayName}}
                    </mat-checkbox>
                    <mat-radio-group *ngSwitchCase="argumentType.RadioButton" [(ngModel)]="argumentsValues[arg.name]" [value]="arg.defaultValue">
                        <mat-radio-button *ngFor="let opt of arg.options" [value]="opt">{{ opt }} </mat-radio-button>
                    </mat-radio-group>
                    <mat-slide-toggle *ngSwitchCase="argumentType.SlideToggle" [(ngModel)]="argumentsValues[arg.name]" [checked]="arg.defaultValue==='true'">
                        {{arg.displayName}}
                    </mat-slide-toggle>
                    <mat-button-toggle-group *ngSwitchCase="argumentType.ButtonToggle" [(ngModel)]="argumentsValues[arg.name]" [value]="arg.defaultValue" required>
                        <mat-button-toggle *ngFor="let opt of arg.options" [value]="opt">{{opt}}</mat-button-toggle>
                    </mat-button-toggle-group>
                    <mat-form-field *ngSwitchCase="argumentType.Dropdown">
                        <mat-label>{{ arg.displayName }}</mat-label>
                        <mat-select [(ngModel)]="argumentsValues[arg.name]" [value]="arg.defaultValue">
                            <mat-option *ngFor="let opt of arg.options" [value]="opt">
                                {{opt}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngSwitchCase="argumentType.Autocomplete">
                        <mat-label>{{ arg.displayName }}</mat-label>
                        <input type="text"
                               placeholder="{{arg.defaultValue}}"
                               matInput
                               [(ngModel)]="argumentsValues[arg.name]"
                               [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of arg.options" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field *ngSwitchCase="argumentType.Input">
                        <mat-label>{{ arg.displayName }}</mat-label>
                        <input matInput [(ngModel)]="argumentsValues[arg.name]" [pattern]="arg.validationPattern" required>
                    </mat-form-field>
                    <ng-container *ngSwitchCase="argumentType.Custom">
                        <div [ngSwitch]="arg.name">
                            <div *ngSwitchCase="'rfid'" >
                                <mat-form-field>
                                    <mat-label>{{ arg.displayName }}</mat-label>
                                    <input type="text"
                                           matInput
                                           [(ngModel)]="argumentsValues[arg.name]"
                                           [formControl]="rfidControl"
                                           [matAutocomplete]="rfidauto">
                                    <mat-autocomplete autoActiveFirstOption #rfidauto="matAutocomplete">
                                        <mat-option *ngFor="let r of filterdRfids | async" [value]="r.token">
                                            {{r.name}}<span class="data-source-info mat-caption">{{r.token}}</span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <div *ngIf="resultText.length > 0">
            <hr>
            <h3>Ergebnis:</h3>
            <div class="result">
                <span [innerHTML]="resultText"></span>
            </div>
        </div>

        <div class="button-div">

        </div>

    </div>
</mat-dialog-content>
<mat-dialog-actions class="buttons d-flex flex-row justify-content-end gap-2">
    <button
        mat-raised-button color="primary"
        type="submit"
        (click)="sendAction()" [disabled]="loadingResults">
        Ausführen<i *ngIf="loadingResults" class="fas fa-circle-notch fa-spin"></i>
    </button>
    <button
        mat-raised-button color="accent"
        type="submit"
        (click)="close()"
        class="close-button">
        Schließen
    </button>
</mat-dialog-actions>
<ng-template #loading>
    Loading...
</ng-template>
