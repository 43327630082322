<div class="position-relative">
    <mat-dialog-content>
        <div class="dialog-head">
            <h2 class="mat-h2">{{headline}}</h2><mat-icon class="close-icon" (click)="close()">close</mat-icon>
        </div>
        <ng-template appContainer></ng-template>
    </mat-dialog-content>
    <mat-dialog-actions class="buttons d-flex flex-row justify-content-end gap-3">
        <app-default-buttons [disabled]="saveActive" (save)="save()" (discard)="close()"></app-default-buttons>
    </mat-dialog-actions>
</div>
