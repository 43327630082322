<div class="outerDiv">
    <ng-container *ngIf="errorText.length === 0">
        <ng-container *ngIf="(data | async) as data; else loading">
            <div>
                <h1>{{data.actorPrettyName}}
                    <span *ngIf="!isAllDataLoaded">({{itemsLoadedCount}}/{{data.lines.length}}) </span>
                    <i *ngIf="!isAllDataLoaded" class="fas fa-circle-notch fa-spin"></i></h1>
                <button [disabled]="!isAllDataLoaded" (click)="refresh()" mat-button class="refresh-button">Aktualisieren <i *ngIf="!isAllDataLoaded" class="fas fa-circle-notch fa-spin"></i></button>
                <mat-hint>{{data.actorClass}} - {{data.actorId}}</mat-hint>
            </div>
            <hr>
            <mat-accordion>
                <div>
                    <ng-container *ngFor="let l of data.lines">
                        <mat-expansion-panel (opened)="open = l.key"
                                             (closed)="open = open === l.key ? '' : open"
                                             [class.success]="l.success"
                                             [class.error]="!l.success"
                                             *ngIf="!l.pending">
                            <mat-expansion-panel-header>
                                <mat-panel-title style="padding-bottom: 13px; position: relative">
                                    {{l.prettyName}}
                                    <span style="transform: translateY(7px); vertical-align: bottom" class="mat-small gray">{{l.type}}</span>
                                    <span style="position:absolute; bottom: 0; left: 0; vertical-align: bottom" class="mat-small gray">{{l.key}}</span>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <ng-container *ngIf="l.success; else noDataMsg">
                                        <span style="align-self: center" [ngSwitch]="l.type" (click)="$event.stopPropagation()" (keydown)="$event.stopImmediatePropagation();">
                                            <select (click)="$event.stopPropagation()" style="display: inline-block" *ngSwitchCase="'enum'" [(ngModel)]="l.value">
                                                <option [value]="l.value">{{l.value}}</option>
                                                <ng-container *ngIf="l.enumValues">
                                                    <optgroup [label]="l.writeable ? 'Werte:' : 'Werte: (Nicht änderbar)'"></optgroup>
                                                    <option *ngFor="let o of l.enumValues" [value]="o" [disabled]="!l.writeable">{{o}}</option>
                                                </ng-container>
                                            </select>
                                            <input (keyup)="validate(l)" *ngSwitchCase="'int'" [(ngModel)]="l.value" [min]="l.numberRangeMin" [max]="l.numberRangeMax" type="number" [disabled]="!l.writeable" [step]="1">
                                            <input (keyup)="validate(l)" *ngSwitchCase="'double'" [(ngModel)]="l.value" [min]="l.numberRangeMin" [max]="l.numberRangeMax" type="number" [disabled]="!l.writeable">
                                            <input (keyup)="validate(l)" *ngSwitchCase="'password'" [(ngModel)]="l.value" [disabled]="!l.writeable" type="password">
                                            <img *ngSwitchCase="'graphic'" [src]="l.value" [alt]="l.key">
                                            <input (keyup)="validate(l)" *ngSwitchDefault [(ngModel)]="l.value" [disabled]="!l.writeable">
                                        </span>
                                        <span style="width: 30px; transform: translateY(7px); vertical-align: bottom">
                                            {{l.unit}}
                                        </span>
                                        <button mat-button (click)="$event.stopPropagation(); write(l)" [disabled]="!l.writeable || !!validations[l.key]">
                                            <i class="fas fa-save"></i>
                                        </button>
                                    </ng-container>
                                    <ng-template #noDataMsg>
                                        <div class="no-data-msg">
                                            Fehler beim Laden der Daten
                                        </div>
                                    </ng-template>
                                    <mat-error style="align-self: center; margin-left: 5px" *ngIf="validations[l.key]">
                                        {{validations[l.key]}}
                                    </mat-error>
                                    <span class="info-small" *ngIf="open !== l.key && !validations[l.key]">
                                {{l.info}}
                            </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <pre>{{l.info}}</pre>
                        </mat-expansion-panel>
                    </ng-container>
                    <h1 *ngIf="!isAllDataLoaded" style="text-align: center">
                        <i class="fas fa-circle-notch fa-spin"></i>
                    </h1>
                </div>
            </mat-accordion>

        </ng-container>
    </ng-container>

    <ng-container *ngIf="errorText.length > 0">
        <div class="error-div">
            <h2>
                {{errorText}}
                <button [disabled]="!isAllDataLoaded" (click)="refresh()" mat-button class="refresh-button">Aktualisieren <i *ngIf="!isAllDataLoaded" class="fas fa-circle-notch fa-spin"></i></button>
            </h2>
        </div>
    </ng-container>
    <ng-template #loading>
        <h1 style="text-align: center">
            <i class="fas fa-circle-notch fa-spin"></i>
        </h1>
    </ng-template>
</div>
