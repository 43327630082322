<mat-card appearance="outlined" class="position-relative p-3">
    <mat-card-title>
        <div class="d-flex flex-row justify-content-between align-items-center">
            Meine Statistiken
            <app-date-selection (updateRangeEvent)="updateRange($event)"></app-date-selection>
        </div>
    </mat-card-title>
    <mat-card-content>
        <app-evse-health-history-table [dataRows] ="dataRows" [footerRow] = "footerRow" [displayColumns] = "displayColumns" *ngIf="dataRows !== undefined"></app-evse-health-history-table>
    </mat-card-content>
</mat-card>
