import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api-handlers/api.service';
import {OcppUplink, ExecuteOcppUplinkAddOrUpdate} from '@io-elon-common/frontend-api';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditOcppUplinkDialogComponent} from '../dialogs/edit-ocpp-uplink-dialog/edit-ocpp-uplink-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
  providedIn: 'root'
})
export class OcppUplinkService extends DialogHandler<OcppUplink, ExecuteOcppUplinkAddOrUpdate, ExecuteOcppUplinkAddOrUpdate, {}, void, void>{

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "OcppUplink", toastr, dialog, dialogService, POLL_INTERVALS.uplinks);
    }

    protected getEditConfig(ocppUplink: OcppUplink): TDialogOptions<ExecuteOcppUplinkAddOrUpdate, EditOcppUplinkDialogComponent> {
        return {
            headline: "OCPP-Abrechnungsbackend-Account bearbeiten",
            component: EditOcppUplinkDialogComponent,
            executeCallback: editResult => this.update(ocppUplink.id, editResult),
            editElement: {
                name: ocppUplink.name,
                baseUrl: ocppUplink.baseUrl,
                cpi : ocppUplink.cpi,
                userName: ocppUplink.userName,
                pass: ocppUplink.pass,
                defToken: ocppUplink.defToken,
                ssl: ocppUplink.ssl,
                meterValueMinDelay: ocppUplink.meterValueMinDelay,
                statusValueMinDelay: ocppUplink.statusValueMinDelay
            },
            extraParams: {
                ocppUplinkId: ocppUplink.id
            }
        };
    }

    protected getNewConfig(newDialogConfigArgs: void): TDialogOptions<ExecuteOcppUplinkAddOrUpdate, EditOcppUplinkDialogComponent> {
        return {
            headline: "OCPP-Abrechnungsbackend-Account hinzufügen",
            component: EditOcppUplinkDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                name : "",
                baseUrl : "",
                cpi: "",
                userName: "",
                pass: "",
                defToken: "io-elon",
                ssl: true,
                meterValueMinDelay: 900,
                statusValueMinDelay: 900
            },
            extraParams: {
                ocppUplinkId: -1
            }
        };
    }

    public async getPassword(id: number, showAlerts = true): Promise<string> {
        const pwObj = await this.apiService.getOcppUplinkPassword(showAlerts, id, undefined, undefined, ApiHandler.customerId).toPromise();
        return pwObj.password
    }
}
