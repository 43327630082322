import {Component, Input} from '@angular/core';
import {PowerSupply, SolarSystem} from '@io-elon-common/frontend-api';
import {SolarSystemService} from '../../../../solar/service/solar-system.service';
import {ToastrService} from 'ngx-toastr';
import {MeterService} from "../../../../meter/service/meter.service";

@Component({
    selector: 'app-solar-system-tree-view-node',
    templateUrl: './solar-system-tree-view-node.component.html',
    styleUrls: ['./solar-system-tree-view-node.component.scss']
})
export class SolarSystemTreeViewNodeComponent {

    @Input()
    solarSystem!: SolarSystem;

    @Input()
    powerSupplies!: PowerSupply[]

    @Input()
    public ampsMode: boolean = false;

    public constructor(
        private readonly solarSystemService: SolarSystemService,
        private readonly toastr: ToastrService,
        private readonly meterService: MeterService
    ) {
    }

    public async handleEditSolarSystem(): Promise<void> {
        if (this.powerSupplies === undefined) {
            this.toastr.warning('Keine Basis ausgewählt');
            return;
        }
        this.meterService.getAllPromise().then(meters => {
            if (meters == undefined) {
                this.toastr.warning('Fehler beim laden der möglichen Messgeräte');
                return;
            }
            this.solarSystemService.showEditDialog(this.solarSystem, {
                possiblePowerSupplys: this.powerSupplies.filter(ps => ps.baseId === this.solarSystem.basis?.id),
                possibleMeters: meters.filter(meter => meter.basis?.id === this.solarSystem.basis?.id),
                basisId: this.solarSystem.basis?.id
            });
        })
    }

    public async handleDeleteSolarSystem(): Promise<void> {
        await this.solarSystemService.showDeleteDialog(this.solarSystem, {});
    }
}
