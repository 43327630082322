import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {SystemService} from "../../services/api-handlers/system.service";
import {AuthService} from "../../shared/guards/auth.service";
import {BehaviorSubject} from "rxjs";

type ToolState = {
    status: "HEALTHY" | "WARNING" | "ERROR"
    message: string
    meta: [{key: string, msg: string}]
}
type ToolsState = {[key: string]: ToolState}

@Component({
  selector: 'app-external-service-warning',
  standalone: false,
  templateUrl: './external-service-warning.component.html',
  styleUrl: './external-service-warning.component.scss'
})
export class ExternalServiceWarningComponent implements OnInit{

    public isDev = false;
    public state = new BehaviorSubject<ToolsState>({});
    public selected: string = "";

    @ViewChild('popup', {static: false})
    public popupRef!: ElementRef;

    constructor(
        private readonly systemService: SystemService,
        private readonly authService: AuthService,
        private elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        this.isDev = this.authService.isDeveloper();

        if(this.isDev) {
            this.state = this.systemService.getExternalToolsState()
        }
    }

    @HostListener("document:keydown.escape")
    onEscPress() {
        this.selected = "";
    }

    @HostListener('document:click', ['$event'])
    handleClickOutside(event: MouseEvent) {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        const clickedInsidePopup = this.popupRef?.nativeElement.contains(event.target);
        if (!clickedInside && !clickedInsidePopup) {
            this.selected = "";
        }
    }

}
