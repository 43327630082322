import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {ApiHandler} from "./api-handler";
import {LiveDataResponse, LiveDataUpdateRequest, LiveDataUpdateRequestField} from "@io-elon-common/frontend-api";

@Injectable({
    providedIn: 'root'
})
export class LivedataService {
    public constructor(
        private readonly apiService: ApiService
    ) {
    }

    public getLiveData(evses: number[], vehicles: number[], showAlerts = true): Promise<LiveDataResponse> {
        return this.apiService.getLiveData(showAlerts, undefined, undefined, ApiHandler.customerId, {
            evses,
            vehicles
        }).toPromise();
    }

    public setLiveData(evseId: number | undefined, vehicleId: number | undefined, method: LiveDataUpdateRequest.MethodEnum, data: Array<LiveDataUpdateRequestField>, tst: number | undefined, showAlerts = true): Promise<{}> {
        return this.apiService.updateLiveData(showAlerts, undefined, undefined, ApiHandler.customerId, {
            data,
            tst,
            evseId,
            method,
            vehicleId
        }).toPromise();
    }

    public assign(evseId: number | undefined, vehicleId: number | undefined, showAlerts = true): Promise<{}> {
        return this.apiService.updateAssignment(showAlerts, undefined, undefined, ApiHandler.customerId, {
            vehicle: vehicleId,
            evse: evseId
        }).toPromise();
    }
}
