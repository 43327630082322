import {Component, OnInit} from '@angular/core';
import {VehicleKey} from '@io-elon-common/frontend-api';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {VehicleKeyService} from '../../service/vehicle-key.service';
import {AuthService} from '../../../../shared/guards/auth.service';

@Component({
  selector: 'app-vehicle-key-list-view',
  templateUrl: './vehicle-key-list-view.component.html',
  styleUrls: ['./vehicle-key-list-view.component.scss']
})
export class VehicleKeyListViewComponent implements OnInit {
    public vehicleKeys!: BehaviorSubject<VehicleKey[] | undefined>
    public canCreate!: boolean;

    public constructor(
        private readonly vehicleKeyService: VehicleKeyService,
        private readonly authService: AuthService,
    ) {
    }

    ngOnInit(): void {
        this.canCreate = this.authService.hasGlobalPermission("ADD_VEHICLE_KEYS");
        this.vehicleKeys = this.vehicleKeyService.getAll();
    }

    public async handleNewReservation(): Promise<void> {
        await this.vehicleKeyService.showNewDialog();
    }
}
