<div *ngIf="(openAdrVenEntities | async) as openAdrVenEntities; else loading">
    <div>
        <div>
            <div class="with-background">
                <app-open-adr-ven-entity-table [openAdrVenEntities]="openAdrVenEntities"></app-open-adr-ven-entity-table>
            </div>
        </div>
    </div>
    <div class="buttons d-flex justify-content-end gap-3">
        <button mat-raised-button color="primary" (click)="newOpenAdrVenEntity()">OpenAdr Ven hinzufügen</button>
    </div>
</div>
<ng-template #loading>
    Loading...
</ng-template>
