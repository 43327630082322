<div *ngIf="evses; else loading">
    <app-help-box-icon class="help-icon" #helpIcon [iconType]="DialogType.HELP"></app-help-box-icon>
    <app-help-box [helpIcon]="helpIcon" key="BULK_OPERATION_POPUP" [dialogType]="DialogType.HELP">
        <p>
            Mit Bulk Operations können Sie eine Aktion gleichzeitig auf mehrere Ladepunkte anwenden.
            Zum Beispiel, wenn Sie viele Ladevorgänge gleichzeitig stoppen möchten.
        </p>
    </app-help-box>
    <mat-horizontal-stepper [linear]="true" #stepper (selectionChange)="onStepChange($event)">
        <mat-step>
            <ng-template matStepLabel>Aktion auswählen<span *ngIf="selectedAction" class="step-summary">{{selectedAction.displayName}}</span></ng-template>
            <app-evse-actions-dropdown [actions]="actions" (updateActionSelection)="updateActionSelection($event)"></app-evse-actions-dropdown>
            <span class="break" *ngIf="selectedAction">
                {{actionSupportCount.get(selectedAction?.name)}}/{{evses.length}} Ladepunkte unterstützen diese Aktion
            </span><br>
            <span class="mat-caption" *ngIf="selectedAction" innerHTML="{{selectedAction.description}}"></span>
            <div *ngIf="selectedAction && selectedAction.arguments && selectedAction.arguments.length > 0">
                <ng-container *ngFor="let arg of selectedAction.arguments">
                    <div [ngSwitch]="arg.argumentType" class="half">
                        <mat-checkbox *ngSwitchCase="argumentType.Checkbox" [checked]="arg.defaultValue==='true'" [(ngModel)]="argumentsValues[arg.name]">
                            {{arg.displayName}}
                        </mat-checkbox>
                        <mat-radio-group *ngSwitchCase="argumentType.RadioButton" [(ngModel)]="argumentsValues[arg.name]" [value]="arg.defaultValue">
                            <mat-radio-button *ngFor="let opt of arg.options" [value]="opt">{{ opt }} </mat-radio-button>
                        </mat-radio-group>
                        <mat-slide-toggle *ngSwitchCase="argumentType.SlideToggle" [(ngModel)]="argumentsValues[arg.name]" [checked]="arg.defaultValue==='true'">
                            {{arg.displayName}}
                        </mat-slide-toggle>
                        <mat-button-toggle-group *ngSwitchCase="argumentType.ButtonToggle" [(ngModel)]="argumentsValues[arg.name]" [value]="arg.defaultValue" required>
                            <mat-button-toggle *ngFor="let opt of arg.options" [value]="opt">{{opt}}</mat-button-toggle>
                        </mat-button-toggle-group>
                        <mat-form-field *ngSwitchCase="argumentType.Dropdown">
                            <mat-label>{{ arg.displayName }}</mat-label>
                            <mat-select [(ngModel)]="argumentsValues[arg.name]" [value]="arg.defaultValue">
                                <mat-option *ngFor="let opt of arg.options" [value]="opt">
                                    {{opt}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngSwitchCase="argumentType.Autocomplete">
                            <mat-label>{{ arg.displayName }}</mat-label>
                            <input type="text"
                                   placeholder="{{arg.defaultValue}}"
                                   matInput
                                   [(ngModel)]="argumentsValues[arg.name]"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option *ngFor="let option of arg.options" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field *ngSwitchCase="argumentType.Input">
                            <mat-label>{{ arg.displayName }}</mat-label>
                            <input matInput [(ngModel)]="argumentsValues[arg.name]" [pattern]="arg.validationPattern" required>
                        </mat-form-field>
                        <ng-container *ngSwitchCase="argumentType.Custom">
                            <div [ngSwitch]="arg.name">
                                <div *ngSwitchCase="'rfid'" >
                                    <mat-form-field>
                                        <mat-label>{{ arg.displayName }}</mat-label>
                                        <input type="text"
                                               matInput
                                               [(ngModel)]="argumentsValues[arg.name]"
                                               [formControl]="rfidControl"
                                               [matAutocomplete]="rfidauto"
                                               (change)="updateSelectableEvses(true, true)">
                                        <mat-autocomplete autoActiveFirstOption #rfidauto="matAutocomplete">
                                            <mat-option *ngFor="let r of filterdRfids | async" [value]="r.token">
                                                {{r.name}}<span class="data-source-info mat-caption">{{r.token}}</span>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="bottom-buttons">
                <button mat-raised-button color="primary" matStepperNext [disabled]="!selectedAction">Weiter</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Ladepunkte auswählen<span *ngIf="selectedEvseIds.length > 0" class="step-summary">{{selectedEvseIds.length}} Ausgewählt</span></ng-template>
            <app-evse-table [evses]="selectableEvses" [showAlgo]="false" [showMode]="false" [isSelect]="true" [showOcppArgs]="true" [showVersions]="true" (selectionChanged)="evseSelectionChanged($event)" [removeEvseSelection]="removeEvseSelection"></app-evse-table>

            <div class="bottom-buttons">
                <button mat-raised-button color="accent" matStepperPrevious>Zurück</button>
                <button mat-raised-button color="primary" matStepperNext [disabled]="!(selectedEvseIds.length > 0)">Weiter</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Ergebnisse</ng-template>
            <mat-accordion>
                <ng-container *ngFor="let element of actionStates">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{element.evseName}}
                            </mat-panel-title>
                            <mat-panel-description>
                                <ng-container [ngSwitch]="element.state">
                                    <ng-container *ngSwitchCase="'pending'"><mat-icon>av_timer</mat-icon>Ausstehend</ng-container>
                                    <ng-container *ngSwitchCase="'running'"><mat-icon>hourglass_empty</mat-icon>Wird ausgeführt</ng-container>
                                    <ng-container *ngSwitchCase="'done'"><mat-icon>check</mat-icon>Erfolgreich</ng-container>
                                    <ng-container *ngSwitchCase="'failed'"><mat-icon>report_problem</mat-icon>Fehlgeschlagen</ng-container>
                                </ng-container>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p class="result">
                            <span [innerHTML]="element.result"></span>
                        </p>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
            <div class="bottom-buttons mt-3">
                <mat-form-field *ngIf="isDev" class="filterbox" appearance="outline">
                    <mat-label><mat-icon style="transform: translate(0, 5px)" matTooltip="Dev Only">bug_report</mat-icon> Parallele Aktionen</mat-label>
                    <input matInput [(ngModel)]="maxParallelActions">
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="startBulkAction()">Start</button>
                <button mat-raised-button color="accent" matStepperPrevious>Zurück</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>
<ng-template #loading>
    <div>Loading...</div>
</ng-template>
