import {Component, OnInit} from '@angular/core';
import {PerformanceStatsDb, SystemService} from "../../../services/api-handlers/system.service";
import {BehaviorSubject} from "rxjs";
import {localStorageGet, localStorageSave} from "../../../shared/helper/typed-local-storage";

@Component({
    selector: 'app-db-performance',
    templateUrl: './db-performance.component.html',
    styleUrls: ['./db-performance.component.scss']
})
export class DbPerformanceComponent implements OnInit {

    public stats!: BehaviorSubject<PerformanceStatsDb | undefined>;
    public filter = "";
    public log: string[] = JSON.parse(localStorageGet("DB_PERFORMANCE_FILTER", "[]"))


    constructor(
        private readonly systemService: SystemService
    ) {
    }

    ngOnInit(): void {
        this.stats = this.systemService.performanceStatsDb();
    }

    public get statsFilter(): PerformanceStatsDb {
        const list: PerformanceStatsDb = {};

        const fullList = this.stats.getValue();
        if(fullList) {
            if(this.filter.indexOf("$") === 0) {
                const cnt = Number.parseInt(this.filter.substr(1))
                for (const [key, value] of Object.entries(fullList)) {
                    if(value.update > cnt || value.read > cnt || value.create > cnt || value._delete > cnt) {
                        list[key] = value;
                    }
                }
            } else {
                const r = new RegExp(this.filter);
                for (const [key, value] of Object.entries(fullList)) {
                    if(r.exec(key)) {
                        list[key] = value;
                    }
                }
            }
        }

        return list;
    }

    public trackByFn(idx: number, item: {key: string, value: any}): string {
        return item.key;
    }

    saveFilter() {
        if (!this.log.includes(this.filter, 0)) {
            this.log.push(this.filter);
            if (this.log.length > 5) {
                this.log.splice(0, 1);
            }
            localStorageSave("DB_PERFORMANCE_FILTER", JSON.stringify(this.log));
        }
    }
}
