<div class="dialog-head">
    <h3 mat-dialog-title>
        Massenvalidierung
    </h3>
    <mat-icon class="close-icon" (click)="close()">close</mat-icon>

    <div class="left-corner">
            <span class="d-flex align-items-center">
                {{getSuccessfulCount()}}/{{selection.selected.length}} &nbsp;
                <ng-container *ngIf="inProgress">
                    <i class="fas fa-circle-notch fa-spin"></i>&nbsp;
                    <div *ngIf="isSetDefaultInProgress; else reValidate">
                        Empfohlende Konfiguration wird übertragen
                    </div>
                    <ng-template #reValidate>
                        Konfiguration wird erneut geprüft
                    </ng-template>
                </ng-container>
                <mat-icon *ngIf="isFinished && !inProgress">check</mat-icon>
            </span>
    </div>
</div>
<mat-dialog-content>


    <mat-accordion displayMode="flat" multi="true">
        <section matSort>
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                          [checked]="isAllSelected()"
                          [indeterminate]="areSomeSelected() && !isAllSelected()"
                          [disabled]="inProgress">
            </mat-checkbox>
            Ladepunkte
        </section>
        <mat-expansion-panel #evsePanel *ngFor="let element of displayedRows | async">
            <mat-expansion-panel-header class="mat-row">
                <div class="flex-grow-0">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(element.evse.id) : null"
                                  [checked]="selection.isSelected(element.evse.id)"
                                  [disabled]="inProgress">
                    </mat-checkbox>
                </div>
                <div class="flex-grow-1">
                    <b>{{element.evse.name}} </b>
                    <span *ngIf="isSetDefaultInProgress && element.status != 'idle'">
                        ({{element.successfulSetDefaultCount}} / {{getSettingDefaultRequiredCount(element.evse.liveData.validationResult)}})
                    </span>
                </div>
                <div class="flex-grow-2 gap-1">
                    <ng-container [ngSwitch]="element.status">
                        <ng-container *ngSwitchCase="'running'">
                            <div class="d-flex align-items-center">
                                <mat-icon>hourglass_empty</mat-icon>
                                Wird ausgeführt&nbsp;
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'pending'">
                            <div class="d-flex align-items-center">
                                <mat-icon>av_timer</mat-icon>
                                Ausstehend&nbsp;
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'partial_success'">
                            <div class="d-flex align-items-center">
                                <mat-icon>check</mat-icon>
                                Teilweise Erfolgreich&nbsp;
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'success'">
                            <div class="d-flex align-items-center">
                                <mat-icon>check</mat-icon>
                                Erfolgreich&nbsp;
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'failed'">
                            <div class="d-flex align-items-center">
                                <mat-icon>report_problem</mat-icon>
                                Fehlgeschlagen&nbsp;
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </mat-expansion-panel-header>
            <div>
                Validierungsregeln: {{element.evse.liveData.validationResult.length}} <br>
                Gültige Regeln: {{getValidationResultTotalCount(element.evse.liveData.validationResult, 'VALID')}} <br>
                Ungültige Regeln: {{getValidationResultTotalCount(element.evse.liveData.validationResult, 'INVALID')}} <br>
                Validierungsfehler: {{getValidationResultTotalCount(element.evse.liveData.validationResult, 'VALIDATION_ERROR')}} <br>
                Whitelist:{{element.whiteList.length}}
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-paginator #paginator
                   [length]="totalRows | async"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions class="button-div">
    <div class="right-corner">
        <button [disabled]="inProgress || selection.isEmpty()" mat-raised-button color="primary" (click)="revalidateAll()">Konfiguration erneut prüfen</button>
        <button [disabled]="inProgress || selection.isEmpty()" mat-raised-button color="primary" (click)="setDefaultToAll()">Empfohlende Konfiguration übertragen</button>
    </div>
</mat-dialog-actions>
