<div class="d-flex flex-column gap-3 align-items-stretch">
    <div style="overflow-y: hidden">
        <mat-card appearance="outlined" class="position-relative p-3">
            <mat-card-title>
                Dynamischer Strompreis
            </mat-card-title>
            <div style="position: absolute; top: 10px; right: 10px">
                <app-graph-help-icon
                    [options]="['controlZoom', 'leftClickMove', 'shiftLeftClickZoom', 'doubleLeftClickReset']"
                    [vAlign]="'sub'"></app-graph-help-icon>
            </div>
            <mat-card-content style="padding-bottom: 16px;">
                <app-electricity-costs-plan [basis]="fleet.base"></app-electricity-costs-plan>
            </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined">
            <mat-card-title>
                Anschlüsse
            <div class="settings">
                <mat-radio-group [(ngModel)]="ampsMode">
                    <mat-radio-button [value]="false">Gesamtleistung</mat-radio-button>
                    <mat-radio-button [value]="true">Phasenstrom</mat-radio-button>
                </mat-radio-group>
            </div>
        </mat-card-title>
            <mat-card-content>
                <ng-container *ngIf="(evses | async) as evses; else loading">
                    <ng-container *ngIf="(solarSystems | async) as solarSystems; else loading">
                        <ng-container *ngIf="(loads | async) as loads; else loading">
                            <app-power-supply-tree-view [ampsMode]="ampsMode" [powerSupplies]="fleet.base.powerSupplies" [basis]="fleet.base"
                                                        [evses]="evses" [solarSystems]="solarSystems"
                                                        [loads]="loads"></app-power-supply-tree-view>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </mat-card-content>
        </mat-card>
        <ng-container *ngFor="let ps of fleet.base.powerSupplies; trackBy:trackPowerSupplyBy">
            <mat-card appearance="outlined" *ngIf="ps.visible">
                <mat-card-title>
                    {{ ps.name }}
                    <div style="float:right" class="d-flex flex-row justify-content-end me-4">
                        <button [disabled]="!ps.canEdit" (click)="handleResetPeaks(ps)" mat-icon-button
                                matTooltip="Höchsten Peak zurücksetzen">
                            <mat-icon>history</mat-icon>
                        </button>
                        <button [disabled]="!ps.canEdit" mat-icon-button (click)="handleEditPowerSupply(ps)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button [disabled]="!ps.canDelete" mat-icon-button (click)="handleDeletePowerSupply(ps)">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <app-graph-help-icon
                            [options]="['controlZoom', 'leftClickMove', 'shiftLeftClickZoom', 'doubleLeftClickReset']"
                            [vAlign]="'sub'"></app-graph-help-icon>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <app-peak-history-graph [powerSupplyId]="ps.id"></app-peak-history-graph>
                    <div class="solar-panels" *ngIf="(solarSystems | async) as solarSystems; else loading">
                        <div class="solar-panel" *ngFor="let sp of solarSystemToPowerSupply(solarSystems, ps)">
                            <mat-icon class="sun-icon">wb_sunny</mat-icon>
                            <div>
                                {{ sp.name }}
                                <button [disabled]="!sp.canEdit" mat-icon-button (click)="handleEditSolarSystem(sp)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button [disabled]="!sp.canDelete" mat-icon-button
                                        (click)="handleDeleteSolarSystem(sp)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ng-template #loading>
                        <div class="solar-panels loading">Loading...</div>
                    </ng-template>
                </mat-card-content>
            </mat-card>
        </ng-container>
        <div class="d-flex flex-row justify-content-end gap-3">
            <button [disabled]="!fleet.canEdit" mat-raised-button color="primary"
                    (click)="handleEditFleet()">Flotteneinstellungen
            </button>
            <button [disabled]="!fleet.base.canEdit" mat-raised-button color="primary" (click)="handleEditBasis()">
                Standort
                Bearbeiten
            </button>
            <button [disabled]="!fleet.base.canAddBasis" mat-raised-button color="primary" (click)="handleNewBasis()">
                Neuer Standort
            </button>
            <button [disabled]="!fleet.base.canAddPowerSupply" mat-raised-button color="primary"
                    (click)="handleNewPowerSupply()">Anschluss hinzufügen
            </button>
            <button [disabled]="!fleet.base.canAddSolarSystem" mat-raised-button color="primary"
                    (click)="handleNewSolarSystem()">Solaranlage hinzufügen
            </button>
        </div>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="error">
        <h1>Hinweis</h1>
        {{error}}
    </div>
    <div *ngIf="!error">
        Loading...
    </div>
</ng-template>
