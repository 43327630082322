import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Basis, Evse, Load, PowerSupply, SolarSystem} from '@io-elon-common/frontend-api';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {deepEqual} from '../../../../shared/helper/util-functions';
// Beispiel für Tree: https://material.angular.io/components/tree/overview

type PowerSupplyTree = {
    ps?: number;
    evse?: number;
    solar?: number;
    load?: number;
    children?: PowerSupplyTree[];
};

@Component({
    selector: 'app-power-supply-tree-view',
    templateUrl: './power-supply-tree-view.component.html',
    styleUrls: ['./power-supply-tree-view.component.scss']
})
export class PowerSupplyTreeViewComponent implements OnChanges {
    @Input()
    public powerSupplies!: PowerSupply[];

    @Input()
    public evses!: Evse[];

    @Input()
    public solarSystems!: SolarSystem[];

    @Input()
    public basis!: Basis;

    @Input()
    public loads!: Load[];

    @Input()
    public ampsMode: boolean = false;

    private oldTree?: PowerSupplyTree;

    public treeControl = new NestedTreeControl<PowerSupplyTree>(node => node.children);
    public dataSource = new MatTreeNestedDataSource<PowerSupplyTree>();

    public constructor() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        const parent = this.powerSupplies.find(ps => ps.parentPowerSupplyId == null);

        if(parent) {
            const tree = this.createNodePs(parent);
            if(this.oldTree && deepEqual(tree, this.oldTree)) {
                return;
            }
            this.oldTree = tree;

            this.dataSource.data = [tree];
        } else {
            this.dataSource.data = [];
        }
    }

    public hasChild(_: number, node: PowerSupplyTree) {
        return !!node.children && node.children.length > 0;
    }

    public getSolarSystem(id: number): SolarSystem | undefined {
        return this.solarSystems.find(ss => ss.id === id);
    }

    public getEvse(id: number): Evse | undefined {
        return this.evses.find(e => e.id === id);
    }

    public getPowerSupply(id: number): PowerSupply | undefined {
        return this.powerSupplies.find(ps => ps.id === id);
    }

    public getLoad(id: number): Load | undefined {
        return this.loads.find(load => load.id === id);
    }

    private createNodePs(powerSupply: PowerSupply): PowerSupplyTree {
        return {
            ps: powerSupply.id,
            children: [
                ...(this.getChildrenSolar(powerSupply).map(ss => this.createNodeSolar(ss))),
                ...(this.getChildrenEvse(powerSupply).map(evse => this.createNodeEvse(evse))),
                ...(this.getChildrenPs(powerSupply).map(ps => this.createNodePs(ps))),
                ...(this.getChildrenLoad(powerSupply).map(load => this.createNodeLoad(load)))
                ]
        };
    }

    private createNodeEvse(evse: Evse): PowerSupplyTree {
        return {
            evse: evse.id,
            children: []
        };
    }

    private createNodeSolar(solarSystem: SolarSystem): PowerSupplyTree {
        return {
            solar: solarSystem.id,
            children: []
        };
    }

    private createNodeLoad(load: Load): PowerSupplyTree {
        return {
            load: load.id,
            children: []
        }
    }

    private getChildrenPsRecursive(parent: PowerSupply): PowerSupply[] {
        const children =  this.getChildrenPs(parent);
        return children.concat(...children.map(ps=>this.getChildrenPsRecursive(ps)));
    }
    public getEvseLeafs(parent: PowerSupply|undefined): Evse[] {
        if(parent === undefined) return [];
        return this.getChildrenEvse(parent)
            .concat(...this.getChildrenPsRecursive(parent).map(ps=>this.getChildrenEvse(ps)));
    }
    public getLoadLeafs(parent: PowerSupply|undefined): Load[] {
        if(parent === undefined) return [];
        return this.getChildrenLoad(parent)
        .concat(...this.getChildrenPsRecursive(parent).map(ps=>this.getChildrenLoad(ps)));
    }
    public getSolarLeafs(parent: PowerSupply|undefined): SolarSystem[] {
        if(parent === undefined) return [];
        return this.getChildrenSolar(parent)
        .concat(...this.getChildrenPsRecursive(parent).map(ps=>this.getChildrenSolar(ps)));
    }

    private getChildrenPs(parent: PowerSupply): PowerSupply[] {
        return this.powerSupplies.filter(ps => ps.parentPowerSupplyId === parent.id);
    }

    private getChildrenEvse(parent: PowerSupply): Evse[] {
        return this.evses.filter(evse => evse.powerSupplyId === parent.id);
    }

    private getChildrenSolar(parent: PowerSupply): SolarSystem[] {
        return this.solarSystems.filter(ss => ss.powerSupplyId === parent.id);
    }

    private getChildrenLoad(parent: PowerSupply): Load[] {
        return this.loads.filter(load => load.powerSupplyId === parent.id);
    }
}
