import {Injectable} from '@angular/core';
import {
    ChargingSessionDetails,
    ChargingSessionList,
    ChargingSessionTableFilterDataList
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {CacheManager} from '../../../services/api-handlers/cacheManager';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
    providedIn: 'root'
})
export class ChargingSessionsService extends CacheManager {

    public constructor(
        private readonly apiService: ApiService
    ) {
        super(POLL_INTERVALS.charging_sessions);
    }

    public getDetails(id: number, showAlerts = true): Promise<ChargingSessionDetails> {
        return this.apiService.getChargingDetails(showAlerts, id, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public getChargingSessionFilterData(showAlerts = true): Promise<ChargingSessionTableFilterDataList> {
        return this.apiService.getChargingSessionTableFilterData(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public getChargingSessionData(basisId: number[], start: number, end: number, showAlerts = true): Promise<ChargingSessionList> {
        return this.apiService.getChargingSessionTimeRange(showAlerts, basisId, start, end, undefined, undefined, ApiHandler.customerId).toPromise();
    }
}
