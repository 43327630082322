import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Evse} from '@io-elon-common/frontend-api';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {EvseHealthDataUtils, PercentageHealthStatus} from '../../../../shared/helper/evse-health-data-utils';
import {Sort} from '@angular/material/sort';
import {compareNumValues, compareStringValues} from '../../../../shared/helper/util-functions';

export interface RowData<T> {
    obj?: T,
    numberOfEvses?: number,
    numberOfFaults: number,
    durationOfFaults: number,
    durationOfFaultsHumanReadable: string,
    pieChartStyle: string | undefined,
    percentage: PercentageHealthStatus,
    totalDuration: number
}

@Component({
    selector: 'app-evse-health-history-table',
    templateUrl: './evse-health-history-table.component.html',
    styleUrl: './evse-health-history-table.component.scss'
})
export class EvseHealthHistoryTableComponent<T> extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

    @Input() public dataRows!: RowData<T>[];
    @Input() public footerRow!: RowData<T>;
    @Input() displayColumns!: string[];

    public dataSource = new MatTableDataSource<RowData<T>>([]);
    public selection = new SelectionModel<number>(true, []);
    public selectedIndex = 0;
    public sort: Sort = {
        active: 'name',
        direction: 'asc',
    };
    public isOpen?: T | string;

    constructor(private readonly evseHealthDataUtils: EvseHealthDataUtils) {
        super();
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource(this.dataRows);
        this.dataSource.paginator = this.paginator;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource = new MatTableDataSource(this.dataRows);
    }

    get getDisplayedColumns(): string[] {
        return this.displayColumns;
    }

    public getHealthIcon(evse: Evse): string {
        return this.evseHealthDataUtils.getEvseHealthIcon(evse);
    }

    public setSort(sort: Sort) {
        const isdesc = sort.direction === 'desc';
        if (!this.dataRows) {
            return;
        }
        switch (sort.active) {
            case 'numberOfFaults':
                this.dataRows.sort((a, b) => {
                    return compareNumValues(a.numberOfFaults, b.numberOfFaults, isdesc);
                });
                break;
            case 'durationOfFault':
                this.dataRows.sort((a, b) => {
                    return compareNumValues(a.durationOfFaults, b.durationOfFaults, isdesc);
                });
                break;
            case 'icon':
                this.dataRows.sort((a, b) => {
                    // @ts-ignore
                    return compareStringValues(a.obj['name'], b.obj['name'], isdesc);
                });
                break;
            case 'fleetName':
                this.dataRows.sort((a, b) => {
                    // @ts-ignore
                    return compareStringValues(a.obj['name'], b.obj['name'], isdesc);
                });
                break;
            case 'percentageOfAvailability':
                this.dataRows.sort((a, b) => {
                    return compareNumValues(a.totalDuration - a.durationOfFaults, b.totalDuration - b.durationOfFaults, isdesc);
                });
                break;
            case 'numberOfEvses':
                this.dataRows.sort((a, b) => {
                    return compareNumValues(a.numberOfEvses, b.numberOfEvses, isdesc);
                });
        }
        this.dataSource.data = this.dataRows;
    }


    public isAvailable(element: RowData<T>): boolean {
        return element && element.pieChartStyle !== '';
    }

    public percentageAsString(data: number, numberOfDecimalPlaces: number, showZero: boolean) {
        if (data === 0 && !showZero) {
            return '---';
        }
        return data.toFixed(numberOfDecimalPlaces) + '%';
    }
}
