import { Component, OnInit } from '@angular/core';
import {MeterService} from "../../service/meter.service";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {MeterConnectionList} from "@io-elon-common/frontend-api";

@Component({
  selector: 'app-meter-connection-debug-view',
  templateUrl: './meter-connection-debug-view.component.html',
  styleUrls: ['./meter-connection-debug-view.component.scss']
})
export class MeterConnectionDebugViewComponent implements OnInit {
    public meterConnections!: BehaviorSubject<MeterConnectionList | undefined>;

    constructor(
        private meterService: MeterService
    ) { }

    ngOnInit(): void {
        this.meterConnections = this.meterService.getMeterConnections();
    }

}
