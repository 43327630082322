import {Injectable} from '@angular/core';
import {DialogHandler} from "../../../services/api-handlers/dialog-handler";
import {
    ExecuteSTecAccountAddOrUpdate,
    STecAccount,
    STecAccountTestResult,
    STecVehicleList
} from "@io-elon-common/frontend-api";
import {ApiService} from "../../../services/api-handlers/api.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../../../services/dialog.service";
import {IEditForm, TDialogOptions} from "../../../shared/components/dialogs/edit-dialog/edit-dialog.component";
import {
    EditSTecAccountDialogComponent
} from "../dialogs/edit-s-tec-account-dialog/edit-s-tec-account-dialog.component";
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
  providedIn: 'root'
})
export class STecAccountService extends DialogHandler<STecAccount, ExecuteSTecAccountAddOrUpdate, ExecuteSTecAccountAddOrUpdate, {}, void, void>{

    public constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "STecAccount", toastr, dialog, dialogService, POLL_INTERVALS.s_tec);
    }

    protected getEditConfig(account: STecAccount): TDialogOptions<ExecuteSTecAccountAddOrUpdate, IEditForm<ExecuteSTecAccountAddOrUpdate>> | Promise<TDialogOptions<ExecuteSTecAccountAddOrUpdate, IEditForm<ExecuteSTecAccountAddOrUpdate>>> {
        return {
            headline: "S-Tec Account bearbeiten",
            component: EditSTecAccountDialogComponent,
            executeCallback: editResult => this.update(account.id, editResult),
            editElement: {
                name: account.name,
                baseUrl: account.baseUrl,
                webTicket: account.webTicket
            }
        };
    }

    protected getNewConfig(): TDialogOptions<ExecuteSTecAccountAddOrUpdate, IEditForm<ExecuteSTecAccountAddOrUpdate>> | Promise<TDialogOptions<ExecuteSTecAccountAddOrUpdate, IEditForm<ExecuteSTecAccountAddOrUpdate>>> {
        return {
            headline: "S-Tec Account hinzufügen",
            component: EditSTecAccountDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                name: "",
                baseUrl: "",
                webTicket: ""
            }
        };
    }

    public testAccount(account: ExecuteSTecAccountAddOrUpdate, showAlerts = true): Promise<STecAccountTestResult> {
        return this.apiService.testSTecAccount(showAlerts, undefined, undefined, ApiHandler.customerId, account).toPromise();
    }

    public getVehicleList(id: number, force: boolean = false, showAlerts = true): Promise<STecVehicleList> {
        return this.apiService.getSTecAccountVehicleList(showAlerts, id, force + "", undefined, undefined, ApiHandler.customerId).toPromise()
    }

}
