import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {ChargingSession, ChargingSessionList} from '@io-elon-common/frontend-api';
import { ApiService } from '../../../../services/api-handlers/api.service';
import { VehicleService } from '../../../../modules/vehicle/service/vehicle.service';
import { ToastrService } from 'ngx-toastr';
import { EvseService } from '../../../../modules/evse/service/evse.service';
import { ChargingsTable } from './chargings-table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DurationPipe } from '../../../helper/duration.pipe';
import { SystemService } from 'src/app/services/api-handlers/system.service';
import { ChargingSessionsService } from "../../../../modules/charging-session/service/chargingSession.service";
import { AuthService } from "../../../guards/auth.service";

@Component({
    selector: 'app-charging-session-table',
    templateUrl: './chargings-table.component.html',
    styleUrls: ['./chargings-table.component.scss']
})
export class ChargingSessionTableComponent extends ChargingsTable implements OnInit, OnChanges {
    @ViewChild(MatPaginator, { static: true })
    public paginator!: MatPaginator;

    @Input() data!: Promise<ChargingSessionList>;

    displayedColumns: string[] = ['expander', 'start', 'end', 'duration', 'vehicle', 'evse', 'rfid', 'power', 'cost', 'stopReason', 'actions'];
    private allowContinueCache: Promise<boolean>[] = [];

    public constructor(
        protected readonly apiService: ApiService,
        protected readonly vehicleService: VehicleService,
        protected readonly evseService: EvseService,
        protected readonly toastrService: ToastrService,
        protected readonly dialog: MatDialog,
        protected readonly durationPipe: DurationPipe,
        protected readonly chargingService: ChargingSessionsService,
        protected readonly systemService: SystemService,
        protected readonly authService: AuthService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onInit();
    }

    protected async getChargings(): Promise<ChargingSessionList> {
        return this.data;
    }

    public isAllowContinue(cs: ChargingSession): Promise<boolean> {
        if(!this.allowContinueCache[cs.id]) {
            if(cs.evseId) {
                this.allowContinueCache[cs.id] = this.evseService.getPromise(cs.evseId).then(e => e.basis.allowContinue);
            } else {
                this.allowContinueCache[cs.id] = Promise.resolve(false);
            }
        }
        return this.allowContinueCache[cs.id];
    }

    public ngOnInit(): void {
        super.onInit();
    }
}
