import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell, MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTooltip} from "@angular/material/tooltip";
import {AbstractTableComponent} from "../../../../../shared/components/tables/AbstractTableComponent";
import {Migration} from "@io-elon-common/frontend-api";
import {MigrationsService} from "../../../../../services/api-handlers/migrations.service";

@Component({
  selector: 'app-migration-table',
  standalone: true,
    imports: [
        AsyncPipe,
        MatCell,
        MatCellDef,
        MatColumnDef,
        MatHeaderCell,
        MatHeaderRow,
        MatHeaderRowDef,
        MatIcon,
        MatIconButton,
        MatPaginator,
        MatRow,
        MatRowDef,
        MatSort,
        MatTable,
        MatTooltip,
        NgIf,
        MatHeaderCellDef
    ],
  templateUrl: './migration-table.component.html',
  styleUrl: './migration-table.component.scss'
})
export class MigrationTableComponent extends AbstractTableComponent implements OnInit, OnChanges{
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input()
    public migrations!: Migration[];

    public displayedColumns: string[] = ['id', 'name', 'state', 'actions'];
    public dataSource = new MatTableDataSource<Migration>([]);

    constructor(private readonly migrationService: MigrationsService) {
        super();
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.migrations);
        this.dataSource.paginator = this.paginator;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.migrations;
    }

    public async start(migration: Migration): Promise<void> {
        await this.migrationService.startMigration(migration.id);
    }

    public async stop(migration: Migration): Promise<void> {
        await this.migrationService.stopMigration(migration.id);
    }

    public trackBy(idx: number, item: Migration) {
        return item.id;
    }
}
