<ng-container *ngIf="(vehicleList | async) as vehicleList; else loading">
    <mat-card appearance="outlined" id="evse-table-container">
        <div class="d-flex flex-row justify-content-end w-100">
            <app-graph-help-icon [options]="['controlZoom', 'leftClickMove', 'doubleLeftClickReset']" [vAlign]="'bottom'"></app-graph-help-icon>
        </div>
        <app-vehicle-reservation-graph
            [vehicles]="vehicleList.list"
            [startIn]="start"
            [endIn]="end"
            [editEnabled]="true"
            (handleDelete)="handleDelete($event)"
            (handleEdit)="handleEdit($event)"
        >
        </app-vehicle-reservation-graph>

    </mat-card>
    <div class="buttons d-flex flex-row justify-content-end gap-3">
        <div
            matTooltip="Kein Fahrzeug für eine Buchung vorhanden"
            [matTooltipDisabled]="vehicleList.list.length > 0"
            matTooltipPosition="above">
            <button mat-raised-button color="primary" (click)="handleNewReservation()" [disabled]="vehicleList.list.length === 0">Neue Buchung</button>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <div *ngIf="error">
        <h1>Hinweis</h1>
        {{error}}
    </div>
    <div *ngIf="!error">
        Loading...
    </div>
</ng-template>
