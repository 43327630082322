import {Component, OnInit} from '@angular/core';

//https://www.w3schools.com/js/js_cookies.asp
function getCookie(cname: string): string {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

@Component({
    selector: 'app-tesla-landing',
    templateUrl: './tesla-landing.component.html',
    styleUrls: ['./tesla-landing.component.scss']
})
export class TeslaLandingComponent implements OnInit {
    success: boolean;
    link: string = "/teslaAccounts";

    constructor() {
        this.success = (/.*teslaOk/.test(window.location.href));
        const fid = getCookie("fleetId")
        const cid = getCookie("customerId")
        if (!fid && !cid) {
            this.link = "/teslaAccounts";
        } else if (!cid) {
            this.link = "/teslaAccounts?fid=" + fid
        } else if (!fid) {
            this.link = "/teslaAccounts?cid=" + cid
        } else {
            this.link = "/teslaAccounts?fid=" + fid + "&cid=" + cid
        }
    }

    ngOnInit(): void {
    }

}
