import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api-handlers/api.service';
import {Dongle, ExecuteDongleAddOrUpdate} from '@io-elon-common/frontend-api';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditDongleDialogComponent} from '../dialogs/edit-dongle-dialog/edit-dongle-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
  providedIn: 'root'
})
export class DongleService extends DialogHandler<Dongle, ExecuteDongleAddOrUpdate, ExecuteDongleAddOrUpdate, {}, void, void>{

  constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
      super(apiService, "Dongle", toastr, dialog, dialogService, POLL_INTERVALS.dongle);
  }

    protected getEditConfig(dongle: Dongle): TDialogOptions<ExecuteDongleAddOrUpdate, EditDongleDialogComponent> {
        return {
            headline: "Dongle bearbeiten",
            component: EditDongleDialogComponent,
            executeCallback: editResult => this.update(dongle.id, editResult),
            editElement: {
                dongleId: dongle.dongleId,
                vehicleId: dongle.vehicle?.id || undefined
            }
        };
    }

    protected getNewConfig(newDialogConfigArgs: void): TDialogOptions<ExecuteDongleAddOrUpdate, EditDongleDialogComponent> {
        return {
            headline: "Dongle hinzufügen",
            component: EditDongleDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                dongleId: -1,
                vehicleId: -1
            }
        };
    }
}
