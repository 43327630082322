<table mat-table [dataSource]="dataSource" class="full-width-table" matSort [trackBy]="trackBy">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-name">Name</th>
        <td mat-cell *matCellDef="let element" class="col-name">
            {{element.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef class="col-state">Zustand</th>
        <td mat-cell *matCellDef="let element" class="col-state">
            {{element.state}}
            <ng-container *ngIf="element.progress"> - {{element.progress}}</ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button [disabled]="!(element.state == 'Paused' || element.state == 'Failed')" (click)="start(element)" mat-icon-button matTooltip="Migration starten"><mat-icon>play_arrow</mat-icon></button>
            <button [disabled]="element.state != 'Running'" (click)="stop(element)" mat-icon-button matTooltip="Migration stoppen"><mat-icon>stop</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
