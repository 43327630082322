import {Component, OnInit} from '@angular/core';
import {
    ChargingSessionList,
    ChargingSessionTableFilterData,
    ChargingSessionTableFilterDataList
} from '@io-elon-common/frontend-api';
import {FormControl, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {ChargingSession} from '@io-elon-common/frontend-api/lib/model/chargingSession';
import {ChargingSessionsService} from '../../service/chargingSession.service';

interface DropDownGroup {
    disabled?: boolean;
    name: string;
    options: Array<ChargingSessionTableFilterData> | undefined;
}

const SELECT_ALL = -1;
const SELECT_UNDEFINED = -2;

type FilterNames = 'locations' | 'rfids' | 'kostenstelle' | 'evses' | 'vehicles';
type TimeRangePreset = 'week' | 'tMonth' | 'lMonth' | 'tYear' | 'lYear' | 'tQuarter' | 'lQuarter';

@Component({
    selector: 'app-charging-session-search-view',
    templateUrl: './charging-session-search-view.component.html',
    styleUrls: ['./charging-session-search-view.component.scss'],
    providers: [DatePipe]
})

export class ChargingSessionSearchViewComponent implements OnInit {

    public readonly SELECT_ALL = SELECT_ALL;
    public readonly SELECT_UNDEFINED = SELECT_UNDEFINED;
    public allFilterOptions!: ChargingSessionTableFilterDataList;
    private previousStartTime!: number;
    private previousEndTime!: number;
    private result!: ChargingSessionList;

    public range = new FormGroup({
        start: new FormControl(new Date()),
        end: new FormControl(new Date())
    });

    public dateRange: Array<{ value: TimeRangePreset, name: string }> = [
        {value: 'week', name: 'letzte 7 Tage'},
        {value: 'tMonth', name: 'Diesen Monat'}, {value: 'lMonth', name: 'Im vergangenen Monat'},
        {value: 'tYear', name: 'Dieses Jahr'}, {value: 'lYear', name: 'Vergangenes Jahr'},
        {value: 'tQuarter', name: 'Dieses Quartal'}, {value: 'lQuarter', name: 'Letztes Quartal'}
    ];

    public currentSelectedLocations: number[] = [];
    public previousSelectedLocations: number[] = [];
    public selectedEvses: number[] = [];
    public selectedKostenstelle: number[] = [];
    public selectedVehicles: number[] = [];
    public selectedRfids: number[] = [];
    public filteredData: Promise<ChargingSessionList> | undefined;

    public evses: DropDownGroup[] = [];
    public vehicles: DropDownGroup[] = [];
    public panelOpenState = true;

    public constructor(
        private readonly chargingSessionsService: ChargingSessionsService,
        private readonly datePipe: DatePipe
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.allFilterOptions = {evses: [], kostenstelle: [], vehicles: [], rfids: [], locations: []}
        this.allFilterOptions = await this.chargingSessionsService.getChargingSessionFilterData();
        this.currentSelectedLocations = [SELECT_ALL];
        this.allFilterOptions.locations.forEach(l => {
            this.currentSelectedLocations.push(l.id);
        });

        this.changeDateRangeSelection('week');
        this.updateEvseVehicleDropdown();
        this.updateSelectionAllFilter();
    }

    public async search(): Promise<void> {
        if (this.currentSelectedLocations !== null) {
            try {
                const currentSelectedStartTime = new Date(this.range.value.start!).setHours(0,0,0,0);
                const currentSelectedEndTime = this.range.value.end !== null ?
                    new Date(this.range.value.end!).setHours(23, 59, 59, 999) :
                    new Date(this.range.value.start!).setHours(23, 59, 59, 999);

                if (this.isLocationSelectionChanged() || this.isTimeDifFromPrevious(currentSelectedStartTime, currentSelectedEndTime)) {
                    this.result = await this.chargingSessionsService.getChargingSessionData(
                        this.currentSelectedLocations,
                        currentSelectedStartTime,
                        currentSelectedEndTime
                    );

                    this.previousSelectedLocations = [...this.currentSelectedLocations];
                    this.previousStartTime = currentSelectedStartTime;
                    this.previousEndTime = currentSelectedEndTime;
                }
                this.filteredData = Promise.resolve(this.getFilteredResult());
            } catch (err) {
                console.error(err);
            }
            this.panelOpenState = false;
        }
    }

    public toggleAllSelection(selectedList: FilterNames): void {
        const list = this.getSelectedList(selectedList);
        list.includes(SELECT_ALL) ? this.setAllItemsSelected(selectedList) : this.setSelectedList(selectedList, []);

        if (selectedList === 'locations') {
            this.updateEvseVehicleDropdown();
            this.updateSelectionAllFilter();
        }
    }

    public toggleSelectedOption(selectedList: FilterNames): void {
        const selected = this.getSelectedList(selectedList);
        // Number of all visible options minus 'ALLE' and minus 'Show Undefined'
        const fullListLength = this.getFullListLength(selectedList);

        const allSelected = selected.includes(SELECT_ALL) ? 1 : 0;
        const listHasUndefined = selectedList === "locations" ? 0 : 1;

        // plus one for undefined selection
        const shouldEnableAll = fullListLength + listHasUndefined === selected.length - allSelected;

        if (allSelected && !shouldEnableAll) {
            this.setSelectedList(selectedList, selected.slice(1));
        } else if (!allSelected && shouldEnableAll) {
            this.setSelectedList(selectedList, [SELECT_ALL, ...selected]);
        }

        if (selectedList === 'locations') {
            this.updateEvseVehicleDropdown();
            this.updateSelectionAllFilter();
        }
    }

    public changeDateRangeSelection(selectionRange: TimeRangePreset): void {
        const endDate = new Date();
        const startDate = new Date();

        switch (selectionRange) {
            case 'tMonth':
                startDate.setDate(1);
                break;
            case 'lMonth':
                endDate.setDate(0);
                startDate.setDate(1);
                startDate.setMonth(startDate.getMonth() - 1);
                break;
            case 'tYear':
                startDate.setDate(1);
                startDate.setMonth(0);
                break;
            case 'lYear':
                startDate.setDate(1);
                startDate.setMonth(0);
                startDate.setFullYear(startDate.getFullYear() - 1);
                endDate.setMonth(12);
                endDate.setDate(0);
                endDate.setFullYear(startDate.getFullYear());
                break;
            case 'tQuarter':
                startDate.setDate(1);
                startDate.setMonth(Math.ceil(startDate.getMonth() / 3));
                endDate.setMonth(startDate.getMonth() + 3);
                endDate.setDate(0);
                break;
            case 'lQuarter':
                startDate.setDate(1);
                startDate.setMonth(Math.ceil(startDate.getMonth() / 3) - 3);
                endDate.setFullYear(startDate.getFullYear());
                endDate.setMonth(startDate.getMonth() + 3);
                endDate.setDate(0);
                break;
            // case "week": week is same as default selection
            default:
                startDate.setDate(endDate.getDate() - 7);
        }

        this.range.setValue({
            start: startDate,
            end: endDate
        });

    }

    public getDropDowntext(selectedItem: number[], data: ChargingSessionTableFilterData[]): string {
        if (selectedItem.includes(SELECT_ALL)) {
            return 'Alles';
        } else if (selectedItem.length === 1) {
            if(selectedItem[0] === SELECT_UNDEFINED) {
                return "Alle ohne Daten";
            }
            return data.find(i => i.id === selectedItem[0])?.name || '1 ausgewählt';
        } else if (selectedItem.length === 0) {
            return 'Nichts ausgewählt';
        }
        return selectedItem.length + ' ausgewählt';
    }

    public queryText(type: FilterNames | 'dateRange'): string {
        if (type === 'dateRange') {
            const startFull = this.datePipe.transform(this.range.value.start, 'dd.MM.yyyy');
            if (startFull === null) {
                return 'Nicht ausgewählt';
            }

            const endFull = this.datePipe.transform(this.range.value.end, 'dd.MM.yyyy');
            if (startFull === endFull) {
                return startFull || '';
            }

            const startYear = this.datePipe.transform(this.range.value.start, 'yyyy');
            const endYear = this.datePipe.transform(this.range.value.end, 'yyyy');
            if (startYear === endYear) {
                const startMonth = this.datePipe.transform(this.range.value.start, 'dd.MM');
                return startMonth + ' bis ' + endFull;
            }

            return startFull + (endFull !== null ? ' bis ' + endFull : '');
        }

        return this.getDropDowntext(this.getSelectedList(type), this.getFullList(type));
    }

    private updateSelectionAllFilter(): void {
        this.setAllItemsSelected('rfids');
        this.setAllItemsSelected('kostenstelle');
        this.setAllItemsSelected('evses');
        this.setAllItemsSelected('vehicles');
    }

    private updateEvseVehicleDropdown() {
        this.evses = [];
        this.vehicles = [];
        this.allFilterOptions.locations.forEach(l => {
            if (this.currentSelectedLocations.includes(l.id)) {
                this.evses.push({
                    name: l.name,
                    options: this.allFilterOptions.evses.filter(vObj => vObj.basisId === l.id).sort((a, b) => a.name.localeCompare(b.name))
                });
                this.vehicles.push({
                    name: l.name,
                    options: this.allFilterOptions.vehicles.filter(vObj => vObj.basisId === l.id).sort((a, b) => a.name.localeCompare(b.name))
                });
            }
        });
    }

    private getSelectedList(type: FilterNames): number[] {
        switch (type) {
            case 'locations':
                return this.currentSelectedLocations;
            case 'rfids':
                return this.selectedRfids;
            case 'kostenstelle':
                return this.selectedKostenstelle;
            case 'evses':
                return this.selectedEvses;
            case 'vehicles':
                return this.selectedVehicles;
            default:
                console.error("Unknown Type: ", type)
                return [];
        }
    }

    private setSelectedList(type: FilterNames, list: number[]) {
        switch (type) {
            case 'locations':
                this.currentSelectedLocations = list;
                break;
            case 'rfids':
                this.selectedRfids = list;
                break;
            case 'kostenstelle':
                this.selectedKostenstelle = list;
                break;
            case 'evses':
                this.selectedEvses = list;
                break;
            case 'vehicles':
                this.selectedVehicles = list;
                break;
            default:
                console.error("Unknown Type: ", type)
        }
    }

    private getFullList(type: FilterNames): ChargingSessionTableFilterData[] {
        switch (type) {
            case 'locations':
                return this.allFilterOptions.locations || [];
            case 'rfids':
                return this.allFilterOptions.rfids || [];
            case 'kostenstelle':
                return this.allFilterOptions.kostenstelle || [];
            case 'evses':
                return this.allFilterOptions.evses || [];
            case 'vehicles':
                return this.allFilterOptions.vehicles || [];
            default:
                return [];
        }
    }

    private getFullListLength(type: FilterNames): number {
        if (type === 'evses' || type === 'vehicles') {
            return this.getFullList(type).filter(d => this.currentSelectedLocations.includes(d.basisId || -1)).length;
        }
        return this.getFullList(type).length;
    }

    private setAllItemsSelected(type: FilterNames): void {
        const list = [SELECT_ALL, SELECT_UNDEFINED];
        this.getFullList(type).forEach(e => {
            if ((type !== 'evses' && type !== 'vehicles') || this.currentSelectedLocations.includes(e.basisId ? e.basisId : -1)) {
                list.push(e.id);
            }
        });
        this.setSelectedList(type, list);
    }

    private doesMatch(id: number | undefined, filter: FilterNames): boolean {
        const selected = this.getSelectedList(filter);
        if(id === undefined) {
            return selected.includes(SELECT_UNDEFINED);
        }
        return selected.includes(id);
    }

    private doesMatchVehicle(id: number | undefined): boolean {
        const selected = this.getSelectedList("vehicles");
        if(id === undefined || id < 0) {
            return selected.includes(SELECT_UNDEFINED);
        }
        return selected.includes(id);
    }

    private doesMatchKostenstelle(d: ChargingSession): boolean {
        if(d.kostenstelle === undefined || d.kostenstelle === "") {
            return this.selectedKostenstelle.includes(SELECT_UNDEFINED);
        }
        return this.selectedKostenstelle.includes(this.allFilterOptions.kostenstelle.find(k => k.name === d.kostenstelle)?.id || -1);
    }

    private doesMatchRfid(d: ChargingSession): boolean {
        if(d.rfid === undefined) {
            return this.selectedRfids.includes(SELECT_UNDEFINED);
        }
        return this.selectedRfids.includes(this.allFilterOptions.rfids.find(r => r.name === d.rfid)?.id || -1);
    }

    private getFilteredResult(): ChargingSessionList {
        const tempResult = Object.assign({}, this.result);
        tempResult.list = tempResult.list.filter(d => (
            this.doesMatchKostenstelle(d) &&
            this.doesMatch(d.evseId, 'evses') &&
            this.doesMatchVehicle(d.vehicleId) &&
            this.doesMatchRfid(d)
        ));
        return tempResult;
    }

    private isLocationSelectionChanged(): boolean {
        return !(this.currentSelectedLocations.every(elem => this.previousSelectedLocations.includes(elem)));
    }

    private isTimeDifFromPrevious(start: number, end: number): boolean {
        return this.previousStartTime !== start || this.previousEndTime !== end;
    }
}
