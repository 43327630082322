import {Component, Input} from '@angular/core';
import {ChargePlanValue} from "@io-elon-common/frontend-api";
import {MatHint} from "@angular/material/form-field";
import {MatTooltip} from "@angular/material/tooltip";
import {NgIf} from "@angular/common";
import {SystemService} from "../../../services/api-handlers/system.service";
import {CdkConnectedOverlay, CdkOverlayOrigin} from "@angular/cdk/overlay";
import {MatIcon} from "@angular/material/icon";
import {PowerSupplyService} from "../../../modules/basis/service/power-supply.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-algo-reason',
  standalone: true,
    imports: [
        MatHint,
        MatTooltip,
        NgIf,
        CdkOverlayOrigin,
        CdkConnectedOverlay,
        MatIcon
    ],
  templateUrl: './algo-reason.component.html',
  styleUrl: './algo-reason.component.scss'
})
export class AlgoReasonComponent {
    @Input()
    set reason(value: ChargePlanValue) {
        this._reason = value;
        for (let fuse of value.limitedByFuses ?? []) {
            if (!this.fuseNames.get(fuse)) {
                this.fuseNames.set(fuse, "[lädt...]");
                this.fuseNameSubscriptions.set(fuse, this.powerSupplyService.get(fuse).subscribe((ps) => {
                    this.fuseNames.set(fuse, ps?.name);
                    this.fuseNameSubscriptions.get(fuse)?.unsubscribe();
                }, _ => {
                    this.fuseNames.set(fuse, "[Error...]");
                    this.fuseNameSubscriptions.get(fuse)?.unsubscribe();
                }));
            }
        }
        for (let fuse of this.fuseNames.keys()) {
            if (!value.limitedByFuses?.includes(fuse)) {
                this.fuseNames.delete(fuse);
            }
        }
        //console.log(this.fuseNames);
    }
    get reason(): ChargePlanValue {
        return this._reason;
    }
    private _reason!: ChargePlanValue

    public isOpen: boolean = false;

    public fuseNameSubscriptions: Map<number, Subscription> = new Map<number, Subscription>();
    public fuseNames: Map<number, string | undefined> = new Map<number, string>();

    constructor(
        private readonly systemService: SystemService,
        private readonly powerSupplyService: PowerSupplyService
    ) {
    }

    public getTooltip(){
        return this._reason?.reasonDetails
    }
    public getIcon() {
        return this.systemService.getSystemInfoSync()?.chargePlanReasons?.find(r => r.name == this._reason?.reasonType)?.icon;
    }
    public getText() {
        return this.systemService.getSystemInfoSync()?.chargePlanReasons?.find(r => r.name == this._reason?.reasonType)?.text;
    }
    public getFuseNames(): string | undefined {
        return this._reason.limitedByFuses?.map(fuse => this.fuseNames.get(fuse)).join(", ");
    }
}
