<table mat-table [dataSource]="dataSource" class="full-width-table" matSort>

    <!-- Id Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
        </td>
    </ng-container>

    <!-- Ven ID Column -->
    <ng-container matColumnDef="venId">
        <th mat-header-cell *matHeaderCellDef class="col-username"> Ven ID</th>
        <td mat-cell *matCellDef="let element" class="col-username">
            {{element.venId}}
        </td>
    </ng-container>

    <!-- Base Url Column -->
    <ng-container matColumnDef="baseUrl">
        <th mat-header-cell *matHeaderCellDef class="col-name"> Base Url</th>
        <td mat-cell *matCellDef="let element" class="col-name">
            {{element.baseUrl}}
        </td>
    </ng-container>  

    <!-- actions -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button (click)="downloadCert(element.cert, element.id)" mat-icon-button matTooltip="Download Certificate" [style.visibility]="element.cert != '' ? 'visible' : 'hidden'">
                <mat-icon fontSet="material-icons">file_download</mat-icon>
            </button>       
            <button (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon fontSet="material-icons">edit</mat-icon></button>            
            <button (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon fontSet="material-icons">delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
