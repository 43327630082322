import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EvseService} from '../../service/evse.service';

export interface EvseDialogData {
    evseId: number,
    evseName: string
}
@Component({
  selector: 'app-evse-log-dialog',
  templateUrl: './evse-log-dialog.component.html',
  styleUrls: ['./evse-log-dialog.component.scss']
})

export class EvseLogDialogComponent implements OnInit {
    public connected: boolean;
    public socket?: WebSocket
    public log = "";
    public autoScroll = true;
    public evseId: number;

    constructor(@Inject(MAT_DIALOG_DATA) public dialogData: EvseDialogData,
                private dialogRef: MatDialogRef<EvseDialogData>,
                private readonly evseService: EvseService) {
        this.connected = false;
        this.evseId = dialogData.evseId;
    }

    ngOnInit(): void {
        this.connect();
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public async downloadLog() {
        if (this.dialogData.evseId !== undefined) {
            await this.evseService.downloadLog(this.dialogData.evseId);
        }
    }

    ngOnDestroy(): void {
        if(this.connected) {
            this.closeConnection();
        }
    }
    private onMessage(msg: string) {
        this.log += msg;
        if(this.autoScroll) {
            this.scrollDown();
        }
    }
    private closeConnection() {
        this.socket?.close();
        this.connected = false;
    }

    private connect() {
        this.socket = this.evseService.getLiveLogs(this.dialogData.evseId);
        this.socket.onmessage = msg => this.onMessage(msg.data + "\n");
        this.socket.onerror = err => this.onMessage("Fehler: " + err);
        this.socket.onclose = evt => this.onMessage("CLOSE --- CLOSE --- CLOSE");
        this.connected = true;
    }

    private scrollDown() {
        if (this.autoScroll) {
            setTimeout(() => {
                const element = document.getElementById("scroll-pre");
                if (element) {
                    element.scrollTop = element.scrollHeight - element.clientHeight;
                }
            });
        }
    }
}
