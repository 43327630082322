import { Component, OnInit } from '@angular/core';
import { ConfigurationDataService } from '../../service/configuration-data.service';
import CategoryEnum = ConfigurationData.CategoryEnum;
import {MatTableDataSource} from '@angular/material/table';
import {ConfigDataComment} from '@io-elon-common/frontend-api/lib/model/configDataComment';
import {ConfigurationData} from '@io-elon-common/frontend-api';

export interface ConfigData {
    name: string,
    environment: ConfigurationData | undefined,
    file: ConfigurationData | undefined,
    default: ConfigurationData | undefined
    missing: ConfigurationData | undefined
    comment: ConfigDataComment | undefined
}
@Component({
  selector: 'app-configuration-list-view',
  templateUrl: './configuration-list-view.component.html',
  styleUrls: ['./configuration-list-view.component.scss']
})

export class ConfigurationListViewComponent implements OnInit {

    public configurationData: Array<ConfigData> = [];
    public displayedColumns: string[] = ['key', 'environment', 'file', 'default'];
    public dataSource = new MatTableDataSource<ConfigData>([]);
    constructor(private readonly configurationDataService: ConfigurationDataService) { }

    async ngOnInit(): Promise<void> {
        await this.initConfigData();
    }

    public getMissingConfigs(): ConfigData[] {
        return this.configurationData.filter(data => data.missing)
    }

    public async updateComment(comment: ConfigDataComment, name: string) {
        if (comment) {
            await this.configurationDataService.showEditDialog(comment);
        } else {
            await this.configurationDataService.showNewDialog(name);
        }
        await this.initConfigData();
    }

    private async initConfigData(): Promise<void> {
        const configList = await this.configurationDataService.getConfigurationDataList();
        if (configList) {
            this.configurationData = [];
            configList.list.forEach((data) => {
                const existingConfig = this.configurationData.find(d => d.name === data.name);
                if (existingConfig) {
                    this.setConfigDataToCategory(data, existingConfig);
                } else {
                    this.configurationData.push(this.setConfigDataToCategory(data, {
                        name: data.name,
                        file: undefined,
                        default: undefined,
                        environment: undefined,
                        missing: undefined,
                        // @ts-ignore
                        comment: configList.comments.find(c => c.name === data.name)
                    }));
                }
            });
            this.dataSource = new MatTableDataSource(this.configurationData.filter(data => !data.missing));
        }
    }

    private setConfigDataToCategory(data: ConfigurationData, config: ConfigData): ConfigData {
      switch (data.category) {
          case CategoryEnum.Environment:
              config.environment = data;
              break;
          case CategoryEnum.File:
              config.file = data;
              break;
          case CategoryEnum.Default:
              config.default = data;
              break;
          case CategoryEnum.Missing:
              config.missing = data;
      }
      return config;
    }
}
