import {Injectable} from '@angular/core';
import {Evse, EvseMini, EvseTeaser} from '@io-elon-common/frontend-api';

@Injectable({
    providedIn: 'root'
})
export class EvseUtilsService {

    constructor() {
    }

    calcEvseName(evse: Evse | EvseTeaser | EvseMini | string): string {
        if (typeof evse === "string") {
            return evse;
        }
        return evse.name || evse.localId || evse.type || "";
    }
}
