import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Evse, Fleet, Load, NoteRelatedObject, SolarSystem} from '@io-elon-common/frontend-api';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {EvseService} from '../../../evse/service/evse.service';
import {AuthService} from '../../../../shared/guards/auth.service';

@Component({
    selector: 'app-basis-tab-group',
    templateUrl: './basis-tab-group.component.html',
    styleUrl: './basis-tab-group.component.scss'
})
export class BasisTabGroupComponent implements OnInit, OnDestroy {
    public fleet!: BehaviorSubject<Fleet | undefined>;
    public solarSystems!: BehaviorSubject<SolarSystem[] | undefined>;
    public evses!: BehaviorSubject<Evse[] | undefined>;
    public loads!: BehaviorSubject<Load[] | undefined>;
    public error = '';
    public hasEvseStatisticViewPermission: boolean = false;
    private currentBasisId: number | undefined = undefined;
    private evsesInBasis: Evse[] = [];
    private fleetIdSubscription?: Subscription;
    protected readonly NoteRelatedObject = NoteRelatedObject;

    constructor(
        private readonly fleetService: FleetService,
        private readonly evseService: EvseService,
        private readonly authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this.evses = this.evseService.getAll();
        this.fleetIdSubscription = this.fleetService.selectedFleet.subscribe(sf => {
            if (sf !== undefined) {
                this.fleet = this.fleetService.get(sf);
            } else {
                this.error = 'Es wurde keine Flotte ausgewählt.';
            }
        });
        this.hasEvseStatisticViewPermission = this.authService.hasGlobalPermission('VIEW_EVSE_STATISTICS');
    }

    ngOnDestroy(): void {
        if (this.fleetIdSubscription) {
            this.fleetIdSubscription.unsubscribe();
        }
    }


    public get basePosition(): { lat: number, lng: number } | undefined {
        const base = this.fleet.getValue()?.base;
        if (!base) {
            return undefined;
        }
        return {
            lat: base.lat,
            lng: base.lon
        };
    }


    public getEvsesInBasis(evses: Evse[], basisId: number): Evse[] {
        if (this.currentBasisId !== basisId) {
            this.evsesInBasis = evses.filter(evse => evse.basis.id === basisId);
            this.currentBasisId = basisId;
        }
        return this.evsesInBasis;
    }
}
