import {Directive, EventEmitter, HostListener, Output, ElementRef, OnInit} from '@angular/core';

@Directive({
    selector: '[appDragHelper]'
})
export class DragHelperDirective implements OnInit{

    private active = false;
    private ignoreNextClick = false;
    private x?: number;
    private y?: number;

    @Output()
    public appOnDragY = new EventEmitter<number>();
    @Output()
    public appOnDragX = new EventEmitter<number>();
    @Output()
    public appOnDrag = new EventEmitter<{x: number, y: number}>();

    constructor(
        private readonly elementRef: ElementRef
    ) {

    }
    ngOnInit(): void {
        this.elementRef.nativeElement.addEventListener("click", (event:MouseEvent) => {
            // capture side, of event chain
            if(this.ignoreNextClick){
                console.log("ignoring click");
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                this.ignoreNextClick = false;
            }
        }, true);

        this.elementRef.nativeElement.addEventListener("touchmove", (event: TouchEvent) => {
            if(this.active) {
                event.preventDefault();
                event.stopPropagation();
                const dx = event.touches[0].pageX - (this.x as number);
                const dy = event.touches[0].pageY - (this.y as number);

                this.x = event.touches[0].pageX;
                this.y = event.touches[0].pageY;

                if(dx) {
                    this.appOnDragX.emit(dx);
                }
                if(dy) {
                    this.appOnDragY.emit(dy);
                }
                if(dx || dy) {
                    this.appOnDrag.emit({x: dx, y: dy});
                }
            }
        }, {
            passive: false,
            capture: true
        });


        this.elementRef.nativeElement.addEventListener("touchstart", (event: TouchEvent) => {
            if(event.touches.length === 1) {
                this.active = true;
                this.x = event.touches[0].pageX;
                this.y = event.touches[0].pageY;
            } else {
                this.active = false;
                this.x = undefined;
                this.y = undefined;
            }
        }, {
            passive: false,
            capture: true
        });
    }


    @HostListener("mousedown", ['$event'])
    public handleDown(event: MouseEvent) {
        event.preventDefault()
        this.x = event.screenX;
        this.y = event.screenY;
        this.active = true;
    }

    @HostListener("mouseup")
    public handleUp() {
        this.x = undefined;
        this.y = undefined;
        this.active = false;
    }

    @HostListener("touchend")
    public handleTouchEnd() {
        this.active = false;
        this.x = undefined;
        this.y = undefined;
    }

    @HostListener("mousemove", ['$event'])
    public handleMove(event: MouseEvent) {
        if(this.active) {
            event.preventDefault();
            const dx = event.screenX - (this.x as number);
            const dy = event.screenY - (this.y as number);

            this.x = event.screenX;
            this.y = event.screenY;

            if(dx) {
                this.appOnDragX.emit(dx);
            }
            if(dy) {
                this.appOnDragY.emit(dy);
            }
            if(dx || dy) {
                this.appOnDrag.emit({x: dx, y: dy});
            }
            this.ignoreNextClick = true;
        }
    }
}
