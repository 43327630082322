import {Component, Input, OnInit} from '@angular/core';
import {NotificationEntry} from "@io-elon-common/frontend-api";
import {DatePipe} from "@angular/common";
import {DialogService} from "../../../services/dialog.service";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    @Input()
    public notification!: NotificationEntry;

    @Input()
    public headerName: string = "hello";

    constructor(
        private readonly datePipe: DatePipe,
        private readonly dialogService: DialogService
    ) { }

    public showNotificationPopup() {
        this.dialogService.showInfoDialog(this.notificationLevelToName()+' für '+this.headerName,this.getNotificationTooltipText());
    }

    public getNotificationTooltipText(): string {
        const tstDate = new Date(this.notification.tst);
        const recentTstDate = new Date(this.notification.recent_tst);

        const formattedTstDate = this.datePipe.transform(tstDate, 'dd.MM.yyyy, HH:mm:ss');
        if (this.checkSameDate(tstDate, recentTstDate)) {
            const formattedRecentTstDate = this.datePipe.transform(recentTstDate, 'HH:mm:ss');
            return this.notification.message + "\nSeit: " + formattedTstDate + "\nZuletzt: " + formattedRecentTstDate;
        } else {
            const formattedRecentTstDate = this.datePipe.transform(recentTstDate, 'dd.MM.yyyy, HH:mm:ss');
            return this.notification.message + "\nSeit: " + formattedTstDate + "\nZuletzt: " + formattedRecentTstDate;
        }
    }

    private checkSameDate(date1: Date, date2: Date): boolean {
        const formattedDate1 = date1.toISOString().split('T')[0];
        const formattedDate2 = date2.toISOString().split('T')[0];
        return formattedDate1 === formattedDate2;
    }

    private notificationLevelToName(): string {
        switch (this.notification.level) {
            case 100: return "Fataler Fehler"
            case 200: return "Error"
            case 300: return "Warnung"
            case 400: return "Information"
            case 500: return "Debugging Informationen"
            default: return "Unbekannt"
        }
    }

    ngOnInit(): void {

    }

}
