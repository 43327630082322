import {Component, OnInit} from '@angular/core';

declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-api-doc',
  templateUrl: './api-doc.component.html',
  styleUrls: ['./api-doc.component.scss']
})
export class ApiDocComponent implements OnInit {

  constructor() { }

    ngOnInit(): void {
        const ui = SwaggerUIBundle({
            dom_id: '#swagger-ui',
            layout: 'BaseLayout',
            presets: [
                SwaggerUIBundle.presets.apis,
                SwaggerUIBundle.SwaggerUIStandalonePreset
            ],
            url: '/api/api',
            docExpansion: 'none',
            operationsSorter: 'alpha'
        });
    }

}
