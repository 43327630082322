<div style="position: relative">
    <mat-spinner *ngIf="reloadExecutor.running || refreshActive" class="center-spinner"></mat-spinner>
    <table mat-table [dataSource]="dataSource" class="full-width-table" appDragHelper (appOnDragX)="onDragX($event)" appZoomHelper (appOnZoom)="onZoom($event)">
        <!-- Fahrzeug -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Fahrzeug</th>
            <td mat-cell *matCellDef="let element" class="vehicle">
                <mat-icon class="igray" [svgIcon]="getBattery(element) | BatteryIcon"></mat-icon> <span *ngIf="!element.canView || !editEnabled">{{element.name}}</span>
                <a class="c-blue-1" *ngIf="element.canView && editEnabled" mat-list-item routerLink="/fleet/vehicle/{{element.id}}">
                    {{element.name}}
                </a><br>
            </td>
        </ng-container>

        <!-- Auslastung -->
        <ng-container matColumnDef="graph">
            <th mat-header-cell *matHeaderCellDef class="graph" id="graphHeader">
                <div *ngFor="let style of getDayLinePositions()" [style]="style" class="grid">
                </div>
                <div *ngFor="let header of getHeaderTimes()" [style]="header.style" class="headerTime">
                    <span class="strong">{{header.line1}}</span><br>{{header.line2}}
                </div>
            </th>
            <td mat-cell *matCellDef="let element" class="graph">
                <div *ngFor="let style of getWeekends()" [style]="style" class="weekend">
                </div>
                <div *ngFor="let style of getDayLinePositions()" [style]="style" class="grid">
                </div>
                <div
                    *ngFor="let res of getReservations(element)"
                    [style]="calcStyle(res)"
                    class="reservation"
                    [matTooltip]="getTooltip(res)"
                    [matMenuTriggerFor]="menu"
                >
                    <span class="strong">
                    {{formatReservationLine1(res)}}<br>
                    {{res.reservation.distance}} km {{res.reservation.driver | fullName: 'kein Fahrer'}}</span>
                    <mat-menu #menu="matMenu" >
                        <span class="popup-title"><b>{{formatReservationLine1(res)}}</b></span>
                        <span class="popup-title follow-line" *ngIf="res.readyAt">Bereit um: <b>{{res.readyAt | date:'shortTime'}}</b></span>
                        <span class="popup-title">Strecke: <b>{{res.reservation.distance}} km</b></span>
                        <span class="popup-title">Fahrer:</span>
                        <span class="popup-title follow-line"><b>{{res.reservation.driver | fullName}}</b></span>
                        <hr>
                        <button *ngIf="editEnabled && !res.reservation.freq" [disabled]="!res.reservation.canEdit" mat-menu-item (click)="edit(res, 'ALL')"><mat-icon>edit</mat-icon>Bearbeiten</button>
                        <span class="popup-title" *ngIf="editEnabled && res.reservation.freq"><b>Bearbeiten:</b></span>
                        <button class="edit-btn-list" *ngIf="editEnabled && res.reservation.freq" [disabled]="!res.reservation.canEdit" mat-menu-item (click)="edit(res, 'ALL')">Serie</button>
                        <button class="edit-btn-list" *ngIf="editEnabled && res.reservation.freq" [disabled]="!res.reservation.canEdit" mat-menu-item (click)="edit(res, 'FUTURE')">Zukünftige Termine</button>
                        <button class="edit-btn-list" *ngIf="editEnabled && res.reservation.freq" [disabled]="!res.reservation.canEdit" mat-menu-item (click)="edit(res, 'SINGLE')">Dieser Termin</button>
                        <hr *ngIf="editEnabled && res.reservation.freq">
                        <button *ngIf="editEnabled && !res.reservation.freq" [disabled]="!res.reservation.canDelete" mat-menu-item (click)="delete(res, 'ALL')"><mat-icon>delete</mat-icon>Löschen</button>
                        <span class="popup-title" *ngIf="editEnabled && res.reservation.freq"><b>Löschen:</b></span>
                        <button class="edit-btn-list" *ngIf="editEnabled && res.reservation.freq" [disabled]="!res.reservation.canDelete" mat-menu-item (click)="delete(res, 'ALL')">Serie</button>
                        <button class="edit-btn-list" *ngIf="editEnabled && res.reservation.freq" [disabled]="!res.reservation.canDelete" mat-menu-item (click)="delete(res, 'FUTURE')">Zukünftige Termine</button>
                        <button class="edit-btn-list" *ngIf="editEnabled && res.reservation.freq" [disabled]="!res.reservation.canDelete" mat-menu-item (click)="delete(res, 'SINGLE')">Dieser Termin</button>
                    </mat-menu>
                </div>
                <div [style]="getNow()" class="now-line">
                </div>

                <div *ngIf="getHighlight(element).visible" [style]="getHighlight(element).style" class="highlight">
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>
    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="10"
                   [pageSizeOptions]="[10, 25, 50, 100, 250]" showFirstLastButtons>
    </mat-paginator>
</div>
