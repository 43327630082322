import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiHandler} from 'src/app/services/api-handlers/api-handler';
import {FleetService} from 'src/app/modules/vehicle/service/fleet.service';
import {AuthService} from "../../shared/guards/auth.service";
import {localStorageGet, localStorageSave} from "../../shared/helper/typed-local-storage";

@Component({
    selector: 'app-default',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
    public isDev?: boolean;
    public muteDevHint = false;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly fleetService: FleetService,
        private readonly authService: AuthService) {
    }

    ngOnInit(): void {
        this.isDev = this.authService.isDeveloper();
        this.muteDevHint = localStorageGet("MUTE_DEV_HINT", "false") === "true"
        this.activatedRoute.queryParams.subscribe(params => {
            if (params['cid']) {
                ApiHandler.customerId = +params['cid'];
            }
            if (params['fid']) {
                this.fleetService.setSelectedFleet(+params['fid']);
            }
        })
    }

    public updateMuteSetting(): void {
        localStorageSave("MUTE_DEV_HINT", this.muteDevHint ? "true" : "false")
    }
}
