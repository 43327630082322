import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    Directive,
    Inject,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {isArray} from "rxjs/internal-compatibility";

export type TDialogOptions<DataType, DialogComponent> = {
    executeCallback: (elem: DataType) => Promise<any>,
    component: Type<DialogComponent>,
    headline: string
    editElement: DataType,
    extraParams?: Partial<DialogComponent>,
    keepAlive?: boolean
};

export interface IEditForm<DataType> {
    data: DataType
    validate: () => string[] | Promise<string[]>
}


@Directive({
    selector: '[appContainer]',
})
export class DynamicContainerDirective {
    constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
    selector: 'app-edit-dialog',
    templateUrl: './edit-dialog.component.html',
    styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent<DataType, DialogComponent extends IEditForm<DataType>> implements AfterViewInit {
    public headline: string
    public saveActive = false;
    private component!: DialogComponent;

    @ViewChild(DynamicContainerDirective, {static: true}) container!: DynamicContainerDirective;

    constructor(
        private readonly dialogRef: MatDialogRef<EditDialogComponent<DataType, DialogComponent>>,
        @Inject(MAT_DIALOG_DATA) private readonly data: TDialogOptions<DataType, DialogComponent>,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly toastr: ToastrService
    ) {
        this.dialogRef.disableClose = true;
        this.headline = this.data.headline;
    }

    ngAfterViewInit(): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.component);
        const viewContainerRef = this.container.viewContainerRef;
        viewContainerRef.clear();
        this.component = viewContainerRef.createComponent<DialogComponent>(componentFactory).instance;
        this.component.data = this.data.editElement;
        if(this.data.extraParams) {
            for(const key in this.data.extraParams){
                if(this.data.extraParams.hasOwnProperty(key)){
                    // @ts-ignore
                    this.component[key] = this.data.extraParams[key];
                }
            }
        }
    }

    public async save(): Promise<void> {
        const validateResult = this.component.validate();
        const validate = isArray(validateResult) ? validateResult : await validateResult
        if(validate.length > 0) { // We got an error message, print it
            this.toastr.warning(validate.join(" \n"), "Ungültige Daten");
            throw new Error("");
        }
        if(!this.data.executeCallback){
            throw new Error("Execute callback is not defined");
        }
        this.saveActive = true;
        try {
            await this.data.executeCallback(this.data.editElement);
            if (this.data.keepAlive === undefined || !this.data.keepAlive) {
                this.dialogRef.close(true);
            }
        } finally {
            this.saveActive = false;
        }
    }

    public close(): void {
        this.dialogRef.close(false);
    }
}
