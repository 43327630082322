import {Component, ElementRef, EventEmitter, Input, OnInit} from '@angular/core';
import {AbstractHistoryGraph, BackgroundLegend} from '../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import Annotation = dygraphs.Annotation;
import {PowerSupplyService} from '../../service/power-supply.service';
import {PeakHistory} from '@io-elon-common/frontend-api';


declare const smoothPlotter: any;

interface IPeakHistoryGraphData{
    data: Array<Array<Date | number | null>>;
    events: Annotation[];
}

@Component({
    selector: 'app-peak-history-graph',
    templateUrl: './peak-history-graph.component.html',
    styleUrls: ['./peak-history-graph.component.scss']
})
export class PeakHistoryGraphComponent extends AbstractHistoryGraph<IPeakHistoryGraphData> implements OnInit {

    @Input()
    public powerSupplyId!: number;

    public autoReload = false;
    public autoReloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(
        private readonly element: ElementRef,
        private readonly powerSupplyService: PowerSupplyService
    ) {
        super();
    }

    public async ngOnInit() {
        await this.init(this.element);
    }

    public defaultStart(): number {
        return Date.now() - 1000 * 60 * 60 * 365; // 1 Jahr
    }

    public defaultEnd(): number {
        return Date.now()// Jetzt
    }

    getBackgroundLegend(): BackgroundLegend[] {
        return [];
    }

    protected async getConfig(): Promise<dygraphs.Options> {
        return {
            axes: {
                y: {
                    axisLabelFormatter: (w: number | Date) => {
                        return '<span>' + ((w as number)/1000).toFixed(1) + ' kW</span>';
                    }
                }
            },
            labels: ['x',
                'Leistung (kW)',
                'Maximum (kw)',
                'Events'
            ],
            colors: [
                '#ffc800',
                '#bbb8b8',
                '#000000'
            ],
            series: {
                'Leistung (kW)': {
                    axis: 'y1',
                    plotter: smoothPlotter
                },
                'Maximum (kw)': {
                    axis: 'y1',
                    strokePattern: [6, 6],
                    drawPoints: false,
                    // plotter: smoothPlotter
                },
                Events: {drawPoints: false, strokeWidth: 0}
            },
        };
    }

    protected async getMaxY2(): Promise<number> {
        return Promise.resolve(0);
    }

    protected async loadData(start: number, end: number): Promise<IPeakHistoryGraphData> {
        const peaks: PeakHistory = await this.powerSupplyService.getPeaksHistory(this.powerSupplyId, start, end);

        // @ts-ignore
        peaks.data.forEach(d => d[0] = new Date(d[0]));
        peaks.data.forEach(d => d.push(0))

        return {
            data: peaks.data,
            events: []
        };
    }

}
