import {Component, Input, OnInit, ElementRef, ViewChild} from '@angular/core';
import {ExecuteOpenAdrVenEntityAddOrUpdate} from '@io-elon-common/frontend-api';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';

@Component({
  selector: 'app-edit-open-adr-ven-dialog',
  templateUrl: './edit-open-adr-ven-dialog.component.html',
  styleUrls: ['./edit-open-adr-ven-dialog.component.scss']
})
export class EditOpenAdrVenDialogComponent implements OnInit, IEditForm<ExecuteOpenAdrVenEntityAddOrUpdate> {

  @Input()
  public data!: ExecuteOpenAdrVenEntityAddOrUpdate;

  @ViewChild('certFile') certFile!: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
  }

  validate(): string[] {
    const ret: string[] = [];

    if(!this.data.venId) {
      ret.push("Ven Id nicht ausgefüllt");
    }
    if(!this.data.baseUrl) {
      ret.push("Base url nicht ausgefüllt");
    }

    if(ret.length === 0) return [];

    return [
        ret.join("<br>")
    ];
  }

  onFileSelected(e: File[]) {
    let file = e[0];
    var promise = pFileReader(this, file);

    function pFileReader (ob: EditOpenAdrVenDialogComponent, file: File) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function found () {
          if (reader.result != null && (<String> reader.result).split(',').length == 2) {
            ob.data.cert = (<String> reader.result).split(',')[1];
            resolve("Valid File");
          } else {
            reject("not a Valid File");
          }
        }
        reader.readAsDataURL(file);
      })
    }
  }

  downloadCert() {
    var a = document.createElement("a");
    window.location.href = "data:application/x-pkcs12;base64, " + this.data.cert;
  }

  removeCert() {
    this.data.cert = "";
    this.certFile.nativeElement.value = null;
  }
}
