import { Injectable } from '@angular/core';
import {CacheManager, CacheUpdater} from "./cacheManager";
import {MigrationList} from "@io-elon-common/frontend-api";
import {POLL_INTERVALS} from "../../app.module";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class MigrationsService extends CacheManager {
    private migrationsCache: CacheUpdater<MigrationList | undefined, any> = this.createManagedCache(()=>true);

    constructor(
        private readonly apiService: ApiService
    ) {
        super(POLL_INTERVALS.migrations);
    }

    public getMigrations(showAlerts = true): BehaviorSubject<MigrationList | undefined> {
        return this.migrationsCache.getOrCreateGet(undefined, () => this.apiService.getMigrationsList(showAlerts, undefined, undefined).toPromise() as Promise<any>).data;
    }

    public async startMigration(id: number): Promise<void | Object> {
        return await this.apiService.startMigration(true, id, undefined, undefined).toPromise().then(x => this.migrationsCache.update());
    }
    public async stopMigration(id: number): Promise<void | Object> {
        return await this.apiService.stopMigration(true, id, undefined, undefined).toPromise().then(x => this.migrationsCache.update());
    }
}
