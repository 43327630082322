<mat-form-field *ngIf="showFilterBox" class="filterbox" appearance="outline">
    <mat-label>Filter</mat-label>
    <input matInput [(ngModel)]="dataSource.filter">
</mat-form-field>
<table mat-table [trackBy]="trackById" [dataSource]="dataSource" class="full-width-table" matSort>
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                          [checked]="isAllSelected()"
                          [indeterminate]="areSomeSelected() && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row.id) : null"
                          [checked]="selection.isSelected(row.id)">
            </mat-checkbox>
        </td>
    </ng-container>

    <!-- Icon Column -->
    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef class="col-icon">Ladepunkt</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            <div class="btn-car">
                <mat-icon class="iblack" svgIcon="{{getHealthIcon(element)}}"></mat-icon>
            </div>
        </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-name"></th>
        <td mat-cell *matCellDef="let element" class="col-name">
            <app-evse-link [evse]="element"></app-evse-link>
        </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="col-type"> Typ</th>
        <td mat-cell *matCellDef="let element" class="col-type">
            <div class="d-flex flex-column gap-2 justify-content-between align-items-start">
                {{element.type}}
                <span class="light" [matTooltip]="getAgeDetails(element)" [matTooltipClass]="'pre-tooltip'" [matTooltipPosition]="'right'">{{getAge(element)}}</span>
            </div>
        </td>
    </ng-container>


    <!-- Plug icon -->
    <ng-container matColumnDef="plug">
        <th mat-header-cell *matHeaderCellDef class="col-plug">Stecker</th>
        <td mat-cell *matCellDef="let element" class="col-plug">
            <span [matTooltip]="element.plugState.title">
                <mat-icon class="iblack"
                          [svgIcon]="element.plugState.icon"
                          inline></mat-icon>
                <br>
                <mat-hint *ngIf="getPwrMsg(element) as pwr" [class.targetVisible]="targetVisible" (click)="toggleTargetVisible()">
                    {{pwr.live}}
                    <span *ngIf="pwr.target" class="target">
                        <span *ngIf="pwr.live"> / </span>
                        {{pwr.target}}
                    </span>
                    {{pwr.unit}}
                </mat-hint>
            </span>
        </td>
    </ng-container>

    <!-- Vehicle Column -->
    <ng-container matColumnDef="vehicle">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Fahrzeug</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            <!--suppress AngularInvalidExpressionResultType -->
            <ng-container *ngIf="element.liveData.vehicle">
                <ng-container *ngIf="element.liveData.vehicle.canView; else noLink">
                    <app-vehicle-link [vehicle]="element.liveData.vehicle"></app-vehicle-link>
                </ng-container>
                <ng-template #noLink>
                    Autorisiertes Fahrzeug
                </ng-template>
            </ng-container>

            <span *ngIf="element.liveData.evsePlugged?.val && !element.liveData.vehicle">Gastfahrzeug</span>
            <span *ngIf="!element.liveData.evsePlugged?.val && !element.liveData.vehicle"> -- </span>
            <i class="fas fa-list-dots assign-menu" *ngIf="isDev" [matMenuTriggerFor]="assignOptions" matTooltip="Assign (dev Only)"></i>
            <mat-menu #assignOptions="matMenu">
                <button mat-menu-item (click)="assign(undefined, element)">
                    <mat-icon svgIcon="plug_disconnect_grey"></mat-icon>
                    <span>Unassign</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="vehicleMenu">
                    <mat-icon svgIcon="plug_connect_grey"></mat-icon>
                    <span>Assign</span>
                </button>
                <button mat-menu-item (click)="plugCycle(element)" matTooltip="Simuliere das Ändern des Plug Zustandes und direkt danach zurück">
                    <mat-icon svgIcon="plug_disconnect_red"></mat-icon>
                    <span>Plug Cycle</span>
                </button>
            </mat-menu>
            <mat-menu #vehicleMenu="matMenu">
                <ng-template matMenuContent>
                    <button mat-menu-item *ngFor="let v of vehicles; trackBy:trackById" (click)="assign(v, element)">
                        {{v.id}} - {{v.name}}
                        ({{(!!v.liveData.plugged?.val) ? "Plugged - " : "Not Plugged"}} <app-evse-link *ngIf="v.evse" [evse]="v.evse"></app-evse-link>)
                    </button>
                </ng-template>
            </mat-menu>
        </td>
    </ng-container>

    <!-- Vorraussichtlicher ladeschluss Column -->
    <ng-container matColumnDef="chargeTargetTime">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle"> Vorraussichtlicher Ladeschluss</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            {{element.liveData.vehicle ? "TODO" : "--"}}
        </td>
    </ng-container>


    <!-- Authorisiert Column -->
    <ng-container matColumnDef="auth">
        <th mat-header-cell *matHeaderCellDef class="col-auth">Auth</th>
        <td mat-cell *matCellDef="let element" class="col-auth">
            <span *ngIf="element.liveData.evseAuthorized !== undefined; else authUnsupported">
                <mat-icon *ngIf="element.liveData.evseAuthorized?.val" [matTooltip]="element.liveData.evseRfidToken ? 'RFID: ' + element.liveData.evseRfidToken.val : 'Ja'">check</mat-icon>
                <mat-icon *ngIf="!element.liveData.evseAuthorized?.val" matTooltip="Nein">cancel</mat-icon>
            </span>
            <ng-template #authUnsupported>
                <mat-icon matTooltip="Nicht benötigt" class="igray">check</mat-icon>
            </ng-template>
        </td>
    </ng-container>

    <!-- Modus Column -->
    <ng-container matColumnDef="mode">
        <th mat-header-cell *matHeaderCellDef class="col-status">Lademodus</th>
        <td mat-cell *matCellDef="let element" class="col-status">
            <ng-container *ngIf="!loading[element.id]; else active">
                <mat-icon (dblclick)="toggle(element, 'monitor')" [class]="{'status-icon': true, 'igray': !element.monitor}" [matTooltip]="'Monitoring ' + (element.monitor ? 'Aktiv' : 'Inaktiv')">multiline_chart</mat-icon>
                <mat-icon (dblclick)="toggle(element, 'control')" [class]="{'status-icon': true, 'igray': !element.control}" [matTooltip]="'Steuerung ' + (element.control ? 'Aktiv' : 'Inaktiv')">network_check</mat-icon>
                <mat-icon (dblclick)="toggle(element, 'smartCharging')" [class]="{'status-icon': true, 'igray': !element.smartCharging}" [matTooltip]="'Smart Charging ' + (element.smartCharging ? 'Aktiv' : 'Inaktiv')">all_inclusive</mat-icon>
                <mat-icon (dblclick)="toggle(element, 'sequencesAllowed')" [class]="{'status-icon': true, 'igray': !element.sequencesAllowed}" [matTooltip]="'Sequenzen ' + (element.sequencesAllowed ? 'Aktiv' : 'Inaktiv')" [svgIcon]="'plug_sequence'"></mat-icon>
                <mat-icon (dblclick)="toggle(element, 'guestCharging')" [class]="{'status-icon': true, 'igray': !element.guestCharging}" [matTooltip]="'Gastfahrzeuge Laden ' + (element.guestCharging ? 'Aktiv' : 'Inaktiv')">battery_unknown</mat-icon>
                <mat-icon (dblclick)="toggle(element, 'authEnabled')" [class]="{'status-icon': true, 'igray': !element.authEnabled}" [matTooltip]="'RFID Karten für Gäste ' + (element.authEnabled ? 'Aktiv' : 'Inaktiv')">account_balance_wallet</mat-icon>
                <mat-icon (dblclick)="toggle(element, 'slowChargeManagementEnabled')" [class]="{'status-icon': true, 'igray': !element.slowChargeManagementEnabled}" [matTooltip]="'Erkennung für langsames Laden ' + (element.slowChargeManagementEnabled ? 'Aktiv' : 'Inaktiv')">filter_list</mat-icon>
                <mat-icon (dblclick)="toggle(element, 'nonChargingCarsToMinAmps')" [class]="{'status-icon': true, 'igray': !element.nonChargingCarsToMinAmps}" [matTooltip]="'Nicht Lader freischalten ' + (element.nonChargingCarsToMinAmps ? 'Aktiv' : 'Inaktiv')">flare</mat-icon>
            </ng-container>
            <ng-template #active>
                Speichern...
            </ng-template>
        </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="col-status">Ladeplan</th>
        <td mat-cell *matCellDef="let element" class="col-status">
            <app-algo-reason [reason]="getChargePlanInfo(element)"></app-algo-reason>
        </td>
    </ng-container>


    <!-- Action Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions">
            <button class="filterbutton" mat-button (click)="toggleFilterBox()">
                Filter <mat-icon>filter_list</mat-icon>
            </button>
        </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <ng-container *ngFor="let notification of activeNotifications; trackBy:trackById">
                <ng-container *ngIf="notification.evse === element.id">
                    <app-notification [notification]="notification" [headerName]="element.name"></app-notification>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let notification of mutedNotifications; trackBy:trackById">
                <ng-container *ngIf="notification.evse === element.id">
                    <app-notification [notification]="notification" [headerName]="element.name"></app-notification>
                </ng-container>
            </ng-container>
            <span class="evse-validation" *ngIf="getValidations(element) as validations">
                <button mat-icon-button matTooltip="Fehler in Stationskonfiguration" (click)="handleValidation(element)">
                    <mat-icon>sms_failed</mat-icon>
                </button>
            </span>
            <span *ngIf="element.type === 'Easee Home'">
                <button mat-icon-button matTooltip="Token erzeugen" (click)="easeeLogin(element)">
                    <mat-icon>login</mat-icon>
                </button>
            </span>
            <button mat-icon-button matTooltip="RFID bis zum Abstecken abschalten" *ngIf="showUnlockButton(element)" (click)="unlock(element)">
                <mat-icon
                    class="iblack">
                    last_page
                </mat-icon>
            </button>
            <ng-container *ngIf="canStop(element) && element.canEdit">
                <button mat-icon-button [matTooltip]="'Ladevorgang stoppen' + (stopButton.disabled ? '(Wird geladen)' : '')" (click)="stop(element)" [disabled]="isChargingStatusLoading(element)" *ngIf="!isStopped(element)" #stopButton>
                    <mat-icon class="iblack">report</mat-icon>
                </button>

                <ng-container *ngIf="isStopped(element)">
                    <button mat-icon-button *ngIf="!basis.allowContinue" matTooltip="Ladevorgang wurde gestoppt.">
                        <mat-icon class="iblack">not_interested</mat-icon>
                    </button>
                    <button mat-icon-button [matTooltip]="'Ladevorgang fortsetzen' + (activeButton.disabled ? '(Wird geladen)' : '')"  (click)="continue(element)" [disabled]="isChargingStatusLoading(element)" *ngIf="basis.allowContinue" #activeButton>
                        <mat-icon class="iblack">play_arrow</mat-icon>
                    </button>
                </ng-container>
            </ng-container>
            <button mat-icon-button *ngIf="element.canEdit && element.sequencesAllowed" (click)="triggerSequence(element)" matTooltip="Fahrzeugerkennung starten">
                <mat-icon
                    class="iblack"
                    svgIcon="plug_sequence">
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="isDev && logSupportedEvses.has(element.id)" (click)="showLog(element)" matTooltip="Protokoll">
                <mat-icon
                    class="iblack">
                    terminal
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="element.canEdit" (click)="actions(element)" matTooltip="Aktionen">
                <mat-icon
                    class="iblack">
                    touch_app
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="element.canEdit" (click)="edit(element)" matTooltip="Bearbeiten">
                <mat-icon
                    class="iblack">
                    edit
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="canCreate" (click)="copy(element)" matTooltip="Kopieren">
                <mat-icon
                    class="iblack">
                    content_copy
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- Algo Column -->
    <ng-container matColumnDef="algoStatus">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle"> Algorithmusstatus</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            TODO
        </td>
    </ng-container>

    <ng-container matColumnDef="versions">
        <th mat-header-cell *matHeaderCellDef class="col-ocpp">Seriennummern</th>
        <td mat-cell *matCellDef="let element" class="col-ocpp">
            <ng-container *ngIf="element.liveData.evseModel?.val"><span class="actorArgs">Modell: {{element.liveData.evseModel?.val}}</span><br></ng-container>
            <ng-container *ngIf="element.liveData.evseFirmwareVersion?.val"><span class="actorArgs">Firmware: {{element.liveData.evseFirmwareVersion?.val}}</span><br></ng-container>
            <ng-container *ngIf="element.liveData.evseSerialNumber?.val"><span class="actorArgs">Serial: {{element.liveData.evseSerialNumber?.val}}</span><br></ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="ocpp">
        <th mat-header-cell *matHeaderCellDef class="col-ocpp">Argumente</th>
        <td mat-cell *matCellDef="let element" class="col-ocpp">
            <ng-container *ngFor="let arg of showActorArgs(element)">
                <span class="actorArgs">{{arg}}</span><br>
            </ng-container>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="$event.stopPropagation(); $event ? selectRow(row) : null"
        [ngClass]="{selected: selectedIndex === row.id}">
    </tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
