import {Component, Input, OnInit} from '@angular/core';
import {ExecuteRfidAddOrUpdate, User, Vehicle,} from '@io-elon-common/frontend-api';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {UserService} from '../../../users/service/user.service';


@Component({
    selector: 'app-edit-rfid-dialog',
    templateUrl: './edit-rfid-dialog.component.html',
    styleUrls: ['./edit-rfid-dialog.component.scss']
})
export class EditRfidDialogComponent implements IEditForm<ExecuteRfidAddOrUpdate>, OnInit{

    @Input()
    public data!: ExecuteRfidAddOrUpdate;
    public vehicles !: Promise<Vehicle[]>
    public users !: Promise<User[]>

    constructor(
        private readonly vehicleService: VehicleService,
        private readonly userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.vehicles = this.loadVehicles();
        this.users = this.loadUsers();
    }
   async loadVehicles (): Promise<Vehicle[]>{
       const vehicles = await this.vehicleService.getAllPromise();
        return vehicles.sort((a, b) => {
           if (a.name === undefined) {
               return 1;
           }
           if (b.name === undefined) {
               return -1;
           }
           return a.name.localeCompare(b.name);
       });
   }
   async loadUsers(): Promise<User[]>{
       const users = await this.userService.getAllPromise();
       return users.sort((a, b) => {
               if (a.name === undefined) {
                   return 1;
               }
               if (b.name === undefined) {
                   return -1
               }
               return a.name.localeCompare(b.name);
           })
   }

    validate(): string[] {
        return [];
    }
}
