<div class="wrapper-all">
    <div class="graph-date-indicator">{{dateIndicatorText()}}</div>
    <div class="line-graph" style="position: relative; width:100%; z-index: 998">

    </div>
    <mat-form-field appearance="fill" class="force-gray-background">
        <mat-label>Anzeige</mat-label>
        <mat-select [formControl]="formControl" (selectionChange)="update()" multiple>
            <mat-select-trigger>
                {{formControl.value ? makePretty(formControl.value[0]) : ''}}
                <span *ngIf="formControl.value?.length > 1">
                  (+{{formControl.value.length - 1}} {{formControl.value?.length === 2 ? 'weiterer' : 'weitere'}})
                </span>
            </mat-select-trigger>
            <mat-option *ngFor="let key of bindedkeys" [disabled]="key.disabled" [value]="key.name">{{key.pretty}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="loading" *ngIf="loading">
        <mat-spinner>

        </mat-spinner>
    </div>
</div>
