import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../../services/api-handlers/api.service";
import {localStorageGet, localStorageSave} from "../../helper/typed-local-storage";
import {MigrationsService} from "../../../services/api-handlers/migrations.service";
import {AuthService} from "../../guards/auth.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    year = 2024;

    private SHOW_MESSAGE_MIN_MILLIS = 10 * 1000;
    public timeAlwaysVisible: boolean = localStorageGet("SERVER_DELTA_TIME_VISIBLE", "false") === "true";
    constructor(
        private readonly apiService: ApiService,
        private readonly migrationService: MigrationsService,
        private readonly authService: AuthService
    ) { }

    public showMigrationWarning: boolean = false;

    ngOnInit(): void {
        if (this.authService.isDeveloper()) {
            this.migrationService.getMigrations(false).subscribe(list => {
                this.showMigrationWarning = false;
                console.log(list);
                list?.list.forEach(entry => {
                    if (entry.state != "Done") {
                        this.showMigrationWarning = true;
                        return;
                    }
                });
            });
        }
    }

    public shouldShowTime(): boolean {
        if (this.getServerTimeDeltaMillis() > this.SHOW_MESSAGE_MIN_MILLIS || this.getServerTimeDeltaMillis() < -this.SHOW_MESSAGE_MIN_MILLIS) {
            return true;
        }
        return this.timeAlwaysVisible;
    }

    public toggleTime():void {
        this.timeAlwaysVisible = !this.timeAlwaysVisible;
        localStorageSave("SERVER_DELTA_TIME_VISIBLE", this.timeAlwaysVisible ? "true" : "false");
    }

    getServerTimeDeltaString(): string {
        return (this.getServerTimeDeltaMillis() / 1000).toFixed(1)
    }
    getServerTimeDeltaMillis(): number {
        return this.apiService.timeDeltaMillis;
    }
}
