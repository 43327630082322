import {Injectable} from '@angular/core';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ExecutePermissionGroupAddOrUpdate, PermissionGroup} from '@io-elon-common/frontend-api';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {ApiService} from '../../../services/api-handlers/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {
    EditPermissionGroupDialogComponent
} from '../dialogs/edit-permission-group-dialog/edit-permission-group-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
  providedIn: 'root'
})
export class PermissionGroupService extends DialogHandler<PermissionGroup, ExecutePermissionGroupAddOrUpdate, ExecutePermissionGroupAddOrUpdate, {}, void, void>{

    constructor(
        apiService: ApiService,
        toastr: ToastrService,
        dialog: MatDialog,
        dialogService: DialogService
    ) {
        super(apiService, 'PermissionGroup', toastr, dialog, dialogService, POLL_INTERVALS.permissionGroups);
    }

    protected getEditConfig(permissionGroup: PermissionGroup): TDialogOptions<ExecutePermissionGroupAddOrUpdate, EditPermissionGroupDialogComponent> {
        return {
            component: EditPermissionGroupDialogComponent,
            headline: "Berechtigung bearbeiten",
            executeCallback: editResult => this.update(permissionGroup.id, editResult),
            editElement: {
                name: permissionGroup.name
            },
            extraParams: {
                edit: true
            }
        }
    }

    protected getNewConfig(): TDialogOptions<ExecutePermissionGroupAddOrUpdate, EditPermissionGroupDialogComponent> {
        return {
            component: EditPermissionGroupDialogComponent,
            headline: "Berechtigung anlegen",
            executeCallback: createResult => this.create(createResult),
            editElement: {
                name: ""
            },
            extraParams: {
                edit: false
            }
        }
    }
}
