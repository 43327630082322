<table mat-table [dataSource]="dataSource" class="full-width-table" matSort>

    <!-- Icon Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
        </td>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef class="col-username"> Benutzername</th>
        <td mat-cell *matCellDef="let element" class="col-username">
            {{element.name}}
        </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-name"> Name</th>
        <td mat-cell *matCellDef="let element" class="col-name">
            {{element.firstname}} {{element.lastname}}
        </td>
    </ng-container>

    <!-- roles -->
    <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef class="col-roles"> Rollen</th>
        <td mat-cell *matCellDef="let element" class="col-roles">
            {{getPermissions(element)}}
        </td>
    </ng-container>

    <!-- actions -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button [disabled]="!element.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon fontSet="material-icons">edit</mat-icon></button>
            <button [disabled]="!element.canEdit && !element.canChangePassword" (click)="editPw(element)" mat-icon-button matTooltip="Passwort ändern"><mat-icon fontSet="material-icons">lock</mat-icon></button>
            <button [disabled]="!element.canDelete" (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon fontSet="material-icons">delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
