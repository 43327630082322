<div class="d-flex flex-row justify-content-end align-items-center">
    <button (click)="goBackward()" class="button-style" matTooltip="Vorherige">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="navigate_before">
        </mat-icon>
    </button>
    <button (click)="goToday()" class="button-style" matTooltip="Heute">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="today"></mat-icon>
    </button>
    <button (click)="goForward()" class="button-style" [disabled]="shouldForwardAvailable()" matTooltip="Nächste">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="navigate_next"></mat-icon>
    </button>
    <b class="human-date">
        {{ getHumanReadable() }}
    </b>
    <mat-chip-listbox aria-label="Date Selection" class="date-range-selection" (change)="selectionChanged($event)"
                      [formControl]="selectedDateRangeFormControl">
        <mat-chip-option color="primary" class="chip-button" selected="true" value="{{TimeRangePreset.week}}">
            Woche
        </mat-chip-option>
        <mat-chip-option color="primary" class="chip-button" value="{{TimeRangePreset.month}}">
            Monat
        </mat-chip-option>
        <mat-chip-option color="primary" class="chip-button" value="{{TimeRangePreset.year}}">
            Jahr
        </mat-chip-option>
    </mat-chip-listbox>
</div>
