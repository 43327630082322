import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BackgroundLegend} from '../../abstract-history-graph/abstract-history-graph';

@Component({
    selector: 'app-single-background-legend',
    templateUrl: './single-background-legend.component.html',
    styleUrls: ['./single-background-legend.component.scss']
})
export class SingleBackgroundLegendComponent implements OnInit {
    @ViewChild('canvas', { static: true })
    public canvas!: ElementRef<HTMLCanvasElement>;

    public w = 30; // Ist im HTML als Magic number eingetragen, da binding bei canvas nicht geht
    public h = 20;

    @Input() public legend!: BackgroundLegend;

    constructor() {
    }

    ngOnInit(): void {
        const ctx = this.canvas.nativeElement.getContext('2d') as CanvasRenderingContext2D;
        this.legend.drawCallback(ctx, 0,0, this.w, this.h);
        console.log("DRAW LEGEND");
    }

}
