<mat-form-field style="width: {{width}}">
    <mat-label>Kommando</mat-label>
    <mat-select [(ngModel)]="selectedAction" (selectionChange)="updateSelectableAction()" required>
        <mat-select-trigger *ngIf="selectedAction">
            <mat-icon style="transform: translate(0, 5px)" *ngIf="selectedAction.isDevOnly" matTooltip="Dev Only">bug_report</mat-icon>
            {{selectedAction.displayName}}
        </mat-select-trigger>
        <mat-option *ngFor="let action of userActions"
                    [value]="action"
                    [matTooltip]="getTooltip(action)"
                    matTooltipPosition="right"
                    matTooltipClass="pre-tooltip"
                    [matTooltipShowDelay]="300">
            {{action.displayName}}
            <mat-icon *ngIf="action.isDevOnly" matTooltip="Dev Only">bug_report</mat-icon>
        </mat-option>
        <mat-optgroup label="User-Hidden" *ngIf="isDev">
            <mat-option *ngFor="let action of userHiddenActions"
                        [value]="action"
                        [matTooltip]="getTooltip(action)"
                        matTooltipPosition="right"
                        matTooltipClass="pre-tooltip"
                        [matTooltipShowDelay]="300">
                {{action.displayName}}
            </mat-option>
        </mat-optgroup>
        <mat-optgroup label="DEV-Only" *ngIf="isDev">
            <mat-option *ngFor="let action of devActions"
                        [value]="action"
                        [matTooltip]="getTooltip(action)"
                        matTooltipPosition="right"
                        matTooltipClass="pre-tooltip"
                        [matTooltipShowDelay]="300">
                {{action.displayName}}
            </mat-option>
        </mat-optgroup>
    </mat-select>
</mat-form-field>
