<div>
    <mat-card appearance="outlined">
        <mat-card-content>
            <table>
                <tr>
                    <th class="mat-header-cell">
                        <mat-checkbox (change)="updateAllSelected()" [(ngModel)]="isAllSelected">Upload</mat-checkbox>
                    </th>
                    <th class="mat-header-cell">Name</th>
                    <th class="mat-header-cell">Token</th>
                    <th class="mat-header-cell">User</th>
                    <th class="mat-header-cell">Fahrzeug</th>
                    <th class="mat-header-cell">Kostenstelle</th>
                    <th class="mat-header-cell">Aktiv</th>
                    <th class="mat-header-cell">Status</th>
                </tr>
                <ng-container *ngFor="let rfid of data">
                    <tr>
                        <td class="mat-cell">
                            <mat-checkbox [disabled]="rfid.status == uploadStatus.SUCCESS" (change)="updatedStatus($event, rfid)" [checked]="checkIfSelected(rfid)"></mat-checkbox>
                        </td>
                        <td class="mat-cell">
                            <input matInput type="text" [(ngModel)]="rfid.rfid.name" [disabled]="rfid.status == uploadStatus.SUCCESS">
                        </td>
                        <td class="mat-cell">
                            <input matInput type="text" [(ngModel)]="rfid.rfid.token" [disabled]="rfid.status == uploadStatus.SUCCESS">
                        </td>
                        <td class="mat-cell selection-options">
                            <mat-select [disabled]="rfid.status == uploadStatus.SUCCESS" [(ngModel)]="rfid.rfid.userId">
                                <mat-option [value]="undefined">Keinem User zuordnen</mat-option>
                                <mat-option *ngFor="let user of users" [value]="user.id">
                                    {{user.name}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="mat-cell selection-options">
                            <mat-select [disabled]="rfid.status == uploadStatus.SUCCESS" [(ngModel)]="rfid.rfid.vehicleId">
                                <mat-option [value]="undefined">Keinem Fahrzeug zuordnen</mat-option>
                                <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle.id">
                                    {{vehicle.name}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="mat-cell">
                            <input matInput type="text" [(ngModel)]="rfid.rfid.invoiceReceiver" [disabled]="rfid.status == uploadStatus.SUCCESS">
                        </td>
                        <td class="mat-cell">
                            <mat-checkbox [(ngModel)]="rfid.rfid.active" [disabled]="rfid.status == uploadStatus.SUCCESS"></mat-checkbox>
                        </td>
                        <td class="mat-cell">
                            <div *ngIf="rfid.status!=uploadStatus.FAILED" class="status-div">
                                {{rfid.status}}
                            </div>

                            <div *ngIf="rfid.status == uploadStatus.FAILED" class="status-div">
                                {{rfid.error}}
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </mat-card-content>
    </mat-card>
</div>
