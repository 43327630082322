import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {SystemService} from '../../../services/api-handlers/system.service';
import {localStorageGet, localStorageSave} from "../../../shared/helper/typed-local-storage";
import {ServiceStatusInfo} from "@io-elon-common/frontend-api";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

type SERVICE_LIST = {
    [key: string]: { name: string, alive: boolean, restartCounter: number, status: ServiceStatusInfo[] }
};

@Component({
    selector: 'app-service',
    templateUrl: './service.component.html',
    styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

    public serviceStatus!: BehaviorSubject<SERVICE_LIST | undefined>;
    public filter = "";
    public log: string[] = JSON.parse(localStorageGet("SERVICE_FILTER", "[]"))


    constructor(
        private readonly systemService: SystemService
    ) {
    }

    public get statusFilter(): Observable<SERVICE_LIST> {
        return this.serviceStatus.pipe(map(val => {
            const ret: SERVICE_LIST = {};
            const serviceStats = val || {};
            for(const key in serviceStats) {
                if(!this.filter || new RegExp(this.filter).test(key)) {
                    ret[key] = serviceStats[key];
                }
            }
            return ret;
        }));
    }

    ngOnInit(): void {
        this.serviceStatus = this.systemService.getServiceStatus() as any;
    }

    saveFilter() {
        if (!this.log.includes(this.filter, 0)) {
            this.log.push(this.filter);
            if (this.log.length > 5) {
                this.log.splice(0, 1);
            }
            localStorageSave("SERVICE_FILTER", JSON.stringify(this.log));
        }
    }
}
