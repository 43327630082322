import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {InputDataValidationFieldAndBooleanPair, InputDataValidationFieldAndStringPair} from '@io-elon-common/frontend-api';


@Injectable({
    providedIn: 'root'
})
export class InputDataValidationService {
    constructor(private readonly apiService: ApiService) {

    }

    public async isInputDataExists(fieldValueList: InputDataValidationFieldAndStringPair, showAlerts = true): Promise<InputDataValidationFieldAndBooleanPair> {
        return this.apiService.getInputDataExists(showAlerts, fieldValueList).toPromise();
    }
}
