import {Injectable} from '@angular/core';
import {
    AlgoV2Config,
    EntityManagerDetails, Evse,
    ModbusConnection,
    PerformanceInfo, PerformanceStatsDbItem,
    QueueSizeList,
    SystemInfo
} from '@io-elon-common/frontend-api';
import {ApiService} from './api.service';
import {CacheManager, CacheUpdater} from './cacheManager';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {ApiHandler} from "./api-handler";
import {POLL_INTERVALS} from "../../app.module";
import {SystemInfoSupportedEvseActions} from "@io-elon-common/frontend-api/lib/model/systemInfoSupportedEvseActions";

const MINUTE = 1000 * 60;

export type PerformanceStats = {[key: string]: PerformanceInfo}
export type PerformanceStatsDb = {[key: string]: PerformanceStatsDbItem}

@Injectable({
    providedIn: 'root'
})
export class SystemService extends CacheManager{
    private info?: SystemInfo;
    private entityManagerCache: CacheUpdater<EntityManagerDetails[] | undefined, any> = this.createManagedCache(()=>true);
    private modbusConnectionsCache: CacheUpdater<ModbusConnection[] | undefined, any> = this.createManagedCache(()=>true);
    private performanceCache: CacheUpdater<PerformanceStats | undefined, any> = this.createManagedCache(()=>true);
    private performanceDbCache: CacheUpdater<PerformanceStatsDb | undefined, any> = this.createManagedCache(()=>true);
    private queueSizeCache: CacheUpdater<QueueSizeList | undefined, any> = this.createManagedCache(()=>true);
    private externalToolsStateCache: CacheUpdater<any | undefined, any> = this.createManagedCache(()=>true);
    private serviceCache: CacheUpdater<{[key: string]: object} | undefined, any> = this.createManagedCache(()=>true);
    private readonly fieldsByStrategy: {[key: string]: Array<keyof AlgoV2Config>} = {};

    constructor(
        private readonly apiService: ApiService
    ) {
        super(POLL_INTERVALS.system);
    }

    public async getSystemInfo(showAlerts = true): Promise<SystemInfo> {
        if (this.info) {
            return this.info;
        }
        this.info = await this.apiService.info(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise();

        this.info.algoParamsDescription.forEach(param => {
            param.strategy.forEach(strategy => {
                if(!this.fieldsByStrategy[strategy]) {
                    this.fieldsByStrategy[strategy] = [];
                }
                this.fieldsByStrategy[strategy].push(param.key as keyof AlgoV2Config);
            });
        });

        return this.info;
    }

    public getActorActions(): {[key: string]: SystemInfoSupportedEvseActions};
    public getActorActions(evse: Evse): SystemInfoSupportedEvseActions[];
    public getActorActions(evse?: Evse): SystemInfoSupportedEvseActions[] | {[key: string]: SystemInfoSupportedEvseActions} {
        if(evse) {
            const ret: SystemInfoSupportedEvseActions[] = [];
            for(const a of this.info!.evseActions) {
                if(evse.actions.some(n => n === a.name)) {
                    ret.push(a);
                }
            }
            return ret;
        } else {
            const ret: {[key: string]: SystemInfoSupportedEvseActions} = {};
            for(const a of this.info!.evseActions) {
                ret[a.name] = a;
            }
            return ret;
        }
    }

    public getWatchdogTime(actorKey?: string): number {
        if(!actorKey) {
            return 0.5 * MINUTE;
        }
        if(!this.info?.supportedEvses) {
            return 0.5 * MINUTE;
        }
        for(const evseType of this.info?.supportedEvses) {
            for(const actor of evseType.actors) {
                if(actor.key === actorKey) {
                    return actor.watchdogTimeout;
                }
            }
        }
        return 0.5 * MINUTE;
    }


    public getAlgoConfigFieldsByStrategyMapping(): { [p: string]: Array<keyof AlgoV2Config> } {
        return this.fieldsByStrategy;
    }

    public getSystemInfoSync(): SystemInfo | undefined {
        return this.info;
    }

    public performanceStats(showAlerts = true): BehaviorSubject<PerformanceStats | undefined> {
        return this.performanceCache.getOrCreateGet(undefined, () => this.apiService.performance(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise() as Promise<any>).data;
    }

    public performanceStatsDb(showAlerts = true): BehaviorSubject<PerformanceStatsDb | undefined> {
        return this.performanceDbCache.getOrCreateGet(undefined, () => this.apiService.performanceDb(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise() as Promise<any>).data;
    }

    public getQueueSizes(showAlerts = true): BehaviorSubject<QueueSizeList | undefined> {
        return this.queueSizeCache.getOrCreateGet(undefined, () => this.apiService.getQueueSizes(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise() as Promise<any>).data;
    }

    public getServiceStatus(showAlerts = true): BehaviorSubject<{[key: string]: object} | undefined> {
        return this.serviceCache.getOrCreateGet(undefined, () => this.apiService.getServiceStatus(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise() as Promise<any>).data;
    }

    public getEntityManagers(showAlerts = true): BehaviorSubject<EntityManagerDetails[] | undefined> {
        return this.entityManagerCache.getOrCreateGet(undefined, () => this.apiService.entityManagerDetailsList(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise().then(l => l.list)).data;
    }

    public getModbusConnections(showAlerts = true): BehaviorSubject<ModbusConnection[] | undefined> {
        return this.modbusConnectionsCache.getOrCreateGet(undefined, () => this.apiService.getModbusConnections(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise().then(l => l.list)).data;
    }

    getExternalToolsState() {
        return this.externalToolsStateCache.getOrCreateGet(undefined, () => this.apiService.externalToolsStatus(true).toPromise()).data;
    }
}
