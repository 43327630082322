import {Component, Inject, OnInit} from '@angular/core';
import {ChargingSession} from '@io-elon-common/frontend-api';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss']
})
export class DetailsDialogComponent implements OnInit {
    public missingTimeInMinutes: string = "";
    public missingTimeInPercentage: string = "";
    public showMissingTimeTxt: boolean = false;
  constructor(
      public readonly dialogRef: MatDialogRef<DetailsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public readonly data: ChargingSession
  ) { }

  ngOnInit(): void {
      if (this.data.lastValidMeterValueTime) {
          const timeDif = this.data.tstEnd? this.data.tstEnd - this.data.lastValidMeterValueTime : 0;
          if (timeDif > 0) {
              this.showMissingTimeTxt = true;
              const totalTime = this.data.tstEnd? this.data.tstEnd - this.data.tstStart : 0;
              this.missingTimeInMinutes = "" +  ((timeDif / 1000) / 60).toFixed(1);
              this.missingTimeInPercentage = "" + ((timeDif * 100) / totalTime).toFixed(1);
          }
      }
  }
}
