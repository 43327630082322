import {Component, Input} from '@angular/core';
import {ExecuteUserAddOrUpdate} from '@io-elon-common/frontend-api';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';

@Component({
  selector: 'app-edit-password-dialog',
  templateUrl: './edit-password-dialog.component.html',
  styleUrls: ['./edit-password-dialog.component.scss']
})
export class EditPasswordDialogComponent implements IEditForm<ExecuteUserAddOrUpdate>{
    @Input()
    public data!: ExecuteUserAddOrUpdate;

    constructor() {}

    validate(): string[] {
        const ret: string[] = []
        if(!this.data.password) {
            ret.push("Passwort ist leer")
        }
        return ret;
    }
}
