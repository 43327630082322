import {Component, OnInit} from '@angular/core';
import {SystemService} from '../../../services/api-handlers/system.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {QueueSizeList} from '@io-elon-common/frontend-api';

@Component({
    selector: 'app-queue-size',
    templateUrl: './queue-size.component.html',
    styleUrls: ['./queue-size.component.scss']
})
export class QueueSizeComponent implements OnInit {

    public queueSizes!: BehaviorSubject<QueueSizeList | undefined>;

    constructor(
        private readonly systemService: SystemService
    ) {
    }

    ngOnInit(): void {
        this.queueSizes = this.systemService.getQueueSizes();
    }

}
