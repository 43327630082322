<table mat-table [dataSource]="dataSource" class="full-width-table" matSort>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
        </td>
    </ng-container>

    <ng-container matColumnDef="mail">
        <th mat-header-cell *matHeaderCellDef class="col-mail">E-Mail</th>
        <td mat-cell *matCellDef="let element" class="col-mail">
            {{element.mail}}
        </td>
    </ng-container>

    <ng-container matColumnDef="vin">
        <th mat-header-cell *matHeaderCellDef class="col-vin">VIN</th>
        <td mat-cell *matCellDef="let element" class="col-vin">
            {{element.vin}}
        </td>
    </ng-container>

    <ng-container matColumnDef="vehicle">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Fahrzeug</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            {{element.vehicle?.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="lastMsg">
        <th mat-header-cell *matHeaderCellDef class="col-last-msg">Letzter Kontakt</th>
        <td mat-cell *matCellDef="let element" class="col-last-msg">
            {{element.lastMsg | date}} {{element.lastMsg | date:'mediumTime'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button [disabled]="!element.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon>edit</mat-icon></button>
            <button [disabled]="!element.canDelete" (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
