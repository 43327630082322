import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {SafeHtml} from "@angular/platform-browser";

export type SupportedLanguages = "SQL" | "BASH";

const HIGHLIGHT = "color: #29ea2d;";
const HINT = "color: #AAA;";
const NO_COPY = "user-select: none;";

const LANGUAGES: {[key in SupportedLanguages]: {search: RegExp, css: string}[]} = {
    "BASH": [
        { search: /(^[$#]\s)/gi, css: HINT + NO_COPY},
        { search: /(\s>>\s)/gi, css: HIGHLIGHT},
        { search: /(\s[|<>]\s)/gi, css: HIGHLIGHT},
    ],
    "SQL": [
        { search: /((\s|^)SELECT($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)INSERT($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)DELETE($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)UPDATE($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)FROM($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)AS($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)JOIN($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)ON($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)WHERE($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)LIKE($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)GROUP BY($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)ORDER BY($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)ASC($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)DESC($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)ADD($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)ADD CONSTRAINT($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)ALL($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)ALTER($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)ALTER COLUMN($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)ALTER TABLE($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)AND($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)OR($|\s))/gi, css: HIGHLIGHT},
        { search: /((\s|^)ANY($|\s))/gi, css: HIGHLIGHT},
        { search: /(--.*$)/gi, css: HINT},
    ]
}

const OL_STYLE = "background: #303030;color: #f1f1f1;padding: 10px 40px;border-radius: 2px;box-shadow: inset 0 0 10px #000;"
const LI_STYLE = "        border-left: 1px solid #ddd;padding: 0 .5em;margin: 0 0 0 .5em;"

@Directive({
  selector: '[appCode]',
  standalone: true,
})
export class CodeDirective implements OnInit {
    @Input()
    public appCode: "SQL" | undefined;

    public lines!: SafeHtml[];

    constructor(
        private el: ElementRef,
        private renderer: Renderer2) {
    }

    ngOnInit() {
        const fullText: string = this.el.nativeElement.innerHTML;
        this.lines = fullText
            .split("\n")
            .map(line => this.highlight(line, this.appCode))
            .map(l => "<li style='" + LI_STYLE + "'>" + l + "</li>");

        const html = `<ol style="${OL_STYLE}">${this.lines.join("")}</ol>`;

        this.renderer.setProperty(this.el.nativeElement, "innerHTML", html);
    }

    private highlight(line: string, lang?: SupportedLanguages): string {
        if(!lang) {
            return line;
        }
        for (const keyword of LANGUAGES[lang]) {
            line = line.replaceAll(keyword.search, "<span style='" + keyword.css + "'>$1</span>");
        }
        return line;
    }
}
