import {Component, OnInit, ViewChild} from '@angular/core';
import {AsyncPipe, DatePipe, NgIf} from "@angular/common";
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow, MatRowDef, MatTable
} from "@angular/material/table";
import {MatHint} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTooltip} from "@angular/material/tooltip";
import {AbstractTableComponent} from "../../../shared/components/tables/AbstractTableComponent";
import {MigrationList} from "@io-elon-common/frontend-api";
import {MigrationsService} from "../../../services/api-handlers/migrations.service";
import {BehaviorSubject} from "rxjs";
import {MigrationTableComponent} from "./tables/migration-table/migration-table.component";

@Component({
  selector: 'app-migrations',
  standalone: true,
    imports: [
        AsyncPipe,
        DatePipe,
        MatCell,
        MatCellDef,
        MatColumnDef,
        MatHeaderCell,
        MatHeaderRow,
        MatHeaderRowDef,
        MatHint,
        MatIcon,
        MatIconButton,
        MatPaginator,
        MatRow,
        MatRowDef,
        MatSort,
        MatTable,
        MatTooltip,
        NgIf,
        MigrationTableComponent
    ],
  templateUrl: './migrations.component.html',
  styleUrl: './migrations.component.scss'
})
export class MigrationsComponent extends AbstractTableComponent implements OnInit {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    public migrations!: BehaviorSubject<MigrationList | undefined>;

    constructor(private readonly migrationService: MigrationsService) {
        super();
    }

    ngOnInit(): void {
        this.migrations = this.migrationService.getMigrations(true);
    }
}
