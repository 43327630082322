import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard  {
    public constructor(
        private readonly authService: AuthService,
        private readonly router: Router) {
    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const currentUser = this.authService.currentUserSubj.getValue();
        const allowed = !!(currentUser && currentUser.loggedIn);
        if (!allowed) {
            this.authService.setPostLoginUrl(state.url);
            return this.router.parseUrl('/login');
        }
        return true;
    }
}
