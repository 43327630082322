import { Injectable } from '@angular/core';
import {Load} from "@io-elon-common/frontend-api";
import {PhaseValues} from "./electricity";

@Injectable({
    providedIn: 'root'
})
export class LoadUtilsService {
    constructor() { }

    public getCurrentPwr(load: Load): PhaseValues {
        const tst = new Date().valueOf();
        const oldPredictions = load.prediction.filter(p=>p.start <= tst);
        if (oldPredictions.length === 0) {
            if (load.prediction.length > 0) {
                return new PhaseValues(
                    load.prediction[0].l1 ?? 0,
                    load.prediction[0].l2 ?? 0,
                    load.prediction[0].l3 ?? 0,
                );
            } else {
                return new PhaseValues(0,0,0);
            }
        }
        const currentPrediction = oldPredictions[oldPredictions.length-1];
        return new PhaseValues(
            currentPrediction.l1 ?? 0,
            currentPrediction.l2 ?? 0,
            currentPrediction.l3 ?? 0,
        );
    }

    public getMaxPwr(load: Load): number {
        return Math.max(...load.prediction.map(p=> {
            let sum = 0;
            if (p.l1 !== undefined) {
                sum += p.l1;
            }
            if (p.l2 !== undefined) {
                sum += p.l2;
            }
            if (p.l3 !== undefined) {
                sum += p.l3;
            }
            return sum;
        }));
    }

    public getMaxAmpsPerPhase(load: Load): PhaseValues {
        let maxP1: number = Math.max(...load.prediction.map(p => {return p.l1 ?? 0}));
        let maxP2: number = Math.max(...load.prediction.map(p => {return p.l2 ?? 0}));
        let maxP3: number = Math.max(...load.prediction.map(p => {return p.l3 ?? 0}));
        return new PhaseValues(maxP1 / 235, maxP2 / 235, maxP3 / 235);
    }
}
