<h1>Load Live Data</h1>
<table *ngIf="(liveData | async) as liveData; else loading">
    <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Power</th>
        <th>Power (Max in Window)</th>
        <th>Impl</th>
        <th>Note</th>
    </tr>
    <tr *ngFor="let l of liveData.list">
        <td>{{l.id}}</td>
        <td>{{l.name}}</td>
        <td>
            <span class="pwr">{{l.p1.toLocaleString("DE-DE", {useGrouping: true, maximumFractionDigits: 1})}} W</span>
            <span class="pwr">{{l.p2.toLocaleString("DE-DE", {useGrouping: true, maximumFractionDigits: 1})}} W</span>
            <span class="pwr">{{l.p3.toLocaleString("DE-DE", {useGrouping: true, maximumFractionDigits: 1})}} W</span>
        </td>
        <td>
            <span class="pwr">{{l.slidingMaxP1.toLocaleString("DE-DE", {useGrouping: true, maximumFractionDigits: 1})}} W</span>
            <span class="pwr">{{l.slidingMaxP2.toLocaleString("DE-DE", {useGrouping: true, maximumFractionDigits: 1})}} W</span>
            <span class="pwr">{{l.slidingMaxP3.toLocaleString("DE-DE", {useGrouping: true, maximumFractionDigits: 1})}} W</span>
        </td>
        <td>{{l.impl}}</td>
        <td>{{l.note}}</td>
    </tr>
</table>

<ng-template #loading>
    Loading...
</ng-template>
