import {Component, Input} from '@angular/core';
import {NotificationEntry} from '@io-elon-common/frontend-api';

@Component({
  selector: 'app-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss']
})
export class NotificationIconComponent {
    @Input()
    public notification!: NotificationEntry;
}
