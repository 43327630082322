import {Injectable} from '@angular/core';
import {Vehicle, VehicleLiveData, VehicleMini, VehicleTeaser} from '@io-elon-common/frontend-api';
import {num} from './util-functions';

@Injectable({
  providedIn: 'root'
})
export class VehicleUtilsService {

  constructor() {
  }

  public calcVehicleString(vehicle: Vehicle | VehicleTeaser | VehicleMini): string {
    if (!vehicle) {
        return '--';
    }
    return vehicle.name ||
        vehicle.numberPlate ||
        vehicle.localId ||
        vehicle.id + '';
  }

  public calcCurrentChargingPower(liveData: VehicleLiveData): number {
      const p1 = num(liveData.p1?.val, num(liveData.u1?.val, 235) * num(liveData.i1?.val, 0))
      const p2 = num(liveData.p2?.val, num(liveData.u2?.val, 235) * num(liveData.i2?.val, 0))
      const p3 = num(liveData.p3?.val, num(liveData.u3?.val, 235) * num(liveData.i3?.val, 0))

      return p1 + p2 + p3;
  }
}
