import { Component, OnInit } from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {ChargePlan} from '@io-elon-common/frontend-api';

@Component({
  selector: 'app-charge-plan-view',
  templateUrl: './charge-plan-view.component.html',
  styleUrls: ['./charge-plan-view.component.scss']
})
export class ChargePlanViewComponent implements OnInit {
    private selectedFleetSubsciption!: Subscription;
    public planSubscription?: Subscription;
    private startTst: number | undefined;
    public chargePlan: BehaviorSubject<ChargePlan | undefined> = new BehaviorSubject<ChargePlan | undefined>(undefined);
    private fleetId!: number;
    constructor(private readonly fleetService: FleetService) { }

    ngOnInit(): void {
        this.selectedFleetSubsciption = this.fleetService.selectedFleet.subscribe(id => {
            if (id !== undefined) {
                this.fleetId = id;
                this.planSubscription?.unsubscribe();
                const planSubject = this.fleetService.getChargePlan(this.fleetId, this.startTst);
                this.planSubscription = planSubject.subscribe(next => {
                    if(next) {
                        this.chargePlan.next(next)
                    }
                });
            }
        });
    }

    public updateStartDate(data: {startTime: number}) {
        this.startTst = data.startTime;
        this.planSubscription?.unsubscribe();
        const planSubject = this.fleetService.getChargePlan(this.fleetId, this.startTst);
        this.planSubscription = planSubject.subscribe(next => {
            if(next) {
                this.chargePlan.next(next)
            }
        });
    }

    ngOnDestroy() {
        this.selectedFleetSubsciption.unsubscribe();
        this.planSubscription?.unsubscribe();
    }

}
