import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Vehicle, DataSourceCommand} from '@io-elon-common/frontend-api';
import {ApiService} from "../../../../services/api-handlers/api.service";
import {ApiHandler} from "../../../../services/api-handlers/api-handler";

@Component({
    selector: 'app-vehicle-action',
    templateUrl: './vehicle-action-dialog.component.html',
    styleUrls: ['./vehicle-action-dialog.component.scss']
})
export class VehicleActionDialogComponent implements OnInit {

    public executed = false;
    public running = false;
    public selectedAction: DataSourceCommand | null = null;
    public paramValues: string[] = [];

    @HostListener('window:keyup.esc') onKeyUp() {
        this.dialogRef.close();
    }

    constructor(
        private dialogRef: MatDialogRef<VehicleActionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public vehicle: Vehicle,
        private readonly apiService: ApiService
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
    }

    async execute(): Promise<void> {
        if (this.selectedAction === null) {
            return;
        }
        const execObj: any = {
            cmd: this.selectedAction.name,
            params: {}
        };
        this.selectedAction.params.forEach((value, index) => execObj.params[value] = this.paramValues[index]);
        this.running = true;
        this.executed = true;
        try {
            await this.apiService.executeVehicleAction(true, this.vehicle.id, undefined, undefined, ApiHandler.customerId, execObj);
        } finally {
            this.running = false;
        }
    }

    close(): void {
        this.dialogRef.close();
    }
}
