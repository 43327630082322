import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {
    Evse,
    EvseMini,
    EvseTeaser,
    Fleet,
    Meter,
    Reservation,
    User,
    UserMini,
    Vehicle,
    VehicleMini,
    VehicleTeaser
} from '@io-elon-common/frontend-api';
import {VehicleUtilsService} from './vehicle-utils.service';
import {EvseUtilsService} from './evse-utils.service';

@Pipe({name: 'fullName'})
export class FullName implements PipeTransform {
    transform(user?: User | UserMini, defaultIfMissing?: string): string {
        if(!user) {
            return defaultIfMissing || "--";
        }
        if(user.firstname && user.lastname) {
            return user.firstname + " " + user.lastname;
        }
        if(user.firstname || user.lastname) {
            return user.firstname || user.lastname;
        }
        return user.name || "--"
    }
}

@Pipe({name: 'firstName'})
export class FirstName implements PipeTransform {
    transform(user?: User | UserMini): string {
        return user?.firstname || user?.name || "--";
    }
}

@Pipe({name: 'lastName'})
export class LastName implements PipeTransform {
    transform(user?: User): string {
        return user?.lastname || user?.name || "--";
    }
}

@Pipe({name: 'VehicleConnected'})
export class VehicleConnected implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: string): SafeHtml {
        const retStr = value === 'null' ? 'nicht angeschlossen oder Gastfahrzeug' :
            '<a routerLink="/vehicle/' + value + '">' + value + '</a>';
        return this.sanitizer.bypassSecurityTrustHtml(retStr);
    }
}

@Pipe({name: 'BatteryIcon'})
export class BatteryIcon implements PipeTransform {
    constructor() {
    }

    transform(value: number | string): string {
        if (isNaN(value as number)) {
            return 'help';
        }
        if ((value as number) < 10) {
            return 'battery_0';
        }
        if ((value as number) < 40) {
            return 'battery_1';
        }
        if ((value as number) < 70) {
            return 'battery_2';
        }
        if ((value as number) < 90) {
            return 'battery_3';
        }
        return 'battery_4';
    }
}


@Pipe({name: 'vehicleName'})
export class VehicleName implements PipeTransform {
    constructor(private readonly vehicleUtils: VehicleUtilsService) {}

    transform(vehicle: Vehicle | VehicleTeaser | VehicleMini): string {
        return this.vehicleUtils.calcVehicleString(vehicle)
    }
}

@Pipe({name: 'evseName'})
export class EvseName implements PipeTransform {
    constructor(private readonly evseUtils: EvseUtilsService) {}

    transform(evse: Evse | EvseTeaser | EvseMini): string {
        return this.evseUtils.calcEvseName(evse)
    }
}

@Pipe({name: 'fleetName'})
export class FleetName implements PipeTransform {
    transform(fleet: Fleet): string {
        return fleet.name || fleet.base.name || ("Flotte " + fleet.id)
    }
}

@Pipe({name: 'meterName'})
export class MeterName implements PipeTransform {
    transform(meter: Meter): string {
        return meter.name || ("Meter " + meter.id)
    }
}

@Pipe({name: 'reservationName'})
export class ReservationName implements PipeTransform {
    transform(reservation: Reservation): string {
        return "Reservierung " + reservation.id;
    }
}

