<div class="table-frame p-2">
    <h2>Dongles</h2>
    <div *ngIf="(dongles | async) as dongles; else loading">
        <app-dongle-table [dongles]="dongles"></app-dongle-table>
    </div>
</div>
<div class="buttons d-flex flex-row justify-content-end gap-3">
    <button mat-raised-button color="primary" (click)="handleNewDongle()">Dongle hinzufügen</button>
</div>
<ng-template #loading>Loading...</ng-template>
