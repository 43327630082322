<div *ngIf="(permissionGroups | async) as permissionGroups; else loading">
    <form [formGroup]="formGroup">
        <ng-container formArrayName="rows">
            <table mat-table [dataSource]="dataSource" class="full-width-table" matSort>
                <!-- Username Column -->
                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef class="col-username"> Benutzername</th>
                    <td mat-cell *matCellDef="let element; let i = index" class="col-username" [formGroup]="element">
                        <mat-form-field class="transparent">
                            <input matInput type="text" formControlName="name" [readonly]="success(i)" required>
                        </mat-form-field>
                    </td>
                </ng-container>

                <!-- Password Column -->
                <ng-container matColumnDef="password">
                    <th mat-header-cell *matHeaderCellDef class="col-password">Passwort</th>
                    <td mat-cell *matCellDef="let element; let i = index" class="col-password" [formGroup]="element" >
                        <mat-form-field class="transparent">
                            <input matInput type="password" formControlName="password" [placeholder]="$any(formGroup.get('rows')).at(i).get('sendWelcome').value ? 'Mail wird gesendet' : ''" [readonly]="success(i) || $any(formGroup.get('rows')).at(i).get('sendWelcome').value" [required] = "hasValidationError(i, 'password')">
                            <mat-checkbox matSuffix [disabled]="success(i)" formControlName="sendWelcome" matTooltip="Anstatt das Passwort zu speichern, wird dem neuen Benutzer eine Begrüßungs-E-Mail mit einem Link zum Festlegen seines Passwortes geschickt."></mat-checkbox>
                        </mat-form-field>
                    </td>
                </ng-container>

                <!-- Firstname Column -->
                <ng-container matColumnDef="firstname">
                    <th mat-header-cell *matHeaderCellDef class="col-firstname">Vorname</th>
                    <td mat-cell *matCellDef="let element; let i = index" class="col-firstname" [formGroup]="element">
                        <mat-form-field class="transparent">
                            <input matInput type="text" formControlName="firstname" [readonly]="success(i)" required>
                        </mat-form-field>
                    </td>
                </ng-container>

                <!-- Lastname Column -->
                <ng-container matColumnDef="lastname">
                    <th mat-header-cell *matHeaderCellDef class="col-lastname">Nachname</th>
                    <td mat-cell *matCellDef="let element; let i = index" class="col-lastname" [formGroup]="element">
                        <mat-form-field class="transparent">
                            <input matInput type="text" formControlName="lastname" [readonly]="success(i)" required>
                        </mat-form-field>
                    </td>
                </ng-container>

                <!-- email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="col-email">Email</th>
                    <td mat-cell *matCellDef="let element; let i = index" class="col-email" [formGroup]="element">
                        <mat-form-field class="transparent">
                            <input matInput type="email" formControlName="email" [readonly]="success(i)" [required] = "hasValidationError(i, 'email')">
                        </mat-form-field>
                    </td>
                </ng-container>

                <!-- Notificationlevel Column -->
                <ng-container matColumnDef="notification-level">
                    <th mat-header-cell *matHeaderCellDef class="col-notification-level">Benachrichtigungs-<br>level</th>
                    <td mat-cell *matCellDef="let element; let i = index" class="col-notification-level" [formGroup]="element">
                        <mat-select formControlName="emailAlertLevel" [disabled]="success(i)">
                            <mat-option [value]="0">Aus</mat-option>
                            <mat-option [value]="200">Fehler</mat-option>
                            <mat-option [value]="300">Warnungen</mat-option>
                            <mat-option [value]="400">Info</mat-option>
                        </mat-select>
                    </td>
                </ng-container>

                <!-- Reservation Column -->
                <ng-container matColumnDef="reservation">
                    <th mat-header-cell *matHeaderCellDef class="col-reservation">E-Mails zu Reservierungen</th>
                    <td mat-cell *matCellDef="let element; let i = index" class="col-reservation" [formGroup]="element">
                        <mat-checkbox formControlName="sendReservation" [disabled]="success(i)"></mat-checkbox>
                    </td>
                </ng-container>

                <!-- roles -->
                <ng-container matColumnDef="roles">
                    <th mat-header-cell *matHeaderCellDef class="col-roles"> Rollen</th>
                    <td mat-cell *matCellDef="let element; let i = index" class="col-roles" [formGroup]="element">
                        <mat-select formControlName="permissionGroups" multiple placeholder="Berechtigungsgruppe 1, ...">
                            <mat-option *ngFor="let pG of permissionGroups" [disabled]="success(i)" [value]="pG">{{pG.name}}</mat-option>
                        </mat-select>
                    </td>
                </ng-container>

                <ng-container matColumnDef="info">
                    <th mat-header-cell *matHeaderCellDef class="col-info"> Status</th>
                    <td mat-cell *matCellDef="let element; let i = index" class="col-info" [formGroup]="element">
                        <mat-icon *ngIf="success(i)" color="primary">done</mat-icon>
                        <mat-icon *ngIf="hasErrors(i)" color="warn" [matTooltip]="getError(i)">error</mat-icon>
                        <mat-icon *ngIf="!success(i) && !hasErrors(i)" color="primary">edit</mat-icon>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" [ngClass]="{'uploaded': success(i)}"></tr>
            </table>
            <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                           [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
            </mat-paginator>
        </ng-container>
    </form>
    <div class="buttons d-flex flex-row justify-content-end gap-3">
        <button mat-raised-button color="primary" (click)="uploadNewUsers()">Benutzer speichern</button>
    </div>
</div>
<ng-template #loading>Loading...</ng-template>
