<mat-card appearance="outlined" class="p-0" *ngIf="noBasisSolarSystems.length > 0">
    <mat-card-header>
        <mat-card-title><h2>Solaranlagen sind keinem Standort zugeordnet</h2></mat-card-title>
    </mat-card-header>
    <app-solar-system-no-fleet-table [solarSystems]="noBasisSolarSystems" (updateListEvent)="updateSolarSystemList()"></app-solar-system-no-fleet-table>
</mat-card>

<div *ngIf="(solarSystems | async) as solarSystems; else loading">
    <ng-container *ngFor="let ss of currentBasisSolarSystems;">
        <mat-card appearance="outlined">
            <div class="d-flex flex-row justify-content-between">
                <mat-card-title >
                    {{ss.name}}
                </mat-card-title>
                <div class="actions d-inline-flex flex-row justify-content-end align-items-center gap-1 me-3">
                    <mat-checkbox #historicSystemForecast checked="checked">Historische Gesamtvorhersagen anzeigen</mat-checkbox>
                    <button [disabled]="!ss.canEdit" mat-icon-button (click)="handleEditSolarSystem(ss)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button [disabled]="!ss.canDelete" mat-icon-button (click)="handleDeleteSolarSystem(ss)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <app-graph-help-icon [options]="['controlZoom', 'leftClickMove', 'shiftLeftClickZoom', 'doubleLeftClickReset']" [vAlign]="'middle'"></app-graph-help-icon>
                </div>
            </div>
            <mat-card-content>
                <app-solar-history-graph [solarSystemId]="ss.id" [historicSystemForecast]="historicSystemForecast.checked" [solarPanelNames]="ss.panels.map(getSolarPanelName)" [flattenWindowMinutes]="ss.meterFlattenWindowMinutes"></app-solar-history-graph>
                <div class="solar-panels">
                    <div class="center" style="position: relative">
                        <h2>{{ss.panels.length}} Panels</h2>
                    </div>
                    <div class="solar-panel" *ngFor="let sp of ss.panels">
                        <div>
                            <div class="d-flex flex-row justify-content-between align-items-center">
                                <h3><strong>{{getSolarPanelName(sp)}}</strong></h3>
                                <div class="actions d-inline-flex flex-row justify-content-end align-items-center gap-1">
                                    <button [disabled]="!ss.canEdit" mat-icon-button (click)="handleEditSolarPanel(sp)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button [disabled]="!ss.canDelete" mat-icon-button (click)="handleDeleteSolarPanel(sp)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <mat-icon class="sun-icon">flash_on</mat-icon>
                                {{sp.installedPower}}kWp
                                <div>
                                <mat-icon class="sun-icon">place</mat-icon>
                                {{sp.latitude.toFixed(4)}}°, {{sp.longitude.toFixed(4)}}°
                                <mat-icon class="sun-icon" [svgIcon]="'height'"></mat-icon>
                                {{sp.height}}m
                                </div>
                                <div>
                                <mat-icon class="sun-icon" [svgIcon]="'orientation'" [style.transform]="'rotate('+(sp.azimuth+180)+'deg)'"></mat-icon>
                                {{sp.azimuth}}°
                                <mat-icon class="sun-icon" [svgIcon]="'angle'"></mat-icon>
                                {{sp.tilt}}°
                            </div>
                            </div>
                        </div>
                    </div>
                    <mat-expansion-panel class="expansion-panel" (opened)="setOpenSate(ss, true)" (closed)="setOpenSate(ss, false)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{getOpenState(ss) ? '' : getNoteHeader(ss)}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-note-table [source]="ss" [sourceType]="NoteRelatedObject.Pv" (numberOfNotes)="updateNumberOfNotes($event, ss)"></app-note-table>
                    </mat-expansion-panel>
                    <div class="d-flex flex-row justify-content-end gap-3">
                        <button mat-raised-button color="primary" (click)="handleAddSolarPanel(ss)">Neues Solarpanel</button>
                    </div>
                </div>
                <ng-template #loading>
                    <div class="solar-panels loading">Loading...</div>
                </ng-template>
            </mat-card-content>
        </mat-card>
    </ng-container>

    <div class="d-flex flex-row justify-content-end gap-3">
        <button mat-raised-button color="primary" (click)="handleNewSolarSystem()">Neue Solaranlage</button>
    </div>
</div>

<ng-template #loading>
    <div *ngIf="error">
        <h1>Hinweis</h1>
        {{error}}
    </div>
    <div *ngIf="!error">
        Loading...
    </div>
</ng-template>
