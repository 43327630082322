import {Moment} from 'moment';
import {RowData} from '../tables/evse-health-history-table/evse-health-history-table.component';

export interface DateRange {
    start: Moment,
    end: Moment
}

export abstract class AbstractFaultHistoryOverview {

    protected range!: DateRange;

    abstract generateData(dateRange: DateRange): Promise<void>;

    protected updateRange($event: DateRange) {
        this.range = $event;
        this.generateData(this.range);
    }

    protected clearData(data: RowData<any>):RowData<any> {
        data = {
            durationOfFaults: 0,
            durationOfFaultsHumanReadable: '',
            numberOfFaults: 0,
            obj: undefined,
            pieChartStyle: undefined,
            percentage: {
                broken: 0,
                working: 0,
                maintenance:0
            },
            totalDuration: 0
        };
        return data
    }
}
