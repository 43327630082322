import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Evse, EvseMini, EvseTeaser, Fleet} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {EvseService} from '../../../../modules/evse/service/evse.service';
import {FleetService} from '../../../../modules/vehicle/service/fleet.service';

@Component({
  selector: 'app-evse-link',
  templateUrl: './evse-link.component.html',
  styleUrls: ['./evse-link.component.scss']
})
export class EvseLinkComponent implements OnInit, OnChanges{

    @Input()
    public evse: Evse | EvseTeaser | EvseMini | undefined;

    @Input()
    public evseId: number | undefined;

    @Input()
    public showAlerts = true

    public evseToDisplay: BehaviorSubject<Evse | EvseTeaser | EvseMini | undefined> = new BehaviorSubject<Evse | EvseTeaser | EvseMini | undefined>(undefined);

    public fleetId: number | undefined;

    private fleets: Fleet[] = [];
    constructor(
        private readonly evseService: EvseService,
        private readonly fleetService: FleetService
    ) { }

    async ngOnInit(): Promise<void> {
        this.fleets = await this.fleetService.getAllPromise(this.showAlerts);
        this.updateDisplayEvse();
        const eId = this.evse ? this.evse.id : this.evseId;
        if (eId) {
            const e = await this.evseService.getPromise(eId, this.showAlerts);
            this.fleets.forEach(fleet => {
                if (fleet.base.id === e.basis.id) {
                    this.fleetId = fleet.id;
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateDisplayEvse();
    }

    private updateDisplayEvse(): void {
        if (this.evse) {
            this.evseToDisplay.next(this.evse);
        } else if (typeof this.evseId === "number") {
            this.evseToDisplay = this.evseService.get(this.evseId, this.showAlerts) as unknown as BehaviorSubject<Evse | EvseTeaser | EvseMini | undefined>;
        }
    }
}
