import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChargingSession, ChargingSessionList} from '@io-elon-common/frontend-api';
import { ApiService } from '../../../../services/api-handlers/api.service';
import { VehicleService } from '../../../../modules/vehicle/service/vehicle.service';
import { ToastrService } from 'ngx-toastr';
import { EvseService } from '../../../../modules/evse/service/evse.service';
import { ChargingsTable } from './chargings-table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DurationPipe } from '../../../helper/duration.pipe';
import { ApiHandler } from "../../../../services/api-handlers/api-handler";
import { SystemService } from 'src/app/services/api-handlers/system.service';
import {ChargingSessionsService} from "../../../../modules/charging-session/service/chargingSession.service";
import {AuthService} from "../../../guards/auth.service";

@Component({
    selector: 'app-evse-chargings-table',
    templateUrl: './chargings-table.component.html',
    styleUrls: ['./chargings-table.component.scss']
})
export class EvseChargingsTableComponent extends ChargingsTable implements OnInit {
    @ViewChild(MatPaginator, { static: true })
    public paginator!: MatPaginator;

    @Input() evseId!: number;

    public displayedColumns: string[] = ['expander', 'start', 'duration', 'power', 'cost', 'vehicle', 'rfid', 'stopReason', 'actions'];
    private allowContinue!: Promise<boolean>;


    public constructor(
        protected readonly apiService: ApiService,
        protected readonly vehicleService: VehicleService,
        protected readonly evseService: EvseService,
        protected readonly toastrService: ToastrService,
        protected readonly dialog: MatDialog,
        protected readonly durationPipe: DurationPipe,
        protected readonly chargingService: ChargingSessionsService,
        protected readonly systemService: SystemService,
        protected readonly authService: AuthService
    ) {
        super();
    }

    protected async getChargings(showAlerts = true): Promise<ChargingSessionList> {
        return this.apiService.getEvseChargings(showAlerts, this.evseId, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public isAllowContinue(cs: ChargingSession): Promise<boolean> {
        return this.allowContinue;
    }

    public async ngOnInit(): Promise<void> {
        super.onInit();
        this.allowContinue = this.evseService.getPromise(this.evseId).then(e => e.basis.allowContinue);
    }
}
