import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {num} from '../../helper/util-functions';

@Component({
    selector: 'app-radial-bar',
    templateUrl: './radial-bar.component.html',
    styleUrls: ['./radial-bar.component.scss']
})
export class RadialBarComponent implements OnInit, OnChanges {
    public num = num;

    @Input() value!: number;
    @Input() label!: string;
    @Input() unit!: string;
    @Input() max = 100;
    @Input() fractionDigits = 0;
    @Input() showMax = false;
    public percent = 0;
    public outerStrokeColor = "lightgray"
    public outerStrokeGradientStopColor = "darkgray"

    constructor(
    ) {
        this.outerStrokeColor = getComputedStyle(document.body).getPropertyValue("--blue-3");
        this.outerStrokeGradientStopColor = getComputedStyle(document.body).getPropertyValue("--blue-1");
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.percent = Math.min(100, Math.max(0, this.value / this.max * 100));
    }

    ngOnInit(): void {
        this.percent = Math.min(100, Math.max(0, this.value / this.max * 100));
    }

}
