import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {NoteRelatedObject, SolarPanel, SolarSystem} from '@io-elon-common/frontend-api';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {ToastrService} from 'ngx-toastr';
import {SolarSystemService} from '../../service/solar-system.service';
import {SolarPanelService} from '../../service/solar-panel.service';
import {MeterService} from '../../../meter/service/meter.service';

@Component({
  selector: 'app-solarpanels-tiles-view',
  templateUrl: './solar-tiles-view.component.html',
  styleUrls: ['./solar-tiles-view.component.scss']
})
export class SolarTilesView implements OnInit, OnDestroy {
    public solarSystems!: BehaviorSubject<SolarSystem[] | undefined>;
    public error = '';
    public currentBasisSolarSystems: SolarSystem[] = [];
    public noBasisSolarSystems: SolarSystem[] = [];
    private selectedFleetSubscription!: Subscription;
    private solarSystemSubscription!: Subscription;
    private basisId!: number;
    private openStates = new Map<SolarSystem, boolean>();
    private numberOfNotes = new Map<SolarSystem, number>();
    public readonly NoteRelatedObject = NoteRelatedObject;

    constructor(
        private readonly toastr: ToastrService,
        private readonly solarSystemService: SolarSystemService,
        private readonly solarPanelService: SolarPanelService,
        private readonly fleetService: FleetService,
        private readonly meterService: MeterService,
    ) {
    }

    ngOnInit(): void {
        this.solarSystems = this.solarSystemService.getAll();
        this.solarSystemSubscription = this.solarSystems.subscribe(() => this.updateSolarSystemList());
        this.selectedFleetSubscription = this.fleetService.selectedFleet.subscribe(id => {
            if (id !== undefined) {
                this.fleetService.getPromise(id).then(fleet => {
                    this.basisId = fleet.base.id;
                    this.updateSolarSystemList();
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.solarSystemSubscription.unsubscribe();
        this.selectedFleetSubscription.unsubscribe();
    }

    public async handleNewSolarSystem(): Promise<void> {
        const selectedFleet = this.fleetService.selectedFleet.value;
        console.log("Selected: ",selectedFleet)
        if (selectedFleet === undefined) {
            this.toastr.warning("Keine Flotte ausgewählt");
            return;
        }
        this.fleetService.getPromise(selectedFleet).then(fleet => {
            console.log("Fleet: ", fleet);
            const powerSupplies = fleet?.base.powerSupplies;
            if (powerSupplies === undefined) {
                this.toastr.warning("Fehler beim laden der möglichen Anschlüsse");
                return;
            }
            this.meterService.getAllPromise().then(meters => {
                if (meters === undefined) {
                    this.toastr.warning("Fehler beim laden der möglichen Messgeräte");
                    return;
                }
                this.solarSystemService.showNewDialog({
                    possiblePowerSupplys: powerSupplies.filter(ps => ps.baseId === this.basisId),
                    possibleMeters: meters.filter(meter => meter.basis?.id === this.basisId),
                    basisId: fleet?.base.id
                });
            });
        });
    }

    public async handleEditSolarSystem(solarSystem: SolarSystem): Promise<void> {
        const selectedFleet = this.fleetService.selectedFleet.value;
        if (selectedFleet === undefined) {
            this.toastr.warning("Keine Flotte ausgewählt");
            return;
        }
        this.fleetService.getPromise(selectedFleet).then(fleet => {
            const powerSupplies = fleet.base.powerSupplies;
            if (powerSupplies === undefined) {
                this.toastr.warning("Fehler beim laden der möglichen Anschlüsse");
                return;
            }
            this.meterService.getAllPromise().then(meters => {
                if (meters === undefined) {
                    this.toastr.warning("Fehler beim laden der möglichen Messgeräte");
                    return;
                }
                this.solarSystemService.showEditDialog(solarSystem, {
                    possibleMeters: meters.filter(meter => meter.basis?.id === solarSystem.basis?.id),
                    possiblePowerSupplys: powerSupplies.filter(ps => ps.baseId === solarSystem.basis?.id),
                    basisId: fleet?.base.id
                });
            });
        });
    }
    public async handleDeleteSolarSystem(solarSystem: SolarSystem): Promise<void> {
        await this.solarSystemService.showDeleteDialog(solarSystem, {});
    }

    public async handleAddSolarPanel(solarSystem: SolarSystem): Promise<void> {
        const solarSystems = this.solarSystems.value;
        if (solarSystems == undefined) {
            this.toastr.warning("Fehler beim laden");
            return;
        }
        await this.solarPanelService.showNewDialog(solarSystems.filter(x => x.id == solarSystem.id));
    }
    public async handleEditSolarPanel(solarPanel: SolarPanel): Promise<void> {
        const solarSystems = this.solarSystems.value;
        if (solarSystems == undefined) {
            this.toastr.warning("Fehler beim laden");
            return;
        }
        await this.solarPanelService.showEditDialog(solarPanel, solarSystems);
    }
    public async handleDeleteSolarPanel(solarPanel: SolarPanel): Promise<void> {
        await this.solarPanelService.showDeleteDialog(solarPanel, {});
    }

    public getSolarPanelName(sp:SolarPanel): string {
        // 0 - 22.5 - 45 - 67.5 - 90 - 112.5 - 135 - 157.5 - 180
        let orientationName: string;
        if (sp.tilt === 0) {
            orientationName = "Flach";
        } else if (sp.azimuth < -157.5 || sp.azimuth >= 157.5) {
            orientationName = "Nord"
        } else if (sp.azimuth < -112.5) {
            orientationName = "Nordost"
        } else if (sp.azimuth < -67.5) {
            orientationName = "Ost"
        } else if (sp.azimuth < -22.5) {
            orientationName = "Südost"
        } else if (sp.azimuth < 22.5) {
            orientationName = "Süd"
        } else if (sp.azimuth < 67.5) {
            orientationName = "Südwest"
        } else if (sp.azimuth < 112.5) {
            orientationName = "West"
        } else {
            orientationName = "Nordwest"
        }
        return sp.installedPower+"kWp "+orientationName;
    }

    public updateSolarSystemList(): void {
        this.currentBasisSolarSystems = [];
        this.noBasisSolarSystems = [];
        this.solarSystems.getValue()?.forEach(solarSystem => {
            if (solarSystem.basis !== undefined && solarSystem.basis.id === this.basisId) {
                this.currentBasisSolarSystems.push(solarSystem);
            }
            if (solarSystem.basis === undefined) {
                this.noBasisSolarSystems.push(solarSystem);
            }
        });
    }

    public setOpenSate(solar: SolarSystem, state: boolean) {
        this.openStates.set(solar, state);
    }

    public getOpenState(solar: SolarSystem) {
        if(!this.openStates.has(solar)) {
            return false;
        }
        else {
            return this.openStates.get(solar);
        }
    }
    public getNoteHeader(solarSystem: SolarSystem) {
        const numberOfNotes = this.numberOfNotes.get(solarSystem);
        return `Notizen (${numberOfNotes})`
    }

    public updateNumberOfNotes(numberOfNotes: number, solarSystem: SolarSystem) {
        this.numberOfNotes.set(solarSystem, numberOfNotes);
    }
}
