export type KnownValidationErrors = {
    min?: {min: number, actual: number}
    max?: {max: number, actual: number}
    required?: true
    email?: true
    minlength?: {requiredLength: number, actualLength: number}
    maxlength?: {requiredLength: number, actualLength: number}
    pattern?: {requiredPattern: string, actualValue: string}
    errorText?: string
}

type ValidationFormatter = Required<{[T in keyof KnownValidationErrors]: (val: KnownValidationErrors[T]) => string}>

const VALIDATION_FORMATTERS: ValidationFormatter = {
    min: val => `Wert darf nicht kleiner als ${val!.min} sein.`,
    max: val => `Wert darf nicht größer als ${val!.max} sein.`,
    required: () => "Feld ist Pflichtfeld.",
    email: () => "Wert ist keine gültige E-Mail-Adresse.",
    minlength: val => `Wert muss mindestens ${val!.requiredLength} Zeichen lang sein.`,
    maxlength: val => `Wert darf maximal ${val!.requiredLength} Zeichen lang sein.`,
    pattern: val => `Wert entspricht nicht dem vorgegebenen Format.`,
    errorText: val => val!
};

export function formatValidationMessage<T extends keyof Required<KnownValidationErrors>>(key: T, val?: Required<KnownValidationErrors>[T]): string {
    if(!val) {
        return "";
    }
    const formatter = VALIDATION_FORMATTERS[key];
    if(!formatter) {
        return `Missing formatter for ${key}. Value is: ${JSON.stringify(val)}`
    }

    return formatter(val as any);
}
