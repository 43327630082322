import {Pipe, PipeTransform} from '@angular/core';

const second = 1000;
const min = second * 60;
const hour = min * 60;
const day = hour * 24;

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {
    // Transform time in ms to a duration String (1:30min, 2h, 3d 5h,...)
    transform(value: number): string {
        if (value < second) {
            return '<1 Sekunde';
        }
        if (value < min) {
            return Math.floor(value / second) + ' Sekunden';
        }
        if (value < min * 10) {
            return Math.floor(value / min) + ':' + pad(Math.floor((value % min) / second), 2) + ' Minuten';
        }
        if (value < hour) {
            return Math.round(value / min) + ' Minuten';
        }
        if (value < hour * 3) {
            return Math.floor(value / hour) + ':' + pad(Math.floor((value % hour) / min), 2) + ' Stunden';
        }
        if (value < day) {
            return Math.round(value / hour) + ' Stunden';
        }
        return Math.round(value / day) + ' Tage';
    }
}

function pad(num: number, size: number): string {
    let numStr = num.toString();
    while (numStr.length < size) {
        numStr = "0" + numStr;
    }
    return numStr;
}
