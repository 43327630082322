import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AlgoV2Config} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {AlgoV2ConfigService} from '../../service/algoV2Config.service';
import {AuthService} from '../../../../shared/guards/auth.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-algo-v2-config-table',
  templateUrl: './algo-v2-config-table.component.html',
  styleUrls: ['./algo-v2-config-table.component.scss']
})
export class AlgoV2ConfigTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input()
    public algoV2Configs!: AlgoV2Config[];

    public canAdd = false;
    public displayedColumns: string[] = ['id', 'name', 'values', 'actions'];
    public dataSource = new MatTableDataSource<AlgoV2Config>([]);

    public constructor(
        private readonly algoV2ConfigService: AlgoV2ConfigService,
        private readonly authService: AuthService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.canAdd = this.authService.hasGlobalPermission("ADD_ALGO_V2_CONFIG");
        this.dataSource = new MatTableDataSource(this.algoV2Configs);
        this.dataSource.paginator = this.paginator;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.algoV2Configs;
    }

    public async edit(algoV2Config: AlgoV2Config): Promise<void> {
        await this.algoV2ConfigService.showEditDialog(algoV2Config);
    }
    public async clone(algoV2Config: AlgoV2Config): Promise<void> {
        await this.algoV2ConfigService.showCloneDialog(algoV2Config);
    }

    public async delete(algoV2Config: AlgoV2Config): Promise<void> {
        await this.algoV2ConfigService.showDeleteDialog(algoV2Config, {});
    }
}
