<div class="d-flex gap-3 w-100">
    <mat-card
        appearance="outlined"
        *ngFor="let box of boxes; trackBy: trackByIdx"
        class="ifo-cards d-flex flex-row gap-0 flex-fill"
        [routerLink]="box.link"
    >
        <div class="card-icon bg-blue-1 flex-grow-0">
            <mat-icon class="iwhite" [svgIcon]="box.icon"></mat-icon>
        </div>
        <div class="card-content flex-grow-1">
            <div class="card-content-helper">
                <h2 class="mat-h2 c-blue-1">{{box.value || '--'}}</h2><br>
                <span class="mat-body-2">{{box.description || ''}}</span>
            </div>
        </div>
    </mat-card>
</div>
