import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Permission, PermissionGroup} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {PermissionService} from '../../service/permission.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';
import { PermissionGroupService } from 'src/app/modules/permissions/service/permission-group.service';

@Component({
  selector: 'app-permission-table',
  templateUrl: './permission-table.component.html',
  styleUrls: ['./permission-table.component.scss']
})
export class PermissionTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() permissions!: Permission[];
    @Input() permissionGroup!: PermissionGroup;

    @Output()
    public permissionDeletedEvent = new EventEmitter<void>();

    displayedColumns: string[] = ['id', 'type', 'impl', 'object', 'actions'];

    dataSource = new MatTableDataSource<Permission>([]);
    selection = new SelectionModel<Permission>(true, []);

    constructor(
        private readonly permissionService: PermissionService,
        private readonly permissionGroupService: PermissionGroupService
    ) {
        super();
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.permissions);
        this.dataSource.paginator = this.paginator;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.permissions;
    }

    private async refresh() {
        this.permissions = (await this.permissionGroupService.getPromise(this.permissionGroup.id)).permissions;
        this.dataSource.data = this.permissions;
    }

    public async edit(permission: Permission): Promise<void> {
        await this.permissionService.showEditDialog(permission, this.permissionGroup);
        await this.refresh();
    }

    public async delete(permission: Permission): Promise<void> {
        await this.permissionService.showDeleteDialog(permission, {});
        await this.refresh();
        this.permissionDeletedEvent.emit();
    }
}
