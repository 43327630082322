import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {Basis, ExecutePowerSupplyAddOrUpdate} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {BasisService} from '../../service/basis.service';


@Pipe({ name: 'factorPipe'})
export class FactorPipe implements PipeTransform{
  transform(val: number|undefined, arg: number) {
    if (val) {
        return val * arg
    }
    return val
  }
}


@Component({
    selector: 'app-edit-power-supply-dialog',
    templateUrl: './edit-power-supply-dialog.component.html',
    styleUrls: ['./edit-power-supply-dialog.component.scss']
})
export class EditPowerSupplyDialogComponent implements IEditForm<ExecutePowerSupplyAddOrUpdate>, OnInit{

    @Input()
    public data!: ExecutePowerSupplyAddOrUpdate;

    public basis!: BehaviorSubject<Basis | undefined>;

    public constructor(
        private readonly basisService: BasisService
    ) {
    }

    public ngOnInit(): void {
        this.basis = this.basisService.get(this.data.basisId);
    }

    public validate(): string[] {
        return [];
    }
}
