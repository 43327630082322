import {Injectable} from '@angular/core';
import {
    ConfirmDialogComponent,
    ConfirmDialogOption
} from '../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(
        private readonly dialog: MatDialog
    ) {
    }

    /** returns true if the user presses the btn, otherwise (ESC-Key,...) false */
    public async showInfoDialog(
        title = 'Hinweis',
        msg = '',
        btnText = 'OK',
        btnColor: 'warn' | 'primary' | 'accent' = 'primary',
    ): Promise<boolean> {
        const btn: ConfirmDialogOption = {text: btnText, color: btnColor};

        const dialogRef = this.dialog.open((ConfirmDialogComponent), {
            data: {
                head: title,
                msg,
                options: [btn]
            },
            closeOnNavigation: true
        });

        const result: ConfirmDialogOption = await dialogRef.afterClosed().toPromise();
        return result === btn;
    }

    public async showConfirmDialog(
        title = 'Bitte bestätigen',
        msg = '',
        yesText = 'Ja',
        noText = 'nein',
        yesColor: 'warn' | 'primary' | 'accent' = 'primary',
        noColor: 'warn' | 'primary' | 'accent' = 'primary',
        matDialogConfig: MatDialogConfig = {}
    ): Promise<boolean> {
        const yes: ConfirmDialogOption = {text: yesText, color: yesColor};
        const no: ConfirmDialogOption = {text: noText, color: noColor};

        const dialogRef = this.dialog.open((ConfirmDialogComponent), {
            data: {
                head: title,
                msg,
                options: [yes, no]
            },
            closeOnNavigation: true,
            ...matDialogConfig
        });

        const result: ConfirmDialogOption = await dialogRef.afterClosed().toPromise();
        return result === yes;
    }
}
