export class DelayedExecutor {
    public running = false;
    private timeoutId: any;

    public constructor(private readonly delay: number) {
    }

    public execute(cb: () => void): void {
        this.running = true;
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            try{
                cb();
            } finally {
                this.running = false;
            }
        }, this.delay);
    }
}
