<div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill" class="input-box">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="data.name">
        </mat-form-field>

        <mat-form-field appearance="fill" [formGroup]="stateForm" class="input-box">
            <mat-label>Typ</mat-label>
            <input matInput type="text" formControlName="stateGroup" [matAutocomplete]="autoGroup" required>
            <mat-autocomplete #autoGroup="matAutocomplete">
                <mat-optgroup *ngFor="let group of manufacturerGroupOptions | async" [label]="group.manufacturer">
                    <mat-option *ngFor="let meter of group.meters" [value]="meter.name">
                        <div matTooltip="{{meter.name}} -- {{group.manufacturer}}">
                            <img src="/img/smartMeter/{{meter.image}}" width="40px" height="40px"
                                alt="Meter Picture">
                            <span class="dropdown-text">{{meter.name}}</span>
                        </div>
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <ng-container *ngIf="meterType">
        <div *ngIf="meterType.type == 'Modbus' || meterType.type == 'Http'" class="d-flex flex-row gap-3">
            <mat-form-field appearance="fill" class="input-box">
                <mat-label>Hostname</mat-label>
                <input matInput [(ngModel)]="data.hostname">
            </mat-form-field>
            <mat-form-field appearance="fill" class="input-box">
                <mat-label>Port</mat-label>
                <input matInput type="number" min="1" max="100000" [(ngModel)]="data.port">
            </mat-form-field>
        </div>
        <div *ngIf="meterType.type == 'Modbus'">
            <mat-form-field appearance="fill" class="input-box">
                <mat-label>Modbus Id</mat-label>
                <input matInput type="number" min="1" max="256" [(ngModel)]="data.modbusId">
            </mat-form-field>
        </div>
        <div *ngIf="meterType.type == 'Modbus' || meterType.type == 'Http'">
            <mat-form-field appearance="fill" class="input-box">
                <mat-label>Abfrage-Intervall (Sekunden)</mat-label>
                <input matInput type="number" min="0" step="0.1" [(ngModel)]="interval">
            </mat-form-field>
        </div>
    </ng-container>
    <div>
        <mat-checkbox [(ngModel)]="data.maintenance" (change)="updateMaintenanceReason()">
            Wartungsmodus
            <br>
            <span class="mat-caption break">
                Im Wartungsmodus wird kein Alarm ausgelöst, wenn der Zähler nicht erreichbar ist.
            </span>
        </mat-checkbox>
    </div>
    <div *ngIf="data.maintenance">
        <mat-form-field appearance="fill" class="input-box">
            <mat-label >Begründung</mat-label>
            <input matInput type="text" [required]="'data.maintenance'" [(ngModel)]="data.maintenanceReason">
            <mat-hint>Notiz, warum der Zähler in den Wartungsmodus versetzt wird</mat-hint>
        </mat-form-field>
    </div>
</div>
<ng-template #loading>
    Loading...
</ng-template>
