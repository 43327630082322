import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {IEditForm, TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditVehicleKeyDialogComponent} from '../dialogs/edit-vehicle-key-dialog/edit-vehicle-key-dialog.component';
import {ExecuteVehicleKeyAddOrUpdate, ReservationInstance, VehicleKey} from '@io-elon-common/frontend-api';
import {defaultTo} from '../../../shared/helper/util-functions';
import {GiveOutVehicleKeyDialogComponent} from '../dialogs/give-out-vehicle-key-dialog/give-out-vehicle-key-dialog.component';
import {POLL_INTERVALS} from "../../../app.module";

const HOUR = 1000 * 60 * 60;

@Injectable({
    providedIn: 'root'
})
export class VehicleKeyService extends DialogHandler<VehicleKey, ExecuteVehicleKeyAddOrUpdate, ExecuteVehicleKeyAddOrUpdate, {}, void, void> {

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, 'VehicleKey', toastr, dialog, dialogService, POLL_INTERVALS.vehicleKeys);
    }

    public async showGiveOutDialog(
        vehicleKey: VehicleKey,
        reservation?: ReservationInstance
    ) {
        await this.showDialog({
            headline: "Schlüssel herausgeben",
            component: GiveOutVehicleKeyDialogComponent,
            executeCallback: elem => this.update(vehicleKey.id, elem),
            editElement: {
                currentLocationUserId: defaultTo(reservation?.reservation.driver?.id, undefined),
                currentLocation: "Beim Fahrer",
                vehicleId: vehicleKey.vehicle.id,
                expectedReturnTime: defaultTo(reservation?.end, Date.now() + 3 * HOUR),
                giveOutTime: Date.now()
            }
        }, "Schlüssel herausgegeben");
    }

    protected getEditConfig(
        vehicleKey: VehicleKey,
        editDialogConfigArgs: void
    ): TDialogOptions<ExecuteVehicleKeyAddOrUpdate, IEditForm<ExecuteVehicleKeyAddOrUpdate>> {
        return  {
            headline: "Schlüssel bearbeiten",
            component: EditVehicleKeyDialogComponent,
            executeCallback: elem => this.update(vehicleKey.id, elem),
            editElement: {
                currentLocationUserId: defaultTo(vehicleKey.currentLocationUser?.id, undefined),
                currentLocation: vehicleKey.currentLocation,
                vehicleId: vehicleKey.vehicle.id,
                expectedReturnTime: vehicleKey.expectedReturnTime,
                giveOutTime: vehicleKey.giveOutTime
            }
        };
    }

    protected getNewConfig(
        newDialogConfigArgs: void
    ): TDialogOptions<ExecuteVehicleKeyAddOrUpdate, IEditForm<ExecuteVehicleKeyAddOrUpdate>> {
        return {
            headline: "Schlüssel anlegen",
            component: EditVehicleKeyDialogComponent,
            executeCallback: elem => this.create(elem),
            editElement: {
                currentLocationUserId: undefined,
                currentLocation: "Schlüsselschrank",
                // @ts-ignore User needs to update it in Dialog
                vehicleId: null,
                expectedReturnTime: Date.now(),
                giveOutTime: undefined
            }
        };
    }
}
