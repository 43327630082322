import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {
    Basis,
    Dongle,
    Evse,
    Meter,
    Note,
    NoteRelatedObject,
    Rfid,
    SolarSystem,
    Vehicle
} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {NoteService} from '../../service/note.service';
import {AuthService} from '../../../../shared/guards/auth.service';
import {Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';

@Component({
  selector: 'app-note-table',
  templateUrl: './note-table.component.html',
  styleUrl: './note-table.component.scss'
})
export class NoteTableComponent extends AbstractTableComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    @Input()
    public source!: Evse | Vehicle | Basis | Meter | Rfid | Dongle | SolarSystem;
    @Input()
    public sourceType!: NoteRelatedObject
    public dataSource = new MatTableDataSource<Note>()
    public loading = true
    public displayedColumns: string[] = ['tst', 'note', 'edited_by.name', 'actions'];
    public canCreateNote = false;
    public noteSubscription!: Subscription
    @Output()
    public numberOfNotes = new EventEmitter<number>();

    constructor(
        private readonly noteService: NoteService,
        private readonly authService: AuthService,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit(): void {
        if(this.source){
            this.noteSubscription = this.noteService.getNotes(this.source.id, this.sourceType).subscribe(value => {
                if(value) {
                    this.dataSource = new MatTableDataSource(value)
                    this.dataSource.sortingDataAccessor = (item, property) => {
                        switch(property) {
                            case 'edited_by.name': return item.user?.name ?? "Autorisierter Benutzer";
                            case 'tst_edited': return item.tstEdited ?? "-";
                            case 'tst_created': return item.tstCreated;
                            case 'note': return item.note;
                            default: return item.tstCreated;
                        }
                    };
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.loading = false;
                    this.updateNumberOfNotes();
                }
            })
        }
        this.canCreateNote = this.authService.hasGlobalPermission("CREATE_NOTES");
    }

    public async addNote() {
        if(this.source) {
           await this.noteService.showNewDialog({
                type: this.sourceType,
                source: this.source
            });
        }
    }

    public async editNote(note: Note) {
          await this.noteService.showEditDialog(note);
    }
    public async deleteNote(note: Note) {
        await this.noteService.showDeleteDialog(note, {})
    }

    public trackById(idx: number, item:{id: number}) {
        return item.id;
    }

    ngOnDestroy(): void {
        this.noteSubscription.unsubscribe();
    }

    public getToolTip(note: Note) {
        const toolTipCreated = `Erstellt: ${this.datePipe.transform(note.tstCreated)} - ${this.datePipe.transform(note.tstCreated, "shortTime")}`;
        return note.tstEdited ? toolTipCreated + ` Bearbeitet: ${this.datePipe.transform(note.tstEdited)} - ${this.datePipe.transform(note.tstEdited, "shortTime")}` : toolTipCreated;
    }
    private updateNumberOfNotes() {
        this.numberOfNotes.emit(this.dataSource.data.length);
    }
}
