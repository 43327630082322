import {Pipe, PipeTransform} from '@angular/core';
import {AlgoV2Config} from '@io-elon-common/frontend-api';
import {SystemService} from '../../services/api-handlers/system.service';

@Pipe({
    name: 'algoV2ConfigValuesPipe'
})
export class AlgoV2ConfigValuesPipePipe implements PipeTransform {

    private readonly fieldsByStrategy: {[key: string]: Array<keyof AlgoV2Config>};
    private readonly strategyNames: {[key: string]: string} = {};

    public constructor(
        private systemService: SystemService
    ) {
        this.fieldsByStrategy = systemService.getAlgoConfigFieldsByStrategyMapping();
    }

    public transform(value: AlgoV2Config): string {
        const fields: Array<keyof AlgoV2Config> = this.fieldsByStrategy[value.strategy];

        const name = this.systemService.getSystemInfoSync()?.algoStrategies.find(s => s.key === value.strategy)?.name || value.strategy

        return name + ": " + fields.map(f => {
            return f + ": " + value[f];
        }).join(", ");
    }

}
