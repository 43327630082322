import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import * as moment from 'moment/moment';
import {Moment} from 'moment/moment';
import {FormControl, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {AuthService} from '../../../../shared/guards/auth.service';
import {durationBetweenStartAndEndHumanReadable} from '../../../../shared/helper/util-functions';
import {EvseHealthDataUtils} from '../../../../shared/helper/evse-health-data-utils';
import {HealthStatusHistory, HealthStatusHistoryEntry} from '@io-elon-common/frontend-api/lib/model/models';
import {EventLogEntry, HealthStatus} from '@io-elon-common/frontend-api';

@Component({
  selector: 'app-evse-health-status-table',
  templateUrl: './evse-health-status-table.component.html',
  styleUrl: './evse-health-status-table.component.scss'
})
export class EvseHealthStatusTableComponent implements OnInit, AfterViewInit{
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    loading = true;
    entries!: HealthStatusHistoryEntry[];
    start: Moment = moment().subtract(7, "days");
    end: Moment = moment();
    isDev!: boolean

    range = new FormGroup({
        start: new FormControl(this.start),
        end: new FormControl(this.end)
    });

    displayedColumns: string[] = ['status', 'tst_start', 'tst_end', 'duration', 'note'];
    today = moment();
    dataSource = new MatTableDataSource<HealthStatusHistoryEntry>([]);
    selection = new SelectionModel<HealthStatusHistoryEntry>(true, []);
    selectedIndex = 0;
    @Input() loadHistoryCb!: (start: number, end: number) => Promise<HealthStatusHistory>;
    selectedStatus: HealthStatus[] = Object.values(HealthStatus);
    constructor(
        private readonly authService: AuthService,
        private readonly evseHealthDataUtils: EvseHealthDataUtils) {
    }

    ngOnInit(): void {

        this.isDev = this.authService.isDeveloper();
        this.dataSource = new MatTableDataSource<HealthStatusHistoryEntry>(this.entries);
        this.dataSource.paginator = this.paginator;

        this.range.setValue({
            start: this.start,
            end: this.end
        });
        this.refresh();
    }
    public getDuration(tstStart: number, tstEnd: number): string {
        return durationBetweenStartAndEndHumanReadable(tstStart, tstEnd, false);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    selectRow(row: EventLogEntry) {
    }

    async refresh() {
        this.loading = true;
        const start = this.range.get("start")?.value as Moment;
        let end = this.range.get("end")?.value as Moment|null;
        if (end === null) end = start;

        this.entries = (await this.loadHistoryCb(
            start.clone().startOf('day').valueOf(),
            end.clone().endOf('day').valueOf())).list;

        this.dataSource = new MatTableDataSource<HealthStatusHistoryEntry>(this.entries.filter(e => this.selectedStatus.includes(e.healthStatus)));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
    }

    public getHealthText(status: HealthStatus): string {
        return this.evseHealthDataUtils.getHealthStatusText(status);
    }

    protected readonly EvseHealthStatus = HealthStatus;
    protected readonly Object = Object;
}
