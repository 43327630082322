<ng-template #loading>
    Laden...
</ng-template>
<div *ngIf="customers | async as customers; else loading" class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput type="text" [(ngModel)]="data.customerName">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Übergeordneter Reseller</mat-label>
            <mat-select [(ngModel)]="data.customerParent">
                <mat-option [value]="undefined">Keiner</mat-option>
                <mat-option *ngFor="let c of customers" [value]="c.id">{{c.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-checkbox [(ngModel)]="data.customerReseller">Ist ein Reseller</mat-checkbox>
    <mat-divider></mat-divider>

    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name des Standorts</mat-label>
            <input matInput [(ngModel)]="data.name">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Name der Flotte</mat-label>
            <input matInput [(ngModel)]="data.fleetName">
        </mat-form-field>
    </div>
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Breitengrad [lat] (°)</mat-label>
            <input matInput type="number" [(ngModel)]="data.lat">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>L&auml;ngengrad [lng] (°)</mat-label>
            <input matInput type="number" [(ngModel)]="data.lon">
        </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <mat-checkbox [(ngModel)]="data.dynamicElectricityCostsEnabled">dynamischer Strompreis</mat-checkbox>

    <div class="reserveSpace d-flex flex-row gap-3">
        <mat-form-field appearance="fill" *ngIf="!data.dynamicElectricityCostsEnabled">
            <mat-label>Strompreis (€/kWh)</mat-label>
            <input matInput [(ngModel)]="data.energyPrice">
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="data.dynamicElectricityCostsEnabled">
            <mat-label>Konzessionsabgabe (ct/kWh)</mat-label>
            <input matInput type="number" [(ngModel)]="data.konzessionsabgabe">
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="data.dynamicElectricityCostsEnabled">
            <mat-label>Netzentgelt (ct/kWh)</mat-label>
            <input matInput type="number" [(ngModel)]="data.netzentgelt">
        </mat-form-field>
    </div>
    <!-- //TODO ist beim Angular Update kaputt gegangen
    <mat-divider fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" [formGroup]="formGroup">
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
            <mat-form-field appearance="fill">
                <mat-label>Icon</mat-label>
                <ngx-mat-file-input formControlName="icon" placeholder="Datei wählen"></ngx-mat-file-input>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" class = "icon">
            <img [src]="data.icon" alt="Aktuell ausgewähltes Bild">
        </div>
    </div> -->
    <mat-divider></mat-divider>
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name des Hauptanschlusses</mat-label>
            <input matInput [(ngModel)]="data.psName">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Maximaler Strom des Hauptanschlusses (A)</mat-label>
            <input matInput type="number" [(ngModel)]="data.psMaxAmps">
        </mat-form-field>
    </div>
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Grid Connection Id (OpenADR)</mat-label>
            <input matInput [(ngModel)]="data.psGridConnectionId">
            <mat-hint class="mat-small">Leer lassen, wenn nicht bekannt.</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Peak Intervall</mat-label>
            <mat-select [(ngModel)]="data.psPeakCalculationIntervall">
                <mat-option value="YEARS">Jahr <mat-hint class="mat-small">(Zurücksetzen am 01.01. jeden Jahres)</mat-hint></mat-option>
                <mat-option value="MONTHS">Monat <mat-hint class="mat-small">(Zurücksetzen am 01. jeden Monats)</mat-hint></mat-option>
                <mat-option value="DAYS">Tag <mat-hint class="mat-small">(Zurücksetzen an jedem Tag 0:00 Uhr)</mat-hint></mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

