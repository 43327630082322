import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Dongle, Vehicle, VehicleTeaser} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {DongleService} from '../../service/dongle.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';
import {VehicleService} from "../../../vehicle/service/vehicle.service";
import {BehaviorSubject} from "rxjs";
import {Sort} from '@angular/material/sort';
import {compareNumValues, compareStringValues} from '../../../../shared/helper/util-functions';

interface VehicleRssi {
    vehicleId: number,
    rssi: number | undefined
}

@Component({
    selector: 'app-dongle-table',
    templateUrl: './dongle-table.component.html',
    styleUrls: ['./dongle-table.component.scss']
})

export class DongleTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input()
    public dongles!: Dongle[];

    public displayedColumns: string[] = ['id', 'dongleId', 'vehicle', 'dongleVin', 'dongleVersion', 'lastMsg','rssi', 'actions'];
    public dataSource = new MatTableDataSource<Dongle>([]);
    public vehicles?: BehaviorSubject<Vehicle[] | undefined>
    public sort: Sort = {
        active: 'dongleId',
        direction: 'asc',
    };

    private vehicleRssis: VehicleRssi[] = [];

    constructor(
        private readonly dongleService: DongleService,
        private readonly vehicleService: VehicleService
    ) {
        super();
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.dongles);
        this.dataSource.paginator = this.paginator;
        this.vehicles = this.vehicleService.getAll();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.sortData();
        this.dataSource.data = this.dongles;
    }

    public async edit(dongle: Dongle): Promise<void> {
        await this.dongleService.showEditDialog(dongle);
    }

    public async delete(dongle: Dongle): Promise<void> {
        await this.dongleService.showDeleteDialog(dongle, {});
    }

    public getSignal(vehicle: VehicleTeaser, vehicles: Vehicle[]) {
        const result = vehicles.find(v => v.id === vehicle.id)?.liveData.rssi;
        const rssiData = this.vehicleRssis.find(vr => vr.vehicleId === vehicle.id);

        if (rssiData) {
            rssiData.rssi = result?.val;
        } else {
            this.vehicleRssis.push({vehicleId: vehicle.id, rssi: result?.val})
        }

        return result;
    }

    public trackBy(idx: number, item: Dongle) {
        return item.id;
    }

    public setSort(sort: Sort) {
        this.sort = sort;
        this.sortData();
    }

    private sortData() {
        const isDesc = this.sort.direction === 'desc';
        switch (this.sort.active) {
            case "id":
                this.dongles.sort((a, b) => compareNumValues(a.id, b.id, isDesc));
                break;
            case "dongleId":
                this.dongles.sort((a, b) => compareNumValues(a.dongleId, b.dongleId, isDesc));
                break;
            case "dongleVin":
                this.dongles.sort((a, b) => compareStringValues(a.dongleVin, b.dongleVin, isDesc));
                break;
            case "vehicle":
                this.dongles.sort((a, b) => compareStringValues(a.vehicle?.name, b.vehicle?.name, isDesc));
                break;
            case "dongleVersion":
                this.dongles.sort((a, b) => compareStringValues(this.getDongleVersion(a), this.getDongleVersion(b), isDesc));
                break;
            case "lastMsg":
                this.dongles.sort((a, b) => compareNumValues(a.lastMsg, b.lastMsg, isDesc));
                break;
            case "rssi":
                this.dongles.sort((a, b) => compareNumValues(
                    a.vehicle? this.vehicleRssis.find(vr => vr.vehicleId === a.vehicle?.id)?.rssi : 0,
                    b.vehicle? this.vehicleRssis.find(vr => vr.vehicleId === b.vehicle?.id)?.rssi : 0,
                    isDesc)
                );
                break;
        }
        this.dataSource = new MatTableDataSource(this.dongles);
        this.dataSource.paginator = this.paginator;
    }

    private getDongleVersion(dongle: Dongle): string {
        return (dongle.detectedVehicle || "--") + " " + (dongle.versionArmApp || "--") + " " + (dongle.versionArmKernel || "--") + " " + (dongle.versionEsp || "--");
    }
}
