import {ApiHandler} from './api-handler';
import {ApiService} from './api.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
    EditDialogComponent,
    IEditForm,
    TDialogOptions
} from '../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogService} from '../dialog.service';

// tslint:disable-next-line:max-line-length
export abstract class DialogHandler<DataType extends { id: number }, EditType, CreateType, DeleteType, NewDialogConfigArgs, EditDialogConfigArgs> extends ApiHandler<DataType, EditType, CreateType, DeleteType> {

    protected constructor(
        apiService: ApiService,
        typeSuffix: string,
        protected readonly toastr: ToastrService,
        protected readonly dialog: MatDialog,
        protected readonly dialogService: DialogService,
        pollInterval: number
    ) {
        super(apiService, typeSuffix, pollInterval);
    }

    public async showEditDialog(element: DataType, editDialogConfigArgs: EditDialogConfigArgs, matDialogConfig: MatDialogConfig  = {}): Promise<void> {
        await this.showDialog(await this.getEditConfig(element, editDialogConfigArgs), "Eintrag bearbeitet", matDialogConfig)
    }
    public async showNewDialog(newDialogConfigArgs: NewDialogConfigArgs, matDialogConfig: MatDialogConfig = {}): Promise<void> {
        await this.showDialog(await this.getNewConfig(newDialogConfigArgs), "Eintrag angelegt", matDialogConfig)
    }

    public async showDeleteDialog(element: DataType, data: DeleteType, matDialogConfig: MatDialogConfig = {}): Promise<void> {
        const confirmed = await this.dialogService.showConfirmDialog(
            "Eintrag wirklich löschen?",
            "Diese Aktion kann nicht Rückgängig gemacht werden.",
            "Löschen",
            "Abbrechen",
            'warn',
            'accent',
            matDialogConfig)

        if (confirmed) {
            await this.delete(element.id, data);
            this.toastr.success("Eintrag gelöscht");
            return;
        }
        throw new Error("Benutzer hat die Aktion abgebrochen");
    }

    protected async showDialog<Type>(options: TDialogOptions<Type, IEditForm<Type>>, successMsg: string, matDialogConfig: MatDialogConfig  = {}): Promise<void> {
        const config = {
            data: options,
            closeOnNavigation: true,
            ...matDialogConfig
        };
        const dialogRef = this.dialog.open(EditDialogComponent, config);

        const result: boolean = await dialogRef.afterClosed().toPromise();
        if(result){
            this.toastr.success(successMsg);
        }
    }

    // tslint:disable-next-line:max-line-length
    protected abstract getEditConfig(element: DataType, editDialogConfigArgs: EditDialogConfigArgs): TDialogOptions<EditType, IEditForm<EditType>> | Promise<TDialogOptions<EditType, IEditForm<EditType>>>;

    // tslint:disable-next-line:max-line-length
    protected abstract getNewConfig(newDialogConfigArgs: NewDialogConfigArgs): TDialogOptions<CreateType, IEditForm<CreateType>> | Promise<TDialogOptions<CreateType, IEditForm<CreateType>>>;
}
