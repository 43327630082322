import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {
    Basis,
    Box,
    Evse,
    EvseLiveDataOcppRemoteType,
    Fleet, NoteRelatedObject,
    SystemInfo,
    Vehicle
} from '@io-elon-common/frontend-api';
import {Keys} from '../../../../shared/components/tables/data-table/data-table.component';
import {FormatterFactoryService} from '../../../../shared/components/tables/data-table/formatter-factory.service';
import {EvseService} from '../../service/evse.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../../services/api-handlers/api.service';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {num} from '../../../../shared/helper/util-functions';
import {VehicleService} from 'src/app/modules/vehicle/service/vehicle.service';
import {MatDialog} from "@angular/material/dialog";
import {ActorDetailsDialogComponent} from "../../dialogs/actor-details-dialog/actor-details-dialog.component";
import {ApiHandler} from "../../../../services/api-handlers/api-handler";
import {
    AbstractHistoryGraph
} from 'src/app/shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import {LocalStorageField, localStorageGet, localStorageSave} from 'src/app/shared/helper/typed-local-storage';
import {PowerUnits} from 'src/app/shared/helper/power-units';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {
    EvseValidationHandlingDialogComponent
} from "../../dialogs/evse-validation-handling-dialog/evse-validation-handling-dialog.component";
import {SystemService} from '../../../../services/api-handlers/system.service';
import {EvseHealthDataUtils} from '../../../../shared/helper/evse-health-data-utils';
import {EvseLogUploadsDialogComponent} from "../../dialogs/evse-log-uploads-dialog/evse-log-uploads-dialog.component";
import {AuthService} from '../../../../shared/guards/auth.service';

@Component({
    selector: 'app-evse-details-view',
    templateUrl: './evse-details-view.component.html',
    styleUrls: ['./evse-details-view.component.scss']
})
export class EvseDetailsViewComponent implements OnInit, OnDestroy {
    @ViewChild('graph1', { static: false }) graph1!: AbstractHistoryGraph<any>;
    @ViewChild('graph2', { static: false }) graph2!: AbstractHistoryGraph<any>;

    private fleetIdSubscription?: Subscription;
    public fleet!: BehaviorSubject<Fleet | undefined>
    public selectedTab!: number;
    public powerUnits: typeof PowerUnits = PowerUnits;
    public readonly NoteRelatedObject = NoteRelatedObject;

    public power!: number;
    public maxPower!: number;
    public i!: number;
    public maxI!: number;
    public boxes!: (Box & {link?: string})[];
    public evse!: BehaviorSubject<Evse | undefined>;
    public vehicles!: BehaviorSubject<Vehicle[] | undefined>;
    public evseLiveDataTableKeys!: Keys<Evse>;
    public evseConfigDataTableKeys!: Keys<Evse>;
    public supportsLog = false;
    public hasEvseStatisticViewPermission: boolean = false;

    private actorKey: string|undefined = undefined;
    private ocPPPort: number|undefined = undefined;
    private currentEvseId: number | undefined = undefined;
    private evseSubscription!: Subscription;
    private routerEventSubscription!: Subscription;

    // @ts-ignore
    public loadHistoryCb = (start: number, end: number) => this.apiService.getEvseEvents(true, this.evse.getValue()?.id, start, end, undefined, undefined, ApiHandler.customerId).toPromise();
    // @ts-ignore
    public evseHealthStatusHistory = (start: number, end: number) => this.apiService.getEvseHealthStatusHistoryInTimeRange(true, this.evse.getValue()?.id, start, end, undefined, undefined, ApiHandler.customerId).toPromise();
    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly evseService: EvseService,
        private readonly apiService: ApiService,
        private readonly formatterFactory: FormatterFactoryService,
        private readonly toastr: ToastrService,
        private readonly fleetService :FleetService,
        private readonly vehicleService :VehicleService,
        private readonly systemService: SystemService,
        private readonly dialog: MatDialog,
        private readonly evseHealthDataUtils: EvseHealthDataUtils,
        private readonly authService: AuthService,

    ) {
    }

    async ngOnInit(): Promise<void> {
        this.hasEvseStatisticViewPermission = this.authService.hasGlobalPermission('VIEW_EVSE_STATISTICS');
        this.routerEventSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.currentEvseId !== undefined) {
                    const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
                    if (this.currentEvseId !== id) {
                        this.currentEvseId = id;
                        this.unsubscribeAll();
                        this.init();
                    }
                }
            }
        });
        this.init();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll();
        this.routerEventSubscription?.unsubscribe();
    }

    private update(evse: Evse) {
        this.boxes = [
            {value: evse.name, description: 'Bezeichnung', icon: 'ladestation'},
            {
                value:
                    '' + (evse.liveData.vehicle ? evse.liveData.vehicle.canView ? (evse.liveData.vehicle.name ||
                        evse.liveData.vehicle.numberPlate ||
                        evse.liveData.vehicle.localId ||
                        evse.liveData.vehicle.id) : "Autorisiertes Fahrzeug" : "--"),
                description: 'Fahrzeug',
                icon: 'car',
                link:evse.liveData.vehicle ? evse.liveData.vehicle.canView ? "/fleet/vehicle/" + evse.liveData.vehicle.id : undefined : undefined
            },
            {value: evse.maxI.toFixed(0) + ' A', description: 'Maximaler Strom', icon: 'plug'}
        ];
        if (evse.guestMinAmps) {
            this.boxes.push({value: evse.guestMinAmps + ' A', description: 'Minimaler Gast Strom', icon: 'plug'})
        }

        const p1 = num(evse.liveData.evseP1?.val);
        const p2 = num(evse.liveData.evseP2?.val);
        const p3 = num(evse.liveData.evseP3?.val);

        this.power = (p1 + p2 + p3) / 1000;
        // @ts-ignore true wird bei addition wie 1 behandelt, false wie 0
        const phaseCount = !!evse.phase1 + !!evse.phase2 + !!evse.phase3;
        this.maxPower = evse.maxI * 235 * phaseCount / 1000;

        this.i = Math.max(
            num(evse.liveData.evseI1?.val),
            num(evse.liveData.evseI2?.val),
            num(evse.liveData.evseI3?.val)
        );
        this.maxI = evse.maxI;
        this.supportsLog = this.evseService.isLogSupported(evse);
    }

    public async edit(): Promise<void> {
        const evse = this.evse.getValue();
        if(!evse) {
            this.toastr.warning("Kein Element ausgewählt");
            return;
        }
        const powerSupplies =  this.fleet.getValue()?.base.powerSupplies;
        if(powerSupplies === undefined){
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        await this.evseService.showEditDialog(evse, powerSupplies);
    }

    public async delete(): Promise<void> {
        const evse = this.evse.getValue();
        if(!evse) {
            this.toastr.warning("Kein Element ausgewählt");
            return;
        }
        await this.evseService.showDeleteDialog(evse, {});
        await this.router.navigateByUrl("/evses");
    }

    public details() {
        const evse = this.evse.getValue();
        if (evse === undefined) {
            this.toastr.warning("Aktion nicht möglich");
            return;
        }
        this.dialog.open(ActorDetailsDialogComponent, {
            data: {
                evse: evse
            },
            maxHeight: '90vh',
            width: '80vw',
            minHeight: '90vh',
        });
    }

    public actions() {
        const cEvse = this.evse.getValue();
        if (cEvse !== undefined) {
            this.evseService.showActionDialog(cEvse);
        }
    }

    public showLog() {
        const cEvse = this.evse.getValue();
        if (cEvse !== undefined) {
            this.evseService.showLog(cEvse);
        }
    }

    public setSelectedTab(event: MatTabChangeEvent) {
        localStorageSave("EVSE_SELECTED_TAB", (event.index + "") as LocalStorageField["EVSE_SELECTED_TAB"]);
    }

    public handleValidation() {
        this.dialog.open(EvseValidationHandlingDialogComponent, {
            data: {
                evseId: this.evse.getValue()?.id,
            },
            maxHeight: '90vh'
        });
    }

    public getHealthIcon(evse: Evse): string {
        if (evse.maintenanceEnabled) return "circle-maintenance";

        switch (evse.liveData.healthStatus?.val) {
            case "WORKING": return "circle-working";
            case "ATTENTION": return "circle-attention";
            case "BROKEN": return "circle-broken";
            case "MAINTENANCE": return "circle-maintenance";
            case "ON_REPAIR": return "circle-repair";
        }
        return "";
    }

    private async init(): Promise<void> {
        this.selectedTab = parseInt(localStorageGet("EVSE_SELECTED_TAB", "0"));
        this.evseLiveDataTableKeys = [
            {field: 'liveData.vehicle', display: 'Fahrzeug', formatter: (val: any | Vehicle) => val ? val.name : '--'},
            {
                field: 'liveData.evseI1',
                display: 'I1',
                formatter: this.formatterFactory.createUnitDataValueFormatter('A')
            }, {
                field: 'liveData.evseI2',
                display: 'I2',
                formatter: this.formatterFactory.createUnitDataValueFormatter('A')
            }, {
                field: 'liveData.evseI3',
                display: 'I3',
                formatter: this.formatterFactory.createUnitDataValueFormatter('A')
            }, {
                field: 'liveData.evseP1',
                display: 'P1',
                formatter: this.formatterFactory.createUnitDataValueFormatter('W')
            }, {
                field: 'liveData.evseP2',
                display: 'P2',
                formatter: this.formatterFactory.createUnitDataValueFormatter('W')
            }, {
                field: 'liveData.evseP3',
                display: 'P3',
                formatter: this.formatterFactory.createUnitDataValueFormatter('W')
            }, {
                field: 'liveData.evseU1',
                display: 'U1',
                formatter: this.formatterFactory.createUnitDataValueFormatter('V')
            }, {
                field: 'liveData.evseU2',
                display: 'U2',
                formatter: this.formatterFactory.createUnitDataValueFormatter('V')
            }, {
                field: 'liveData.evseU3',
                display: 'U3',
                formatter: this.formatterFactory.createUnitDataValueFormatter('V')
            }, {
                field: 'liveData.evseCosPhi1',
                display: 'CosPhi 1',
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            }, {
                field: 'liveData.evseCosPhi2',
                display: 'CosPhi 2',
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            }, {
                field: 'liveData.evseCosPhi3',
                display: 'CosPhi 3',
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            }, {
                field: 'liveData.evseCharging',
                display: 'Laden',
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            }, {
                field: 'liveData.evseAlw',
                display: 'Freigegeben',
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            }, {
                field: 'liveData.evseIecStatus',
                display: 'IEC Status',
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            }, {
                field: 'liveData.evseTargetI1',
                display: 'Ziel Strom',
                formatter: this.formatterFactory.createUnitDataValueFormatter('A')
            }, {
                field: 'liveData.evseTemperature',
                display: "Temperatur",
                formatter: this.formatterFactory.createUnitDataValueFormatter('°C')
            }, {
                field: 'liveData.evseSerialNumber',
                display: "Seriennummer",
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            }, {
                field: 'liveData.evseFirmwareVersion',
                display: "Firmware",
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            }, {
                field: 'liveData.evseIccid',
                display: "ICCID(OCPP)",
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            }, {
                field: 'liveData.evseLastBootnotification',
                display: "letzte Boot Notification(OCPP)",
                formatter: this.formatterFactory.createDateDataValueFormatter()
            }, {
                field: 'liveData.evseModel',
                display: "EVSE Type(OCPP)",
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            }, {
                field: 'liveData.ocppRemoteType',
                display: "Detektierter OCPP Dialekt",
                formatter: val => {
                    if(!val) {
                        return "--"
                    }
                    const t = val as EvseLiveDataOcppRemoteType
                    return t.val + " (" + t.source + ")"
                }
            }
        ];

        this.evseConfigDataTableKeys = [
            {field: 'localId', display: 'Local ID', formatter: this.formatterFactory.createPlainFormatter()},
            {field: 'type', display: 'Typ', formatter: this.formatterFactory.createPlainFormatter()},
            {field: 'creation', display: 'Erstellt am', formatter: this.formatterFactory.createDateValueFormatter()},
            {field: 'basis', display: 'Basis', formatter: (val: any | Basis) => val ? val.name : '--'},
        ];
        this.currentEvseId = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);

        this.evse = this.evseService.get(this.currentEvseId);
        this.vehicles = this.vehicleService.getAll();

        this.systemService.getSystemInfo().then(
            (info:SystemInfo) => {
                this.ocPPPort = info.ocppWebsocketPort;
            }
        )

        this.evseSubscription = this.evse.subscribe(evse => {
            if (evse) {
                this.update(evse);
                if(evse.actorKey && (this.actorKey === undefined || this.actorKey !== evse.actorKey)) {
                    this.actorKey = evse.actorKey;
                    let actorSpecificKeys: Keys<Evse> = [];
                    switch (evse.actorKey.substr(evse.actorKey.lastIndexOf(".")+1)) {
                        case "OcppActor":
                            actorSpecificKeys = [
                                {
                                    field: "actorArgs",
                                    display: "OCPP URL",
                                    formatter: (val: any | Evse["actorArgs"]) => {
                                        if (Array.isArray(val)) {
                                            const identity = val.find(v => v.key === "chargePointIdentity")
                                            if (identity && this.ocPPPort) {
                                                return "ws(s)://"+ location.hostname + ":"+ this.ocPPPort + "/ocppj/" + identity.value;
                                            }
                                        }
                                        return "--";
                                    }
                                }
                            ];
                            break;
                    }
                    this.evseConfigDataTableKeys.push(...actorSpecificKeys);
                }
            }
        });

        this.fleetIdSubscription = this.fleetService.selectedFleet.subscribe(sf => {
            if(sf !== undefined) {
                this.fleet = this.fleetService.get(sf);
            }
        });
    }

    private unsubscribeAll(): void {
        this.evseSubscription.unsubscribe();
        this.fleetIdSubscription?.unsubscribe();
    }

    public showUploads() {
        this.dialog.open(EvseLogUploadsDialogComponent, {
            data: this.evse,
            maxHeight: '90vh'
        });
    }

    public logUploadDisabled() {
        return this.evse.value?.actorArgs.find(a => a.key === "connectorId")?.value != "1";
    }
}
