import { Component, OnInit } from '@angular/core';
import {MeterService} from '../../../modules/meter/service/meter.service';

@Component({
  selector: 'app-meter-no-fleet-info',
  templateUrl: './meter-no-fleet-info.component.html',
  styleUrls: ['./meter-no-fleet-info.component.scss']
})
export class MeterNoFleetInfoComponent implements OnInit {
    public shouldInfoShown: boolean = false;
    constructor(
      private meterService: MeterService
    ) { }

    async ngOnInit(): Promise<void> {
        const meters = await this.meterService.getAllPromise();
        meters.forEach(meter => {
            if (meter.canEdit && meter.basis === undefined) {
            this.shouldInfoShown = true;
            }
        });
    }
}
