import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {API_VERSION} from '../../app.component';

declare const $: any

@Injectable({
  providedIn: 'root'
})
export class CheckVersionInterceptor implements HttpInterceptor{
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map(value => {
                // @ts-ignore headers gibt es nicht immer, aber das prüfen wir hier ja
                if(value.headers && value.headers.get("api-version")) {
                    // @ts-ignore
                    const serverApiVersion = value.headers.get("api-version");
                    if(serverApiVersion !== API_VERSION) {
                        document.getElementById("angularMain")?.remove()
                        // @ts-ignore
                        document.getElementById("updateInfo").style.display = "";
                        // @ts-ignore
                        document.getElementById("updateInfoVersion").innerText =
                            "Server: " + serverApiVersion + ", Frontend: " + API_VERSION;
                    }
                }
                return value;
            })
        );
    }
}
