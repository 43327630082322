import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {WhitelistEntry} from "@io-elon-common/frontend-api";
import {WhitelistService} from "../../service/whitelist.service";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-whitelist-view',
    templateUrl: './whitelist-view.component.html',
    styleUrls: ['./whitelist-view.component.scss']
})
export class WhitelistViewComponent implements OnInit {

    public whitelist?: Observable<{evse: number, items: WhitelistEntry[]}[] | undefined>

    constructor(
        private readonly whitelistService: WhitelistService
    ) {
    }

    ngOnInit(): void {
        this.whitelist = this.whitelistService.getAll().pipe(
            map(whitelist => {
                const ret: {evse: number, items: WhitelistEntry[]}[] = [];

                if(whitelist) {
                    for(const e of whitelist) {
                        const line = ret.find(group => group.evse === e.evse)
                        if(line) {
                            line.items.push(e);
                        } else {
                            ret.push({
                                evse: e.evse,
                                items: [e]
                            });
                        }
                    }
                }

                return ret;
            })
        );
    }

    public delete(e: WhitelistEntry) {
        this.whitelistService.delete(e.id, {});
    }
}
