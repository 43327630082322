import {Component, Input} from '@angular/core';
import {Evse} from '@io-elon-common/frontend-api';
import * as moment from 'moment/moment';

@Component({
    selector: 'app-evse-health-diagram-list',
    templateUrl: './evse-health-diagram-list.component.html',
    styleUrl: './evse-health-diagram-list.component.scss'
})
export class EvseHealthDiagramListComponent {
    @Input() evses!: Evse[];
    public start = moment().subtract(0.5, 'days').startOf('hour');
    public end = moment().add(1.5, 'days').startOf('hour');
}
