<div class="main-div d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="data.name">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Maximaler Strom (A)</mat-label>
            <input matInput type="number" min="0" [(ngModel)]="data.maxAmps">
        </mat-form-field>
    </div>
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill" *ngIf="(basis | async) as basis">
            <mat-label>Übergeordneter Knoten</mat-label>
            <mat-select [(value)]="data.parentPowerSupplyId">
                <mat-option [value]="null">---</mat-option>
                <mat-option *ngFor="let ps of basis.powerSupplies" [value]="ps.id">{{ps.name}} <span class="current">{{ps.maxAmps}}
                    A</span></mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="data.parentPowerSupplyId === undefined">
            <mat-label>OpenADR - Grid Connection ID</mat-label>
            <input matInput [(ngModel)]="data.oadrGridConnectionId">
            <mat-hint>Wenn OpenADR eingesetzt wird, kommt diese ID vom Stromanbieter. Ansonsten einfach leer lassen.</mat-hint>
        </mat-form-field>
    </div>
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Zurücksetzen des Peaks</mat-label>
            <mat-select [(value)]="data.peakCalculationIntervall">
                <mat-option [value]="'DAYS'">Täglich 0:00 Uhr</mat-option>
                <mat-option [value]="'MONTHS'">1. Tag im Monat</mat-option>
                <mat-option [value]="'YEARS'">1. Tag im Jahr</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Leistungsspitze (kW)</mat-label>
            <input matInput type="number" placeholder="10" min="0" [ngModel]="data.userDefinedPeak | factorPipe:0.001" (ngModelChange)="data.userDefinedPeak=$event*1000">
        </mat-form-field>
    </div>
    <div>
        <mat-checkbox [(ngModel)]="data.visible" matTooltip="Wenn diese Option ausgewählt ist, wird in der Übersicht des Standortes ein Diagramm mit dem Verlauf der Leistung an dieser Unterverteilung angezeigt">
            Verlauf in Standortansicht anzeigen
        </mat-checkbox>
    </div>
</div>
<ng-template #loading>
    Loading...
</ng-template>
