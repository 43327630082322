import { Component, Input, OnInit } from '@angular/core';
import { IEditForm } from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import { Evse, ExecutePermissionAddOrUpdate, Fleet, PermissionImpl, PermissionType, Reservation, User, Vehicle } from '@io-elon-common/frontend-api';
import { SystemService } from '../../../../services/api-handlers/system.service';
import { EvseService } from '../../../evse/service/evse.service';
import { VehicleService } from '../../../vehicle/service/vehicle.service';
import { UserService } from '../../../users/service/user.service';
import { ReservationService } from '../../../reservations/service/reservation.service';
import { FleetService } from '../../../vehicle/service/fleet.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import {FormBuilder} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

interface StateGroupManufacturer {
    //For Autocomplete
    category: string;
    permissions: Array<PermissionType>
}

@Component({
    selector: 'app-edit-permission-dialog',
    templateUrl: './edit-permission-dialog.component.html',
    styleUrls: ['./edit-permission-dialog.component.scss']
})
export class EditPermissionDialogComponent implements OnInit, IEditForm<ExecutePermissionAddOrUpdate> {
    @Input() public edit!: boolean;
    @Input() public data!: ExecutePermissionAddOrUpdate;

    public allUsers!: BehaviorSubject<User[] | undefined>;
    public allVehicles!: BehaviorSubject<Vehicle[] | undefined>;
    public allEvses!: BehaviorSubject<Evse[] | undefined>;
    public allFleets!: BehaviorSubject<Fleet[] | undefined>;
    public allReservations!: BehaviorSubject<Reservation[] | undefined>;
    public allPermissionTypes!: Promise<PermissionType[]>;
    public allPermissionTypesList: Array<PermissionType> | undefined;
    public allPossibleImpls!: Array<{ name: PermissionImpl, displayName: string }>

    public permissionsCateogryList: StateGroupManufacturer[] = [];
    public permissionTypeDisplayNameWhenEdit: string | undefined;

    public stateForm = this.formBuilder.group({
        stateGroup: "",
    });
    public permissionCategoryGroupOptions: Observable<StateGroupManufacturer[]> | undefined;
    private userWrittenDisplayName: string | undefined;

    constructor(
        private systemService: SystemService,
        private evseService: EvseService,
        private vehicleService: VehicleService,
        private userService: UserService,
        private reservationService: ReservationService,
        private fleetService: FleetService,
        private readonly formBuilder: FormBuilder
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.allPermissionTypes = this.systemService.getSystemInfo().then(si => si.permissions.filter(p => p.visible));
        this.allUsers = this.userService.getAll();
        this.allVehicles = this.vehicleService.getAll();
        this.allEvses = this.evseService.getAll();
        this.allFleets = this.fleetService.getAll();
        this.allReservations = this.reservationService.getAll();
        this.allPossibleImpls = [];
        this.allPermissionTypesList = await this.allPermissionTypes;

        this.permissionTypeDisplayNameWhenEdit = this.edit ? this.allPermissionTypesList.find(t => t.name === this.data.type)?.displayName : "";

        const uniquePermissionCategory = Array.from(
            new Set(
                this.allPermissionTypesList.map((permission) => {
                    return permission.category;
                })
            )
        );

        uniquePermissionCategory.sort((a, b) => a.localeCompare(b));

        for (const category of uniquePermissionCategory) {
            this.permissionsCateogryList.push({
                category: category,
                permissions: this.allPermissionTypesList.filter(vObj => vObj.category === category).sort((a, b) => a.displayName.localeCompare(b.displayName))
            });
        }

        this.permissionCategoryGroupOptions = this.stateForm
            .get("stateGroup")!
            .valueChanges.pipe(
                startWith(""),
                map((value) => this.filterGroup(value || ""))
            );
    }

    private filterGroup(value: string): StateGroupManufacturer[] {
        if (value) {
            this.typeChanged(value);
            return this.permissionsCateogryList.map(group => ({
                category: group.category,
                permissions: group.permissions.filter(e => e.name.toLowerCase().includes(value.toLowerCase()) || e.category.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
            })).filter((group) => group.permissions.length > 0);
        }
        return this.permissionsCateogryList;
    }

    public async typeChanged(value: string) {
        this.userWrittenDisplayName = value;
        const type = this.allPermissionTypesList?.find(t => t.displayName === value);

        if (type) {
            this.data.type = type.name;
        }

        if (!type) {
            this.allPossibleImpls = [];
            // @ts-ignore
            this.data.impl = "";
        } else if (type.globalPermission) {
            this.allPossibleImpls = [{
                name: "GLOBAL",
                displayName: "Alle Elemente"
            }]
            this.data.impl = "GLOBAL";
        } else if (['ADD_VEHICLES_OF_FLEET', 'VIEW_VEHICLES_OF_FLEET', 'EDIT_VEHICLES_OF_FLEET', 'DELETE_VEHICLES_OF_FLEET'].indexOf(type.name) !== -1) {
            this.allPossibleImpls = [{
                name: "FLEET",
                displayName: "Flotte"
            }]
            this.data.impl = "FLEET";
        } else if (['ADD_EVSES_OF_BASIS', 'VIEW_EVSES_OF_BASIS', 'EDIT_EVSES_OF_BASIS', 'DELETE_EVSES_OF_BASIS'].indexOf(type.name) !== -1) {
            this.allPossibleImpls = [{
                name: "FLEET",
                displayName: "Flotte"
            }]
            this.data.impl = "FLEET";
        } else if (['ADD_ENERGY_SYSTEMS_OF_BASIS', 'VIEW_ENERGY_SYSTEMS_OF_BASIS', 'EDIT_ENERGY_SYSTEMS_OF_BASIS', 'DELETE_ENERGY_SYSTEMS_OF_BASIS'].indexOf(type.name) !== -1) {
            this.allPossibleImpls = [{
                name: "FLEET",
                displayName: "Flotte"
            }]
            this.data.impl = "FLEET";
        } else {
            this.allPossibleImpls = [{
                name: "EVSE",
                displayName: "Ladepunkt"
            }, {
                name: "FLEET",
                displayName: "Flotte"
            }, {
                name: "RESERVATION",
                displayName: "Reservierung"
            }, {
                name: "VEHICLE",
                displayName: "Fahrzeug"
            }]
            if (this.data.impl === "GLOBAL" || !this.data.impl) {
                this.data.impl = 'EVSE'
            }
        }
    }

    validate(): string[] {
        const name = this.edit? this.permissionTypeDisplayNameWhenEdit: this.userWrittenDisplayName;
        const type = this.allPermissionTypesList?.find(t => t.displayName === name);
        if (type === undefined) {
            return ["Art der Berechtigung nicht gefunden"]
        }
        return [];
    }
}
