import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {MeterLiveDataList} from "@io-elon-common/frontend-api";
import {MeterService} from "../../service/meter.service";

@Component({
    selector: 'app-meter-live-data-debug-view',
    templateUrl: './meter-live-data-debug-view.component.html',
    styleUrls: ['./meter-live-data-debug-view.component.scss']
})
export class MeterLiveDataDebugViewComponent implements OnInit {
    public meterLiveData!: BehaviorSubject<MeterLiveDataList | undefined>;

    constructor(
        private meterService: MeterService
    ) {
    }

    ngOnInit(): void {
        this.meterLiveData = this.meterService.getMeterLiveData();
    }
}
