<div *ngIf="success" class="success frame">
    <img src="/assets/img/iod-logo.svg" width="200px" alt="Logo">
    <h1>Fahrzeug verbunden.</h1>

    <p>
        Das Fahrzeug wurde erfolgreich mit IO-ELON verbunden.
    </p>
    <p>
        <a [href]="link">Weiter zu IO-ELON</a>
    </p>

</div>
<div *ngIf="!success" class="fail frame">
    <img src="/assets/img/iod-logo.svg" width="200px" alt="Logo">
    <h1>Verbindung fehlgeschlagen.</h1>

    <p>
        Es ist ein Problem aufgetreten. Das Fahrzeug wurde nicht korrekt mit IO-ELON verbunden.
    </p>
    <p>
        <a [href]="link">Weiter zu IO-ELON</a>
    </p>
</div>
