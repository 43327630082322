import {BehaviorSubject} from 'rxjs';
import {ApiService} from './api.service';
import {skip} from 'rxjs/operators';
import {CacheManager, CacheUpdater} from './cacheManager';


export abstract class ApiHandler<T extends { id: number }, UpdateType, CreateType, DeleteType> extends CacheManager{
    public static customerId: number | undefined = undefined

    private cache: CacheUpdater<T | undefined, number> = this.createManagedCache(((ce, id) => ce.id === id));
    private getAllCache: CacheUpdater<T[] | undefined, any> = this.createManagedCache(() => true);

    protected constructor(
        protected readonly apiService: ApiService,
        protected readonly typeSuffix: string,
        pollInterval: number
    ) {
        super(pollInterval);
    }

    protected executeGet(id: number, showAlerts = true): Promise<T> {
        // @ts-ignore
        return this.apiService['get' + this.typeSuffix](showAlerts, id, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    protected executeGetAll(showAlerts = true): Promise<{ list: T[] }> {
        // @ts-ignore
        return this.apiService['get' + this.typeSuffix + 'List'](showAlerts, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    protected executeUpdate(id: number, data: UpdateType, showAlerts = true): Promise<void> {
        // @ts-ignore
        return this.apiService['edit' + this.typeSuffix](showAlerts, id, undefined, undefined, ApiHandler.customerId, data).toPromise() as Promise<any>;
    }

    protected executeCreate(data: CreateType, showAlerts = true): Promise<void> {
        // @ts-ignore
        return this.apiService['add' + this.typeSuffix](showAlerts, undefined, undefined, ApiHandler.customerId, data).toPromise() as Promise<any>;
    }

    protected executeDelete(id: number, data: DeleteType, showAlerts = true): Promise<void> {
        // @ts-ignore
        return this.apiService['delete' + this.typeSuffix](showAlerts, id, undefined, undefined, ApiHandler.customerId, data).toPromise() as Promise<any>;
    }



    public getAll(showAlerts = true): BehaviorSubject<T[] | undefined> {
        return this.getAllCache.getOrCreateGet(
            undefined,
            () => this.executeGetAll(showAlerts).then(list => list.list.sort((a, b) => a.id - b.id))
        ).data;
    }

    public getAllPromise(showAlerts = true): Promise<T[]> {
        return this.wrapInPromise(() => this.getAll(showAlerts));
    }

    public get(id: number, showAlerts = true): BehaviorSubject<T | undefined> {
        return this.cache.getOrCreateGet(id, () => this.executeGet(id, showAlerts)).data;
    }

    public getPromise(id: number, showAlerts = true): Promise<T> {
        return this.wrapInPromise(() => this.get(id, showAlerts));
    }

    public update(id: number, data: UpdateType, showAlerts = true): Promise<void> {
        return this.executeUpdate(id, data, showAlerts);
    }

    public create(data: CreateType, showAlerts = true): Promise<void> {
        return this.executeCreate(data, showAlerts);
    }

    public delete(id: number, data: DeleteType, showAlerts = true): Promise<void> {
        return this.executeDelete(id, data, showAlerts);
    }

    protected async wrapInPromise<U>(cb: () => BehaviorSubject<U | undefined>): Promise<U> {
        const list = cb().getValue();
        if (list) {
            return list;
        }
        return new Promise((resolve, reject) => {
            const subscription = cb().pipe(skip(1)).subscribe(val => {
                subscription.unsubscribe();
                if (val) {
                    resolve(val);
                } else {
                    reject('Failed to load');
                }
            }, _ => {
                subscription.unsubscribe();
                reject('Failed to load');
            });
        });
    }
}
