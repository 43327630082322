import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {AlgoV2Config, Basis, ChargePlan, Fleet} from '@io-elon-common/frontend-api';
import {AlgoV2ConfigService} from '../../../algo-configs/service/algoV2Config.service';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {ToastrService} from 'ngx-toastr';
import {SystemService} from '../../../../services/api-handlers/system.service';
import {download} from "../../../../shared/helper/util-functions";


@Component({
    selector: 'app-algo-debugger-view',
    templateUrl: './algo-debugger-view.component.html',
    styleUrls: ['./algo-debugger-view.component.scss']
})
export class AlgoDebuggerViewComponent implements OnInit {
    public configs!: BehaviorSubject<AlgoV2Config[] | undefined>;
    public fleets!: BehaviorSubject<Fleet[] | undefined>;

    public apiVersion?: string

    public args: string = "";
    public data: string = "";
    public planStr: string = "";
    public fleet: number | null = null;
    public plan?: ChargePlan;
    public config?: number;

    public loading = false;

    public constructor(
        private readonly algoService: AlgoV2ConfigService,
        private readonly fleetService: FleetService,
        private readonly toastr: ToastrService,
        private readonly systemService: SystemService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.configs = this.algoService.getAll();
        this.fleets = this.fleetService.getAll();
        this.systemService.getSystemInfo().then(si => this.apiVersion = si.algoVersion)
        const fleets = await this.algoService.getAllPromise();
        if(fleets && fleets.length === 1) {
            this.fleet = fleets[0].id;
        }
    }

    public get basis(): Basis | undefined {
        return this.fleets.getValue()?.find(f => f.id === this.fleet)?.base;
    }

    public async loadFromPlan() {
        if(this.loading) {
            this.toastr.warning("Es wird bereits geladen!", "Fehler")
            return;
        }
        if(!this.fleet) {
            this.toastr.warning("Es ist keine Flotte ausgewählt!", "Fehler")
            return;
        }
        try {
            this.loading = true;
            this.plan = await this.fleetService.getChargePlanPromise(this.fleet);
            this.readFromPlan();
        } finally {
            this.loading = false;
        }
    }

    public async sendRequest() {
        if(this.loading) {
            this.toastr.warning("Es wird bereits geladen!", "Fehler")
            return;
        }
        try {
            this.loading = true;
            const body = {
                args: JSON.parse(this.args),
                data: JSON.parse(this.data)
            };

            this.plan = await this.algoService.testAlgo(body.args.config.id, body);
            this.planStr = JSON.stringify(this.plan);
        } finally {
            this.loading = false;
        }
    }

    private readFromPlan() {
        if(!this.plan) {
            this.toastr.warning("Es gibt keinen Plan!", "Fehler")
            return;
        }
        if (this.plan.devDetails) {
            this.args = JSON.stringify(this.plan.devDetails.args, undefined, 4);
            this.data = JSON.stringify(this.plan.devDetails.data, undefined, 4);
            this.planStr = JSON.stringify(this.plan);
        } else {
            this.toastr.warning("Plan has no devDetails!", "Fehler")
            return;
        }
    }

    public download(data: string, name="algo-debugger.json") {
        download(name, data);
    }

    public upload(files: FileList) {
        if(files.length !== 1) {
            this.toastr.warning("Es muss genau eine Datei ausgewählt werden!", "Fehler");
            return;
        }

        const file = files.item(0);
        if(file === null) {
            this.toastr.warning("Datei kann nicht geladen werden.", "Fehler")
            return;
        }

        file.arrayBuffer().then((ab: ArrayBuffer)=> {
            this.plan = undefined;
            this.args = "";
            this.data = "";
            this.planStr = "";
            this.plan = JSON.parse(new TextDecoder().decode(ab));
            this.readFromPlan();
        });
    }

    public copy(id: string) {
        const elem = document.getElementById(id) as HTMLTextAreaElement;

        // Select the text field
        elem.select();
        elem.setSelectionRange(0, 999999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(elem.value);
    }

    public async editConfig() {
        if(this.config) {
            this.algoService.showEditDialog(await this.algoService.getPromise(this.config));
        } else {
            this.toastr.warning("Keine Config ausgewählt.", "Fehler")
        }
    }
}
