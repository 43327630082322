import {Component, Input} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {ExecuteTeslaAccountAddOrUpdate} from '@io-elon-common/frontend-api';

@Component({
  selector: 'app-edit-tesla-account-dialog',
  templateUrl: './edit-tesla-account-dialog.component.html',
  styleUrls: ['./edit-tesla-account-dialog.component.scss']
})
export class EditTeslaAccountDialogComponent implements IEditForm<ExecuteTeslaAccountAddOrUpdate> {

    @Input()
    public data!: ExecuteTeslaAccountAddOrUpdate;

    public constructor(

    ) { }

    validate(): string[] {
        return [];
    }

}
