<div *ngIf="(vehicles | async) as vehicles; else loading" class="d-flex flex-column gap-3">
    <mat-form-field appearance="fill">
        <mat-label>Dongle ID</mat-label>
        <input matInput type="number" [(ngModel)]="data.dongleId" [min]="0">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Fahrzeug</mat-label>
        <mat-select [(value)]="data.vehicleId">
            <mat-option [value]="null">Keinem Fahrzeug zuordnen</mat-option>
            <mat-option *ngFor="let v of vehicles" [value]="v.id">{{v.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<ng-template #loading>Loading...</ng-template>
