import {Component} from '@angular/core';
import {LoadService} from "../../service/load.service";
import {LoadLiveDataList} from "@io-elon-common/frontend-api";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-load-live-data-view',
    templateUrl: './load-live-data-view.component.html',
    styleUrls: ['./load-live-data-view.component.scss']
})
export class LoadLiveDataViewComponent {

    public liveData: BehaviorSubject<LoadLiveDataList | undefined>

    constructor(
        private readonly loadService: LoadService
    ) {
        this.liveData = loadService.getLiveData();
    }
}
