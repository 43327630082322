<div>
    <mat-card appearance="outlined">
        <mat-card-title>Konfigurationseinträge</mat-card-title>
        <mat-card-content>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef> Key </th>
            <td mat-cell *matCellDef="let element">
                {{element.name}}
                <button (click)="updateComment(element.comment, element.name)" mat-icon-button
                        matTooltip="Kommentar">
                    <mat-icon>comment</mat-icon>
                </button>
                <ng-container *ngIf="element.comment && element.comment.comment">
                    <br/>
                    <pre class="comment">{{element.comment.comment}}</pre>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="environment">
            <th mat-header-cell *matHeaderCellDef matTooltip="Enviroment Variables with the prefix 'IO_ELON_CONFIG_' can be used to configure IO-ELON"> Environment </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.environment">
                    {{element.environment.value}}
                    <mat-icon *ngIf="element.environment.isUsed" color="primary">done</mat-icon>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="file">
            <th mat-header-cell *matHeaderCellDef matTooltip="Settings found in the file './cfg/io_elon.properties'"> File </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.file">
                    {{element.file.value}}
                    <mat-icon *ngIf="element.file.isUsed" color="primary">done</mat-icon>
                </ng-container>
        </ng-container>

        <ng-container matColumnDef="default">
            <th mat-header-cell *matHeaderCellDef matTooltip="Values that are hardcoded in the application."> Default </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.default">
                    {{element.default.value}}
                    <mat-icon *ngIf="element.default.isUsed" color="primary">done</mat-icon>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
        </mat-card-content>
    </mat-card>
</div>

<div class="missing-config-div">
    <mat-card appearance="outlined">
        <mat-card-title>Fehlende Konfigurationseinträge</mat-card-title>
        <mat-card-content>
            <table>
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
                <ng-container *ngFor="let data of getMissingConfigs()">
                    <tr>
                        <td>
                            {{data.name}}
                            <button (click)="updateComment(data.comment, data.name)" mat-icon-button
                                    matTooltip="Kommentar">
                                <mat-icon>comment</mat-icon>
                            </button>
                            <ng-container *ngIf="data.comment && data.comment.comment">
                                <br/>
                                <pre class="comment">{{data.comment.comment}}</pre>
                            </ng-container>
                        </td>
                        <td>
                            {{data.missing.value}}
                        </td>
                    </tr>
                </ng-container>
            </table>
        </mat-card-content>
    </mat-card>
</div>

