import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectDescription} from '../Messages';
import {isArray} from 'rxjs/internal-compatibility';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, OnChanges {

    @Input()
    public structure!: ObjectDescription;

    @Output()
    public textChanged = new EventEmitter<string>();

    public text = "Generated stuff here";

    constructor() {
    }

    ngOnInit(): void {
        this.updateText();
    }

    private updateText() {
        const structureCopy = JSON.parse(JSON.stringify(this.structure));

        this.text = JSON.stringify(this.prepareStructure(structureCopy), undefined, 2)
        this.onTextChange()
    }

    private prepareStructure(obj:  ObjectDescription): any {
        const ret: any = {};
        for(const key in obj) {
            if(typeof obj[key] === "string") {
                if(obj[key] === "number") {
                    ret[key] = 1;
                } else if(obj[key] === "Date") {
                    ret[key] = new Date().toISOString();
                } else {
                    ret[key] = obj[key];
                }
            } else if(typeof obj[key] === "object") {
                if(isArray(obj[key])) {
                    ret[key] = (obj[key] as string[]) [0];
                } else {
                    ret[key] = this.prepareStructure(obj[key] as ObjectDescription)
                }
            } else {
                ret[key] = obj[key];
            }
        }

        return ret;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.updateText();
    }

    public onTextChange() {
        this.textChanged.emit(this.text);
    }

}
