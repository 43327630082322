import {Component, Input, OnInit} from '@angular/core';
import {Load} from "@io-elon-common/frontend-api";
import {LoadService} from "../../service/load.service";

@Component({
    selector: 'app-load-history-dialog',
    templateUrl: './load-history-dialog.component.html',
    styleUrls: ['./load-history-dialog.component.scss']
})
export class LoadHistoryDialogComponent implements OnInit {

    @Input()
    public load!: Load;

    public points?: Array<Array<Date | number | null>>
    public labels = ["Date", "P1 (W)", "P2 (W)", "P3 (W)"]
    public loading = false;


    public constructor(
        private readonly loadService: LoadService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        const hist = await this.loadService.getHistory(this.load);
        this.points = hist
            .points
            .sort((p1, p2) => p1.tst - p2.tst)
            .map(p => [new Date(p.tst), p.p1, p.p2, p.p3])
    }

    public async refreshHistory() {
        this.loading = true
        try {
            await this.loadService.regenerateLoadHistory(this.load);
            const hist = await this.loadService.getHistory(this.load);
            this.points = hist
                .points
                .sort((p1, p2) => p1.tst - p2.tst)
                .map(p => [new Date(p.tst), p.p1, p.p2, p.p3])
        } catch (e) {
            console.error(e);
        }
        this.loading = false;
    }
}
