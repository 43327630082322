import {Pipe, PipeTransform} from '@angular/core';
import {formatValidationMessage, KnownValidationErrors} from "./known-validation-error";
import {AbstractControl} from "@angular/forms";

@Pipe({
    name: 'validationErrorFormatter',
    standalone: true,
    pure: false
})
export class ValidationErrorFormatterPipe implements PipeTransform {

    transform(ctrl: AbstractControl<any>): string {
        const errors = ctrl.errors;
        if(!errors) {
            return "";
        }
        const keys = Object.keys(errors) as Array<keyof KnownValidationErrors>;
        return keys.map(k => formatValidationMessage(k, errors[k])).join(", ")
    }

}
