import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {multicast} from 'rxjs/operators';
import {BehaviorSubject, ConnectableObservable, Observable, Subject} from 'rxjs';
import {localStorageGet} from '../../shared/helper/typed-local-storage';
import {ActiveToast} from 'ngx-toastr/toastr/toastr.service';
import {
    BASE_PATH,
    Configuration,
    ErrorResponseData,
    FrontendService
} from '@io-elon-common/frontend-api';
import {LOGIN_STATE} from "../../app.module";

function getAllProperties<T>(obj: T): Array<keyof T> {
    const allProps: Array<keyof T> = [];
    let curr: T = obj;
    do {
        const props: Array<keyof T> = Object.getOwnPropertyNames(curr) as Array<keyof T>;
        props.forEach(prop => {
            if (allProps.indexOf(prop) === -1) {
                allProps.push(prop);
            }
        });
        curr = Object.getPrototypeOf(curr) as T;
    } while (curr);
    return allProps;
}
export class ApiServiceRaw extends FrontendService {
    public constructor(
        httpClient: HttpClient,
        basePath: string,
        configuration: Configuration,
        toastr: ToastrService,
        loginState: BehaviorSubject<boolean>
    ) {
        super(httpClient, basePath, configuration);

        const that = this;
        getAllProperties(this)
            .filter(key => typeof this[key] === "function")
            .filter(key => key !== "addToHttpParams" && key !== "addToHttpParamsRecursive")
            .forEach(key => {
                const origFunc: any = this[key];

                // @ts-ignore
                // tslint:disable-next-line:only-arrow-functions
                this[key] = function() {
                    try {
                        const showAlerts: boolean = arguments[0] && loginState.getValue();
                        const result = origFunc.call(that, ...Array.from(arguments).slice(1));
                        if (typeof result.pipe === "function") {
                            let statusToast: ActiveToast<any> | null = null;
                            if(localStorageGet("SHOW_DEV_WEB_REQUESTS") === 'true') {
                                statusToast = toastr.info((key as string) + " - sending...", "API Request", {positionClass: "toast-bottom-left"})
                            }
                            const multi: ConnectableObservable<any> = result.pipe(multicast(() => new Subject()));
                            multi.subscribe(()=>{
                                if(statusToast) {
                                    statusToast.toastRef.componentInstance.message = (key as string) + " - OK";
                                }
                            }, error => {
                                if(statusToast) {
                                    statusToast.toastRef.componentInstance.message = (key as string) + " - Failed";
                                }
                                try{
                                    const data = error.error as ErrorResponseData;
                                    switch (data.level) {
                                        case 'WARN':
                                            if (showAlerts) {
                                                toastr.warning(data.msg);
                                            }
                                            break;
                                        case 'INFO':
                                            if (showAlerts) {
                                                toastr.info(data.msg);
                                            }
                                            break;
                                        default:
                                            if(showAlerts) {
                                                toastr.error(data.msg || error.message);
                                            }
                                    }
                                    if(data.details) {
                                        if (showAlerts) {
                                            toastr.info(data.details, "Fehlerdetails", {positionClass: "toast-top-left"})
                                        }
                                    }
                                } catch (e) {
                                    console.log("Failed to handle error: ", error, e);
                                    toastr.warning(error.message, 'Communication error');
                                }
                            });
                            multi.connect();
                            return multi;
                        }
                        return result;
                    } catch (e: any) {
                        console.log(e);
                        toastr.warning(e.message, "Communication error");
                        throw e;
                    }
                };
            });
    }
}

type TOptionsWithShowAlertFlag = {
    [K in keyof FrontendService]: FrontendService[K] extends (...args: infer _P) => infer R
        ? (showWarning: boolean, ...args: Parameters<FrontendService[K]>) => R extends Observable<HttpEvent<infer T>>?Observable<T>:R
        : never;
};

// @ts-ignore
const COptionsWithShowAlertFlag: new (
    httpClient: HttpClient,
    basePath: string,
    configuration: Configuration,
    toastr: ToastrService,
    loginState: BehaviorSubject<boolean>
) => TOptionsWithShowAlertFlag = function (
    httpClient: HttpClient,
    basePath: string,
    configuration: Configuration,
    toastr: ToastrService,
    loginState: BehaviorSubject<boolean>
) {
    return new ApiServiceRaw(httpClient, basePath, configuration, toastr, loginState);
}

@Injectable({
    providedIn: 'root'
})
export class ApiService extends COptionsWithShowAlertFlag {
    private _timeDeltaMillis = 0;

    public constructor(
        httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration,
        toastr: ToastrService,
        @Inject(LOGIN_STATE) loginState: BehaviorSubject<boolean>
    ) {
        super(httpClient, basePath, configuration, toastr, loginState);
    }

    get timeDeltaMillis(): number {
        return this._timeDeltaMillis;
    }
    set timeDeltaMillis(value: number) {
        this._timeDeltaMillis = value;
    }
}
