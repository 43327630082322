import {Component, Input, OnInit} from '@angular/core';
import {AbstractHistoryGraph, BackgroundLegend} from '../abstract-history-graph/abstract-history-graph';
import {localStorageGet} from '../../../helper/typed-local-storage';

@Component({
    selector: 'app-background-legend',
    templateUrl: './background-legend.component.html',
    styleUrls: ['./background-legend.component.scss']
})
export class BackgroundLegendComponent implements OnInit {
    showDevElements = localStorageGet("SHOW_DEV_ITEMS") === "true"

    @Input() public matchingGraph!: AbstractHistoryGraph<any>;

    public backgrounds!: BackgroundLegend[];

    constructor() {
    }

    ngOnInit(): void {
        this.backgrounds = this.matchingGraph.getBackgroundLegend();
    }

}
