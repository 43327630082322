<h1>Validierung Ausnahmen</h1>
<div *ngIf="(whitelist | async) as whitelist">
    <ng-container *ngFor="let group of whitelist">
        <div class="row">
            <div class="col-1"><app-evse-link [evseId]="group.evse"></app-evse-link></div>
            <div class="col-11">
                <div *ngFor="let e of group.items" class="key">
                    <mat-hint>{{e.key}}</mat-hint>
                    <a mat-icon-button matTooltip="Löschen" (click)="delete(e)"><mat-icon>delete</mat-icon></a>
                </div>
            </div>
        </div>
        <hr>
    </ng-container>
</div>
