import {Component, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {ExecuteConfigCommentAddOrUpdate} from '@io-elon-common/frontend-api';
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {take} from "rxjs/operators";



@Component({
  selector: 'app-edit-configuration-comment-dialog',
  templateUrl: './edit-configuration-comment-dialog.component.html',
  styleUrls: ['./edit-configuration-comment-dialog.component.scss']
})
export class EditConfigurationCommentDialogComponent implements OnInit, IEditForm<ExecuteConfigCommentAddOrUpdate> {

    @ViewChild('autosize') autosize!: CdkTextareaAutosize;

    @Input()
    public data!: ExecuteConfigCommentAddOrUpdate;

    constructor(
        private readonly _ngZone: NgZone
    ) {
    }

    ngOnInit(): void {
        this.triggerResize();
    }

    validate(): string[] {
        return [];
    }

    triggerResize() {
        // Wait for changes to be applied, then trigger textarea resize.
        this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
    }
}
