import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import {TimeZoneName} from '@io-elon-common/frontend-api';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
    selector: 'app-time-zone-picker',
    templateUrl: './time-zone-picker.component.html',
    styleUrl: './time-zone-picker.component.scss'
})
export class TimeZonePickerComponent implements OnInit {

    public timeZone !: TimeZoneName;
    public timeZoneFromControl!: FormControl<TimeZoneName | null>;
    public filteredTimeZone!: Observable<TimeZoneName[] | undefined>;
    public timeZoneNames = Object.values(TimeZoneName);

    constructor(
        public dialogRef: MatDialogRef<TimeZonePickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TimeZoneName
    ) {
        this.timeZone = data;
    }

    ngOnInit(): void {
        this.timeZoneFromControl = new FormControl<TimeZoneName | null>(this.timeZone);
        this.filteredTimeZone = this.timeZoneFromControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterTimeZone(value || '')),
        );
        this.timeZoneFromControl.valueChanges.subscribe(() => {
            this.timeZone = this.timeZoneFromControl.value || this.timeZone;
        });
    }

    public cancel() {
        this.dialogRef.close();
    }

    private filterTimeZone(search: string): TimeZoneName[] {
        const filter = search.toLowerCase();
        return this
        .timeZoneNames
        .filter(timezone => timezone.toLowerCase().includes(filter))
        .sort((a, b) => a.localeCompare(b));
    }
}
