<app-help-box-icon class="help-icon" [iconType]="DialogType.HELP" #helpIcon></app-help-box-icon>
<div class="form d-flex flex-column gap-3">
    <app-help-box [dialogType]="DialogType.HELP" [key]="'ocpp_uplinks_dialog'" [helpIcon]="helpIcon">
        <p>
            OCPP Uplinks können verwendet werden, wenn das Energiemanagement des Ladepunktes durch IO-ELON erfolgen und
            gleichzeitig Dienste anderer Anbieter genutzt werden sollen, um z.B. Abrechnung oder Autorisierung durchzuführen.
        </p>
        <p>
            Im Uplink kann man die CPI (Charge Point Identity) überschreiben. Alternativ wird die CPI des Ladepunktes verwendet,
            um die Anmeldung beim Backend des weiteren Dienstleisters durchzuführen.<br>
            Wenn die CPI überschrieben wird, kann der Uplink nur von einer Station verwendet werden.<br>
            Wenn die CPI leer gelassen wird, kann der Uplink für mehrere Stationen verwendet werden.
        </p>
        <p>
            Damit die neue Konfiguration angewendet werden kann, muss die OCPP-Verbindung getrennt werden
        </p>
    </app-help-box>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Name:</mat-label>
            <input matInput type="text" [(ngModel)]="data.name">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Basis-URL</mat-label>
            <input matInput type="text" [(ngModel)]="data.baseUrl">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="fill" *ngIf="setCpi">
            <mat-label>Charge Point Identity (CPI)</mat-label>
            <input matInput type="text" [(ngModel)]="data.cpi">
            <mat-hint>Leer lassen, um die CPI der Station zu verwenden.</mat-hint>
        </mat-form-field>
        <mat-checkbox *ngIf="!setCpi" [(ngModel)]="setCpi" class="cpi-checkbox">CPI überschreiben</mat-checkbox>
        <mat-form-field appearance="fill">
            <mat-label>Benutzername:</mat-label>
            <input matInput type="text" [(ngModel)]="data.userName">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Passwort:</mat-label>
            <input matInput type="password" [(ngModel)]="data.pass">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Default RFID Token</mat-label>
            <input matInput type="text" [(ngModel)]="data.defToken">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="fill" *ngIf="isDev">
            <mat-label>meterValueMinDelay - DEV ONLY</mat-label>
            <input matInput type="number" [(ngModel)]="data.meterValueMinDelay">
            <mat-hint>Blockiert alle MeterValues die weniger als X nach den letzten gesendet wurden.</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="isDev">
            <mat-label>statusValueMinDelay - DEV ONLY</mat-label>
            <input matInput type="number" [(ngModel)]="data.statusValueMinDelay">
            <mat-hint>Maximal eine von uns angefragte StatusNotifications pro X Sekunden an den Uplink weiterleiten.</mat-hint>
        </mat-form-field>
    </div>
    <mat-checkbox  [(ngModel)]="data.ssl">SSL Verbindung benutzen</mat-checkbox>
</div>
<ng-template #loading>Loading...</ng-template>
