<div class="main d-flex flex-row align-items-stretch gap-0">
    <app-sidebar class="flex-grow-0"></app-sidebar>
    <div class="main-body flex-grow-1 d-flex flex-column align-items-stretch gap-0">
        <app-header class="flex-grow-0"></app-header>
        <div class="content-wrapper flex-grow-1">
            <div id="content">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer class="flex-grow-0"></app-footer>
    </div>
</div>
<div class="devHint" [class.mute]="muteDevHint" *ngIf="isDev" (click)="muteDevHint=!muteDevHint; updateMuteSetting()">
    <h1 class="text-center">Entwicklungsmodus</h1>
    <p class="text-center">nicht für den Kunden</p>
</div>
