import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MeterService} from '../../service/meter.service';
import {AuthService} from '../../../../shared/guards/auth.service';
import {Router} from '@angular/router';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';
import {DatePipe} from '@angular/common';
import {AgePipe} from '../../../../shared/helper/age.pipe';
import {Meter, SystemInfoMeterType} from '@io-elon-common/frontend-api';
import {SystemService} from '../../../../services/api-handlers/system.service';

export const OUTDATED_TIME = 1000 * 60;
@Component({
  selector: 'app-meter-table',
  templateUrl: './meter-table.component.html',
  styleUrls: ['./meter-table.component.scss']
})
export class MeterTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

    @Input()
    public meters!: Meter[];

    public canAdd = false;
    public displayedColumns: string[] = ['id', 'name', 'type', 'hostname', 'port','modbusId', 'interval', 'actions'];
    public dataSource = new MatTableDataSource<Meter>([]);
    private meterTypes: Array<SystemInfoMeterType> = [];
    public constructor(
        private readonly meterService: MeterService,
        private readonly authService: AuthService,
        private router: Router,
        private readonly agePipe: AgePipe,
        private readonly datePipe: DatePipe,
        private readonly systemService: SystemService,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        this.meterTypes = (await this.systemService.getSystemInfo()).supportedMeters;
        this.canAdd = this.authService.hasGlobalPermission("ADD_METER");
        this.dataSource = new MatTableDataSource(this.meters);
        this.dataSource.paginator = this.paginator;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.meters;
    }

    public async edit(meter: Meter): Promise<void> {
        await this.meterService.showEditDialog(meter);
    }
    public async clone(meter: Meter): Promise<void> {
        await this.meterService.showCloneDialog(meter);
    }

    public async delete(meter: Meter): Promise<void> {
        await this.meterService.showDeleteDialog(meter, {});
    }

    public navigate(meter: Meter){
        this.router.navigate(['/meters/meter', meter.id]); // we can send product object as route param
    }

    public isOutdated(meter: Meter): boolean {
        return meter.lastMsg < Date.now() - OUTDATED_TIME;
    }

    public getAge(meter: Meter): string {
        return this.agePipe.transform(meter.lastMsg);
    }

    public getNotificationTooltipText(meter: Meter): string {
        let lastContact = this.datePipe.transform(new Date(meter.lastMsg), 'dd.MM.yyyy, HH:mm:ss');
        if (meter.lastMsg === 0 || lastContact === null) {
            lastContact = "Unbekannt";
        }
        return "Zuletzt: " + lastContact;
    }

    public trackById(idx: number, item:{id: number}) {
        return item.id;
    }
    public getMeterName(type: string): string {
        return this.meterTypes.find(t => t.key === type)?.name ?? "Unbekannt";
    }
}
