import {Injectable} from '@angular/core';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ExecutePermissionAddOrUpdate, Permission, PermissionGroup} from '@io-elon-common/frontend-api';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {ApiService} from '../../../services/api-handlers/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {EditPermissionDialogComponent} from '../dialogs/edit-permission-dialog/edit-permission-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends DialogHandler<Permission, ExecutePermissionAddOrUpdate, ExecutePermissionAddOrUpdate, {}, PermissionGroup, PermissionGroup>{

    constructor(
        apiService: ApiService,
        toastr: ToastrService,
        dialog: MatDialog,
        dialogService: DialogService
    ) {
        super(apiService, 'Permission', toastr, dialog, dialogService, POLL_INTERVALS.permissions);
    }

    protected getEditConfig(permission: Permission, permissionGroup: PermissionGroup): TDialogOptions<ExecutePermissionAddOrUpdate, EditPermissionDialogComponent> {
        return {
            component: EditPermissionDialogComponent,
            headline: "Berechtigung bearbeiten",
            executeCallback: editResult => this.update(permission.id, editResult),
            editElement: {
                type: permission.type.name,
                impl: permission.impl,
                permissionGroupId: permissionGroup.id,
                evseId: permission.evse ? permission.evse.id : undefined,
                fleetId: permission.fleet ? permission.fleet.id : undefined,
                reservationId: permission.reservation ? permission.reservation.id : undefined,
                vehicleId: permission.vehicle ? permission.vehicle.id : undefined,
            },
            extraParams: {
                edit: true
            }
        }
    }

    protected getNewConfig(permissionGroup: PermissionGroup): TDialogOptions<ExecutePermissionAddOrUpdate, EditPermissionDialogComponent> {
        return {
            component: EditPermissionDialogComponent,
            headline: "Berechtigung anlegen",
            executeCallback: createResult => this.create(createResult),
            editElement: {
                type: "",
                impl: 'GLOBAL',
                permissionGroupId: permissionGroup.id,
                evseId: undefined,
                fleetId: undefined,
                reservationId: undefined,
                vehicleId: undefined
            },
            extraParams: {
                edit: false
            }
        }
    }
}
