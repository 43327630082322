<div class="d-flex flex-column gap-3">

    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill" *ngIf="edit" class="flex-fill">
            <mat-label>Art der Berechtigung</mat-label>
            <input matInput [disabled]="edit" [(ngModel)]="permissionTypeDisplayNameWhenEdit" required>
        </mat-form-field>

        <div *ngIf="!edit" class="flex-fill">
            <mat-form-field appearance="fill" class="w-100"
                *ngIf="(allPermissionTypes | async) as allPermissionTypes; else loading" [formGroup]="stateForm">
                <mat-label>Art der Berechtigung</mat-label>
                <input matInput type="text" formControlName="stateGroup" [matAutocomplete]="autoGroup" tabindex="-1"
                    [disabled]="edit" required>
                <mat-autocomplete #autoGroup="matAutocomplete">
                    <mat-optgroup *ngFor="let group of permissionCategoryGroupOptions | async" [label]="group.category">
                        <mat-option *ngFor="let permission of group.permissions" [value]="permission.displayName">
                            <div matTooltip="{{permission.displayName}} -- {{permission.category}}">
                                <span class="dropdown-text">{{permission.displayName}}</span>
                            </div>
                        </mat-option>
                    </mat-optgroup>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <mat-form-field appearance="fill" class="flex-fill" *ngIf="data.impl; else selectType">
            <mat-label>Berechtigung auf</mat-label>
            <mat-select [(ngModel)]="data.impl" [disabled]="edit || allPossibleImpls.length <= 1">
                <mat-option *ngFor="let impl of allPossibleImpls" [value]="impl.name">{{impl.displayName}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-form-field *ngIf="(data.impl === 'EVSE' && (allEvses | async)) as allEvses" appearance="fill">
        <mat-label>Ladepunkt</mat-label>
        <mat-select [(ngModel)]="data.evseId">
            <mat-option *ngFor="let evse of  allEvses" [value]="evse.id">{{evse | evseName}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="(data.impl === 'FLEET' && (allFleets | async)) as allFleets" appearance="fill">
        <mat-label>Flotte</mat-label>
        <mat-select [(ngModel)]="data.fleetId">
            <mat-option *ngFor="let fleet of allFleets" [value]="fleet.id">{{fleet | fleetName}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="(data.impl === 'RESERVATION' && (allReservations | async)) as allReservations"
        appearance="fill">
        <mat-label>Reservierung</mat-label>
        <mat-select [(ngModel)]="data.reservationId">
            <mat-option *ngFor="let reservation of allReservations" [value]="reservation.id">{{reservation |
                reservationName}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="(data.impl === 'VEHICLE' && (allVehicles | async)) as allVehicles" appearance="fill">
        <mat-label>Fahrzeug</mat-label>
        <mat-select [(ngModel)]="data.vehicleId">
            <mat-option *ngFor="let vehicle of allVehicles" [value]="vehicle.id">{{vehicle | vehicleName}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<ng-template #loading>
    <div>
        Laden...
    </div>
</ng-template>
<ng-template #selectType>
    <div>
        Bitte Art der Berechtigung auswählen
    </div>
</ng-template>
