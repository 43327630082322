import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractTableComponent} from "../../../../shared/components/tables/AbstractTableComponent";
import {MatPaginator} from "@angular/material/paginator";
import {STecAccount} from "@io-elon-common/frontend-api";
import {MatTableDataSource} from "@angular/material/table";
import {STecAccountService} from "../../service/s-tec-account.service";

@Component({
    selector: 'app-s-tec-account-table',
    templateUrl: './s-tec-account-table.component.html',
    styleUrls: ['./s-tec-account-table.component.scss']
})
export class STecAccountTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() public accounts!: STecAccount[];

    displayedColumns: string[] = ['id', "name",'base','webTicket', 'actions'];
    dataSource = new MatTableDataSource<STecAccount>([]);

    constructor(
        private sTecService: STecAccountService
    ) {
        super();
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<STecAccount>(this.accounts)
        this.dataSource.paginator = this.paginator;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.accounts;
    }

    public async edit(account: STecAccount): Promise<void> {
        await this.sTecService.showEditDialog(account);
    }

    public async delete(account: STecAccount): Promise<void> {
        await this.sTecService.showDeleteDialog(account, {});
    }
}
