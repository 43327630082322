<h1>Entity Managers</h1>

<div *ngIf="(entityManagers | async) as entityManagers;">
    Anzahl:  {{entityManagers.length}}<br/>
    Offen:  {{openCount(entityManagers)}}<br/>
    <mat-checkbox [(ngModel)]="onlyOpen">Nur offene Anzeigen</mat-checkbox>
    <table >
        <tr>
            <th [class]="{sortable: true, sortActive:sortByKey==='name'}" (click)="sortByKey='name'">Name</th>
            <th [class]="{sortable: true, sortActive:sortByKey==='tst'}" (click)="sortByKey='tst'">Erstellt</th>
            <th [class]="{sortable: true, sortActive:sortByKey==='open'}" (click)="sortByKey='open'">Offen</th>
            <th [class]="{sortable: true, sortActive:sortByKey==='activeTransaction'}" (click)="sortByKey='activeTransaction'">Transaktion aktiv</th>
        </tr>
        <tr *ngFor="let entityManager of sortBy(entityManagers, sortByKey)">
            <td>{{entityManager.name}}</td>
            <td>{{entityManager.tst | age}}</td>
            <td>{{entityManager.open}}</td>
            <td>{{entityManager.activeTransaction}}</td>
        </tr>
    </table>
</div>
