import {Component, OnDestroy, OnInit} from '@angular/core';
import {webSocket} from 'rxjs/webSocket';
import {Subject} from 'rxjs';
import {actions, structure} from '../../Messages';
import {download} from '../../../../shared/helper/util-functions';
import {localStorageGet, localStorageSave} from "../../../../shared/helper/typed-local-storage";
import {SystemService} from '../../../../services/api-handlers/system.service';
import {SystemInfo} from '@io-elon-common/frontend-api';

@Component({
    selector: 'app-ocpp-debugger-view',
    templateUrl: './ocpp-debugger-view.component.html',
    styleUrls: ['./ocpp-debugger-view.component.scss']
})
export class OcppDebuggerViewComponent implements OnInit, OnDestroy {

    public uri: string;
    public connected: boolean;
    public socket: Subject<any> | null = null;
    public log = "";
    public grep = "";
    public tail = 0;
    public autoScroll = true;
    public showWizard = false;

    public actions = actions;
    public structure = structure;

    public callCmd : {
        cmd: "send"
        chargePointIdentity: string
        action: string
        msgId: string
        body: string
    } = {
        cmd: "send",
        chargePointIdentity: "",
        action: "",
        msgId: "",
        body: ""
    }

    constructor(
        private readonly systemService: SystemService
    ) {
        const uri: string|undefined = localStorageGet("OCPP_DEBUG_URL");
        if (uri!==undefined) {
            this.uri = uri;
        } else {
            this.uri =  "ws://"
                + location.hostname
                + ":9000/debug/io-geheim/wow";
            this.systemService.getSystemInfo().then(
                (info: SystemInfo) => {
                    this.uri = "ws://"
                        + location.hostname
                        + ":"
                        + info.ocppWebsocketPort
                        + "/debug/io-geheim/wow";
                }
            );
        }
        this.connected = false;
    }

    ngOnInit(): void {

    }

    ngOnDestroy() {
        if(this.connected) {
            this.close();
        }
    }

    public connect() {
        localStorageSave("OCPP_DEBUG_URL", this.uri);
        this.socket = webSocket({
            url: this.uri,
            deserializer: e => e.data
        });
        this.socket.subscribe(
            msg => this.onMessage(msg),
            err => this.onMessage("Fehler: " + err),
            () => this.onMessage("CLOSE --- CLOSE --- CLOSE")
        )
        this.requestList();
        this.connected = true;
    }

    public requestList() {
        this.socket?.next({cmd: 'list'});
    }

    private onMessage(msg: string) {
        console.log(msg);
        if(typeof msg === "object"){
            this.log += JSON.stringify(msg, undefined, 2);
        } else {
            this.log += msg;
        }
        this.scrollDown();
    }

    private scrollDown() {
        if(this.autoScroll) {
            setTimeout(() => { // Timeout, damit der Text vorher gerendert wird. Sonnst scrollen wir zu kurz
                const element = document.getElementById("scroll-pre");
                if(element) {
                    element.scrollTop = element.scrollHeight - element.clientHeight;
                }
            });
        }
    }

    public close() {
        this.socket?.complete();
        this.connected = false;
    }

    public filterLog(): string {
        const regExp = new RegExp(this.grep);
        let lines = this.log.split("\n");
        lines = lines.filter(line => regExp.exec(line))
        if(this.tail && lines.length > this.tail) {
            lines = lines.filter((line, index) => index > lines.length - this.tail - 2);
        }
        return lines.join("\n");
    }

    public sendCmd() {
        this.socket?.next(this.callCmd);
    }

    public download() {
        download("ocpp.log", this.log);
    }

    clear() {
        this.log = "";
    }
}
