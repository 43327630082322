import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Basis, Fleet, Meter} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {MeterService} from '../../service/meter.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-meter-no-fleet-table',
  templateUrl: './meter-no-fleet-table.component.html',
  styleUrls: ['./meter-no-fleet-table.component.scss']
})
export class MeterNoFleetTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

    @Input()
    public meters!: Meter[];

    @Output()
    public updateListEvent = new EventEmitter<void>();
    public displayedColumns: string[] = ['id', 'name', 'type', 'basis', 'actions'];
    public dataSource = new MatTableDataSource<Meter>([]);
    public fleets: Fleet[] = [];
    public meterBasisMap: {[key: number]: Basis} = {};

    public constructor(
        private readonly meterService: MeterService,
        private readonly fleetService: FleetService,
        private readonly toastr: ToastrService
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        this.dataSource = new MatTableDataSource(this.meters);
        this.dataSource.paginator = this.paginator;
        this.fleets = await this.fleetService.getAllPromise();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.meters;
    }

    public async save(meter: Meter): Promise<void> {
        const basis = this.meterBasisMap[meter.id];
        if (basis) {
            meter.basis = basis;
            await this.meterService.updateMeterBasis(meter);
            this.toastr.success("Der Standort des Zählers wird aktualisiert");
            this.updateListEvent.emit();
        }
    }
    public onBasisChange(basis: Basis, meter: Meter): void {
        this.meterBasisMap[meter.id] = basis;
    }

    public trackById(idx: number, item:{id: number}) {
        return item.id;
    }
}
