import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {AlgoV2Config, SystemInfoAlgoParamDescriptionItem} from '@io-elon-common/frontend-api';
import {ChartMeta} from 'chart.js';
import {SystemService} from '../../../../services/api-handlers/system.service';

declare const Chart: any;

@Component({
    selector: 'app-algo-v2-config-graphs',
    templateUrl: './algo-v2-config-graphs.component.html',
    styleUrls: ['./algo-v2-config-graphs.component.scss']
})
export class AlgoV2ConfigGraphsComponent implements OnInit, AfterViewInit {

    @Input()
    public config!: AlgoV2Config;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly systemService: SystemService
    ) {
    }

    ngOnInit(): void {
    }


    private getConfigToKey(algoParams: SystemInfoAlgoParamDescriptionItem[], key: string): SystemInfoAlgoParamDescriptionItem & { val: number } {
        const param = algoParams.find(p => p.key === key);
        if (param == null) {
            throw new Error('Invalid key: ' + key);
        }
        return {
            ...param,
            val: Number(this.config.params[key])
        };
    }

    private transformToGraph(param: SystemInfoAlgoParamDescriptionItem, val: number): number {
        if (param.min === undefined || param.max === undefined)
            throw new Error("Param has no min/max")
        return (val - param.min) / (param.max - param.min) * 100;
    }

    private getConfigAlgo1(algoParams: Array<SystemInfoAlgoParamDescriptionItem>): Array<SystemInfoAlgoParamDescriptionItem & { val: number }> {
        return [
            this.getConfigToKey(algoParams, 'reservPre'),
            this.getConfigToKey(algoParams, 'peakPre'),
            this.getConfigToKey(algoParams, 'sellPre'),
            this.getConfigToKey(algoParams, 'buyPre'),
            this.getConfigToKey(algoParams, 'bestPreBelow'),
            this.getConfigToKey(algoParams, 'bestPreAbove'),
            this.getConfigToKey(algoParams, 'minPreAbove'),
            this.getConfigToKey(algoParams, 'minPreBelow'),
            this.getConfigToKey(algoParams, 'changeCost'),
            this.getConfigToKey(algoParams, 'planSocLowCost'),
            this.getConfigToKey(algoParams, 'planSocHighCost')
        ];
    }

    private getConfigAlgoMi(algoParams: Array<SystemInfoAlgoParamDescriptionItem>): Array<SystemInfoAlgoParamDescriptionItem & { val: number }> {
        return [
            this.getConfigToKey(algoParams, 'miCostWrongI'),
            this.getConfigToKey(algoParams, 'miCostBufferHigh'),
            this.getConfigToKey(algoParams, 'miCostBufferLow'),
            this.getConfigToKey(algoParams, 'miCostICpChange'),
            this.getConfigToKey(algoParams, 'miCostDistToTotal'),
            this.getConfigToKey(algoParams, 'miBufferSize'),
        ];
    }

    public async ngAfterViewInit(): Promise<void> {
        const algoParams = (await this.systemService.getSystemInfo()).algoParamsDescription;

        const config = this.getConfigAlgo1(algoParams);
        const options = this.getChartOptions(config);

        const ctx = (this.elementRef.nativeElement.querySelector('.canvas1') as HTMLCanvasElement).getContext('2d');
        if (ctx) {
            // tslint:disable-next-line:no-unused-expression
            new Chart(ctx, options);
        }

        const config2 = this.getConfigAlgoMi(algoParams);
        const options2 = this.getChartOptions(config2);
        const ctx2 = (this.elementRef.nativeElement.querySelector('.canvas2') as HTMLCanvasElement).getContext('2d');
        if (ctx2) {
            // tslint:disable-next-line:no-unused-expression
            new Chart(ctx2, options2);
        }
    }

    private getChartOptions(config: Array<SystemInfoAlgoParamDescriptionItem & { val: number }>): ChartMeta {
        return {
            type: 'radar',
            data: {
                // @ts-ignore
                labels: config.map(l => l.description),
                datasets: [{
                    label: 'Wert',
                    data: config.map(l => this.transformToGraph(l, l.val)),
                    pointHitRadius: 25,
                    borderColor: '#00F',
                    backgroundColor: '#9999FF99',
                }]
            },
            options: {
                responsive: false,
                onHover: (e: any) => {
                    const point = e.chart.getElementsAtEventForMode(e, 'nearest', {intersect: true}, false);
                    if (point.length) e.native.target.style.cursor = 'grab';
                    else e.native.target.style.cursor = 'default';
                },
                plugins: {
                    legend: {
                        display: false
                    }
                },
                scales: {
                    r: {
                        max: 100,
                        min: 0,
                        stepSize: 25,
                        ticks: {
                            callback: () => '',
                            showLabelBackdrop: false
                        },
                        pointLabels: {
                            display: false
                        }
                    }
                }
            }
        };
    }
}
