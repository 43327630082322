import {Injectable} from '@angular/core';
import {
    Basis,
    BasisOverview,
    DynamicElectricityCosts, ExecuteBasisAdd, ExecuteBasisUpdate,
    SolarPredictionAggregation,
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {BehaviorSubject} from 'rxjs';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {EditBasisDialogComponent} from '../dialogs/edit-basis-dialog/edit-basis-dialog.component';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {CacheUpdater} from '../../../services/api-handlers/cacheManager';
import {NewBasisDialogComponent} from "../dialogs/new-basis-dialog/new-basis-dialog.component";
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
    providedIn: 'root'
})
export class BasisService extends DialogHandler<Basis, ExecuteBasisUpdate, ExecuteBasisAdd, { }, void, void> {
    private overviewCache: CacheUpdater<BasisOverview, number>

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "Basis", toastr, dialog, dialogService, POLL_INTERVALS.basis);
        this.overviewCache = this.createManagedCache((obj, id) => obj.id === id);
    }

    public getOverview(id: number): BehaviorSubject<BasisOverview | undefined> {
        return this.overviewCache.getOrCreateGet(id, ()=>this.apiService.getBasisOverview(true, id, undefined, undefined, ApiHandler.customerId).toPromise()).data;
    }

    protected getEditConfig(basis: Basis): TDialogOptions<ExecuteBasisUpdate, EditBasisDialogComponent> {
        return {
            component: EditBasisDialogComponent,
            headline: "Basis bearbeiten",
            executeCallback: editResult => this.update(basis.id, editResult),
            editElement: {
                name: basis.name || "",
                icon: basis.icon || "",
                lon: basis.lon,
                lat: basis.lat,
                energyPrice: Math.round(basis.energyPrice*1000)/1000,
                geofenceRadius: basis.geofenceRadius,
                dynamicElectricityCostsEnabled: basis.dynamicElectricityCostsEnabled,
                konzessionsabgabe: basis.konzessionsabgabe || 0.0,
                netzentgelt: basis.netzentgelt || 0.0,
                allowContinue: basis.allowContinue
            }
        };
    }

    protected getNewConfig(): TDialogOptions<ExecuteBasisAdd, NewBasisDialogComponent> {
        return {
            component: NewBasisDialogComponent,
            headline: "Basis erstellen",
            executeCallback: editResult => this.create(editResult),
            editElement: {
                fleetName: "",
                rangeCorrectionFactor:0.7,
                psName: "",
                psMaxAmps: 32,
                psGridConnectionId: undefined,
                psPeakCalculationIntervall: "YEARS",
                name: "",
                icon: "",
                lon: 0,
                lat: 0,
                energyPrice: 0.3,
                geofenceRadius: 100,
                dynamicElectricityCostsEnabled: false,
                konzessionsabgabe: 0.0,
                netzentgelt: 0.0,
                allowContinue: true,
                defaultSoh: 80
            }
        };
    }

    // tslint:disable-next-line:max-line-length
    public getSolarPredictionListByTime(basisId: number, start: number | Date, end: number | Date, showAlerts = true): Promise<Array<SolarPredictionAggregation>>{
        if (typeof start !== 'number') {
            start = start.getTime();
        }
        if (typeof end !== 'number') {
            end = end.getTime();
        }
        return this.apiService.getSolarPredictionInTimeRange(showAlerts, basisId, start, end, undefined, undefined, ApiHandler.customerId).toPromise().then(l => l.list);
    }

    // tslint:disable-next-line:max-line-length
    public getDynamicElectricityCostsListByTime(basisId: number, start: number | Date, end: number | Date, showAlerts = true): Promise<Array<DynamicElectricityCosts>>{
        if (typeof start !== 'number') {
            start = start.getTime();
        }
        if (typeof end !== 'number') {
            end = end.getTime();
        }
        return this.apiService.getDynamicElectricityCostsInTimeRange(showAlerts, basisId, start, end, undefined, undefined, ApiHandler.customerId).toPromise().then(l => l.list);
    }

}
