import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {User} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {UserService} from '../../service/user.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() users!: User[];

    displayedColumns: string[] = ['id', 'username', 'name', 'roles', 'actions'];

    dataSource = new MatTableDataSource<User>([]);
    selection = new SelectionModel<User>(true, []);

    constructor(
        private readonly userService: UserService
    ) {
        super();
    }


    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.users;
    }

    public async edit(user: User): Promise<void> {
        await this.userService.showEditDialog(user);
    }

    public async editPw(user: User): Promise<void> {
        await this.userService.showEditPwDialog(user);
    }

    public async delete(user: User): Promise<void> {
        await this.userService.showDeleteDialog(user, {});
    }

    public getPermissions(user: User): string {
        return user.permissionGroups? user.permissionGroups.map(p => p.name).sort().join(", "): "";
    }
}
