<h1>Queue Sizes</h1>
<div *ngIf="(queueSizes | async) as queueSizes; else loading">

    <table>
        <tr>
            <th>Name</th>
            <th>Events in Queue</th>
            <th>Total Events</th>
            <th>Total Skipped Events</th>
            <th>Total Dropped Events</th>
            <th>Total Batch Count</th>
        </tr>
        <tr *ngFor="let queueSize of queueSizes.list">
            <td>{{queueSize.queueName}}</td>
            <td>{{queueSize.eventCount}}</td>
            <td>{{queueSize.totalEventCount}}</td>
            <td>{{queueSize.totalSkippedEventCount}}</td>
            <td>{{queueSize.totalDroppedEventCount}}</td>
            <td>{{queueSize.totalBatchCount}}</td>
        </tr>
    </table>
</div>
<ng-template #loading>
    Loading...
</ng-template>
