<table mat-table [trackBy]="trackById" [dataSource]="dataSource" class="full-width-table" matSort>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
            <a fxFlexFill [routerLink]="'/meters/meter/'+element.id" class="mat-row-link"></a>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Name</th>
        <td mat-cell *matCellDef="let element" class="col-dongle-id">
            {{element.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Typ</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            {{getMeterName(element.type)}}<br/>
            <span class="light" *ngIf="isOutdated(element)">{{getAge(element)}}</span>
            <span class="light" *ngIf="!isOutdated(element)" [matTooltip]="getAge(element)" [matTooltipPosition]="'right'">Aktuell</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="hostname">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Hostname</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            {{element.hostname}}
        </td>
    </ng-container>

    <ng-container matColumnDef="port">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Port</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            {{element.port}}
        </td>
    </ng-container>

    <ng-container matColumnDef="modbusId">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Modbus Id</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            {{element.modbusId}}
        </td>
    </ng-container>

    <ng-container matColumnDef="interval">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Abfrage-Intervall</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            <div *ngIf="element.interval">
                {{element.interval / 1000}}s
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions">Aktionen</th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button mat-icon-button *ngIf="isOutdated(element)" [matTooltip]="getNotificationTooltipText(element)" [matTooltipClass]="'pre-tooltip'">
                <mat-icon>new_releases</mat-icon>
            </button>
            <button (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon>edit</mat-icon></button>
            <button [disabled]="!canAdd" (click)="clone(element)" mat-icon-button matTooltip="Kopie erstellen"><mat-icon>content_copy</mat-icon></button>
            <button (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
