<div class="all">
    <ng-container [ngSwitch]="evse.liveData.healthStatus?.val">
        <mat-icon *ngSwitchCase="'WORKING'" class="iblack" svgIcon="evse_working"></mat-icon>
        <mat-icon *ngSwitchCase="'ATTENTION'" class="iblack" svgIcon="evse_attention"></mat-icon>
        <mat-icon *ngSwitchCase="'MAINTENANCE'" class="iblack" svgIcon="evse_maintenance"></mat-icon>
        <mat-icon *ngSwitchCase="'BROKEN'" class="iblack" svgIcon="evse_broken"></mat-icon>
        <mat-icon *ngSwitchDefault class="iblack" svgIcon="ladestation"></mat-icon>
    </ng-container>
    <div class="info">
        <span class="name">{{evse.name}}</span> <mat-hint>{{(getPwr().getPSum() / 1000).toFixed(1)}}kW / {{(getMaxAmps().sum() * 235 / 1000).toFixed(1)}}kW</mat-hint>
        <app-electricity-bar [ampsMode]="ampsMode" [electricity]="getPwr()" [plannedAmps]="getPlannedPwr()" [maxAmps]="getMaxAmps()"></app-electricity-bar>
    </div>

    <div class="evse-buttons">
        <button [disabled]="!evse.canEdit" mat-icon-button (click)="handleEditEvse(evse)"><mat-icon>edit</mat-icon></button>
        <button [disabled]="!evse.canDelete" mat-icon-button (click)="handleDeleteEvse(evse)"><mat-icon>delete</mat-icon></button>
    </div>
</div>
