<mat-card appearance="outlined">
    <div>
        <h3>{{load.name}}</h3>
        <span class="actions">
            <button *ngIf="load.type === 'FormulaLoad'" [routerLink]="'/load-formular-simulator/'+load.id" mat-icon-button matTooltip="In Simulation öffnen (DEV Only)"><i class="fa-solid fa-microscope"></i></button>
            <button *ngIf="load.hasHistory" (click)="showHistory(load)" mat-icon-button matTooltip="History"><i class="fa-solid fa-chart-line"></i></button>
            <button [disabled]="!load.canEdit" (click)="handleEditLoad(load)" mat-icon-button matTooltip="Bearbeiten"><mat-icon fontSet="material-icons">edit</mat-icon></button>
            <button [disabled]="!load.canDelete" (click)="handleDeleteLoad(load)" mat-icon-button matTooltip="Löschen"><mat-icon fontSet="material-icons">delete</mat-icon></button>
        </span>
    </div>
    <mat-card-content>
        <div *ngIf="load.prediction.length > 0;">
            <canvas width="400px" height="200px" class="canvas"></canvas>
            <mat-hint class="start">Jetzt</mat-hint>
            <mat-hint class="end">in 24h</mat-hint>
        </div>

        <div *ngIf="load.prediction.length == 0;">
            <div class="no-data-div">Daten werden geladen oder keine Daten</div>
        </div>
    </mat-card-content>
</mat-card>
