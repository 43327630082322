import {Pipe, PipeTransform} from '@angular/core';
import {NotificationEntry} from '@io-elon-common/frontend-api';

@Pipe({
    name: 'notificationIcon'
})
export class NotificationIconPipe implements PipeTransform {

  public transform(entry: NotificationEntry): string {
    switch (entry.key) {
        case "log.evse.adjustment":
            return "sync_problem";
        case "log.evse.online":
        case "log.vehicle.online":
            return "cloud_off";
        case "log.vehicle.invalid.soc":
            return "battery_alert";
        case "log.vehicle.reservation":
            return "today";
        case "tesla.poll.status.sleep":
            return "notifications_paused";
        case "tesla.poll.status.driving":
            return "near_me";
        case "tesla.poll.status.charging":
            return "battery_charging_full";
        case "tesla.poll.status.arrived":
            return "location_on";
        case "tesla.poll.status.want_to_sleep":
            return "av_timer";
        case "tesla.poll.status.just_stopped_charging":
            return "battery_full";
        case "actor.auth.change.enable":
            return "assignment_ind";
        case "actor.auth.change.disable":
            return "assignment_turned_in";
        case "ocpp.status.available":
            return "done";
        case "ocpp.status.preparing":
            return "hourglass_empty";
        case "ocpp.status.suspended.ev":
            return "pause_circle_filled";
        case "ocpp.status.suspended.evse":
            return "pause_circle_outline";
        case "ocpp.status.finishing":
            return "flag";
        case "ocpp.status.reserved":
            return "bookmark_border";
        case "ocpp.status.unavailable":
            return "build";
        case "ocpp.status.charging":
            return "flash_on";
        //case "ocpp.status.faulted": // default icon

        default:
            return "info_outline";
    }
  }
}
