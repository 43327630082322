import {Component, Input} from '@angular/core';
import {PhaseValues, ThreePhaseElectricity} from "../../../../../shared/helper/electricity";

@Component({
  selector: 'app-electricity-bar',
  templateUrl: './electricity-bar.component.html',
  styleUrl: './electricity-bar.component.scss'
})
export class ElectricityBarComponent {
    @Input()
    ampsMode: boolean = false;

    expertMode: boolean = false;

    @Input()
    electricity!: ThreePhaseElectricity;

    @Input()
    plannedAmps?: PhaseValues

    @Input()
    maxAmps!: PhaseValues;

    public isOpen: boolean = false;

    zeroIfNan(number: number) {
        return Number.isNaN(number) ? 0 : number;
    }

    protected readonly alert = alert;
}
