import {Injectable} from '@angular/core';
import {AlgoV2Config, ExecuteAlgoV2ConfigAddOrUpdate} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {
    EditAlgoConfigDialogComponent
} from '../dialogs/edit-algo-config-dialog/edit-algo-config-dialog.component';
import {SystemService} from '../../../services/api-handlers/system.service';
import {AuthService} from '../../../shared/guards/auth.service';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
    providedIn: 'root'
})
export class AlgoV2ConfigService extends DialogHandler<AlgoV2Config, ExecuteAlgoV2ConfigAddOrUpdate, ExecuteAlgoV2ConfigAddOrUpdate, {}, void, void> {
    constructor(
        apiService: ApiService,
        toastr: ToastrService,
        dialog: MatDialog,
        dialogService: DialogService,
        private systemInfoService: SystemService,
        private authService: AuthService
    ) {
        super(apiService, 'AlgoV2Config', toastr, dialog, dialogService, POLL_INTERVALS.algo_v2_configs);
    }


    public async showCloneDialog(element: AlgoV2Config): Promise<void> {
        const cloneConfig = this.getEditConfig(element);
        cloneConfig.headline = "Kopie anlegen";
        cloneConfig.editElement.name = "Kopie von " + cloneConfig.editElement.name;
        cloneConfig.executeCallback = editResult => this.create({
            ...editResult,
            fallbackConfigId: editResult.fallbackConfigId === -1 ? undefined : editResult.fallbackConfigId
        });
        await this.showDialog(cloneConfig, "Eintrag angelegt")
    }

    protected getEditConfig(algoConf: AlgoV2Config): TDialogOptions<ExecuteAlgoV2ConfigAddOrUpdate, EditAlgoConfigDialogComponent> {
        return {
            headline: 'Algorithmus Konfiguration bearbeiten',
            component: EditAlgoConfigDialogComponent,
            executeCallback: editResult => this.update(algoConf.id, {
                ...editResult,
                fallbackConfigId: editResult.fallbackConfigId === -1 ? undefined : editResult.fallbackConfigId
            }),
            editElement: {
                url: algoConf.url,
                strategy: algoConf.strategy,
                params: {...algoConf.params},
                name: algoConf.name,
                fallbackConfigId: algoConf.fallbackConfigId
            }
        };
    }

    protected getNewConfig(): TDialogOptions<ExecuteAlgoV2ConfigAddOrUpdate, EditAlgoConfigDialogComponent> {
        const info = this.systemInfoService.getSystemInfoSync()
        const user = this.authService.user;
        const params: {[key:string]:string} = {};
        info?.algoParamsDescription.filter(x => x.strategy.indexOf('v2_soft_int') !== -1).forEach(i => params[i.key] = i.initial);

        return {
            headline: 'Algorithmus Konfiguration anlegen',
            component: EditAlgoConfigDialogComponent,
            executeCallback: editResult => this.create({
                ...editResult,
                fallbackConfigId: editResult.fallbackConfigId === -1 ? undefined : editResult.fallbackConfigId
            }),
            editElement: {
                url: "default",
                strategy: 'v2_soft_int',
                params: params,
                name: (user?.firstname || "Neue") + " Konfiguration",
                fallbackConfigId: undefined
            }
        };
    }

    public testAlgo(configId: number, body: any) {
        return this.apiService.testAlgo(true, configId, body, undefined, undefined, ApiHandler.customerId).toPromise();
    }
}
