import { APP_BASE_HREF, PathLocationStrategy, PlatformLocation } from "@angular/common";
import { Inject, Injectable, Optional } from "@angular/core";
import { DefaultUrlSerializer, UrlTree } from "@angular/router";

@Injectable()
export class PreserveQueryLocationStrategy extends PathLocationStrategy {
	constructor(
		_platformLocation: PlatformLocation,
		@Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
	) {
		super(_platformLocation, _baseHref);
	}

	prepareExternalUrl(internal: string): string {
		let target = internal.split('?')[0];
		const queryParams: {[key: string]: any} = {};

		//get old query params
		const oldUrl = window.location.pathname + window.location.search;
		const oldUrlObj: UrlTree = new DefaultUrlSerializer().parse(oldUrl);

        for(const k of Object.keys(oldUrlObj.queryParams)) {
            if(k === "fid" || k === "cid") {
                queryParams[k] = oldUrlObj.queryParams[k];
            }
        }

		//merge with new query params
		const newUrlObj: UrlTree = new DefaultUrlSerializer().parse(internal);
		for (let newParamName in newUrlObj.queryParams) {
		  queryParams[newParamName] = newUrlObj.queryParams[newParamName];
		}

		//build resulting query params
		let first = true;
		for (let paramName in queryParams) {
		    target += first ? '?' : '&';
		    target += paramName + '=' + queryParams[paramName];
		    first = false;
		}

		return target;
	  }
}
