import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '../../shared/guards/auth.service';
import {ThemesService} from '../../services/themes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
    public requestForm!: FormGroup<{
        email: FormControl<string | null>
    }>;
    public resetForm!: FormGroup<{
        password: FormControl<string | null>
        password2: FormControl<string | null>
    }>;
    public loading = false;
    private loginError = false;
    public token: string|undefined = undefined;
    public formSubmitAttempt = false;
    private passwordsMatch = true;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private route: ActivatedRoute,
        private readonly themesService: ThemesService, // Included to do initial setup
        private readonly toastr: ToastrService,
        private router: Router
    ) {
    }

    passwordsDiffer(): boolean {
        return !this.passwordsMatch;
    }


    ngOnInit() {
        this.token = undefined;
        this.route.queryParams
        .subscribe(params => {
            if (this.token != params['token']) {
                this.authService
                .validateToken(params['token'])
                .then(validaton => {
                    if (validaton.valid) {
                        this.token = params['token'];

                    } else {
                        this.toastr.error(validaton.error);
                    }
                })
            }
        });


        this.requestForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        });
        this.resetForm = this.fb.group({
            password: ['', [Validators.required]],
            password2: ['', [Validators.required]]
        });
    }

    isFieldInvalid(field: string) {
        const ctrl = field == "email"? this.requestForm.get(field): this.resetForm.get(field);
        return !ctrl || (
            (ctrl.touched && !ctrl.valid) ||
            (ctrl.untouched && this.formSubmitAttempt)
        );
    }

    async onSubmitRequest() {
        this.loading = true;
        try {
            if (this.requestForm.valid) {
                await this.authService.requestPasswordReset(this.requestForm.get('email')?.value!);
                this.toastr.success("Bitte überprüfen sie ihr E-Mail Postfach");
                this.formSubmitAttempt = true;
            }
        }catch(e: any) {
            console.log(e.error.msg);
            this.loginError = true;
        } finally {
            this.loading = false;
        }
    }
    async onSubmitReset() {
        this.loading = true;
        try {
            if (this.resetForm.valid && this.token) {
                const pw1 = this.resetForm.get('password')?.value;
                const pw2 = this.resetForm.get('password2')?.value;

                if (pw1 !== pw2) {
                    this.passwordsMatch = false;
                    this.loginError = true;
                    return;
                } else {
                    this.passwordsMatch = true;

                    await this.authService.resetPassword(this.token, pw1!);

                    this.toastr.success("Password Änderung erfolgreich, bitte melden sie sich mit ihrem neuen Password an");
                    setTimeout(() =>
                        this.router.navigate(["/login"]),
                        2000
                    );
                }
            }
        }catch(e: any) {
            console.log(e.error.msg);
            this.loginError = true;
        } finally {
            this.loading = false;
        }
    }
}
