import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VehicleService} from '../../service/vehicle.service';
import {
    AbstractHistoryGraph,
    BackgroundLegend,
    GraphRange,
    HistoryCellData
} from '../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import {CHARGING_COLOR, PLUGGED_COLOR} from '../vehicle-history-graph/vehicle-history-graph.component';
import Annotation = dygraphs.Annotation;
import Options = dygraphs.Options;

const HOUR = 3600 * 1000;
const DAY = 24 * HOUR;
const SHOW_EVENT_MAX_TIME = 3 * DAY;


export interface ISohGraphData {
    data: Array<Array<HistoryCellData>>;
    pluggedSlots: GraphRange[];
    chargingSlots: GraphRange[];
    events: Annotation[];
}

@Component({
  selector: 'app-soh-history-graph',
  templateUrl: './soh-history-graph.component.html',
  styleUrls: ['./soh-history-graph.component.scss']
})
export class SohHistoryGraphComponent extends AbstractHistoryGraph<ISohGraphData> implements OnInit {
    @Input() vehicleId!: number;
    @Input() autoReload!: boolean;
    @Output() autoReloadChange = new EventEmitter<boolean>();
    private maxY2?: Promise<number>;

    constructor(
        private readonly element: ElementRef,
        private readonly vehicleService: VehicleService
    ) {
        super();
    }

    public getBackgroundLegend(): BackgroundLegend[] {
        return [
            {
                name: 'Eingesteckt',
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawHatched(canvas, x, y, w, h, PLUGGED_COLOR)
            }, {
                name: 'Laden',
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawHatched(canvas, x, y, w, h, CHARGING_COLOR)
            }, {
                name: 'Jetzt',
                visible: true,
                drawCallback: (canvas, x, y, w, h) => this.drawNowMarker(canvas, x + w / 2 - 1, y, 2, h)
            }
        ];
    }

    protected async getMaxY2(): Promise<number> {
        if (!this.maxY2) {
            this.maxY2 = this.vehicleService.getPromise(this.vehicleId).then(v => ((v.maxAmps || 32) * 235 * v.numPhases) * 1.2);
        }
        return this.maxY2;
    }

    protected async loadData(start: number, end: number): Promise<ISohGraphData> {
        const historyRequest = this.vehicleService.getHistoryOfVehicle(this.vehicleId, start, end);

        const data = await historyRequest;

        const gapDistance = (end - start) / 2;

        const dataMapped = this.mapToArray(data.data.soh, 'time', 'val', 'min', 'max', 0, 2, undefined, gapDistance);

        // @ts-ignore
        dataMapped.sort((a, b) => a[0] - b[0]);
        const dataJoined = this.joinLines(dataMapped);

        const pluggedSlots = new Array<GraphRange>();
        const chargingSlots = new Array<GraphRange>();
        const events = new Array<Annotation>();

        return {
            data: dataJoined,
            pluggedSlots,
            chargingSlots,
            events
        };
    }

    protected async getConfig(): Promise<Options> {
        return {
            errorBars: true,
            customBars: true,
            axes: {
                y: {
                    valueRange: [0, 120],
                    axisLabelFormatter: (soh: number | Date) => {
                        return '<span style="color: #8800FF">' + soh + ' %</span>';
                    }
                }
            },
            labels: ['x',
                'SoH (%)',
                'Events'
            ],
            colors: [
                '#8800FF',
                '#000000'
            ],
            series: {
                'SoH (%)': {
                    axis: 'y1',
                    stepPlot: false
                },
                Events: {drawPoints: false, strokeWidth: 0}
            },
            underlayCallback: (canvas, area, graph) => {
                this.drawAreaRangesHatched(canvas, area, graph, this.data.pluggedSlots, PLUGGED_COLOR);
                this.drawAreaRangesHatched(canvas, area, graph, this.data.chargingSlots, CHARGING_COLOR);
                const nowX = graph.toDomXCoord(Date.now());
                if (nowX > -10 && nowX < area.w) {
                    this.drawNowMarker(canvas, nowX, area.y, 2, area.h);
                }
            },
        };
    }

    async ngOnInit(): Promise<void> {
        await this.init(this.element);
    }
}
