import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {required} from '../../../../shared/components/form-control-validator/validator';
import {Vehicle, VehicleSetSohResult} from '@io-elon-common/frontend-api';
import {VehicleService} from '../../service/vehicle.service';
import {ToastrService} from 'ngx-toastr';

export interface SohData {
    vehicle: Vehicle,
    oldSoh: number | undefined
}

@Component({
  selector: 'app-set-soh-vehicle-dialog',
  templateUrl: './set-soh-vehicle-dialog.component.html',
  styleUrl: './set-soh-vehicle-dialog.component.scss'
})
export class SetSohVehicleDialogComponent implements OnInit{
    public fc!: FormControl
    public resultText : undefined | string
    constructor(
        private dialogRef: MatDialogRef<SetSohVehicleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: SohData,
        private readonly vehicleService: VehicleService,
        private readonly toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.fc = new FormControl<number | undefined>(this.dialogData.oldSoh, [
            required("Batteriegesundheit muss ausgefüllt sein"),
            Validators.min(0), Validators.max(200)
        ])
    }

    public close() {
        this.dialogRef.close();
    }

    public async save() {
        this.resultText = "";
        if (this.fc.valid) {
            const vehicleSetSohResult: VehicleSetSohResult = await this.vehicleService.setSoh(this.dialogData.vehicle.id, this.fc.value);
            this.resultText = vehicleSetSohResult.result
        } else {
            this.toastr.warning("Ungültiger Wert");
        }
    }
}
