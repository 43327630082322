<div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput type="text" [(ngModel)]="data.name">
            <mat-hint>Name</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Token</mat-label>
            <input matInput type="text" [(ngModel)]="data.token">
        </mat-form-field>
    </div>

    <div class="d-flex flex-row gap-3 justify-content-start">
        <mat-form-field appearance="fill" *ngIf="(vehicles | async) as vehicles; else loading">
            <mat-label>Fahrzeug</mat-label>
            <mat-select [disabled]="data.userId!=null" [(value)]="data.vehicleId">
                <mat-option [value]="null">Keinem Fahrzeug zuordnen</mat-option>
                <mat-option *ngFor="let v of vehicles" [value]="v.id">{{v.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="(users | async) as users; else loading">
            <mat-label>User</mat-label>
            <mat-select [disabled]="data.vehicleId!=null" [(value)]="data.userId">
                <mat-option [value]="null">Keinem User zuordnen</mat-option>
                <mat-option *ngFor="let u of users" [value]="u.id">{{u.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-form-field appearance="fill">
        <mat-label>Kostenstelle</mat-label>
        <input matInput type="text" [(ngModel)]="data.invoiceReceiver">
    </mat-form-field>

    <mat-checkbox [(ngModel)]="data.active">Aktiv</mat-checkbox>
</div>
<ng-template #loading>Loading...</ng-template>
