import {Component} from '@angular/core';
import {AuthService} from '../../guards/auth.service';
import {ELinkLocation, getMenu} from "../../../app-routing.module";
import {localStorageGet, localStorageSave} from '../../helper/typed-local-storage';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  closed: boolean = localStorageGet('SIDEBAR_CLOSED') ? localStorageGet('SIDEBAR_CLOSED') === "true" : window.screen.width < 1000;

  public menu = getMenu(ELinkLocation.LEFT_MENU);

  constructor(
    private authService: AuthService
  ) { }

  public hasPermission(permissions: string[]): boolean {
      return this.authService.hasAllPermissions(...permissions);
  }

  public setClose(shouldClose: boolean) {
      this.closed = shouldClose;
      localStorageSave("SIDEBAR_CLOSED", shouldClose ? "true" : "false");
  }
}
