import {Injectable} from '@angular/core';

type ThemeName = 'iod-theme' | 'amueller-theme';

const defaultTheme: ThemeName = 'iod-theme';

@Injectable({
    providedIn: 'root'
})
export class ThemesService {
    private currentTheme: ThemeName = 'iod-theme';

    constructor() {
        if(window.location.host.includes("amueller")) {
            this.currentTheme = 'amueller-theme';
        }
        this.updateTheme(this.currentTheme);
    }

    private updateTheme(newTheme: ThemeName): void {
        document.body.classList.remove(this.currentTheme);
        this.currentTheme = newTheme;
        document.body.classList.add(this.currentTheme);
    }

    public rotateTheme() {
        switch (this.currentTheme) {
            case 'amueller-theme':
                this.updateTheme('iod-theme');
                break;
            case 'iod-theme':
                this.updateTheme('amueller-theme');
                break;
        }
    }
}
