import {Component, Input, OnInit} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {ExecuteNoteAddOrUpdate} from '@io-elon-common/frontend-api/lib/model/models';
import {AbstractControl, FormControl} from '@angular/forms';
import {required} from '../../../../shared/components/form-control-validator/validator';

@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.component.html',
  styleUrl: './edit-note.component.scss'
})
export class EditNoteComponent implements OnInit, IEditForm<ExecuteNoteAddOrUpdate>{
    @Input()
    data!: ExecuteNoteAddOrUpdate;

    public fc!: FormControl

    validate(): string[] | Promise<string[]> {
        if(this.fc.valid) {
            this.data.note = this.fc.value
        }
        const ret: string[] = [];
        this.addErrors(ret, this.fc);
        return ret;
    }

    ngOnInit(): void {
        this.fc  = new FormControl<string | undefined>(this.data.note, [
            required("Notiz muss ausgefüllt sein")
            ]
        )
    }

    private addErrors(target: string[], ctrl: AbstractControl) {
        if(ctrl.errors) {
            for(const e of Object.values(ctrl.errors)) {
                if(e) {
                    target.push(e);
                }
            }
        }
    }
}
