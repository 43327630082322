import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit} from '@angular/core';
import {LoadFormularHistoricValues} from "@io-elon-common/frontend-api/lib/model/models";
import {
    AbstractHistoryGraph,
    BackgroundLegend,
    HistoryCellData
} from "../../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph";
import Annotation = dygraphs.Annotation;
import Options = dygraphs.Options;

interface IResultGraphData {
    data: Array<Array<HistoryCellData>>;
    events: Annotation[];
}

@Component({
  selector: 'app-load-formula-simulation-result',
  standalone: true,
  imports: [],
  templateUrl: './load-formula-simulation-result.component.html',
  styleUrl: './load-formula-simulation-result.component.scss'
})
export class LoadFormulaSimulationResultComponent extends AbstractHistoryGraph<IResultGraphData> implements OnChanges, OnInit {

    autoReload = false;
    autoReloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public result!: LoadFormularHistoricValues

    private terms: string[] = [];


    constructor(
        private readonly element: ElementRef,
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.init(this.element);
    }

    ngOnChanges(): void {
        const termData = this.result.historyTerms as Array<Array<{tst: number, term: string, p1: number, p2: number, p3: number}>>;
        if(termData.length === 0) {
            this.terms = [];
            return;
        }
        this.terms = termData[0].map(t => t.term);
    }

    getBackgroundLegend(): BackgroundLegend[] {
        return [];
    }

    protected async getConfig(): Promise<Options> {
        return {
            legend: 'always',
            errorBars: true,
            customBars: true,
            axes: {
                y: {

                }
            },
            labels: ["time", "Result L1", "Result L2", "Result L3", ...this.terms.flatMap((t, idx) => { return ["Term " + idx + " L1", "Term " + idx + " L2", "Term " + idx + " L3"]})],
            legendFormatter: data => {
                if (data.x == null) return ""; // No selection, return empty string

                // Access series data
                const series = data.series;

                const formatVal = (pwr: number) => pwr > 1000 ? ((pwr / 1000).toFixed(1) + "kW") : (pwr.toFixed(0) + "W")

                let text = "";
                for(let i = 0; i < series.length; i+=3) {
                    text += "<span style='border: 1px solid lightgray; border-radius: 4px' class='px-1'>";
                    text += i === 0 ? "Result  " : ("Term " + (i/3-1)) + "  "
                    text += series[i].dashHTML + " L1: <i>" + formatVal(series[i].y) + "</i> "
                    text += series[i].dashHTML + " L2: <i>" + formatVal(series[i+1].y) + "</i> "
                    text += series[i].dashHTML + " L3: <i>" + formatVal(series[i+2].y) + "</i>"
                    text += "</span>   "
                }

                return text;
            },
            series: {
                'time':{

                },
                'Result L1': {
                    axis: 'y1',
                    stepPlot: true
                },
                'Result L2': {
                    axis: 'y1',
                    stepPlot: true
                },
                'Result L3': {
                    axis: 'y1',
                    stepPlot: true
                },
                ...(Object.fromEntries(this.terms.flatMap((t, idx) => [
                    ["Term " + idx + " L1", { axis: 'y1', stepPlot: true, strokePattern: [0,2,2,0], drawPoints: false}],
                    ["Term " + idx + " L2", { axis: 'y1', stepPlot: true, strokePattern: [0,2,2,0], drawPoints: false}],
                    ["Term " + idx + " L3", { axis: 'y1', stepPlot: true, strokePattern: [0,2,2,0], drawPoints: false}]
                ])))
            }
        };
    }

    protected getMaxY2(): Promise<number> {
        return Promise.resolve(0);
    }

    protected async loadData(): Promise<IResultGraphData> {
        const data = this.result.historyResult!.map((r, idx) => {
            return [
                new Date(r.tst),
                [r.p1 + Math.min(0, r.p1Window), r.p1, r.p1 + Math.max(0, r.p1Window)],
                [r.p2 + Math.min(0, r.p2Window), r.p2, r.p2 + Math.max(0, r.p2Window)],
                [r.p3 + Math.min(0, r.p3Window), r.p3, r.p3 + Math.max(0, r.p3Window)],
                ...this.terms.flatMap(t => {
                    const terms = this.result.historyTerms![idx] as Array<{tst: number, term: string, p1: number, p2: number, p3: number}>;
                    const term = terms.find(t1 => t1.term === t);

                    return [
                        [term!.p1,term!.p1,term!.p1],
                        [term!.p2,term!.p2,term!.p2],
                        [term!.p3,term!.p3,term!.p3],
                    ]
                })
            ] as Array<HistoryCellData>
        });
        return {
            data,
            events: []
        };
    }
}
