import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {GraphPoint} from '@io-elon-common/frontend-api';
import {ChartConfiguration} from 'chart.js';

declare const Chart: any;
const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const GRID_X = 15 * MINUTE;

const KEY_ALT = 'Alt';
const KEY_ALT_GR = 'AltGraph';
const KEY_CTRL = 'Control';
const KEY_SHIFT = 'Shift';
const KEY_CAPS_LOCK = 'CapsLock';

const GRID_LINES_Y_COUNT = 10;

@Component({
    selector: 'app-user-defined-load-graph',
    templateUrl: './user-defined-load-graph.component.html',
    styleUrls: ['./user-defined-load-graph.component.scss']
})
export class UserDefinedLoadGraphComponent implements OnInit, AfterViewInit {

    @Input()
    public dayOffset = 0;

    @Input()
    public graphPoints!: Array<GraphPoint>;

    @Input()
    public dayCount = 1;

    public min = 0;
    public max = 10000;

    private minTime!: number;
    private maxTime!: number;
    private minIdx!: number;
    private maxIdx!: number;
    private minIdxTime!: number;
    private maxIdxTime!: number;

    private chart!: any;

    constructor(
        private readonly elementRef: ElementRef,
    ) {
    }

    ngOnInit(): void {
        this.minTime = this.dayOffset * DAY;
        this.maxTime = this.dayOffset * DAY + DAY;

        if(this.graphPoints.length === 0) {
            // Init with default graph???
        }

        this.minIdx = 0;
        this.minIdxTime = this.graphPoints[0].tst;

        for(let i = 0; i < this.graphPoints.length; i++) {
            const tst = this.graphPoints[i].tst;
            if(tst <= this.minTime) {
                this.minIdx = i;
                this.minIdxTime = tst;
            }
            this.maxIdx = i;
            this.maxIdxTime = tst
            if(tst >= this.maxTime) {
                break;
            }
        }
    }

    public async ngAfterViewInit(): Promise<void> {
        const options = this.getChartOptions();

        const ctx = (this.elementRef.nativeElement.querySelector('.load-graph-canvas') as HTMLCanvasElement).getContext('2d');
        if (ctx) {
            this.chart = new Chart(ctx, options);
        }

        this.max = 1;
        this.graphPoints.forEach(x => {
            if (this.max < x.l1)
                this.max = x.l1;
            if (this.max < x.l2)
                this.max = x.l2;
            if (this.max < x.l3)
                this.max = x.l3;
        });
        this.max = Math.round(this.max*1.1);
        this.updateScale();
    }

    public updateScale(): void {
        // @ts-ignore
        this.chart.options.scales.y = {
            ticks: {
                callback: (v: number | string) => {
                    const delta = (this.max - this.min);
                    if ((v as number) > (this.max - (delta / GRID_LINES_Y_COUNT / 2))) {
                        return '';
                    }
                    if ((v as number) < (this.min + (delta / GRID_LINES_Y_COUNT / 2))) {
                        return '';
                    }
                    return (v as number / 1000).toFixed(1) + 'kW';
                },
                stepSize: (this.max - this.min) / GRID_LINES_Y_COUNT
            },
            min: this.min,
            max: this.max
        };
        this.chart.update();
    }
    public updateData(): void {
        // @ts-ignore
        this.chart.data = {
            datasets: this.getDatasets()
        };
        this.chart.update();
    }

    private getDatasets() {
        const l1: { x: number, y: number }[] = [];
        const l2: { x: number, y: number }[] = [];
        const l3: { x: number, y: number }[] = [];

        for (const point of this.graphPoints) {
            const x = point.tst;
            l1.push({y: point.l1, x});
            l2.push({y: point.l2, x});
            l3.push({y: point.l3, x});
        }

        l1.push({y: this.graphPoints[0].l1, x: this.dayCount * DAY});
        l2.push({y: this.graphPoints[0].l2, x: this.dayCount * DAY});
        l3.push({y: this.graphPoints[0].l3, x: this.dayCount * DAY});

        return [
            {
                label: 'L1',
                borderColor: '#F00',
                backgroundColor: '#F00',
                data: l1
            },
            {
                label: 'L2',
                borderColor: '#0F0',
                backgroundColor: '#0F0',
                data: l2
            },
            {
                label: 'L3',
                borderColor: '#00F',
                backgroundColor: '#00F',
                data: l3
            }
        ]
    }

    private getChartOptions(): ChartConfiguration {
        const config = {
            animation: false,
            responsive: false,
            onClick: (event: MouseEvent, points: any[]) => {
                const position = this.getPosition(event);
                if(points.length === 0){ // Click in free space
                    let i = 1;
                    while(i < this.graphPoints.length) {
                        if(this.graphPoints[i].tst > position.x) {
                            this.graphPoints.splice(i, 0, {
                                tst: position.x,
                                l1: position.y,
                                l2: position.y,
                                l3: position.y
                            });
                            this.updateData();
                            return;
                        }
                        i++;
                    }
                    this.graphPoints.push({
                        tst: position.x,
                        l1: position.y,
                        l2: position.y,
                        l3: position.y
                    });
                } else {
                        for (const point of points) {
                            const pointPos = this.getPositionFromXy(point.element.x, point.element.y);
                            const index = this.graphPoints.findIndex(value1 => value1.tst === pointPos.x);
                            if (index > 0 && index) {
                                this.graphPoints.splice(index, 1);
                                this.updateData();
                                return;
                            }
                    }
                }
                this.updateData();
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (context: any) => this.formatDate(context[0].parsed.x)
                    }
                },
                dragData: {
                    round: 0,
                    dragX: true,
                    showTooltip: true,
                    onDragStart: (e: MouseEvent, datasetIndex: number, index: number, value: { x: number, y: number }) => {
                    },
                    onDrag: (e: MouseEvent, datasetIndex: number, index: number, value: { x: number, y: number }) => {
                        // @ts-ignore
                        e.target.style.cursor = 'grabbing';
                        let newX = value.x;

                        const modelIndex = index === this.graphPoints.length ? 0 : index;

                        if (!e.getModifierState(KEY_ALT)) {
                            // Default in X-Achse auf 15 Minuten fangen

                            newX += (newX % GRID_X) > (GRID_X / 2) ? GRID_X - (newX % GRID_X) : -(newX % GRID_X);
                        }

                        const left = this.dayOffset * DAY;
                        const right = this.dayOffset * DAY + DAY;
                        if (index === this.minIdx) {
                            newX = this.minIdxTime;
                        } else {
                            newX = Math.max(newX, left + 1);
                        }
                        if (index !== modelIndex) {
                            newX = this.dayCount * DAY;
                        } else {
                            newX = Math.min(newX, right - 1);
                        }

                        this.chart.data.datasets[0].data[index].x = newX;
                        this.chart.data.datasets[1].data[index].x = newX;
                        this.chart.data.datasets[2].data[index].x = newX;
                        this.chart.data.datasets[datasetIndex].data[index].y = value.y;

                        let i = index;
                        while (--i > 0 && this.chart.data.datasets[0].data[i].x > newX) {
                            this.chart.data.datasets[0].data[i].x = newX;
                            this.chart.data.datasets[1].data[i].x = newX;
                            this.chart.data.datasets[2].data[i].x = newX;
                            this.graphPoints[i].tst = value.x;
                        }
                        i = index;
                        while (++i < this.graphPoints.length && this.chart.data.datasets[0].data[i].x < newX) {
                            this.chart.data.datasets[0].data[i].x = newX;
                            this.chart.data.datasets[1].data[i].x = newX;
                            this.chart.data.datasets[2].data[i].x = newX;
                            this.graphPoints[i].tst = value.x;
                        }

                        if (!e.getModifierState(KEY_SHIFT)) {
                            const phase: 'l1' | 'l2' | 'l3' = datasetIndex === 0 ? 'l1' : datasetIndex === 1 ? 'l2' : 'l3';

                            const dy = value.y - this.graphPoints[modelIndex][phase];

                            this.chart.data.datasets[0].data[index].y = this.graphPoints[modelIndex].l1 + dy;
                            this.chart.data.datasets[1].data[index].y = this.graphPoints[modelIndex].l2 + dy;
                            this.chart.data.datasets[2].data[index].y = this.graphPoints[modelIndex].l3 + dy;
                        }

                        if (e.getModifierState(KEY_CTRL)) {
                            this.chart.data.datasets[0].data[index].y = value.y;
                            this.chart.data.datasets[1].data[index].y = value.y;
                            this.chart.data.datasets[2].data[index].y = value.y;
                        }

                        if(index !== modelIndex) {
                            this.chart.data.datasets[0].data[0].y = this.chart.data.datasets[0].data[index].y;
                            this.chart.data.datasets[1].data[0].y = this.chart.data.datasets[1].data[index].y;
                            this.chart.data.datasets[2].data[0].y = this.chart.data.datasets[2].data[index].y;

                            this.graphPoints[0].l1 = this.chart.data.datasets[0].data[0].y;
                            this.graphPoints[0].l2 = this.chart.data.datasets[1].data[0].y;
                            this.graphPoints[0].l3 = this.chart.data.datasets[2].data[0].y;
                        } else if(index === 0) {
                            const maxIndex = this.graphPoints.length;
                            this.chart.data.datasets[0].data[maxIndex].y = this.chart.data.datasets[0].data[0].y;
                            this.chart.data.datasets[1].data[maxIndex].y = this.chart.data.datasets[1].data[0].y;
                            this.chart.data.datasets[2].data[maxIndex].y = this.chart.data.datasets[2].data[0].y;
                            this.graphPoints[modelIndex].l1 = this.chart.data.datasets[0].data[0].y;
                            this.graphPoints[modelIndex].l2 = this.chart.data.datasets[1].data[0].y;
                            this.graphPoints[modelIndex].l3 = this.chart.data.datasets[2].data[0].y;
                        } else {
                            this.graphPoints[modelIndex].tst = value.x;
                            this.graphPoints[modelIndex].l1 = this.chart.data.datasets[0].data[index].y;
                            this.graphPoints[modelIndex].l2 = this.chart.data.datasets[1].data[index].y;
                            this.graphPoints[modelIndex].l3 = this.chart.data.datasets[2].data[index].y;
                        }


                        // console.log("Drag Value: ", value.x)
                    },
                    onDragEnd: (e: MouseEvent, datasetIndex: number, index: number, value: { x: number, y: number }) => {
                        // @ts-ignore
                        e.target.style.cursor = 'default';
                        this.updateData();
                    },
                },
            },
            scales: {
                x: {
                    type: 'linear',
                    min: this.minTime,
                    max: this.maxTime,
                    ticks: {
                        callback: (v: number) => {
                            return ((v / HOUR) % 2) === 0 ? this.formatDate(v) : '';
                        },
                        stepSize: HOUR
                    }
                },
                y: {
                    ticks: {
                        callback: (v: number) => {
                            const delta = (this.max - this.min);
                            if (v > (this.max - (delta / GRID_LINES_Y_COUNT / 2))) {
                                return '';
                            }
                            if (v < (this.min + (delta / GRID_LINES_Y_COUNT / 2))) {
                                return '';
                            }
                            return (v / 1000).toFixed(1) + 'kW';
                        },
                        stepSize: (this.max - this.min) / GRID_LINES_Y_COUNT
                    },
                    min: this.min,
                    max: this.max
                },
            }
        };

        return {
            type: 'line',
            data: {
                datasets: this.getDatasets()
            },
            // @ts-ignore
            options: config
        };
    }

    private formatDate(val: number) {
        return new Date(val).toLocaleTimeString(navigator.language, {
            timeZone: 'utc',
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    private getPosition(event: MouseEvent): { x: number, y: number } {
        return this.getPositionFromXy(event.x, event.y);
    }

    private getPositionFromXy(xValue: number, yValue: number): { x: number, y: number } {
        const yTop = this.chart.chartArea.top;
        const yBottom = this.chart.chartArea.bottom;

        const yMin = this.chart.scales.y.min;
        const yMax = this.chart.scales.y.max;
        let newY = 0;

        if (yValue <= yBottom && yValue >= yTop) {
            newY = Math.abs((yValue - yTop) / (yBottom - yTop));
            newY = (newY - 1) * -1;
            newY = newY * (Math.abs(yMax - yMin)) + yMin;
        }

        const xTop = this.chart.chartArea.left;
        const xBottom = this.chart.chartArea.right;
        const xMin = this.chart.scales.x.min;
        const xMax = this.chart.scales.x.max;
        let newX = 0;

        if (xValue <= xBottom && xValue >= xTop) {
            newX = Math.abs((xValue - xTop) / (xBottom - xTop));
            newX = newX * (Math.abs(xMax - xMin)) + xMin;
        }

        return {x: newX, y: newY};
    }
}

