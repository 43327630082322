import {Component, OnInit} from '@angular/core';
import {PermissionGroup} from '@io-elon-common/frontend-api';
import {AuthService} from '../../../../shared/guards/auth.service';
import { PermissionGroupService } from 'src/app/modules/permissions/service/permission-group.service';

@Component({
    selector: 'app-permissions-group-list-view',
    templateUrl: './permissions-group-list-view.component.html',
    styleUrls: ['./permissions-group-list-view.component.scss']
})
export class PermissionsGroupListViewComponent implements OnInit {
    public allPermissions!: Promise<PermissionGroup[] | undefined>;
    public canCreate!: boolean;

    constructor(
        private readonly permissionGroupService: PermissionGroupService,
        private readonly authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this.allPermissions = this.permissionGroupService.getAllPromise();
        this.canCreate = this.authService.hasGlobalPermission("ADD_PERMISSIONS")

    }

    public async newPermission(): Promise<void> {
        await this.permissionGroupService.showNewDialog();
        this.allPermissions = this.permissionGroupService.getAllPromise();
    }
}
