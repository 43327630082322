import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-local-storage',
    templateUrl: './local-storage.component.html',
    styleUrls: ['./local-storage.component.scss']
})
export class LocalStorageComponent implements OnInit {

    public data: Array<[string, string | null]> = [];
    public newKey = '';
    public newVal = '';

    constructor() {
    }

    ngOnInit(): void {
        this.update();
    }

    public update(): void {
        this.data.length = 0;
        Object.keys(localStorage).forEach(k => {
            this.data.push([k, localStorage.getItem(k)]);
        });
    }

    public delete(key: string): void {
        localStorage.removeItem(key);
        this.update();
    }

    public updateVal(): void {
        localStorage.setItem(this.newKey, this.newVal);
        this.update();
    }

}
