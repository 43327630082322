import {
    AbstractControl,
    AsyncValidatorFn, FormControl, FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {ExecuteUserAddOrUpdate, PermissionGroup} from '@io-elon-common/frontend-api';
import {Directive, Input, OnInit} from '@angular/core';
import {IEditForm} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {PermissionGroupService} from '../../permissions/service/permission-group.service';
import {AuthService} from '../../../shared/guards/auth.service';
import {BehaviorSubject} from "rxjs";
import {InputDataValidationService} from '../../../services/api-handlers/input-data-validation.service';

@Directive()
export abstract class AbstractUserDialogDirective implements OnInit, IEditForm<ExecuteUserAddOrUpdate>{
    @Input()
    public data!: ExecuteUserAddOrUpdate;
    public formGroup !:FormGroup<{
        userNameCtrl: FormControl<string | null>
        permissionCtrl: FormControl<number[] | null>
        firstNameCtrl: FormControl<string | null>
        lastNameCtrl: FormControl<string | null>
        mailCtrl: FormControl<string | null>
        notificationLevelCtrl: FormControl<number | null>
        sendReservationMailsCtrl: FormControl<boolean | null>
        sendSocReachedNotificationCtrl: FormControl<boolean | null>
        passwordCtrl?: FormControl<string | null>
        welcomeMailCtrl?: FormControl<boolean | null>
    }>;
    public isDeveloper: boolean;
    public permissionGroups!: BehaviorSubject<PermissionGroup[] | undefined>;
    public trackById<T extends {id: number | string}>(idx: number, obj: T): string | number {
        return obj.id;
    }

    public constructor(
        private readonly permissionGroupService : PermissionGroupService,
        private readonly authService : AuthService,
        private inputDataValidationService: InputDataValidationService
    ) {
        this.isDeveloper = this.authService.isDeveloper();
    }

    public async ngOnInit(): Promise<void> {
        this.setUpFromGroup();
        this.permissionGroups = this.permissionGroupService.getAll();
    }

    public async validate(): Promise<string[]> {
        this.formGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

        await new Promise((resolve) => {
            if (this.formGroup.pending) {
                let sub = this.formGroup.statusChanges.subscribe((res) => {
                    if(!this.formGroup.pending) {
                        sub.unsubscribe();
                        resolve(null);
                    }
                });
            } else {
                resolve(null);
            }
        });

        if (this.formGroup.valid) {
            return [];
        }

        const ret: string[] = [];
        this.addErrors(ret, this.formGroup);
        for(const c of Object.values(this.formGroup.controls)) {
            this.addErrors(ret, c);
        }

        return ret;
    }

    protected setUpFromGroup(): void {
        const userNameCtrl = new FormControl(
            this.data.name,
            {
                validators: [
                    this.checkUsernameWhitespace,
                    this.required('Benutzername nicht ausgefüllt')
                ],
                asyncValidators: this.userNameExist(this.data.name),
                updateOn: 'blur'
            });
        userNameCtrl.valueChanges.subscribe(() => this.data.name = userNameCtrl.value!);

        const permissionCtrl = new FormControl(this.data.permissionGroups);
        permissionCtrl.valueChanges.subscribe(() => this.data.permissionGroups = permissionCtrl.value!)

        const firstNameCtrl = new FormControl(this.data.firstname);
        firstNameCtrl.valueChanges.subscribe(() => this.data.firstname = firstNameCtrl.value!);

        const lastNameCtrl = new FormControl(this.data.lastname);
        lastNameCtrl.valueChanges.subscribe(() => this.data.lastname = lastNameCtrl.value!);

        const mailCtrl = new FormControl(
            this.data.email ?? null,
            {
                validators: this.checkMailIsValid,
                asyncValidators: this.mailExist(this.data.email),
                updateOn: 'blur'
            });
        mailCtrl.valueChanges.subscribe(() => this.data.email = mailCtrl.value || undefined);

        const notificationLevelCtrl = new FormControl(this.data.emailAlertLevel ?? null);
        notificationLevelCtrl.valueChanges.subscribe(() => this.data.emailAlertLevel = notificationLevelCtrl.value!);

        const sendReservationMailsCtrl = new FormControl(this.data.sendReservation);
        sendReservationMailsCtrl.valueChanges.subscribe(() => this.data.sendReservation = sendReservationMailsCtrl.value!);

        const sendSocReachedNotificationCtrl = new FormControl(this.data.sendSocReachedNotification);
        sendSocReachedNotificationCtrl.valueChanges.subscribe(() => this.data.sendSocReachedNotification = sendSocReachedNotificationCtrl.value!);

        this.formGroup = new FormGroup({
            userNameCtrl,
            permissionCtrl,
            firstNameCtrl,
            lastNameCtrl,
            mailCtrl,
            notificationLevelCtrl,
            sendReservationMailsCtrl,
            sendSocReachedNotificationCtrl
        },{
            validators:[
                this.checkEmailAlertLevel(mailCtrl,notificationLevelCtrl),
                this.checkEmailReservation(mailCtrl,sendReservationMailsCtrl),
                this.checkEmailSocReached(mailCtrl,sendSocReachedNotificationCtrl)
            ],
            updateOn:'blur'
        });
    };

    protected checkEmailAlertLevel(mailFiled: FormControl<string | null>, alterLevelField: FormControl<number | null>): ValidatorFn {
        return (): ValidationErrors | null => {
            if (!mailFiled.value && alterLevelField.value! > 0) {
                return {mailNotification:"Zum senden von Benachrichtigungen, muss eine E-Mail gespeichert werden"}
            }
            return null;
        }
    };

    protected checkEmailReservation(mailFiled: FormControl<string | null>, reservationField: FormControl<boolean | null>): ValidatorFn {
        return (): ValidationErrors | null => {
            if (!mailFiled.value && reservationField.value) {
                return {reservation:"Zum senden von Emails zu Reservierungen, muss eine E-Mail gespeichert werden"}
            }
            return null;
        }
    };

    protected checkEmailSocReached(mailFiled: FormControl<string | null>, reservationField: FormControl<boolean | null>): ValidatorFn {
        return (): ValidationErrors | null => {
            if (!mailFiled.value && reservationField.value) {
                return {socReachedNotification:"senden wenn default SOC erreicht ist"}
            }
            return null;
        }
    };

    private userNameExist(oldUsername: string | undefined): AsyncValidatorFn {
        return async (control: AbstractControl): Promise<ValidationErrors | null> => {
            if (control.value === oldUsername) {
                return null;
            }

            const value = await this.inputDataValidationService.isInputDataExists({
                key: "userName",
                value: control.value
            });

            if (value.value) {
                return {userExists: "Benutzername bereits verwendet"};
            }
            return null;
        };
    };

    private mailExist(oldMail :string | undefined): AsyncValidatorFn {
        return async (control: AbstractControl): Promise<ValidationErrors | null> => {
            if (control.value === oldMail || control.value == undefined) {
                return null;
            }
            const value = await this.inputDataValidationService.isInputDataExists({
                key: "userEmail",
                value: control.value
            });
            if (value.value) {
                return {mailExist: "E-Mail bereits verwendet"};
            }
            return null;
        };
    };

    private checkUsernameWhitespace(ctrl: AbstractControl): ValidationErrors | null {
        const username: string = ctrl.value;
        if (username !== username.trim()) {
            return {usernameInvalid: "Benutzername darf keine Leerzeichen am Anfang oder Ende haben"};
        }
        return null;
    };

    private required(err: string): ValidatorFn {
        return (ctrl: AbstractControl): ValidationErrors | null => {
            if (ctrl.value) {
                return null;
            }
            return {required: err};
        };
    };

    private checkMailIsValid(ctrl: AbstractControl): ValidationErrors | null {
        if(ctrl.value) {
            const err = Validators.email(ctrl);
            if (err) {
                return {mailInvalid: 'E-Mail Adresse ungültig'};
            }
        }
        return null;
    }

    private addErrors(target: string[], ctrl: AbstractControl) {
        if(ctrl.errors) {
            for(const e of Object.values(ctrl.errors)) {
                if(e) {
                    target.push(e);
                }
            }
        }
    }
}
