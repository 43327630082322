import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '../../shared/guards/auth.service';
import {ThemesService} from '../../services/themes.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public form!: FormGroup<{
        username: FormControl<string | null>
        password: FormControl<string | null>
    }>;
    public loading = false;
    public communicationError = false;
    private loginError = false;
    private formSubmitAttempt = false;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private readonly themesService: ThemesService // Included to do initial setup
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    loginInvalid(): boolean {
        return this.loginError && !this.loading;
    }


    isFieldInvalid(field: string) {
        const ctrl = this.form.get(field);
        return !ctrl || (
            (ctrl.touched && !ctrl.valid) ||
            (ctrl.untouched && this.formSubmitAttempt)
        );
    }

    async onSubmit() {
        this.loading = true;
        try {
            if (this.form.valid) {
                await this.authService.login(this.form.get('username')?.value!, this.form.get('password')?.value!);
            }
            this.formSubmitAttempt = true;
        }catch(e: any) {
            if(e?.status === 401) {
                this.loginError = true;
                this.communicationError = false;
            } else {
                this.loginError = false;
                this.communicationError = true;
            }
            console.log(e.error.msg);
        } finally {
            this.loading = false;
        }
    }
}
