import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiService} from "./api.service";
import {DialogService} from "../dialog.service";

@Injectable({
  providedIn: 'root'
})
export class CheckServerTimeInterceptor implements HttpInterceptor{

    private SHOW_MESSAGE_MIN_MILLIS = 10 * 60 * 1000;
    private WRONG_TIME_LIMIT = 5;
    private messageAlreadyShown = false;
    private recordedWrongTimeCounter = 0;
    constructor(private apiHandler: ApiService, private dialogService: DialogService) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map(value => {
                // @ts-ignore headers gibt es nicht immer, aber das prüfen wir hier ja
                if(value.headers && value.headers.get("date") && value.headers.get("content-type") == "application/json") {
                    const nowTst = new Date().getTime();
                    // @ts-ignore
                    const serverTst = new Date(value.headers.get("date")).getTime();
                    const delta = nowTst - serverTst;
                    this.apiHandler.timeDeltaMillis = delta;

                    if (!this.messageAlreadyShown) {
                        if ((delta > this.SHOW_MESSAGE_MIN_MILLIS || delta < -this.SHOW_MESSAGE_MIN_MILLIS)) {
                            this.recordedWrongTimeCounter++;
                        } else {
                            this.recordedWrongTimeCounter = 0;
                        }

                        if (this.recordedWrongTimeCounter >= this.WRONG_TIME_LIMIT) {
                            this.dialogService.showInfoDialog("Zeiteinstellungen prüfen!", "Die Uhrzeit dieses Computers weicht von der Uhrzeit des IO-Elon-Servers um "+(delta/1000/60).toFixed(0)+" Minuten ab.\nBitte stelle sicher, das die Uhrzeit auf dem Computer richtig eingestellt ist.\nEine genaue Uhrzeit wird für die korrekte Darstellung einige Diagramme und andere Angaben von Zeitpunkten benötigt.")
                            this.messageAlreadyShown = true;
                            this.recordedWrongTimeCounter = 0;
                        }
                    }
                }
                return value;
            })
        );
    }
}
