<div>
    <table>
        <tr>
            <th>Key</th>
            <th>Value</th>
            <th>Action</th>
        </tr>
        <tr *ngFor="let pair of data">
            <td>{{pair[0]}}</td>
            <td>{{pair[1]}}</td>
            <td>
                <button (click)="delete(pair[0])" mat-icon-button matTooltip="Löschen"><mat-icon>delete</mat-icon></button>
            </td>
        </tr>
    </table>
    <mat-form-field appearance="fill">
        <mat-label>New Key</mat-label>
        <input matInput [(ngModel)]="newKey">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>New Value</mat-label>
        <input matInput [(ngModel)]="newVal">
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit" (click)="updateVal()">Add/Update</button>
    <button mat-raised-button color="primary" type="submit" (click)="update()">Refresh</button>
</div>
