import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api-handlers/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {ExecuteFreeToMoveDataSourceAddOrUpdate, FreeToMoveDataSource} from '@io-elon-common/frontend-api';
import {IEditForm, TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {EditFreeToMoveDialogComponent} from '../dialogs/edit-free-to-move/edit-free-to-move-dialog.component';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
  providedIn: 'root'
})
export class FreeToMoveService extends DialogHandler<FreeToMoveDataSource, ExecuteFreeToMoveDataSourceAddOrUpdate, ExecuteFreeToMoveDataSourceAddOrUpdate, {}, void, void>{

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, 'FreeToMoveDataSource', toastr, dialog, dialogService, POLL_INTERVALS.free2Move);
    }

    protected getEditConfig(
        dataSource: FreeToMoveDataSource,
        editDialogConfigArgs: void
    ): TDialogOptions<ExecuteFreeToMoveDataSourceAddOrUpdate, IEditForm<ExecuteFreeToMoveDataSourceAddOrUpdate>> {
        return  {
            headline: "Free To Move Verknüpfung bearbeiten",
            component: EditFreeToMoveDialogComponent,
            executeCallback: elem => this.update(dataSource.id, elem),
            editElement: {
                vin: dataSource.vin,
                vehicle: dataSource.vehicle?.id,
                mail: dataSource.mail,
                password: dataSource.password
            }
        };
    }

    protected getNewConfig(
        newDialogConfigArgs: void
    ): TDialogOptions<ExecuteFreeToMoveDataSourceAddOrUpdate, IEditForm<ExecuteFreeToMoveDataSourceAddOrUpdate>> {
        return {
            headline: "Free To Move Verknüpfung  anlegen",
            component: EditFreeToMoveDialogComponent,
            executeCallback: elem => this.create(elem),
            editElement: {
                vin: "",
                vehicle: undefined,
                mail: "",
                password: ""
            }
        };
    }

    public async getVinList(mail: string, password: string, showAlerts = true): Promise<Array<{vin: string, endDate: number}>> {
        return await this.apiService.loadFreeToMoveVins(showAlerts, undefined, undefined, ApiHandler.customerId,{
            mail,
            password
        }).toPromise() as any;
    }
}
