<button
    mat-raised-button color="primary"
    type="submit"
    (click)="save.emit()"
    [disabled]="disabled"
>
    Speichern
</button>
<button
    mat-raised-button color="accent"
    type="submit"
    (click)="discard.emit()"
    [disabled]="disabled"
>
    Verwerfen
</button>
