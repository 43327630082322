<table mat-table [trackBy]="trackById" [dataSource]="dataSource" class="full-width-table" matSort>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
            <a fxFlexFill [routerLink]="'/meters/meter/'+element.id" class="mat-row-link"></a>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Name</th>
        <td mat-cell *matCellDef="let element" class="col-dongle-id">
            {{element.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Typ</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            {{element.type}}
        </td>
    </ng-container>

    <ng-container matColumnDef="basis">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Standort</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            <mat-form-field appearance="fill">
                <mat-label>Standort</mat-label>
                <mat-select (valueChange)="onBasisChange($event, element)">
                    <mat-option *ngFor="let f of fleets" [value]="f.base">{{f.name}} </mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions">Aktionen</th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button [disabled]="!element.canEdit" (click)="save(element)" mat-icon-button matTooltip="Speichern"><mat-icon>save</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
