import {Component, OnInit} from '@angular/core';
import {Dongle} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {DongleService} from '../../service/dongle.service';

@Component({
    selector: 'app-dongle-list-view',
    templateUrl: './dongle-list-view.component.html',
    styleUrls: ['./dongle-list-view.component.scss']
})
export class DongleListViewComponent implements OnInit {

    public dongles: BehaviorSubject<Dongle[] | undefined>

    constructor(
        private readonly dongleService: DongleService
    ) {
        this.dongles = this.dongleService.getAll();
    }

    ngOnInit(): void {
    }


    public async handleNewDongle(): Promise<void> {
        await this.dongleService.showNewDialog(undefined);
    }
}
