import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Rfid} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import {RfidService} from '../../service/rfid.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-rfid-table',
    templateUrl: './rfid-table.component.html',
    styleUrls: ['./rfid-table.component.scss']
})
export class RfidTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() public rfids!: Rfid[];
    @Input() public filter!: string;

    displayedColumns: string[] = ['id', "name",'token','user', 'vehicle', 'invoiceReceiver', 'active', 'actions'];
    rfidMatTableDataSource = new MatTableDataSource<Rfid>([]);

    constructor(
        private readonly rfidService: RfidService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.rfidMatTableDataSource = new MatTableDataSource(this.filterRfids(this.rfids));
        this.rfidMatTableDataSource.paginator = this.paginator;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.rfidMatTableDataSource.data = this.filterRfids(this.rfids);
    }

    private filterRfids(rfids: Rfid[]) {
        if(!this.filter) {
            return rfids;
        }
        return rfids.filter(r => {
            const strToCompare = `${r.id}
            ${r.name}
            ${r.token}
            ${r.user?.name ?? ""}
            ${r.vehicle?.name ?? ""}
            ${r.invoiceReceiver}
            ${r.active ? "Ja" : "Nein"}`
            return strToCompare.toLowerCase().includes(this.filter.toLowerCase());
        });
    }

    public async edit(rfid: Rfid): Promise<void> {
        await this.rfidService.showEditDialog(rfid);
    }

    public async delete(rfid: Rfid): Promise<void> {
        await this.rfidService.showDeleteDialog(rfid, {});
    }
}
