import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {AlgoV2Config} from '@io-elon-common/frontend-api';
import {AlgoV2ConfigService} from '../../service/algoV2Config.service';
import {AuthService} from '../../../../shared/guards/auth.service';

@Component({
    selector: 'app-algo-configs-tiles-view',
    templateUrl: './algo-configs-tiles-view.component.html',
    styleUrls: ['./algo-configs-tiles-view.component.scss']
})
export class AlgoConfigsTilesViewComponent implements OnInit {

    public allConfigs!: BehaviorSubject<AlgoV2Config[] | undefined>
    public canAdd = false;

    public constructor(
        private algoV2ConfigService: AlgoV2ConfigService,
        private authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.canAdd = this.authService.hasGlobalPermission("ADD_ALGO_V2_CONFIG");
        this.allConfigs = this.algoV2ConfigService.getAll();
    }

    public async newConfig(): Promise<void> {
        await this.algoV2ConfigService.showNewDialog();
    }
}
