<div class="d-flex flex-column gap-3">
    <mat-form-field appearance="fill">
        <mat-label>Angeschlossen an:</mat-label>
        <mat-select [(value)]="data.solarSystemId">
            <mat-option *ngFor="let s of possibleSolarSystems" [value]="s.id">{{s.name}} </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Breitengrad (WGS84)</mat-label>
            <input matInput type="number" min="-90.0" max="90.0" step="any" required [(ngModel)]="data.latitude" [formControl]="latFormControl">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Längengrad (WGS84)</mat-label>
            <input matInput type="number" min="-180.0" max="180.0" step="any" required [(ngModel)]="data.longitude" [formControl]="lonFormControl">
        </mat-form-field>
    </div>

    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Azimuth</mat-label>
            <input matInput type="number" min="-180.0" max="180.0" step="any" [(ngModel)]="data.azimuth" [formControl]="azimuthFormControl">
            <mat-hint align="start">-90=Ost, 0=Süd, 90=West</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Neigungswinkel</mat-label>
            <input matInput type="number" min="0" max="90.0" step="any" [(ngModel)]="data.tilt" [formControl]="tiltFormControl">
            <mat-hint align="start">0=horizontal, 90=vertikal</mat-hint>
        </mat-form-field>
    </div>

    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Höhe (Meter)</mat-label>
            <input matInput type="number" [(ngModel)]="data.height">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Installierte Leistung (kWp)</mat-label>
            <input matInput type="number" min="0" [(ngModel)]="data.installedPower">
        </mat-form-field>
    </div>
</div>
