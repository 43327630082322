<div style="width: 640px">
    <div>
        <mat-form-field class="half" appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput type="text" [(ngModel)]="data.name" required>
            <mat-hint>Name der Ladepunkt in der Anzeige</mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="edit" class="half" appearance="fill">
            <mat-label>Typ</mat-label>
            <input matInput [disabled]="edit" [(ngModel)]="evseType.name" required>
        </mat-form-field>

        <mat-form-field *ngIf="!edit" class="half" appearance="fill" [formGroup]="stateForm">
            <mat-label>Typ</mat-label>
            <input matInput type="text" formControlName="stateGroup" [matAutocomplete]="autoGroup" required>
            <mat-autocomplete #autoGroup="matAutocomplete">
                <mat-optgroup *ngFor="let group of manufacturerGroupOptions | async">
                    <span style="font-weight: bold">{{group.manufacturer}}</span>
                    <mat-option *ngFor="let evse of group.evses" [value]="evse.name">
                        <div matTooltip="{{evse.name}} -- {{evse.manufacturer}}" class="d-flex flex-row justify-content-start align-items-center">
                            <img src="/img/evses/{{evse.image}}" width="40px" height="40px" alt="Evse Picture">
                            <span class="dropdown-text">{{evse.name}}</span>
                        </div>
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div *ngIf="evseType" style="padding-bottom: 10px">
        <mat-form-field class="full" appearance="fill">
            <mat-label>Anschlussart</mat-label>
            <mat-select [(ngModel)]="actorType" (selectionChange)="updateActorArgs()" required>
                <mat-option *ngFor="let actorType of evseType.actors" [value]="actorType">
                    {{actorType.name}}
                </mat-option>
            </mat-select>
            <mat-hint>Das Protokoll, über welches mit dem Ladepunkt kommuniziert werden soll.</mat-hint>
        </mat-form-field>
    </div>

    <div>
        <app-actor-arg *ngFor="let arg of actorType?.args; let i = index" [(model)]="data.actorArgs[i].value" [name]="arg.name" [key]="arg.key" [type]="arg.type" [isRequired]="arg.isRequired"></app-actor-arg>
    </div>

    <div style="padding-bottom: 20px">
        <mat-form-field  class="half" appearance="fill">
            <mat-label>Maximaler Ladestrom</mat-label>
            <input matInput type="number" [(ngModel)]="data.maxI" required>
            <mat-hint>Der maximale Strom darf nicht größer sein, als das Hardware-Limit</mat-hint>
        </mat-form-field>
        <mat-form-field  class="half" appearance="fill">
            <mat-label>Minimaler Gastladestrom</mat-label>
            <input matInput type="number" min="6" [max]="data.maxI" [(ngModel)]="data.minGuestI">
            <mat-hint>Der minimale Strom darf nicht größer sein, als der maximale Ladestrom</mat-hint>
        </mat-form-field>
    </div>
    <div>
        <mat-checkbox [(ngModel)]="data.monitor">
            Aktives Anfragen von Daten
            <br>
            <span class="mat-caption break">Aktives anfragen von Daten. Automatisch gesendete Daten werden immer empfangen.</span>
        </mat-checkbox>
    </div>
    <div>
        <mat-checkbox [(ngModel)]="data.control">
            Steuerung aktivieren
            <br>
            <span class="mat-caption break">IO-ELON steuert diesen Ladepunkt. Nur deaktivieren, wenn anderer Master vorhanden.</span>
        </mat-checkbox>
    </div>
    <div>
        <mat-checkbox [(ngModel)]="data.smartCharging">
            Smart Charging aktivieren
            <br>
            <span class="mat-caption break">
                IO-ELON entscheidet intelligent, wann welches Fahrzeug geladen wird. <strong>ACHTUNG</strong> Wenn deaktiviert, kein Lastlimit und keine Authorisierung.
            </span>
        </mat-checkbox>
    </div>
    <div>
        <mat-checkbox [(ngModel)]="data.sequencesAllowed">
            Sequenzen aktivieren
            <br>
            <span class="mat-caption break">
                Sequenzen zur Fahrzeugerkennung an diesem Ladepunkt erlauben
            </span>
        </mat-checkbox>
    </div>
    <div>
        <mat-checkbox [(ngModel)]="data.guestCharging">
            Fremdfahrzeuge laden
            <br>
            <span class="mat-caption break">
                Lädt unbekannte Fahrzeuge mit minimalem Strom und minimaler Priorität.
            </span>
        </mat-checkbox>
    </div>
    <div>
        <mat-checkbox [(ngModel)]="data.authEnabled">
            RFID Karten für Gäste
            <br>
            <span class="mat-caption break">
                Wenn diese Option aktiv ist, müssen Gäste sich mit einer RFID Karte freischalten. Bekannte Fahrzeuge
                können ohne Karte laden.
            </span>
        </mat-checkbox>
    </div>
    <div>
        <mat-checkbox [(ngModel)]="data.slowChargeManagementEnabled">
            Langsames Laden erkennen
            <br>
            <span class="mat-caption break">
                Beobachtet das Ladeverhalten von hier angeschlossenen Fahrzeugen und reduziert die geplante
                Ladeleistung, wenn Fahrzeuge die zugewiesene Ladeleistung nicht ausnutzen.
                <div class="warn d-flex" *ngIf="!data.slowChargeManagementEnabled">
                    <div class="pt-1 mx-1"><i class="fas fa-2x fa-warning"></i></div>
                    <div>Wenn diese Funktion abgeschaltet ist, können Fahrzeuge ohne Datenquelle Ladeleistung im Plan blockieren, die sonst von anderen Fahrzeugen genutzt werden könnte.</div>
                </div>
            </span>
        </mat-checkbox>
    </div>
    <div>
        <mat-checkbox [(ngModel)]="data.nonChargingCarsToMinAmps" [disabled]="!data.slowChargeManagementEnabled">
            Fahrzeuge die nicht Laden einschalten.
            <br>
            <span class="mat-caption break">
                Wenn mit der Langsam-Laden-Erkennung erkannt wird, dass ein Fahrzeug nicht laden möchte, und der
                Ladevorgang dadurch pausiert ist, kann hier eingestellt werden, ob dem Fahrzeug trotzdem Energie
                freigegeben wird, die im Ladeplan jedoch nicht berücksichtigt ist.
            </span>
        </mat-checkbox>

    </div>
    <div>
        <mat-checkbox [(ngModel)]="data.maintenance" (change)="updateMaintenanceReason()">
            Wartungsmodus
            <br>
            <span class="mat-caption break">
                Im Wartungsmodus wird kein Alarm ausgelöst und keine Energie für den Ladepunkt reserviert.
                <br>
                Achtung: Es ist nicht möglich, während des Wartungsmodus an der Station zu laden.
                Wenn die Station keine Verbindung zum Backend hat,
                kann der Maximalstrom der Sicherung überschritten werden.
            </span>
        </mat-checkbox>
        <div *ngIf="data.maintenance">
            <mat-form-field  class="full" appearance="fill">
                <mat-label >Begründung</mat-label>
                <input matInput [required]="'data.maintenance'" [(ngModel)]="data.maintenanceReason" type="text" maxlength="255" >
                <mat-hint>Notiz, warum die Ladestation in den Wartungsmodus versetzt wird</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div>

    </div>
</div>
<div>
    <mat-form-field class="half" appearance="fill">
        <mat-label>Angeschlossen an:</mat-label>
        <mat-select [(value)]="data.powerSupplyId" required>
            <mat-option *ngFor="let p of possiblePowerSupplies" [value]="p.id">{{p.name}} </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="small" appearance="fill">
        <mat-label>Phasenzuordnung</mat-label>
        <mat-select [(ngModel)]="data.phaseRotation">
            <mat-option [value]="'NO_ROTATION'">Keine Phasenänderung</mat-option>
            <mat-option [value]="'LEFT'">Links rotiert</mat-option>
            <mat-option [value]="'RIGHT'">Rechts rotiert</mat-option>
            <mat-option [value]="'SWITCH_1_2'">L1 und L2 getauscht</mat-option>
            <mat-option [value]="'SWITCH_1_3'">L1 und L3 getauscht</mat-option>
            <mat-option [value]="'SWITCH_2_3'">L2 und L3 getauscht</mat-option>
        </mat-select>
        <mat-hint align="start">Anschluss des Ladepunktes relativ zum Netz</mat-hint>
    </mat-form-field>
    <div class="img">
        Netz<br>
        <img [src]="rotationImage()" [alt]="data.phaseRotation"><br>
        EVSE
    </div>
</div>
