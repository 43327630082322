<div class="center" style="position: relative">
    <h2 class="center">Ladepunkt Gesundheit</h2>
    <mat-select multiple [(ngModel)]="selectedStatus" (valueChange)="refresh()" style="position: absolute; top: 0; left: 0; width: 200px;">
        <mat-option *ngFor="let state of Object.values(EvseHealthStatus); "
                    [value]="state">{{getHealthText(state)}}
        </mat-option>
    </mat-select>
    <div style="position: absolute; right: 0; top: 0; margin: 0; padding: 0">
        <button mat-button  (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>
        <mat-form-field appearance="fill" style="position:absolute;width:1px;visibility:hidden;">
            <mat-date-range-input [formGroup]="range" [rangePicker]="dateRangePicker" [max]="today">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-date-range-picker #dateRangePicker (closed)="refresh()"></mat-date-range-picker>
        </mat-form-field>
        <button mat-button (click)="dateRangePicker.open()" ><mat-icon>today</mat-icon></button>
    </div>
</div>

<table *ngIf="!loading; else loadingTemplate" mat-table [dataSource]="dataSource" class="full-width-table" matSort>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="col-icon">Zustand</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            {{getHealthText(element.healthStatus)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="tst_start">
        <th mat-header-cell *matHeaderCellDef class="col-icon">Start</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            <span matTooltipPosition="right" [matTooltip]="element.tstStart | date:'mediumTime'">{{element.tstStart | date}} - {{element.tstStart | date:'shortTime'}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="tst_end">
        <th mat-header-cell *matHeaderCellDef class="col-icon">Ende</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            <span matTooltipPosition="right" [matTooltip]="element.tstEnd | date:'mediumTime'">{{element.tstEnd | date}} - {{element.tstEnd | date:'shortTime'}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef class="col-icon">Dauer</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            {{getDuration(element.tstStart, element.tstEnd)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef class="col-icon">Note</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            {{element.note}}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="$event.stopPropagation(); $event ? selectRow(row) : null" [ngClass]="{selected: selectedIndex === row.id}">
    </tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="10"
               [pageSizeOptions]="[10, 25, 50, 100, 250]" showFirstLastButtons>
</mat-paginator>

<ng-template #loadingTemplate>
    <div style="position: relative; height: 300px">
        <mat-spinner class="center-spinner"></mat-spinner>
    </div>
</ng-template>
