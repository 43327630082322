<div style="min-width: 300px;">
    <mat-form-field appearance="fill" class="w-100" *ngIf="(allUsers | async) as allUsers; else loading">
        <mat-label>Gruppen Name</mat-label>
        <input [(ngModel)]="data.name" matInput>
    </mat-form-field>

</div>
<ng-template #loading>
    <div>
        Laden...
    </div>
</ng-template>
