import {Component, Input, OnInit} from '@angular/core';


type HelpOptions = 'controlZoom' | 'leftClickMove' | 'shiftLeftClickZoom' | 'doubleLeftClickReset' | 'mouseLinkOptions' | 'rightClickZoom' | 'dynamicLoadMouseClickDetails';
type VAlign = 'bottom' | 'sub' | 'middle';

type HelpOptionsValues = {
    [K in HelpOptions]: string;
};

@Component({
  selector: 'app-graph-help-icon',
  templateUrl: './graph-help-icon.component.html',
  styleUrls: ['./graph-help-icon.component.scss']
})
export class GraphHelpIconComponent implements OnInit {
    helpOptionsText: HelpOptionsValues = {
        controlZoom: "STRG+Mausrad für Zoom",
        leftClickMove: "Linksklick+Bewegen für Horizontale Verschiebung",
        shiftLeftClickZoom: "Shift+Mauslinks+Bewegen für Zoom auf makierten Bereich",
        doubleLeftClickReset: "Doppelklick Mauslinks für Reset Zoom und Position",
        mouseLinkOptions: "Mauslinks für Optionen",
        rightClickZoom: "Rechte Maustaste + Bewegen, um auf den markierten Bereich zu zoomen",
        dynamicLoadMouseClickDetails: "Für Hilfe die Tasten anklicken\nStrg\n⇧ Shift\nAlt\n🖰  Click"
    };

    @Input() options!: HelpOptions[];
    @Input() vAlign!: VAlign;
    helpText = "";
    constructor() { }

    ngOnInit(): void {
        this.helpText = this.options.map(option => this.getTextForOption(option, this.helpOptionsText)).join("\n");
    }

    private getTextForOption(option: HelpOptions, helpOptionsText: HelpOptionsValues): string {
        switch (option) {
            case 'controlZoom': return helpOptionsText.controlZoom;
            case 'leftClickMove': return helpOptionsText.leftClickMove;
            case 'shiftLeftClickZoom': return helpOptionsText.shiftLeftClickZoom;
            case 'doubleLeftClickReset': return helpOptionsText.doubleLeftClickReset;
            case 'mouseLinkOptions': return helpOptionsText.mouseLinkOptions;
            case 'rightClickZoom': return helpOptionsText.rightClickZoom;
            case 'dynamicLoadMouseClickDetails': return helpOptionsText.dynamicLoadMouseClickDetails;
            default: return "";
        }
    }



}
