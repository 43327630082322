<div class="bars-container" cdk-overlay-origin #trigger="cdkOverlayOrigin" (mousemove)="expertMode = $event.ctrlKey" (mouseenter)="expertMode = $event.ctrlKey; isOpen = true" (mouseleave)="isOpen = false">
    <ng-container *ngIf="ampsMode; else psumMode">
        <div class="amps-bar">
            <ng-container *ngIf="plannedAmps">
                <div class="planned" [style.width]="zeroIfNan(plannedAmps.l1 / maxAmps.l1)*100 + '%'"></div>
            </ng-container>
            <div class="measured" [style.width]="zeroIfNan(electricity.l1.getAmps() / maxAmps.l1) * 100 + '%'"></div>
        </div>
        <div class="amps-bar">
            <ng-container *ngIf="plannedAmps">
                <div class="planned" [style.width]="zeroIfNan(plannedAmps.l2 / maxAmps.l2)*100 + '%'"></div>
            </ng-container>
            <div class="measured" [style.width]="zeroIfNan(electricity.l2.getAmps() / maxAmps.l2) * 100 + '%'"></div>
        </div>
        <div class="amps-bar">
            <ng-container *ngIf="plannedAmps">
                <div class="planned" [style.width]="zeroIfNan(plannedAmps.l3 / maxAmps.l3)*100 + '%'"></div>
            </ng-container>
            <div class="measured" [style.width]="zeroIfNan(electricity.l3.getAmps() / maxAmps.l3) * 100 + '%'"></div>
        </div>
    </ng-container>
    <ng-template #psumMode>
        <div class="power-bar">
            <ng-container *ngIf="plannedAmps">
                <div class="planned" [style.width]="zeroIfNan(plannedAmps.sum() / maxAmps.sum()) * 100 + '%'"></div>
            </ng-container>
            <div class="measured" [style.width]="zeroIfNan(electricity.getPSum() / (maxAmps.sum() * 235)) * 100 + '%'"></div>
        </div>
    </ng-template>
</div>

<ng-template cdk-connected-overlay
             [cdkConnectedOverlayOpen] = "isOpen"
             [cdkConnectedOverlayOrigin]="trigger"
             [cdkConnectedOverlayPositions]="[
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'center',
                        overlayY: 'bottom',
                        offsetY: -15
                    }
                 ]"
><div class="megatooltip">
<table>
    <thead>
        <tr>
            <td></td>
            <th *ngIf="expertMode">Spannung</th>
            <th>Strom</th>
            <th *ngIf="plannedAmps">Reservierter Strom</th>
            <th>Erlaubter Strom</th>
            <th *ngIf="expertMode">Phasenverschiebung</th>
            <th>Wirkleistung</th>
            <th *ngIf="expertMode">Blindleistung</th>
            <th *ngIf="expertMode">Scheinleistung</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th>L1</th>
            <td *ngIf="expertMode">{{electricity.l1.getVolts().toFixed(1)}}V</td>
            <td>{{electricity.l1.getAmps().toFixed(1)}}A</td>
            <td *ngIf="plannedAmps">{{(plannedAmps.l1).toFixed(1)}}A</td>
            <td>{{(maxAmps.l1).toFixed(1)}}A</td>
            <td *ngIf="expertMode">{{electricity.l1.getPhiDeg().toFixed(1)}}°</td>
            <td>{{electricity.l1.getWatts().toFixed(1)}}W</td>
            <td *ngIf="expertMode">{{electricity.l1.getVAR().toFixed(1)}}W</td>
            <td *ngIf="expertMode">{{electricity.l1.getVA().toFixed(1)}}W</td>
        </tr>
        <tr>
            <th>L2</th>
            <td *ngIf="expertMode">{{electricity.l2.getVolts().toFixed(1)}}V</td>
            <td>{{electricity.l2.getAmps().toFixed(1)}}A</td>
            <td *ngIf="plannedAmps">{{(plannedAmps.l2).toFixed(1)}}A</td>
            <td>{{(maxAmps.l2).toFixed(1)}}A</td>
            <td *ngIf="expertMode">{{electricity.l2.getPhiDeg().toFixed(1)}}°</td>
            <td>{{electricity.l2.getWatts().toFixed(1)}}W</td>
            <td *ngIf="expertMode">{{electricity.l2.getVAR().toFixed(1)}}W</td>
            <td *ngIf="expertMode">{{electricity.l2.getVA().toFixed(1)}}W</td>
        </tr>
        <tr>
            <th>L3</th>
            <td *ngIf="expertMode">{{electricity.l3.getVolts().toFixed(1)}}V</td>
            <td>{{electricity.l3.getAmps().toFixed(1)}}A</td>
            <td *ngIf="plannedAmps">{{(plannedAmps.l3).toFixed(1)}}A</td>
            <td>{{(maxAmps.l3).toFixed(1)}}A</td>
            <td *ngIf="expertMode">{{electricity.l3.getPhiDeg().toFixed(1)}}°</td>
            <td>{{electricity.l3.getWatts().toFixed(1)}}W</td>
            <td *ngIf="expertMode">{{electricity.l3.getVAR().toFixed(1)}}W</td>
            <td *ngIf="expertMode">{{electricity.l3.getVA().toFixed(1)}}W</td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th><mat-icon class="iwhite">functions</mat-icon></th>
            <td *ngIf="expertMode"></td>
            <td>{{electricity.getPhaseAmps().sum().toFixed(1)}}A</td>
            <td *ngIf="plannedAmps">{{(plannedAmps.sum()).toFixed(1)}}A</td>
            <td>{{(maxAmps.sum()).toFixed(1)}}A</td>
            <td *ngIf="expertMode"></td>
            <td>{{electricity.getPSum().toFixed(1)}}W</td>
            <td *ngIf="expertMode">{{electricity.getVARSum().toFixed(1)}}W</td>
            <td *ngIf="expertMode"></td>
        </tr>
    </tfoot>
</table>
</div></ng-template>
