<div style="min-width: 400px" class="d-flex flex-column gap-3 align-items-stretch">
    <mat-form-field appearance="fill">
        <mat-label>Benutzername</mat-label>
        <input matInput type="text" [(ngModel)]="data.username">
        <mat-hint>E-Mail oder Telefonnummer mit Länderkennung: Beispiel: +49151512345</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Passwort</mat-label>
        <input matInput type="password" [(ngModel)]="data.password">
    </mat-form-field>
</div>
