import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActorSpecificDataLine, Evse} from '@io-elon-common/frontend-api';
import {ActorDetailsLoadingStatus, EvseService} from "../../service/evse.service";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject, Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ActorDetailsDialogData {
    evse: Evse,
}

@Component({
  selector: 'app-actor-details-dialog',
  templateUrl: './actor-details-dialog.component.html',
  styleUrls: ['./actor-details-dialog.component.scss']
})
export class ActorDetailsDialogComponent implements OnInit, OnDestroy {

    public open?: string;
    public validations: {[key: string]: string} = {};
    public data!: BehaviorSubject<ActorDetailsLoadingStatus | undefined>;
    public isAllDataLoaded = false;
    public itemsLoadedCount = 0;
    public errorText = "";
    private subscription?: Subscription;

    constructor(
        private dialogRef: MatDialogRef<ActorDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: ActorDetailsDialogData,
        private evseService: EvseService,
        private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.loadData();
    }

    private loadData(): void {
        this.itemsLoadedCount = 0;
        this.isAllDataLoaded = false;
        this.errorText = "";
        this.data = this.evseService.getActorDetails(this.dialogData.evse);
        this.subscription = this.data.subscribe(details => {
            this.itemsLoadedCount = details?.lines.filter(l => !l.pending).length || 0;
            const count = details?.lines.length || 0;
            this.isAllDataLoaded = this.itemsLoadedCount == count;
        }, error => {
            this.isAllDataLoaded = true;
            this.errorText = error.error.msg;
        });
    }

    refresh() {
        this.loadData();
    }

    close(): void {
        this.dialogRef.close();
    }

    validate(line: ActorSpecificDataLine) {
        if(!line.value) {
            return
        }
        if(line.allowedRegex) {
            const valid = new RegExp(line.allowedRegex).test(line.value);
            if(valid) {
                delete this.validations[line.key];
            } else {
                this.validations[line.key] = "Erlaubt (Regex): " + line.allowedRegex;
            }
        }
    }

    async write(l: ActorSpecificDataLine) {
        try {
            const result = await this.evseService.setActorSpecificDataField(this.dialogData.evse.id, l.key, l.value as string);
            if (result.success) {
                this.toastr.success(result.result, "Erfolg")
            } else {
                this.toastr.error(result.result, "Fehler");
            }
        } catch (e) {
            this.toastr.error("Fehler beim schreiben");
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
