<div class="d-flex flex-column align-items-stretch gap-3">
    <mat-form-field appearance="fill">
        <mat-label>Ven Id</mat-label>
        <input matInput placeholder="Ven Id" [(ngModel)]="data.venId">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Base Url</mat-label>
        <input matInput placeholder="Base Url" [(ngModel)]="data.baseUrl">
    </mat-form-field>
    <div appearance="fill">
        <h2>Zertifikat</h2>
    </div>
    <div></div>
    <div appearance="fill" [style.visibility]="data.cert == '' ? 'visible' : 'hidden'">
        <mat-label>Hochladen: </mat-label>
        <input #certFile type="file" accept=".p12" (change)="onFileSelected($event.target.files)" class="file-upload"/>
    </div>
    <div appearance="fill" [style.visibility]="data.cert != '' ? 'visible' : 'hidden'">
        <button mat-raised-button color="primary" (click)="downloadCert()">Herunterladen</button>
        <button mat-raised-button color="warn" (click)="removeCert()" style="margin-left: 8px;">Remove Entfernen</button>
    </div>
</div>




