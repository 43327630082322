export interface RangeCorrectionFactor {
    name : string;
    value : number;
}
export const DEFAULT_RANGE_CORRECTION_FACTORS : RangeCorrectionFactor[] = [
    {value:1.0,name:"WLTP"},
    {value:0.9,name:"Stadt"},
    {value:0.7,name:"Standard"},
    {value:0.6,name:"Winter"},
    {value:-1,name:"Benutzerdefiniert"}
]
