<div *ngIf="!parseError; else parseErrorMsg" style="min-width: 700px">
    <div class="formular-line" style="padding: 10px">
        <h2 class="d-flex flex-row justify-content-between w-100 px-3">
            <span>{{name}}</span>
            <span class="d-flex flex-row gap-3">
                @if(liveResult; as lr) {
                    <span class="result-power">L1: {{lr.p1}}<span class="window">{{lr.p1Win}}</span></span>
                    <span class="result-power">L2: {{lr.p2}}<span class="window">{{lr.p2Win}}</span></span>
                    <span class="result-power">L3: {{lr.p3}}<span class="window">{{lr.p3Win}}</span></span>
                } @else {
                    <span> -- Keine Live Daten --</span>
                }
            </span>
        </h2>
    </div>
    @for (t of terms; let idx = $index; track idx ) {
        <div class="formular-line d-flex flex-row gap-1" [class.editActive]="idx === editIdx">
            <div class="operation flex-grow-0" >
                <i *ngIf="t.operation === '+'" style="color: green" class="fa-solid fa-plus"></i>
                <i *ngIf="t.operation === '-'" style="color: orangered" class="fa-solid fa-minus"></i>
            </div>
            @switch (t.type) {
                @case ('E') {
                    <div class="name flex-grow-0">Ladepunkt: <app-evse-link *ngIf="$any(t.args).id !== -1; else choose" [evseId]="$any(t.args).id"></app-evse-link></div>
                    <div class="arg flex-grow-0"></div>
                }
                @case ('M') {
                    <div class="name flex-grow-0">Zähler: <span *ngIf="$any(t.args).id !== -1; else choose">{{meterName($any(t.args).id)}}</span></div>
                    <div class="arg flex-grow-0">
                        <img *ngIf="$any(t.args).rotation !== 'NO_ROTATION'" [src]="rotationImage($any(t.args).rotation)" [alt]="$any(t.args).rotation">
                    </div>
                }
                @case ('L') {
                    <div class="name flex-grow-0">Last: <span *ngIf="$any(t.args).id !== -1; else choose">{{loadName($any(t.args).id)}}</span></div>
                    <div class="arg flex-grow-0">
                        <img *ngIf="$any(t.args).rotation !== 'NO_ROTATION'" [src]="rotationImage($any(t.args).rotation)" [alt]="$any(t.args).rotation">
                    </div>
                }
                @case ('C') {
                    <div class="name flex-grow-0">Konstante: </div>
                    <div class="arg flex-grow-0"></div>
                }
            }
            <div class="flex-grow-1 align-content-end d-flex gap-2">
                @if(t.type !== 'C') {
                    @if(liveData(t); as ld) {
                        <span class="w-33">{{ld.p1}}</span>
                        <span class="w-33">{{ld.p2}}</span>
                        <span class="w-33">{{ld.p3}}</span>
                    } @else {
                        <span> -- Keine Live Daten --</span>
                    }
                } @else {
                    <span class="w-33">{{formatPower($any(t.args).p1)}}</span>
                    <span class="w-33">{{formatPower($any(t.args).p2)}}</span>
                    <span class="w-33">{{formatPower($any(t.args).p3)}}</span>
                }
            </div>
            <button *ngIf="t.type==='L'" class="btn btn-link btnEdit" matTooltip="Vermeiden Sie es Formel-Lasten oder Meter-Lasten in Formeln zu verwenden, da es zu Problemen mit zeitlich versetzten Messwerten kommen wird. Der Einsatz von Nutzerdefinierten Lasten stellt kein Problem dar"><i class="fas fa-warning"></i> </button>
            <button class="btn btnEdit" matTooltip="Bearbeiten" (click)="edit(idx)">
                <i class="fas fa-edit"></i>
            </button>
            <button *ngIf="removeIdx!==idx" class="btnEdit btn btn-link" matTooltip="Löschen" (click)="remove1(idx)">
                <i class="fas fa-trash"></i>
            </button>
            <button *ngIf="removeIdx===idx" class="btnEdit btn btn-link btnWarn" matTooltip="Löschen bestätigen!" (click)="remove2(idx)">
                <i class="fas fa-trash"></i>
            </button>
        </div>
        <span *ngIf="idx !== editIdx; else editor"></span>
    }
    <span class="newWrapper">
        <button *ngIf="editIdx !== -1" class="btn btnAdd" mat-raised-button color="primary" matTooltip="Neuer Term" (click)="edit(-1)">
            <i class="fas fa-square-plus"></i>
        </button>
        <ng-container *ngIf="editIdx === -1">
            <ng-container *ngTemplateOutlet="editor; context: {showBtn: true}"></ng-container>
        </ng-container>
    </span>
</div>

<ng-template #choose>
    Bitte wählen
</ng-template>

<ng-template #parseErrorMsg>
    Fehler beim interpretieren der Formel:<br>
    {{formula}}
</ng-template>

<ng-template #editor let-showBtn="showBtn">
    <div class="editor" [style.height]="editorHeight">
        <mat-form-field appearance="fill" class="editOperation">
            <mat-label>Operation</mat-label>
            <mat-select [(value)]="editTerm.operation" (selectionChange)="emitTerms()">
                <mat-option [value]="'+'">+</mat-option>
                <mat-option [value]="'-'">-</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="editType">
            <mat-label>Typ</mat-label>
            <mat-select [(value)]="editTerm.type" (selectionChange)="resetId(); emitTerms()">
                <mat-option [value]="'E'">EVSE</mat-option>
                <mat-option [value]="'M'">Smartmeter</mat-option>
                <mat-option [value]="'L'">Last <button class="btn btn-link btnEdit" matTooltip="Vermeiden Sie es Formel-Lasten oder Meter-Lasten in Formeln zu verwenden, da es zu Problemen mit zeitlich versetzten Messwerten kommen wird. Der Einsatz von Nutzerdefinierten Lasten stellt kein Problem dar"><i class="fas fa-warning"></i> </button></mat-option>
                <mat-option [value]="'C'">Konstante</mat-option>
            </mat-select>
        </mat-form-field>
        <ng-container [ngSwitch]="editTerm.type">
            <ng-container *ngSwitchCase="'E'">
                <mat-form-field appearance="fill" class="editEvse" *ngIf="(allEvses | async) as allEvses; else loading">
                    <mat-label>Ladepunkt</mat-label>
                    <mat-select [(value)]="editTerm.args.id" (selectionChange)="emitTerms()">
                        <mat-option [value]="-1">Bitte wählen</mat-option>
                        <mat-option *ngFor="let e of filterAndSortEvses(allEvses)" [value]="e.id">{{e.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchCase="'M'">
                <mat-form-field appearance="fill" class="editMeter" *ngIf="(allMeters | async) as allMeters; else loading">
                    <mat-label>Smartmeter</mat-label>
                    <mat-select [(value)]="editTerm.args.id" (selectionChange)="emitTerms()">
                        <mat-option [value]="-1">Bitte wählen</mat-option>
                        <mat-option *ngFor="let m of filterAndSortMeter(allMeters)" [value]="m.id">{{m.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngTemplateOutlet="phaseRotation"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'L'">
                <mat-form-field appearance="fill" class="editLoad" *ngIf="(allLoads | async) as allLoads; else loading">
                    <mat-label>Last</mat-label>
                    <mat-select [(value)]="editTerm.args.id" (selectionChange)="emitTerms()">
                        <mat-option [value]="-1">Bitte wählen</mat-option>
                        <mat-option *ngFor="let l of allLoads" [value]="l.id">{{l.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngTemplateOutlet="phaseRotation"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'C'">
                <mat-form-field appearance="fill" class="editPwr">
                    <mat-label>L1 Leistung (W)</mat-label>
                    <input matInput [(ngModel)]="editTerm.args.p1" (change)="emitTerms()">
                </mat-form-field>
                <mat-form-field appearance="fill" class="editPwr">
                    <mat-label>L2 Leistung (W)</mat-label>
                    <input matInput [(ngModel)]="editTerm.args.p2" (change)="emitTerms()">
                </mat-form-field>
                <mat-form-field appearance="fill" class="editPwr">
                    <mat-label>L3 Leistung (W)</mat-label>
                    <input matInput [(ngModel)]="editTerm.args.p3" (change)="emitTerms()">
                </mat-form-field>
            </ng-container>
        </ng-container>
        <button *ngIf="showBtn" class="btn btnCommit" mat-raised-button color="primary" matTooltip="Übernehmen" (click)="commit()">
            <i class="fas fa-save"></i>
        </button>
    </div>
</ng-template>

<ng-template #loading>
    Laden...
</ng-template>

<ng-template #phaseRotation>
    <mat-form-field appearance="fill" class="editPhases">
        <mat-label>Phasenzuordnung</mat-label>
        <mat-select [(ngModel)]="editTerm.args.rotation" (selectionChange)="emitTerms()">
            <mat-option [value]="'NO_ROTATION'">Keine Phasenänderung</mat-option>
            <mat-option [value]="'LEFT'">Links rotiert</mat-option>
            <mat-option [value]="'RIGHT'">Rechts rotiert</mat-option>
            <mat-option [value]="'SWITCH_1_2'">L1 und L2 getauscht</mat-option>
            <mat-option [value]="'SWITCH_1_3'">L1 und L3 getauscht</mat-option>
            <mat-option [value]="'SWITCH_2_3'">L2 und L3 getauscht</mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>

