import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {LoadService} from "../../service/load.service";
import {ExecuteLoadAddOrUpdate, Load, PowerSupply} from "@io-elon-common/frontend-api";
import {PowerSupplyService} from "../../../basis/service/power-supply.service";
import {LoadFormularHistoricValues} from "@io-elon-common/frontend-api/lib/model/models";
import {DialogType} from '../../../../shared/components/help-box/dialogType';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

@Component({
  selector: 'app-load-formula-simulation',
  standalone: false,
  templateUrl: './load-formula-simulation.component.html',
  styleUrl: './load-formula-simulation.component.scss'
})
export class LoadFormulaSimulationComponent implements OnInit, OnDestroy{

    public id: number | null = null;
    public loads!: Promise<Load[]>;
    public powerSupplies!: Promise<PowerSupply[]>;
    public data?: ExecuteLoadAddOrUpdate;
    public drawEditor = true;

    public start: number = Date.now() - DAY
    public end: number = Date.now()
    public loading = false;
    public result: LoadFormularHistoricValues | null = null;
    public readonly DialogType = DialogType;
    private subscription: any;

    constructor(
        private route: ActivatedRoute,
        private loadService: LoadService,
        private psService: PowerSupplyService
    ) {
    }

    ngOnInit() {
        this.loads = this.loadService.getAllPromise();
        this.powerSupplies = this.psService.getAllPromise();
        this.powerSupplies.then(pss => {
            this.data = this.loadService.generateCreateObject(pss);
            this.data.type = "FormulaLoad"
        })


        this.subscription = this.route.paramMap.subscribe(params => {
            this.id = parseInt(params.get('id') ?? '', 10); // Parse to number
            this.idUpdate();
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe(); // Clean up subscription
        }
    }

    public idUpdate() {
        if(this.id && !isNaN(this.id)) {
            this.loadService.getPromise(this.id).then(load => {
                this.powerSupplies.then(pss => {
                    this.data = this.loadService.generateEditObject(load, pss);
                    this.drawEditor = false;
                    setTimeout(() => this.drawEditor = true, 10)
                });
            });
        }

    }

    public parseDate(dateStr: string) {
        return new Date(dateStr).getTime();
    }

    public get dateWarning(): string {
        const duration = this.end - this.start;
        if(duration <= DAY) {
            return ""
        }

        let msg = "Zeitraum ist:"
        const d = Math.floor(duration / DAY);
        const h = Math.floor((duration % DAY) / HOUR);
        const m = Math.floor((duration % HOUR) / MINUTE);

        if(d > 0) {
            msg += " " + d + "d"
        }
        if(h > 0) {
            msg += " " + h + "h"
        }
        if(m > 0) {
            msg += " " + m + "m"
        }
        msg += ". Zeiträume von mehr als einem Tag können erheblichen Einfluss auf die Backend Performance haben!"

        return msg;
    }

    public async simulate() {
        if(!this.data) {
            alert("Data is missing");
            return;
        }

        this.loading = true;
        this.result = null;
        try {
            this.result = await this.loadService.simulateFormularLoad(this.data, this.start, this.end);
        } finally {
            this.loading = false;
        }
    }
}
