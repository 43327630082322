import {Component, Input} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {LoginData} from "../../service/evse.service";


@Component({
  selector: 'app-easee-login-dialog',
  templateUrl: './easee-login-dialog.component.html',
  styleUrls: ['./easee-login-dialog.component.scss']
})
export class EaseeLoginDialogComponent implements IEditForm<LoginData> {

    @Input()
    public data!: LoginData;

    constructor() { }

    validate(): string[] {
        const ret: string[] = []
        if(!this.data.username.trim())
            ret.push("Benutzername ist leer")
        if(!this.data.password.trim())
            ret.push("Passwort ist leer")
        return ret;
    }

}
