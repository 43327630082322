import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {OpenAdrVenEntity} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {OpenAdrVenEntityService} from '../../service/open-adr-ven-entity.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-open-adr-ven-entity-table',
  templateUrl: './open-adr-ven-entity-table.component.html',
  styleUrls: ['./open-adr-ven-entity-table.component.scss']
})
export class OpenAdrVenEntityTableComponent extends AbstractTableComponent implements OnInit, OnChanges {

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() openAdrVenEntities!: OpenAdrVenEntity[];

    displayedColumns: string[] = ['id', 'venId', 'baseUrl', 'actions'];

    dataSource = new MatTableDataSource<OpenAdrVenEntity>([]);
    selection = new SelectionModel<OpenAdrVenEntity>(true, []);

    constructor(
        private readonly openAdrVenEntityService: OpenAdrVenEntityService
    ) {
        super();
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.openAdrVenEntities);
        this.dataSource.paginator = this.paginator;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.openAdrVenEntities;
    }

    public async edit(openAdrVenEntity: OpenAdrVenEntity): Promise<void> {
        await this.openAdrVenEntityService.showEditDialog(openAdrVenEntity);
    }

    public async delete(openAdrVenEntity: OpenAdrVenEntity): Promise<void> {
        await this.openAdrVenEntityService.showDeleteDialog(openAdrVenEntity, {});
    }

    downloadCert(uri : string, id: string) {
        var a = document.createElement('a');
        a.href = "data:application/x-pkcs12;base64, " + uri;
        a.download = id;
        a.click();
      }
}
