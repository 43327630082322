import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface ConfirmDialogOption {
    text: string;
    color: "warn" | "primary" | "accent";
}

export interface ConfirmDialogData<T extends ConfirmDialogOption> {
    head: string;
    msg: string;
    options: T[];
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent<T extends ConfirmDialogOption> {
    public config: ConfirmDialogData<ConfirmDialogOption>;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent<T>>,
        @Inject(MAT_DIALOG_DATA) config: Partial<ConfirmDialogData<T>>) {
        if (!config.head) {
            config.head = "Bitte bestätigen";
        }
        if (!config.msg) {
            config.msg = "Wollen sie diese Aktion wirklich ausführen";
        }
        if (!config.options || config.options.length === 0) {
            // @ts-ignore
            config.options = [{text: "Weiter", color: "warn"}, {text: "Abbrechen", color: "primary"}];
        }
        this.config = config as ConfirmDialogData<T>;
    }

    onButton(option: ConfirmDialogOption) {
        this.dialogRef.close(option);
    }

    validate(): string[] {
        return [];
    }
}
