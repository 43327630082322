import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {EventLog, EventLogEntry} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {FormControl, FormGroup } from '@angular/forms';
import {Moment} from 'moment';
import {AuthService} from '../../../guards/auth.service';
import {localStorageGet, localStorageSave} from '../../../helper/typed-local-storage';
import * as moment from 'moment';
import {compareNumValues, compareStringValues} from '../../../helper/util-functions';

@Component({
    selector: 'app-event-log-table',
    templateUrl: './event-log-table.component.html',
    styleUrls: ['./event-log-table.component.scss']
})
export class EventLogTableComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

    public sort: Sort = {
        active: 'tst',
        direction: 'desc',
    };

    loading = true;
    events!: EventLogEntry[];
    start: Moment = moment().subtract(7, "days");
    end: Moment = moment();
    level = +localStorageGet('EVENT_LOG_LEVEL', "400");
    isDev!: boolean

    @Input() loadDataCb!: (start: number, end: number) => Promise<EventLog>;

    range = new FormGroup({
        start: new FormControl(this.start),
        end: new FormControl(this.end)
    });

    displayedColumns: string[] = ['tst', 'level', 'message'];

    today = moment();

    dataSource = new MatTableDataSource<EventLogEntry>([]);
    selection = new SelectionModel<EventLogEntry>(true, []);
    selectedIndex = 0;

    constructor(
        private readonly authService: AuthService) {
    }

    ngOnInit(): void {
        this.isDev = this.authService.isDeveloper();
        this.dataSource = new MatTableDataSource<EventLogEntry>(this.events);
        this.dataSource.paginator = this.paginator;

        this.range.setValue({
            start: this.start,
            end: this.end
        });

        this.refresh();
    }

    ngAfterViewInit() {

    }

    selectRow(row: EventLogEntry) {
    }

    async refresh() {
        this.loading = true;
        const start = this.range.get("start")?.value as Moment;
        let end = this.range.get("end")?.value as Moment|null;
        if (end === null) end = start;
        this.events = (await this.loadDataCb(
            start.clone().startOf('day').valueOf(),
            end.clone().endOf('day').valueOf())).events;

        localStorageSave('EVENT_LOG_LEVEL', this.level + "" as any)
        this.events = this.events
            .filter(e => e.level <= this.level);
        this.updateSort();
        this.dataSource = new MatTableDataSource<EventLogEntry>(this.events);
        this.dataSource.paginator = this.paginator;
        this.loading = false;
    }

    public trackById(idx: number, item:{id: number}) {
        return item.id;
    }

    public setSort(sort: Sort) {
        this.sort = sort;
        this.updateSort();
    }

    private updateSort() {
        const isDesc = this.sort.direction === 'desc';
        switch (this.sort.active) {
            case "tst":
                this.events.sort((a, b) => compareNumValues(a.tst, b.tst, isDesc));
                break;
            case "level":
                this.events.sort((a, b) => compareNumValues(a.level, b.level, isDesc));
                break;
            case "message":
                this.events.sort((a, b) => compareStringValues(a.message, b.message, isDesc));
                break;
        }
        this.dataSource = new MatTableDataSource(this.events);
        this.dataSource.paginator = this.paginator;
    }
}

