export const actions = ['Authorize',
    'BootNotification',
    'CancelReservation',
    'ChangeAvailability',
    'ChangeConfiguration',
    'ClearCache',
    'ClearChargingProfile',
    'DataTransfer',
    'DiagnosticsStatusNotification',
    'FirmwareStatusNotification',
    'GetCompositeSchedule',
    'GetConfiguration',
    'GetDiagnostics',
    'GetLocalListVersion',
    'Heartbeat',
    'MeterValues',
    'RemoteStartTransaction',
    'RemoteStopTransaction',
    'ReserveNow',
    'Reset',
    'SendLocalList',
    'SetChargingProfile',
    'StartTransaction',
    'StatusNotification',
    'StopTransaction',
    'TriggerMessage',
    'UnlockConnector',
    'UpdateFirmware'];

export type ObjectDescription = {
    [key: string]: ObjectDescription | 'text' | 'number' | 'List' | 'Date' | string[]
}

export const structure: {[key: string]: ObjectDescription} = {
    Authorize: {
        idTag: 'text',
    },
    BootNotification: {
        chargePointVendor: 'text',
        chargePointModel: 'text',
        chargePointSerialNumber: 'text',
        chargeBoxSerialNumber: 'text',
        firmwareVersion: 'text',
        iccid: 'text',
        imsi: 'text',
        meterType: 'text',
        meterSerialNumber: 'text',
    },
    CancelReservation: {
        reservationId: 'number',
    },
    ChangeAvailability: {
        connectorId: 'number',
        type: ['Inoperative', 'Operative',],
    },
    ChangeConfiguration: {
        key: 'text',
        value: 'text',
    },
    ClearCache: {},
    ClearChargingProfile: {
        id: 'number',
        connectorId: 'number',
        chargingProfilePurpose: ['ChargePointMaxProfile', 'TxDefaultProfile', 'TxProfile',]
        ,
        stackLevel: 'number',
    },
    DataTransfer: {
        vendorId: 'text',
        messageId: 'text',
        data: 'text',
    },
    DiagnosticsStatusNotification: {
        status: ['Idle', 'Uploaded', 'UploadFailed', 'Uploading',]
        ,
    },
    FirmwareStatusNotification: {
        status: ['Downloaded', 'DownloadFailed', 'Downloading', 'Idle', 'InstallationFailed', 'Installing', 'Installed',]
        ,
    },
    GetCompositeSchedule: {
        connectorId: 'number',
        duration: 'number',
        chargingRateUnit: ['A', 'W',]
        ,
    },
    GetConfiguration: {
        key: 'List',
    },
    GetDiagnostics: {
        location: 'text',
        retries: 'number',
        retryInterval: 'number',
        startTime: 'Date',
        stopTime: 'Date',
    },
    GetLocalListVersion: {},
    Heartbeat: {},
    MeterValues: {
        connectorId: 'number',
        transactionId: 'number',
        meterValue: 'List',
    },
    RemoteStartTransaction: {
        connectorId: 'number',
        idTag: 'text',
        chargingProfile: {
            chargingProfileId: 'number',
            transactionId: 'number',
            stackLevel: 'number',
            chargingProfilePurpose: ['ChargePointMaxProfile', 'TxDefaultProfile', 'TxProfile',],
            chargingProfileKind: ['Absolute', 'Recurring', 'Relative',],
            recurrencyKind: ['Daily', 'Weekly',],
            validFrom: 'Date',
            validTo: 'Date',
            chargingSchedule: {
                duration: 'number',
                startSchedule: 'Date',
                chargingRateUnit: ['A', 'W',],
                chargingSchedulePeriod: 'List',
                minChargingRate: 'number',
            },
        },
    },
    RemoteStopTransaction: {
        transactionId: 'number',
    },
    ReserveNow: {
        connectorId: 'number',
        expiryDate: 'Date',
        idTag: 'text',
        parentIdTag: 'text',
        reservationId: 'number',
    },
    Reset: {
        type: ['Hard', 'Soft',],
    },
    SendLocalList: {
        listVersion: 'number',
        localAuthorizationList: 'List',
        updateType: ['Differential', 'Full',],
    },
    SetChargingProfile: {
        connectorId: 'number',
        csChargingProfiles: {
            chargingProfileId: 'number',
            transactionId: 'number',
            stackLevel: 'number',
            chargingProfilePurpose: ['ChargePointMaxProfile', 'TxDefaultProfile', 'TxProfile',],
            chargingProfileKind: ['Absolute', 'Recurring', 'Relative',],
            recurrencyKind: ['Daily', 'Weekly'],
            validFrom: 'Date',
            validTo: 'Date',
            chargingSchedule: {
                duration: 'number',
                startSchedule: 'Date',
                chargingRateUnit: ['A', 'W',],
                chargingSchedulePeriod: 'List',
                minChargingRate: 'number',
            },
        },
    },
    StartTransaction: {
        connectorId: 'number',
        idTag: 'text',
        meterStart: 'number',
        reservationId: 'number',
        timestamp: 'Date',
    },
    StatusNotification: {
        connectorId: 'number',
        errorCode: ['ConnectorLockFailure', 'EVCommunicationError', 'GroundFailure', 'HighTemperature', 'InternalError', 'LocalListConflict', 'NoError', 'OtherError', 'OverCurrentFailure', 'PowerMeterFailure', 'PowerSwitchFailure', 'ReaderFailure', 'ResetFailure', 'UnderVoltage', 'OverVoltage', 'WeakSignal',],
        info: 'text',
        status: ['Available', 'Preparing', 'Charging', 'SuspendedEVSE', 'SuspendedEV', 'Finishing', 'Reserved', 'Unavailable', 'Faulted',],
        timestamp: 'Date',
        vendorId: 'text',
        vendorErrorCode: 'text',
    },
    StopTransaction: {
        idTag: 'text',
        meterStop: 'number',
        timestamp: 'Date',
        transactionId: 'number',
        reason: ['EmergencyStop', 'EVDisconnected', 'HardReset', 'Local', 'Other', 'PowerLoss', 'Reboot', 'Remote', 'SoftReset', 'UnlockCommand', 'DeAuthorized',],
        transactionData: 'List',
    },
    TriggerMessage: {
        requestedMessage: ['BootNotification', 'DiagnosticsStatusNotification', 'FirmwareStatusNotification', 'Heartbeat', 'MeterValues', 'StatusNotification',],
        connectorId: 'number',
    },
    UnlockConnector: {
        connectorId: 'number',
    },
    UpdateFirmware: {
        location: 'text',
        retries: 'number',
        retrieveDate: 'Date',
        retryInterval: 'number',
    },
};
