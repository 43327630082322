import {Component, Input, OnInit} from '@angular/core';
import {ExecuteWhitelistAddOrUpdate} from "@io-elon-common/frontend-api";
import {IEditForm} from "../../../../shared/components/dialogs/edit-dialog/edit-dialog.component";

@Component({
    selector: 'app-edit-whitelist-entry-dialog',
    templateUrl: './edit-whitelist-entry-dialog.component.html',
    styleUrls: ['./edit-whitelist-entry-dialog.component.scss']
})
export class EditWhitelistEntryDialogComponent implements  IEditForm<ExecuteWhitelistAddOrUpdate>, OnInit {

    @Input()
    public data!: ExecuteWhitelistAddOrUpdate;

    constructor(
    ) {
    }

    ngOnInit(): void {
    }

    validate(): string[] {
        if(this.data.evse <= 0) {
            return ["Es muss ein Ladepunkt ausgewählt werden."]
        }
        if(!this.data.key) {
            return ["Es muss ein Schlüssel ausgewählt werden."]
        }
        return [];
    }
}
