<h1>Performance</h1>

<div *ngIf="(performanceStats | async) as performanceStats; else loading">
    <table >
        <tr>
            <th [class]="{sortable: true, sortActive:sortByKey==='key'}" (click)="sortByKey='key'">Key</th>
            <th [class]="{sortable: true, sortActive:sortByKey==='count'}" (click)="sortByKey='count'">Count</th>
            <th [class]="{sortable: true, sortActive:sortByKey==='callsPerSecond'}" (click)="sortByKey='callsPerSecond'">call/s</th>
            <th [class]="{sortable: true, sortActive:sortByKey==='timeAvg'}" (click)="sortByKey='timeAvg'">Avg</th>
            <th [class]="{sortable: true, sortActive:sortByKey==='timeTotal'}" (click)="sortByKey='timeTotal'">Total</th>
            <th [class]="{sortable: true, sortActive:sortByKey==='timeMin'}" (click)="sortByKey='timeMin'">Min</th>
            <th [class]="{sortable: true, sortActive:sortByKey==='timeMax'}" (click)="sortByKey='timeMax'">Max</th>
            <th>History</th>
        </tr>
        <tr *ngFor="let infoName of keysOf(performanceStats)">
            <td>{{infoName}}</td>
            <td style="text-align: right; font-family: monospace">{{padCount(performanceStats[infoName].count)}}</td>
            <td style="text-align: right; font-family: monospace">{{padCount(performanceStats[infoName].callsPerSecond)}}</td>
            <td style="text-align: right; font-family: monospace">{{padValue(performanceStats[infoName].timeAvg, performanceStats[infoName].unit)}}</td>
            <td style="text-align: right; font-family: monospace">{{padValue(performanceStats[infoName].timeAvg * performanceStats[infoName].count, performanceStats[infoName].unit)}}</td>
            <td style="text-align: right; font-family: monospace">{{padValue(performanceStats[infoName].timeMin, performanceStats[infoName].unit)}}</td>
            <td style="text-align: right; font-family: monospace">{{padValue(performanceStats[infoName].timeMax, performanceStats[infoName].unit)}}</td>
            <td style="white-space: pre; font-family: monospace">{{formatHistory(performanceStats[infoName].history, performanceStats[infoName].unit)}}</td>
        </tr>
    </table>
</div>


<ng-template #loading>
    Loading...
</ng-template>
