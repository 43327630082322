<h1>Datenbank Performance Info</h1>

Filter:
<input [(ngModel)]="filter" (keyup.enter)="saveFilter()">
<br>
<mat-hint>$100 um alles mit weniger als 100 Einträgen auszublenden. Enter Drücken um Filterbegriff zu speichern.</mat-hint><br>
<ng-container *ngFor="let val of log">
    <a class="log-entry" (click)="filter = val" >{{val}}</a><br>
</ng-container>


<ng-container *ngIf="(stats | async) as stats">
    <table class="table" aria-label="Tabelle mit DB Statistik">
        <thead>
            <tr>
                <th></th>
                <th>read</th>
                <th>update</th>
                <th>create</th>
                <th>delete</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let s of (statsFilter | keyvalue); trackBy:trackByFn">
                <th>{{s.key}}</th>
                <td>{{s.value.read | number:'':'de-DE'}}</td>
                <td>{{s.value.update | number:'':'de-DE'}}</td>
                <td>{{s.value.create | number:'':'de-DE'}}</td>
                <td>{{s.value.delete | number:'':'de-DE'}}</td>
            </tr>
        </tbody>
    </table>
</ng-container>
