import {Injectable} from '@angular/core';
import {CacheManager, CacheUpdater} from './cacheManager';
import {NotificationEntry, Notifications} from '@io-elon-common/frontend-api';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {ApiHandler} from "./api-handler";
import {POLL_INTERVALS} from "../../app.module";

export const NOTIFICATION_LEVEL_FATAL = 100;
export const NOTIFICATION_LEVEL_ERROR = 200;
export const NOTIFICATION_LEVEL_WARN = 300;
export const NOTIFICATION_LEVEL_INFO = 400;
export const NOTIFICATION_LEVEL_DEBUG = 500;
export const NOTIFICATION_LEVEL_TRACE = 600;


@Injectable({
    providedIn: 'root'
})
export class NotificationService extends CacheManager {
    private activeNotifications: CacheUpdater<Notifications | undefined, any> = this.createManagedCache(() => true);

    public constructor(
        private readonly apiService: ApiService
    ) {
        super(POLL_INTERVALS.notifications);
    }

    public async clearNotifications(showAlerts = true): Promise<void> {
        await this.apiService.clearNotifications(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public getActiveNotifications(showAlerts = true): BehaviorSubject<Notifications | undefined> {
        return this.activeNotifications.getOrCreateGet(
            undefined,
            () => this.apiService.activeNotifications(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise() as Promise<any>
        ).data;
    }

    public async ackNotification(notification: NotificationEntry, showAlerts = true): Promise<void> {
        await this.apiService.ackNotification(showAlerts, notification.id, undefined, undefined, ApiHandler.customerId);
    }

    public clearCache(): void {
        super.clearCache();
        this.activeNotifications = this.createManagedCache(() => true);
    }
}
