import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Box, NoteRelatedObject, Rfid} from '@io-elon-common/frontend-api';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RfidService} from '../../service/rfid.service';

@Component({
  selector: 'app-rfid-details-view',
  templateUrl: './rfid-details-view.component.html',
  styleUrl: './rfid-details-view.component.scss'
})
export class RfidDetailsViewComponent implements OnInit, OnDestroy{
    public rfid!: BehaviorSubject<Rfid | undefined>
    private routerEventSubscription!: Subscription;
    protected readonly NoteRelatedObject = NoteRelatedObject;

    ngOnInit(): void {
        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
                console.log(id)
                if (id) {
                    this.rfid = this.rfidService.get(id);
                }
            }
        });
        const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
        this.rfid = this.rfidService.get(id);
    }

    public getBoxes(rfid: Rfid){
        if(rfid !== undefined) {
            const boxes: (Box & {link?: string})[] = [
                {value: rfid.name, description: 'RFID Karte', icon: 'sim_card'},
                {value: rfid.vehicle?.name ?? "---", description: 'Fahrzeug', icon: 'car'},
                {value: rfid.token ?? "--", description: 'Token', icon: 'id'},
            ]
            return boxes;
        }
    }

    constructor(
        private readonly route: ActivatedRoute,
        private readonly rfidService: RfidService,
        private readonly router: Router
    ) {
    }

    ngOnDestroy(): void {
        this.routerEventSubscription.unsubscribe();
    }
}
