<div class="d-flex flex-column gap-3" style="min-width: 300px">
    <div appearance="fill">
        {{data.name}}
    </div>
    <mat-form-field appearance="fill">
        <mat-label>Kommentar</mat-label>
        <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            placeholder="Kommentar"
            [(ngModel)]="data.comment"></textarea>
    </mat-form-field>
</div>




