import {Component, OnInit} from '@angular/core';
import {ApiHandler} from '../../../services/api-handlers/api-handler';
import {ExecuteEmailSettingsChange} from '@io-elon-common/frontend-api';
import {AuthService} from '../../../shared/guards/auth.service';
import {UserService} from '../../users/service/user.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageField, localStorageGet, localStorageSave} from '../../../shared/helper/typed-local-storage';

@Component({
    selector: 'app-settings-view',
    templateUrl: './settings-view.component.html',
    styleUrls: ['./settings-view.component.scss']
})
export class SettingsViewComponent implements OnInit {

    public checked = false;
    public disabled = false;
    public updateEmailSettings!: ExecuteEmailSettingsChange;
    public isDeveloper: boolean;

    public constructor(
        private readonly authService: AuthService,
        private readonly userService: UserService,
        private readonly toastr: ToastrService
    ) {
        this.isDeveloper = this.authService.isDeveloper();
    }

    ngOnInit(): void {
        this.updateEmailSettings = {
            email: this.authService.user?.email || "",
            emailAlertLevel: this.authService.user?.emailAlertLevel || 0,
            sendReservation: this.authService.user?.sendReservation || false
        }
    }

    public hasEmail(): boolean {
        return this.authService.user?.email !== undefined && this.authService.user?.email !== "";
    }

    public set pollEnabled(val: LocalStorageField["POLL_ENABLED"]) {
        ApiHandler.POLL_ENABLED = val === 'true';
        localStorageSave("POLL_ENABLED", val);
        this.toastr.info("Bitte Seite neue Laden!");
    }

    public get pollEnabled(): LocalStorageField['POLL_ENABLED'] {
        return localStorageGet('POLL_ENABLED', 'true');
    }

    public setPolling(newValue: boolean) {
        ApiHandler.POLL_ENABLED = newValue;
        localStorageSave("POLL_ENABLED", newValue ? "true" : 'false');
    }

    public setShowDevInfo(newValue: boolean) {
        localStorageSave("SHOW_DEV_ITEMS", newValue ? "true" : 'false');
    }

    public isDevInfo(): boolean {
        return localStorageGet("SHOW_DEV_ITEMS") === "true";
    }

    public async saveEmailSettings() {
        await this.userService.updateEmailSettings(this.updateEmailSettings);
        this.toastr.success("E-Mail Einstellungen gespeichert")
    }

    public async requestPasswordChange() {
        await this.userService.requestPasswordChange();
        this.toastr.success("Bitte überprüfen sie ihr E-Mail postfach");
    }

    public get vehicleDefaultSortOrder(): LocalStorageField['VEHICLE_DEFAULT_SORT_ORDER'] {
        return localStorageGet('VEHICLE_DEFAULT_SORT_ORDER', "name");
    }

    public set vehicleDefaultSortOrder(order: LocalStorageField['VEHICLE_DEFAULT_SORT_ORDER']) {
        localStorageSave('VEHICLE_DEFAULT_SORT_ORDER', order)
        this.toastr.success("Neue Reihenfolge gespeichert")
    }

    public get showDevWebRequests(): LocalStorageField['SHOW_DEV_WEB_REQUESTS'] {
        return localStorageGet("SHOW_DEV_WEB_REQUESTS") || "false"
    }

    public set showDevWebRequests(val: LocalStorageField["SHOW_DEV_WEB_REQUESTS"]) {
        localStorageSave("SHOW_DEV_WEB_REQUESTS", val);
    }

    public get defaultListEntriesCount(): LocalStorageField['DEFAULT_LIST_ENTRIES_COUNT'] {
        return localStorageGet("DEFAULT_LIST_ENTRIES_COUNT") || "20"
    }

    public set defaultListEntriesCount(val: LocalStorageField["DEFAULT_LIST_ENTRIES_COUNT"]) {
        localStorageSave("DEFAULT_LIST_ENTRIES_COUNT", val);
    }

    public get chargePlanAnimationVehicleLimit(): LocalStorageField['CHARGE_PLAN_ANIMATION_VEHICLE_LIMIT'] {
        return localStorageGet("CHARGE_PLAN_ANIMATION_VEHICLE_LIMIT") || "20"
    }

    public set chargePlanAnimationVehicleLimit(val: LocalStorageField["CHARGE_PLAN_ANIMATION_VEHICLE_LIMIT"]) {
        localStorageSave("CHARGE_PLAN_ANIMATION_VEHICLE_LIMIT", val);
    }
}
