import {Component, Input, OnInit} from '@angular/core';
import {Basis, DataValueNum, Evse} from '@io-elon-common/frontend-api';
import {num} from '../../../../../shared/helper/util-functions';
import {EvseService} from '../../../../evse/service/evse.service';
import {ToastrService} from 'ngx-toastr';
import {PhaseValues, SinglePhaseElectricity, ThreePhaseElectricity} from "../../../../../shared/helper/electricity";

@Component({
    selector: 'app-evse-tree-view-node',
    templateUrl: './evse-tree-view-node.component.html',
    styleUrls: ['./evse-tree-view-node.component.scss']
})
export class EvseTreeViewNodeComponent implements OnInit {

    @Input()
    public evse!: Evse;

    @Input()
    public basis!: Basis;

    @Input()
    public ampsMode: boolean = false;

    public constructor(
        private readonly toastr: ToastrService,
        private readonly evseService: EvseService,
    ) {
    }

    public ngOnInit(): void {
    }

    public getPwr(): ThreePhaseElectricity {
        const live = this.evse.liveData;

        return new ThreePhaseElectricity(
            SinglePhaseElectricity.fromAmpsAndVoltsAndCosPhi(live.evseI1?.val ?? 0, live.evseU1?.val ?? 235, live.evseCosPhi1?.val ?? 1),
            SinglePhaseElectricity.fromAmpsAndVoltsAndCosPhi(live.evseI2?.val ?? 0, live.evseU2?.val ?? 235, live.evseCosPhi2?.val ?? 1),
            SinglePhaseElectricity.fromAmpsAndVoltsAndCosPhi(live.evseI3?.val ?? 0, live.evseU3?.val ?? 235, live.evseCosPhi3?.val ?? 1)
        );
    }

    public getMaxAmps(): PhaseValues {
        return PhaseValues.threePhased(this.evse.maxI)
    }
    public getPlannedPwr(): PhaseValues {
        return PhaseValues.threePhased(this.evse.liveData.currentPlan.ampsCp);
    }

    public async handleEditEvse(evse: Evse): Promise<void> {
        await this.evseService.showEditDialog(evse, this.basis.powerSupplies);
    }

    public  async handleDeleteEvse(evse: Evse): Promise<void> {
        await this.evseService.showDeleteDialog(evse, {});
    }
}
