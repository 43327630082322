import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractTableComponent} from "../../../../shared/components/tables/AbstractTableComponent";
import {MatPaginator} from "@angular/material/paginator";
import {Fleet, UnknownOcppClient} from "@io-elon-common/frontend-api";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {DetailsUnknownCpiDialogComponent} from "../../dialogs/details-unknown-cpi/details-unknown-cpi-dialog.component";
import {FleetService} from "../../../vehicle/service/fleet.service";
import {BehaviorSubject} from "rxjs";
import {EvseService} from "../../../evse/service/evse.service";
import {ToastrService} from "ngx-toastr";
import {UnknownCpiService} from "../../service/unknown-cpi.service";

@Component({
    selector: 'app-unknown-cpi-table',
    templateUrl: './unknown-cpi-table.component.html',
    styleUrls: ['./unknown-cpi-table.component.scss']
})
export class UnknownCpiTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

    @Input()
    public unknownCpis!: UnknownOcppClient[];

    public displayedColumns: string[] = ['id', 'fleet', 'cpi', 'time', 'remote', 'model', 'serial', 'firmware', 'actions'];
    public dataSource = new MatTableDataSource<UnknownOcppClient>([]);
    public fleets!: BehaviorSubject<Fleet[] | undefined>;
    public readonly MAX_NUM = Number.MAX_SAFE_INTEGER;

    public constructor(
        private readonly dialog: MatDialog,
        private readonly fleetService: FleetService,
        private readonly evseService: EvseService,
        private readonly toastrService: ToastrService,
        private readonly unknownCpiService: UnknownCpiService
    ) {
        super()
    }

    public ngOnInit(): void {
        this.dataSource.data = this.unknownCpis;
        this.dataSource.paginator = this.paginator;
        this.fleets = this.fleetService.getAll();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.unknownCpis;
    }

    public getFleetName(client: UnknownOcppClient): string {
        const fleets = this.fleets.getValue()
        if(fleets) {
            return fleets.find(f => f.id === client.fleetId)?.name || "";
        }
        return "---";
    }

    public getModel(client: UnknownOcppClient): string {
        if(!client.bootNotification) {
            return "---"
        }
        try {
            const bn = JSON.parse(client.bootNotification);
            return bn.chargePointVendor + " " + bn.chargePointModel;
        } catch (e) {
            return "Fehler beim Erkennen, Info Dialog benutzen."
        }
    }

    public getSerial(client: UnknownOcppClient): string {
        if(!client.bootNotification) {
            return "---"
        }
        try {
            const bn = JSON.parse(client.bootNotification);
            const sn1 = bn.chargePointSerialNumber;
            const sn2 = bn.chargeBoxSerialNumber;

            if(!sn1 && !sn2) {
                return "---"
            }
            if(sn1 && !sn2) {
                return sn1;
            }
            if(!sn1 && sn2) {
                return sn2;
            }
            return "Charge Point: " + sn1 + ", Charge Box: " + sn2;
        } catch (e) {
            return "Fehler beim Erkennen, Info Dialog benutzen."
        }
    }

    public getFirmware(client: UnknownOcppClient): string {
        if(!client.bootNotification) {
            return "---"
        }
        try {
            const bn = JSON.parse(client.bootNotification);
            return bn.firmwareVersion || "---"
        } catch (e) {
            return "Fehler beim Erkennen, Info Dialog benutzen."
        }
    }

    public showDetails(client: UnknownOcppClient): void {
        this.dialog.open(DetailsUnknownCpiDialogComponent, {
            data: client,
            maxHeight: '90vh',
            width: '80vw'
        });
    }

    public async createStation(client: UnknownOcppClient) {
        const fleetId = client.fleetId || this.fleetService.selectedFleet.getValue();
        if(!fleetId) {
            this.toastrService.warning("Keine Flotte ausgewählt");
            return;
        }
        const fleet = this.fleets.getValue()?.find(f => f.id === fleetId);
        if(!fleet) {
            this.toastrService.warning("Kann Flotte mit ID " + fleetId + " nicht finden.")
            return;
        }
        await this.evseService.showNewDialogFromUnknownCpi(client, fleet.base);
    }

    public async deleteEntry(client: UnknownOcppClient) {
        await this.unknownCpiService.delete(client.id, {});
    }
}
