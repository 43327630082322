<div style="position: relative">
    <mat-form-field appearance="fill" style="position:absolute;width:1px;visibility:hidden;">
        <mat-date-range-input [formGroup]="range" [rangePicker]="dateRangePicker" [max]="today">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-date-range-picker #dateRangePicker (closed)="refresh()"></mat-date-range-picker>
    </mat-form-field>
    <button mat-button (click)="update(); dateRangePicker.open()" ><mat-icon>today</mat-icon></button>
</div>
