import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api-handlers/api.service';
import {BehaviorSubject} from 'rxjs';
import {CacheUpdater} from '../../../services/api-handlers/cacheManager';
import {POLL_INTERVALS} from '../../../app.module';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {IEditForm, TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditNoteComponent} from '../views/edit-note/edit-note.component';
import {
    Basis,
    Dongle,
    Evse,
    Meter,
    Note,
    NoteRelatedObject,
    Rfid,
    SolarSystem,
    Vehicle
} from '@io-elon-common/frontend-api';
import {ExecuteNoteAddOrUpdate} from '@io-elon-common/frontend-api/lib/model/models';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';

@Injectable({
    providedIn: 'root'
})
export class NoteService extends DialogHandler<Note, ExecuteNoteAddOrUpdate, ExecuteNoteAddOrUpdate, {}, DialogParamsWrapperType, void>  {
    private noteChache: CacheUpdater<Note[], { id: number, type: NoteRelatedObject }>;

    constructor(
       apiService: ApiService,
        toastr: ToastrService,
        dialog: MatDialog,
        dialogService: DialogService
    ) {
        super(apiService, "Note", toastr, dialog, dialogService, POLL_INTERVALS.evse);
        this.noteChache = this.createManagedCache((obj, arg) => obj.id.id === arg.id && obj.id.type === arg.type);

    }

    public getNotes(id: number, type: NoteRelatedObject): BehaviorSubject<Note[] | undefined> {
        return this.noteChache.getOrCreateGet({
                id,
                type
            },
            () => this.apiService.getNotesOfObject(true, id, type).toPromise().then(value => value.list)
        ).data;
    }

    protected getEditConfig(element: Note, editDialogConfigArgs: void): TDialogOptions<ExecuteNoteAddOrUpdate, IEditForm<ExecuteNoteAddOrUpdate>> | Promise<TDialogOptions<ExecuteNoteAddOrUpdate, IEditForm<ExecuteNoteAddOrUpdate>>> {
        return {
            headline: "Notiz bearbeiten",
            component: EditNoteComponent,
            executeCallback: editResult => this.update(element.id, editResult),
            editElement: {
                note: element.note,
                objectId: element.objectId,
                objectType: element.objectType
            }
        }
    }

    getNewConfig(newDialogConfigArgs: DialogParamsWrapperType): TDialogOptions<ExecuteNoteAddOrUpdate, IEditForm<ExecuteNoteAddOrUpdate>> | Promise<TDialogOptions<ExecuteNoteAddOrUpdate, IEditForm<ExecuteNoteAddOrUpdate>>> {
        return {
            headline: "Notiz anlegen",
            component: EditNoteComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                note: "",
                objectType: newDialogConfigArgs.type,
                objectId: newDialogConfigArgs.source.id
            }
        }
    }

}

export class DialogParamsWrapperType {
    source: Vehicle | Evse | Basis | Meter | Rfid | Dongle | SolarSystem;
    type: NoteRelatedObject

    constructor(
        source: Vehicle | Evse | Basis | Meter | Rfid | Dongle | SolarSystem,
        type: NoteRelatedObject
    ) {
       this.source = source;
        this.type = type;
    }
}
