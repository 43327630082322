import {Component} from '@angular/core';
import {SystemService} from "../../../services/api-handlers/system.service";
import {BehaviorSubject} from "rxjs";
import {ModbusConnection} from "@io-elon-common/frontend-api";

@Component({
    selector: 'app-modbus-connections',
    templateUrl: './modbus-connections.component.html',
    styleUrls: ['./modbus-connections.component.scss']
})
export class ModbusConnectionsComponent {
    public connections: BehaviorSubject<ModbusConnection[] | undefined>;

    constructor(
        private readonly systemService: SystemService
    ) {
        this.connections = this.systemService.getModbusConnections();
    }
    public trackByFn(idx: number, item: ModbusConnection): string {
        return item.host + item.port + item.unitId;
    }
}
