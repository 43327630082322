<div class="sidebar h-100" [class.closed]="closed">
    <div id="branding" class="nowrap">
        <div *ngIf="!closed" class="nowrap d-flex flex-row justify-content-between align-items-center">
            <a routerLink="/fleet">
            <div class="banner" style="box-sizing: unset"></div>
            <span class="io-elon mt-2">IO-ELON</span>
            </a>
            <button id="sidebar-toggler-close" class="c-blue-1 mt-2" mat-button (click)="setClose(true)"
                    matTooltip="Seitenmenü zu- oder aufklappen">
                <mat-icon class="scale-1x5">close</mat-icon>
            </button>
        </div>
        <div *ngIf="closed" class="nowrap pt-2">
            <button id="sidebar-toggler-open" class="c-blue-1" mat-button (click)="setClose(false)"
                    matTooltip="Seitenmenü zu- oder aufklappen">
                <mat-icon class="scale-1x5">menu</mat-icon>
            </button>
        </div>
    </div>

    <div class="io-nav d-flex flex-column gap-0 align-items-stretch" [class]="closed ? 'closed' : 'open'">
        <ng-container *ngFor="let m of menu">
            <a class="io-nav-item d-flex flex-row justify-content-start align-items-stretch" *ngIf="hasPermission(m.globalPermissions)" [routerLink]="m.link" routerLinkActive="active-link">
                <app-icon class="flex-grow-0" [type]="m.iconType" [icon]="m.icon"></app-icon>
                <span class="title flex-grow-1">{{m.text}}</span>
            </a>
        </ng-container>
    </div>
</div>
