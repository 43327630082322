<mat-form-field appearance="fill">
    <mat-label>{{label || 'Datum'}}</mat-label>
    <input matInput [matDatepicker]="dp" [formControl]="date" (dateChange)="dateChange()">
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp></mat-datepicker>
</mat-form-field>

<mat-form-field appearance="fill" class="ms-3">
    <mat-label>Uhrzeit</mat-label>
    <input matInput placeholder="Uhrzeit"
           [ngxTimepicker]="pickerStart"
           [format]="24"
           [value]="time"
    >
    <ngx-material-timepicker #pickerStart [preventOverlayClick]="true" (timeChanged)="timeChange($event)"></ngx-material-timepicker>
</mat-form-field>
