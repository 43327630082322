import {Injectable} from '@angular/core';
import {durationBetweenHumanReadable} from './util-functions';
import {RowData} from '../../modules/evse-health/tables/evse-health-history-table/evse-health-history-table.component';
import {Evse, HealthStatus} from '@io-elon-common/frontend-api';
import {HealthStatusHistoryEntry} from '@io-elon-common/frontend-api/lib/model/models';

export interface StatusData {
    status: HealthStatus,
    numberOfOccurrence: number,
    durationOfOccurrence: number
}

export interface HealthData<T> {
    obj: T,
    data: StatusData[],
    totalDuration: number
}

export interface PercentageHealthStatus {
    broken: number,
    working: number,
    maintenance: number
}


@Injectable({
    providedIn: 'root'
})

export class EvseHealthDataUtils {
    public getWorkingStatusEnum(): HealthStatus[] {
        return [HealthStatus.Working, HealthStatus.Attention];
    }
    public getFaultStatusEnum(): HealthStatus[] {
        return [HealthStatus.Broken, HealthStatus.OnRepair];
    }

    public getEvseHealthIcon(evse: Evse): string {
        if (evse.maintenanceEnabled) return "evse_maintenance";

        switch (evse.liveData.healthStatus?.val) {
            case "WORKING": return "evse_working";
            case "ATTENTION": return "evse_attention";
            case "BROKEN": return "evse_broken";
            case "MAINTENANCE": return "evse_maintenance";
            case "ON_REPAIR": return "evse_on_repair";
            default: return "evse-unkown";
        }
    }
    public updateHealthDataEntry<T>(entry: HealthStatusHistoryEntry, healthData: HealthData<T>): HealthData<T> {
        const endEnd = entry.tstEnd? entry.tstEnd : new Date().valueOf();
        const sessionDuration = endEnd - entry.tstStart;
        healthData.totalDuration += sessionDuration;

        const status = this.getHealthStatusData(healthData.data, entry.healthStatus);
        status.numberOfOccurrence += 1;
        status.durationOfOccurrence += sessionDuration;
        return healthData;
    }
    public getCalculatedFaultStatistic(data: StatusData[]) {
        let totalNumberOfFaults = 0;
        let totalDurationOfFaults = 0;
        this.getFaultStatusEnum().forEach(status => {
            const result = this.getHealthStatusData(data, status);
            totalNumberOfFaults += result.numberOfOccurrence;
            totalDurationOfFaults += result.durationOfOccurrence;
        });

        return {
            numberOfFaults: totalNumberOfFaults,
            durationOfFaults: totalDurationOfFaults
        }
    }
    public getCalculatedAvailabilityStatistic(data: StatusData[]) {
        const totalNumberOfAvailability = this.getHealthStatusData(data, HealthStatus.Working).numberOfOccurrence +
                                                  this.getHealthStatusData(data, HealthStatus.Maintenance).numberOfOccurrence +
                                                  this.getHealthStatusData(data, HealthStatus.Attention).numberOfOccurrence;


        const totalDurationOfAvailability = this.getHealthStatusData(data, HealthStatus.Working).durationOfOccurrence +
            this.getHealthStatusData(data, HealthStatus.Maintenance).durationOfOccurrence +
            this.getHealthStatusData(data, HealthStatus.Attention).durationOfOccurrence;

        return {
            numberOfAvailability: totalNumberOfAvailability,
            durationOfAvailability: totalDurationOfAvailability
        }
    }
    public getHealthStatusText(status: HealthStatus): string {
        switch (status) {
            case HealthStatus.Working:
                return "Funktionsfähig";
            case HealthStatus.Attention:
                return "Hinweise";
            case HealthStatus.Broken:
                return "Störung";
            case HealthStatus.Maintenance:
                return "Wartung(Geplant)";
            case HealthStatus.OnRepair:
                return "Störung(In Arbeit)";
            default:
                return "Unbekannt";
        }
    }
    public getHealthStatusColor(status: HealthStatus): string {
        switch (status) {
            case HealthStatus.Working:
                return "#5ec8a7";
            case HealthStatus.Attention:
                return "#5ec8a7";
            case HealthStatus.Broken:
                return "#DA0808";
            case HealthStatus.Maintenance:
                return "#808080";
            case HealthStatus.OnRepair:
                return "#DA0808";
            default:
                return "white";
        }
    }

    public getHealthStatusData(data: StatusData[], status: HealthStatus): StatusData {
        let result = data.find(d => d.status === status);
        if (!result) {
            result = {status: status, durationOfOccurrence: 0, numberOfOccurrence: 0};
            data.push(result);
        }
        return result;
    }
    public getEmptyStatus(): StatusData[] {
        const emptyStatus: StatusData[] = [];
        Object.values(HealthStatus).forEach(status => {
            emptyStatus.push({
                status: status,
                numberOfOccurrence: 0,
                durationOfOccurrence: 0
            });
        });
        return emptyStatus;
    }
    public getFaultDuration(data: StatusData[]): string {
        const overview = this.getCalculatedFaultStatistic(data);
        if (overview.numberOfFaults === 0) {
            return "---";
        }
        return durationBetweenHumanReadable(overview.durationOfFaults, false);
    }

    public getPercentages(data: StatusData[], totalDuration: number): PercentageHealthStatus{
        if(totalDuration <= 0){
            return {
                working: 0,
                broken: 0,
                maintenance: 0
            }
        }
        const working = (this.getHealthStatusData(data, HealthStatus.Working).durationOfOccurrence / totalDuration) * 100.0 +
            (this.getHealthStatusData(data, HealthStatus.Attention).durationOfOccurrence / totalDuration) * 100.0;
        const broken = (this.getHealthStatusData(data, HealthStatus.OnRepair).durationOfOccurrence / totalDuration) * 100.0 +
            (this.getHealthStatusData(data, HealthStatus.Broken).durationOfOccurrence / totalDuration) * 100.0;
        const maintenance = (this.getHealthStatusData(data, HealthStatus.Maintenance).durationOfOccurrence / totalDuration) * 100;
        return {
            maintenance,
            working,
            broken
        }
    }


    public generatePieChartStyle(statusPercentages: PercentageHealthStatus) {
        // generate pie chart of the % of the states ("maintenance", "working", "broken")
        let style = '';
        const enums = [
            HealthStatus.Working,
            HealthStatus.Broken,
            HealthStatus.Maintenance
        ];
        let currentPointer = 0;
        enums.forEach(status => {
            let percentage = 0;
            switch (status) {
                case HealthStatus.Working: {
                    percentage = statusPercentages.working;
                    break;
                }
                case HealthStatus.Broken: {
                    percentage = statusPercentages.broken;
                    break;
                }
                case  HealthStatus.Maintenance: {
                    percentage = statusPercentages.maintenance;
                    break;
                }
            }
            const color = this.getHealthStatusColor(status);
            const toEndDegree = percentage * 3.6;
            if (toEndDegree > 0) {
                const endDeg = toEndDegree + currentPointer;
                style += color + ' ' + currentPointer + 'deg ' + endDeg + 'deg,';
                currentPointer = endDeg + 1;
            }
        });
        if (style !== '') {
            style = style.substring(0, style.length - 1);
            style += ')';
            style = 'background: conic-gradient(' + style;
        }
        return {
            style,
        };
    }

    public getDataFooterRows<T>(resultData: HealthData<T>[]) {
        const footerRow: RowData<T> = {
            percentage: {
                broken: 0,
                maintenance: 0,
                working: 0
            },
            numberOfFaults: 0,
            durationOfFaults: 0,
            durationOfFaultsHumanReadable: "",
            pieChartStyle: "",
            totalDuration: 0
        };
        const dataRows: RowData<T>[] = [];
        let totalAvailabilityDuration = 0;
        let totalDuration = 0;
        let totalDurationOfFaults = 0;
        let totalNumberOfFaults = 0;
        const allEvseHealthStatus = this.getEmptyStatus();

        resultData.forEach(value => {
            const faults = this.getCalculatedFaultStatistic(value.data);
            const availability = this.getCalculatedAvailabilityStatistic(value.data);
            totalNumberOfFaults += faults.numberOfFaults;
            totalDurationOfFaults += faults.durationOfFaults;
            totalAvailabilityDuration += availability.durationOfAvailability;
            totalDuration += value.totalDuration;

            value.data.forEach(d => {
                const ad = allEvseHealthStatus.find(a => a.status === d.status);
                if (ad) {
                    ad.numberOfOccurrence += d.numberOfOccurrence;
                    ad.durationOfOccurrence += d.durationOfOccurrence;
                }
            });

            const percentage = this.getPercentages(value.data, value.totalDuration);
            const pieChartData = this.generatePieChartStyle(percentage);
            const faultData = this.getCalculatedFaultStatistic(value.data);

            const rowData: RowData<T> = {
                obj: value.obj,
                numberOfFaults: faultData.numberOfFaults,
                durationOfFaults: faultData.durationOfFaults,
                durationOfFaultsHumanReadable: this.getFaultDuration(value.data),
                pieChartStyle: pieChartData.style,
                percentage: percentage,
                totalDuration: value.totalDuration
            };
            dataRows.push(rowData);
        });

        const overviewPercentage = this.getPercentages(allEvseHealthStatus,totalDuration)
        const overviewPieChartData = this.generatePieChartStyle(overviewPercentage);
        footerRow.numberOfFaults = totalNumberOfFaults;
        footerRow.durationOfFaults = totalDurationOfFaults;
        footerRow.durationOfFaultsHumanReadable = durationBetweenHumanReadable(totalDurationOfFaults, false);
        footerRow.pieChartStyle = overviewPieChartData.style;
        footerRow.percentage = overviewPercentage;
        footerRow.totalDuration = totalDuration;

        return {
            dataRows, footerRow
        }
    }
}
