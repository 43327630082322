import {Component, OnInit} from '@angular/core';
import {PerformanceStats, SystemService} from '../../../services/api-handlers/system.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Component({
    selector: 'app-performance',
    templateUrl: './performance.component.html',
    styleUrls: ['./performance.component.scss']
})
export class PerformanceComponent implements OnInit {
    public performanceStats!: BehaviorSubject<PerformanceStats | undefined>;

    public sortByKey = "key"

    constructor(
        private systemService: SystemService
    ) {
    }

    ngOnInit(): void {
        this.performanceStats = this.systemService.performanceStats();
    }

    public keysOf(obj: any): string[] {
        if(this.sortByKey === "key") {
            return Object.keys(obj).sort();
        } else if(this.sortByKey === "timeTotal") {
            return Object.keys(obj).sort((a, b) => {
                return (obj[a].count * obj[a].timeAvg) - (obj[b].count * obj[b].timeAvg);
            });
        } else {
            return Object.keys(obj).sort((a, b) => {
                return (obj[a][this.sortByKey] as number) - (obj[b][this.sortByKey] as number);
            });
        }
    }

    public padCount(count: number): string {
        return (count+ "").padStart(6, " ");
    }

    public padValue(time: number, unit: string): string {
        time = Math.round(time *10) /10;
        return (time + unit).padStart(6, " ");
    }

    public formatHistory(timings: number[], unit: string): string {
        return timings.map(t => this.padValue(t, unit)).reverse().join(", ")
    }

    public cutTitle(title: string): string {
        return title.substr(0, 15).padStart(15, " ");
    }
}
