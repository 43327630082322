import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '../../../../services/api-handlers/customer.service';
import {AuthService} from '../../../../shared/guards/auth.service';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {Evse, Fleet, FleetHierarchy} from '@io-elon-common/frontend-api';
import {FleetHierarchyFleet} from '@io-elon-common/frontend-api/lib/model/fleetHierarchyFleet';
import {Subscription} from 'rxjs';
import {EvseService} from '../../../evse/service/evse.service';
import {EvseHealthDataUtils, HealthData, StatusData} from '../../../../shared/helper/evse-health-data-utils';
import {EvseHealthService} from '../../service/evseHealth.service';
import {RowData} from '../../tables/evse-health-history-table/evse-health-history-table.component';
import {AbstractFaultHistoryOverview, DateRange} from '../abstract-evse-fault-history';

@Component({
    selector: 'app-fleet-fault-statistics',
    templateUrl: './fleet-fault-statistics.component.html',
    styleUrl: './fleet-fault-statistics.component.scss'
})

export class FleetFaultStatisticsComponent extends AbstractFaultHistoryOverview implements OnInit, OnDestroy {
    public dataRows!: RowData<Fleet>[];
    public footerRow!: RowData<Fleet>;
    public displayColumns: string [] = [
        'fleetName',
        'numberOfEvses',
        'numberOfFaults',
        'durationOfFault',
        'percentageOfAvailability',
        "pieChart"
    ];
    private fleetIdSubscription: Subscription | undefined;
    private fleetHierarchySubscription: Subscription | undefined;
    private fleetHierarchy: FleetHierarchy | undefined;
    private selectedFleet: Fleet | undefined;
    private fleets: Fleet[] = [];

    constructor(private readonly customerService: CustomerService,
                private readonly authService: AuthService,
                private readonly evseService: EvseService,
                private readonly fleetService: FleetService,
                private readonly evseHealthDataUtils: EvseHealthDataUtils,
                private readonly evseHealthService: EvseHealthService) {
        super();
    }

    ngOnDestroy(): void {
        this.fleetIdSubscription?.unsubscribe();
        this.fleetHierarchySubscription?.unsubscribe();
    }

    async ngOnInit(): Promise<void> {
        this.fleetIdSubscription = this.fleetService.selectedFleet.subscribe(async id => {
            if (id !== undefined && !isNaN(id)) {
                this.selectedFleet = await this.fleetService.getPromise(id);
                await this.updateDataFleets();
            }
        });

        this.fleetHierarchySubscription = this.customerService.getFleetHierarchy(this.authService.user?.customerId || -1).subscribe(fh => {
            this.fleetHierarchy = fh;
            this.updateDataFleets();
        });
    }

    async generateData(dateRange: DateRange): Promise<void> {
        if (!dateRange?.start) {
            return;
        }
        const fleetEvseCount: any[] = [];
        if (this.fleets) {
            const result = (await this.evseHealthService.getEvsesHealthHistoryData(
                    dateRange.start.valueOf(), dateRange.end.valueOf())
            ).list;
            const evses = await this.evseService.getAllPromise();

            const resultData: HealthData<Fleet>[] = [];
            this.fleets.forEach(fleet => {
                const evsesInFleet: Evse[] = evses.filter(e => e.basis.id === fleet.base.id);
                const emptyStatus: StatusData[] = this.evseHealthDataUtils.getEmptyStatus();
                let healthData: HealthData<Fleet> = {obj: fleet, data: emptyStatus, totalDuration: 0};
                fleetEvseCount.push({
                    fleetId: fleet.id,
                    evseCount: evsesInFleet.length
                });
                evsesInFleet.forEach(evse => {
                    const filteredData = result.filter(entry => entry.evseId === evse.id);
                    filteredData.forEach(entry => {
                        healthData = this.evseHealthDataUtils.updateHealthDataEntry<Fleet>(entry, healthData);
                    });
                });
                resultData.push(healthData);
            });

            const data = this.evseHealthDataUtils.getDataFooterRows(resultData);
            data.footerRow.numberOfEvses = 0;
            data.dataRows.forEach(value => {
                const evseCount = fleetEvseCount.find(v => v.fleetId === value.obj?.id).evseCount ?? 0;
                value.numberOfEvses = evseCount;
                data.footerRow.numberOfEvses += evseCount;
            });

            this.dataRows = data.dataRows;
            this.footerRow = data.footerRow;
        }
    }

    private async updateDataFleets() {
        if (!this.fleetHierarchy || !this.selectedFleet) {
            this.footerRow = this.clearData(this.footerRow);
            this.dataRows = [];
            return;
        }
        this.fleets = [];
        const fleets = this.findOtherFleets(this.fleetHierarchy, this.selectedFleet.id);

        if (fleets) {
            const allFleets = await this.fleetService.getAllPromise();
            fleets.forEach(fleet => {
                const f = allFleets.find(f => f.id === fleet.id);
                if (f) {
                    this.fleets.push(f);
                }
            });
        } else {
            console.error('unable to find fleets');
        }
        this.generateData(this.range);
    }

    private findOtherFleets(fh: FleetHierarchy, fleetId: number | undefined): FleetHierarchyFleet[] | undefined {
        if (fh.fleets.some(f => f.id === fleetId)) {
            return fh.fleets;
        }
        for (const sfh of fh.subCustomers) {
            const cId = this.findOtherFleets(sfh, fleetId);
            if (cId) {
                return cId;
            }
        }
        return undefined;
    }

}
