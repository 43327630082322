import {Component, Input, OnInit} from '@angular/core';
import {
    ExecuteSolarPanelAddOrUpdate,
    SolarSystem
} from "@io-elon-common/frontend-api";
import {FormControl, Validators} from "@angular/forms";
import {IEditForm} from "../../../../shared/components/dialogs/edit-dialog/edit-dialog.component";

@Component({
  selector: 'app-edit-solar-panel-dialog',
  templateUrl: './edit-solar-panel-dialog.component.html',
  styleUrls: ['./edit-solar-panel-dialog.component.scss']
})
export class EditSolarPanelDialogComponent implements OnInit, IEditForm<ExecuteSolarPanelAddOrUpdate> {

    @Input()
    public possibleSolarSystems!: SolarSystem[];

    @Input()
    public data!: ExecuteSolarPanelAddOrUpdate;

    public latFormControl = new FormControl("",[Validators.min(-90), Validators.max(90)]);
    public lonFormControl = new FormControl("",[Validators.min(-180), Validators.max(180)]);
    public tiltFormControl = new FormControl("",[Validators.min(0), Validators.max(90)]);
    public azimuthFormControl = new FormControl("",[Validators.min(-180), Validators.max(180)]);

    constructor() { }

    ngOnInit(): void {
    }

    validate(): string[] {
        const errors: string[] = [];
        if (!this.latFormControl.valid) {
            errors.push("Breitengrad ungültig");
        }
        if (!this.lonFormControl.valid) {
            errors.push("Längengrad ungültig");
        }
        if (!this.tiltFormControl.valid) {
            errors.push("Neigung ungültig");
        }
        if (!this.azimuthFormControl.valid) {
            errors.push("Azimuth ungültig");
        }
        return errors;
    }
}
