import {Component, Input, OnInit} from '@angular/core';
import {Evse, Meter, NotificationEntry, Vehicle} from '@io-elon-common/frontend-api';
import {EvseService} from '../../../../modules/evse/service/evse.service';
import {VehicleService} from '../../../../modules/vehicle/service/vehicle.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {NotificationService} from '../../../../services/api-handlers/notification.service';
import {MeterService} from "../../../../modules/meter/service/meter.service";

@Component({
    selector: 'app-notification-menu-line',
    templateUrl: './notification-menu-line.component.html',
    styleUrls: ['./notification-menu-line.component.scss']
})
export class NotificationMenuLineComponent implements OnInit {

    @Input()
    public notification!: NotificationEntry;
    public evse!: BehaviorSubject<Evse | undefined>;
    public vehicle!: BehaviorSubject<Vehicle | undefined>;
    public meter!: BehaviorSubject<Meter | undefined>;

    constructor(
        private readonly evseService: EvseService,
        private readonly vehicleService: VehicleService,
        private readonly meterService: MeterService,
        private readonly notificationService: NotificationService
    ) {
    }

    ngOnInit(): void {
        if(this.notification.evse != null) {
            this.evse = this.evseService.get(this.notification.evse, false);
        }
        if(this.notification.vehicle != null) {
            this.vehicle = this.vehicleService.get(this.notification.vehicle, false);
        }
        if(this.notification.meter != null) {
            this.meter = this.meterService.get(this.notification.meter, false);
        }
    }

    public ignore(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.notificationService.ackNotification(this.notification);
    }

}
