import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../guards/auth.service';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {FleetHierarchy, NotificationEntry, Notifications, SystemInfo, User} from '@io-elon-common/frontend-api';
import {FleetService} from '../../../modules/vehicle/service/fleet.service';
import {SystemService} from '../../../services/api-handlers/system.service';
import {
    NOTIFICATION_LEVEL_ERROR,
    NOTIFICATION_LEVEL_INFO,
    NOTIFICATION_LEVEL_TRACE,
    NOTIFICATION_LEVEL_WARN,
    NotificationService
} from '../../../services/api-handlers/notification.service';
import {VERSION} from '../../../../main';
import {SolarSystemService} from '../../../modules/solar/service/solar-system.service';
import {ToastrService} from 'ngx-toastr';
import {ThemesService} from '../../../services/themes.service';
import {CustomerService} from 'src/app/services/api-handlers/customer.service';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {ELinkLocation, getMenu, IMenuEntry} from "../../../app-routing.module";
import {UserService} from "../../../modules/users/service/user.service";

type FleetSelectorType = {
    customerId: number,
    customerName: string,
    margin: number,
    fleets: Array<{
        name: string
        id: number
    }>,
    visibility: boolean
};

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public versionFrontend: string = VERSION;

    public menuAll = getMenu(ELinkLocation.TOP_RIGHT_MENU);
    public menuDev = getMenu(ELinkLocation.TOP_RIGHT_MENU_DEV_PART);


    shadow = false;
    user!: BehaviorSubject<User | null>;

    systemInfo!: Promise<SystemInfo>;
    notifications!: Observable<Notifications | undefined>
    fleetId = -1
    fleetHierarchy!: BehaviorSubject<FleetHierarchy | undefined>
    isReseller = false;
    fleetHierarchyConvertedCache: [FleetHierarchy, FleetSelectorType[]] | undefined
    queryString = "";
    keyword!: string;
    isUserDev: boolean = false;

    constructor(
        public readonly authService: AuthService,
        private readonly router: Router,
        public readonly fleetService: FleetService,
        public readonly systemService: SystemService,
        private readonly notificationService: NotificationService,
        private readonly solarSystemService: SolarSystemService,
        private readonly toastrService: ToastrService,
        private readonly themesService: ThemesService,
        private readonly customerService: CustomerService,
        private readonly userService: UserService
    ) {

    }


    public rotateTheme() {
        this.themesService.rotateTheme();
    }

    async ngOnInit() {
        this.fleetHierarchy = this.customerService.getFleetHierarchy(this.authService.user?.customerId || -1);
        const cId = this.authService.currentUserSubj.getValue()?.customerId;
        if(typeof cId === "number") {
            this.customerService.getPromise(cId).then(c => this.isReseller = c.isReseller && this.authService.isAdmin() || false)
        }

        const allNotifications = this.notificationService.getActiveNotifications();
        this.notifications = allNotifications.pipe(
            map(notifications=> {
                if (notifications) {
                    return {
                        activeNotifications : notifications.activeNotifications.filter(notification => notification.level < NOTIFICATION_LEVEL_INFO),
                        mutedNotifications: notifications.mutedNotifications.filter(notification => notification.level < NOTIFICATION_LEVEL_INFO)
                    }
                } else {
                    return undefined;
                }
            }
        ));

        this.systemInfo = this.systemService.getSystemInfo();
        this.user = this.authService.currentUserSubj;

        const fleets = (await this.fleetService.getAllPromise()).sort((a,b) => a.name.localeCompare(b.name));

        let selectedFleet = this.fleetService.selectedFleet.getValue();
        if (selectedFleet === undefined || !fleets.find(x => x.id == selectedFleet)) {
           this.fleetService.setSelectedFleet(fleets[0].id);
           this.fleetId = fleets[0].id;
        } else {
            this.fleetId = selectedFleet;
        }
        this.isUserDev = this.authService.isDeveloper();
    }

    public trackByCustomerId<T extends {customerId: number | string}>(idx: number, val: T): number | string {
        return val.customerId;
    }

    public trackById<T extends {id: number | string}>(idx: number, val: T): number | string {
        return val.id;
    }

    public getCustomerList(fleetHierarchy: FleetHierarchy): FleetSelectorType[] {
        if(this.fleetHierarchyConvertedCache && this.fleetHierarchyConvertedCache[0] === fleetHierarchy){
            return this.fleetHierarchyConvertedCache[1];
        }

        const ret: FleetSelectorType[] = [];

        function addToRet(sc: FleetHierarchy, depth: number, isReseller: boolean, isUserDev: boolean): boolean {
            sc.fleets = sc.fleets.sort((a,b) => a.name.localeCompare(b.name));
            sc.subCustomers = sc.subCustomers.sort((a,b) =>  a.customer.name.localeCompare(b.customer.name));

            const fleetSelectorType = {
                customerId: sc.customer.id,
                customerName: sc.customer.name,
                margin: depth * 16,
                fleets: sc.fleets,
                visibility: false
            };
            ret.push(fleetSelectorType);
            let showFleet = isReseller || isUserDev || sc.fleets.length > 0;
            sc.subCustomers.forEach(x => {
                const hasFleet = addToRet(x, depth + 1, isReseller, isUserDev);
                if (hasFleet) {
                    showFleet = true;
                }
            });
            fleetSelectorType.visibility = showFleet;
            return showFleet;
        }
        addToRet(fleetHierarchy, 0, this.isReseller, this.isUserDev);

        this.fleetHierarchyConvertedCache = [fleetHierarchy, ret];
        return ret;
    }

    public async refreshSolarPanels(): Promise<void> {
        this.toastrService.info("Daten werden neu Abgerufen...")
        await this.solarSystemService.refreshData();
    }

    onLogout() {
        this.isReseller = false;
        ApiHandler.customerId = undefined;
        this.authService.logout();
        this.user = this.authService.currentUserSubj;
        this.fleetHierarchy = new BehaviorSubject<FleetHierarchy | undefined>(undefined);
        this.fleetHierarchyConvertedCache = undefined;
        this.notifications = new Observable<Notifications | undefined>(undefined);

        this.fleetService.resetSelectedFleet();
        this.notificationService.clearCache();
        this.authService.clearCache();

    }

    public async clearNotifications(): Promise<void> {
        try{
            await this.notificationService.clearNotifications();
            this.toastrService.success("Bestehende Meldungen werden beim nächsten Auftreten wieder erscheinen", "Wird gelöscht");
        } catch (e) {
            this.toastrService.warning("Fehler beim löschen der Meldungen");
        }
    }

    public getBadgeClassObj(notificationEntries: NotificationEntry[]): any {
        const minLevel = notificationEntries.reduce((previousValue, currentValue) => Math.min(previousValue, currentValue.level), 1000);
        if(minLevel <= NOTIFICATION_LEVEL_ERROR) {
            return {
                iblack: true,
                rotate: true,
                'badge-parent': true,
                error: true
            };
        }
        if(minLevel <= NOTIFICATION_LEVEL_WARN) {
            return {
                iblack: true,
                rotate: true,
                'badge-parent': true,
                warn: true
            };
        }
        if(minLevel <= NOTIFICATION_LEVEL_INFO) {
            return {
                iblack: true,
                rotate: true,
                'badge-parent': true,
                info: true
            };
        }
        if(minLevel <= NOTIFICATION_LEVEL_TRACE) {
            return {
                iblack: true,
                rotate: true,
                'badge-parent': true,
                debug: true
            };
        }
        return {
            iblack: true,
            rotate: true,
            'badge-parent': true,
            inactive: true
        };
    }


    public async newBug() {
        const url = await this.newIssue(1);

        window.open(
            url
            + "&issue[custom_field_values][4]="
            + (await this.systemInfo).version,
            "_blank");
    }


    public async newEnhancement() {
        const url = await this.newIssue(12);
        window.open(
            url
            +"%0AVersion:%0A- "
            + (await this.systemInfo).version,
            "_blank");
    }


    async newIssue(trackerId: number): Promise<string> {

        const selectedFleet = this.fleetService.selectedFleet.getValue();
        console.log(selectedFleet);

        const fleet = await this.fleetService.getPromise(this.fleetService.selectedFleet.getValue() || 0);
        const customer = (await this.customerService.getAllPromise())[0];
        const user = this.user.getValue();

        console.log(fleet);


        const link = [
            "https://redmine.iodynamics.de/projects/io-chargebus/issues/new?",
            "issue[tracker_id]=",
            trackerId,
            "&issue[description]=",
            "Customer:%0A- [",
            customer?.id,
            "] ",
            customer?.name,
            "%0AFlotte:%0A- [",
            fleet.id,
            "] ",
            fleet.name,
            "%0AUser:%0A- [",
            user?.id,
            "] ",
            user?.name,
            "%0AURL:%0A- ",
            window.location.href,
            "%0AUhrzeit:%0A- ",
            new Date().toLocaleString()];


        return link.join("");

    }

    setSelectedFleet(fleetId: number) {
        this.fleetId = fleetId;
        function findCustomerId(fh: FleetHierarchy, fleetId: number): number | undefined {
            if(fh.fleets.some(f => f.id === fleetId)) {
                return fh.customer.id;
            }
            for(const sfh of fh.subCustomers) {
                const cId = findCustomerId(sfh, fleetId);
                if(typeof cId === "number"){
                    return cId;
                }
            }
            return undefined;
        }

        const fh = this.fleetHierarchy.getValue();
        if(!fh){
            throw new Error("No Hierarchy")
        }
        const url = this.router.url.split('?')[0];
        const customerId = findCustomerId(fh, fleetId);
        const userCustomerId = this.authService.user?.customerId;
        const queryParams:any = {fid: fleetId};
        this.queryString = "?fid=" + fleetId;
        const apiCustomerId = ApiHandler.customerId;

        if (userCustomerId !== customerId || (apiCustomerId != undefined && apiCustomerId !== customerId)) {
            queryParams["cid"] = customerId;
            this.queryString += "&cid=" + customerId;
        }
        console.log("user: "+userCustomerId+" api: "+ApiHandler.customerId+" fleet: "+customerId);
        console.log("Fleet has been selected, nav to " + url + " params: " + JSON.stringify(queryParams));
        this.router.navigate(
            [url.split("/")[1]],
            {
                queryParams
            }
        );
    }

    selectedCustomerName(): string {
        const selectedFleetId = this.fleetService.selectedFleet.getValue();
        const fleetHierarchy = this.fleetHierarchy.getValue();
        if (fleetHierarchy === undefined) {
            return "Standort";
        }
        const list = this.getCustomerList(fleetHierarchy);
        const fleetSelector = list.find(e=> e.fleets.some(f=>f.id===selectedFleetId))
        if (fleetSelector=== undefined) {
            return "Standort";
        }
        return fleetSelector?.customerName;
    }


    async editUser() {
        const userValue = this.user.getValue();
        if (userValue != null) {
            await this.userService.showEditDialog(userValue);
        }
    }

    async editCustomer(customerInfo: FleetSelectorType) {
        const c = await this.customerService.getPromise(customerInfo.customerId);
        await this.customerService.showEditDialog(c);
    }


    async createCustomer() {
        await this.customerService.showNewDialog();
    }

    public textChanged(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.gotToSearchPage();
        }
    }

    public gotToSearchPage() {
        const searchString = this.keyword ?? "";
        this.keyword = "";
        this.router.navigate(['search'], {
            queryParams: { search: encodeURIComponent(searchString) },
            queryParamsHandling: 'merge'
        });
    }

    public checkPermission(menuEntriy: IMenuEntry) {
        return this.authService.hasOneOfGlobalPermissions(...menuEntriy.globalPermissions) ||
            (menuEntriy.fleetPermission??[]).some(p => this.authService.hasFleetPermission(p, this.fleetId));
    }

    public getFleetName(id: number, list = this.fleetHierarchy.getValue()): string {
        if(!list) {
            return "---";
        }

        for(const f of list.fleets) {
            if(f.id === id) {
                return f.name;
            }
        }

        for(const s of list.subCustomers) {
            const name = this.getFleetName(id, s);
            if(name) {
                return name;
            }
        }

        return "---"
    }

    public isOverflow (element: HTMLElement) {
        return element.offsetHeight < element.scrollHeight ||
            element.offsetWidth < element.scrollWidth;
    }
    public getMaxWidth(customer: FleetSelectorType, isFleet: boolean): number {
        const width = 250 - customer.margin;
        if(isFleet)
            // reduce width of fleet by on depth (16)
            return width - 16;
        else return width;
    }
}
