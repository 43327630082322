<div class="graph-date-indicator">{{dateIndicatorText()}}</div>
<div class="line-graph" style="position: relative; width:100%; z-index: 998">

</div>
<div class="loading" *ngIf="loading">
    <mat-spinner>

    </mat-spinner>
</div>

