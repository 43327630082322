
<div style="position: relative" class="frame">
    <div class="inputs">
        <input type="number" class="input-min" matTooltip="Min einstellen" [max]="max / 1000 - 1" [ngModel]="min / 1000" (ngModelChange)="min = 1000 * $event; updateScale()">
        <input type="number" class="input-max" matTooltip="Max einstellen" [min]="min / 1000 + 1" [ngModel]="max / 1000" (ngModelChange)="max = 1000 * $event; updateScale()">
    </div>
    <div class="canvas-container">
        <canvas width="900px" height="380px" class="load-graph-canvas"></canvas>
    </div>
    <div class="help">
        <app-graph-help-icon [options]="['dynamicLoadMouseClickDetails']" [vAlign]="'bottom'"></app-graph-help-icon>
        <kbd [matMenuTriggerFor]="menuCtrl">Strg</kbd> <kbd [matMenuTriggerFor]="menuShift">⇧ Shift</kbd> <kbd [matMenuTriggerFor]="menuAlt">Alt</kbd> <kbd [matMenuTriggerFor]="menuClick">🖰  Click</kbd></div>
</div>
<mat-menu #menuCtrl="matMenu">
    Bei gedrückter <kbd>Strg</kbd> Taste, werden alle Phasen zusammen gelegt<br/>
    <img src="../../../../../assets/img/help/dynamic_load/ctrl.webp">
</mat-menu>
<mat-menu #menuShift="matMenu">
    Bei gedrückter <kbd>⇧ Shift</kbd> Taste, können die Phasen unabhängig voneinander bewegt werden<br/>
    <img src="../../../../../assets/img/help/dynamic_load/shift.webp">
</mat-menu>
<mat-menu #menuAlt="matMenu">
    Bei gedrückter <kbd>Alt</kbd> Taste, wird der Rasterfang in der Zeit-Achse entfernt<br/>
    <img src="../../../../../assets/img/help/dynamic_load/alt.webp">
</mat-menu>
<mat-menu #menuClick="matMenu">
    Durch einen Linksklick <kbd>🖰  Click</kbd> auf eine freie Fläche wird ein Punkt erzeugt. Bei einem Klick auf einen Punkt, wird dieser entfernt.<br/>
    <img src="../../../../../assets/img/help/dynamic_load/click.webp">
</mat-menu>
