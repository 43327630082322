import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FreeToMoveDataSource} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import {FreeToMoveService} from '../../service/free-to-move.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-free-to-move-table',
  templateUrl: './free-to-move-table.component.html',
  styleUrls: ['./free-to-move-table.component.scss']
})
export class FreeToMoveTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input()
    public freeToMoves!: FreeToMoveDataSource[];

    displayedColumns: string[] = ['id', 'mail', 'vin', 'vehicle', 'lastMsg', 'actions'];
    dataSource = new MatTableDataSource<FreeToMoveDataSource>([]);

    constructor(
        private readonly freeToMoveService: FreeToMoveService
    ) {
        super();
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.freeToMoves);
        this.dataSource.paginator = this.paginator;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.freeToMoves;
    }

    public async edit(freeToMove: FreeToMoveDataSource): Promise<void> {
        await this.freeToMoveService.showEditDialog(freeToMove);
    }

    public async delete(freeToMove: FreeToMoveDataSource): Promise<void> {
        await this.freeToMoveService.showDeleteDialog(freeToMove, {});
    }
}
