import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EvseTeaser, ReservationInstance, VehicleKey} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {VehicleKeyService} from '../../service/vehicle-key.service';
import {AuthService} from '../../../../shared/guards/auth.service';
import {defaultTo} from '../../../../shared/helper/util-functions';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Subscription} from 'rxjs';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {createByFieldComparator} from '../../../../shared/helper/comparatorsFactory';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';

const RESERVATION_PREVIEW_TIME = 1000 * 60 * 60 * 48 // 2 Tage

@Component({
    selector: 'app-vehicle-key-table',
    templateUrl: './vehicle-key-table.component.html',
    styleUrls: ['./vehicle-key-table.component.scss']
})
export class VehicleKeyTableComponent extends AbstractTableComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() vehicleKeys!: VehicleKey[];
    private reservations: Array<BehaviorSubject<ReservationInstance[] | undefined>> = [];
    private reservationsSubscriptions: Array<Subscription> = [];

    public displayedColumns: string[] = ['icon', 'vehicle', 'currentLocation', 'currentLocationUser', 'giveOutTime', 'expectedReturnTime', 'actions'];

    public dataSource = new MatTableDataSource<VehicleKey>([]);
    public selection = new SelectionModel<VehicleKey>(true, []);
    public selectedIndex = 0;

    public constructor(
        private readonly vehicleKeyService: VehicleKeyService,
        private readonly authService: AuthService,
        private readonly toastr: ToastrService,
        private readonly vehicleService: VehicleService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.vehicleKeys);
        this.dataSource.paginator = this.paginator;
    }

    public ngOnChanges(): void {
        this.dataSource.data = this.vehicleKeys

        const newSubscriptions: Subscription[] = [];
        const newReservations: Array<BehaviorSubject<ReservationInstance[] | undefined>> = [];

        this.vehicleKeys.forEach(vk => {
            if(newReservations[vk.vehicle.id]) {
                return;
            }
            newReservations[vk.vehicle.id] = this.vehicleService.getReservationsByTime(
                vk.vehicle.id,
                Date.now(),
                Date.now() + RESERVATION_PREVIEW_TIME
            );
            newSubscriptions[vk.vehicle.id] = newReservations[vk.vehicle.id].subscribe(val=>{
                if(val) {
                    this.reservations[vk.vehicle.id] = newReservations[vk.vehicle.id];
                }
            });
        });

        this.reservationsSubscriptions.forEach(s => s.unsubscribe())
        this.reservationsSubscriptions = newSubscriptions
    }

    public ngOnDestroy(): void {
        this.reservationsSubscriptions.forEach(s => s.unsubscribe())
    }

    public selectRow(row: EvseTeaser) {
        this.selectedIndex = row.id;
    }

    public async handOutKeyAuto(key: VehicleKey) {
        const reservation = this.nextReservation(key);
        if(!reservation) {
            this.toastr.warning("Keine Reservierung zugeordnet!", "Fehler");
            return;
        }

        await this.vehicleKeyService.update(key.id, {
            giveOutTime: Date.now(),
            expectedReturnTime: reservation.end,
            vehicleId: key.vehicle.id,
            currentLocation: "Beim Fahrer",
            currentLocationUserId: defaultTo(reservation.reservation.driver?.id, undefined)
        });
        this.toastr.success("Schlüssel herausgegeben");
    }

    public async handOutKey(key: VehicleKey) {
        const reservation = this.nextReservation(key);
        await this.vehicleKeyService.showGiveOutDialog(key, reservation);
    }

    public async keyReturned(key: VehicleKey) {
        await this.vehicleKeyService.update(key.id, {
            giveOutTime: undefined,
            expectedReturnTime: undefined,
            vehicleId: key.vehicle.id,
            currentLocation: "Schlüsselschrank",
            currentLocationUserId: defaultTo(this.authService.user?.id, undefined)
        });
        this.toastr.success("Schlüssel zurückgegeben");
    }

    public async edit(key: VehicleKey) {
        await this.vehicleKeyService.showEditDialog(key)
    }

    public async delete(key: VehicleKey) {
        await this.vehicleKeyService.showDeleteDialog(key, {});
    }

    public nextReservation(key: VehicleKey): ReservationInstance | undefined {
        return this.reservations[key.vehicle.id]?.getValue()?.sort(createByFieldComparator('start'))[0];
    }
}
