import {Component, Input, OnInit} from '@angular/core';
import {
    ExecuteVehicleAddOrUpdateSTecData
} from "@io-elon-common/frontend-api/lib/model/executeVehicleAddOrUpdateSTecData";
import {STecAccountService} from "../../../../s-tec-accounts/service/s-tec-account.service";
import {STecAccount, STecVehicleList, STecVehicleListItem} from "@io-elon-common/frontend-api";
import {ToastrService} from "ngx-toastr";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-edit-ds-s-tec',
    templateUrl: './edit-ds-s-tec.component.html',
    styleUrls: ['./edit-ds-s-tec.component.scss']
})
export class EditDsSTecComponent implements OnInit {

    @Input()
    public connection!: ExecuteVehicleAddOrUpdateSTecData
    @Input()
    public accounts!: STecAccount[]

    public vehicles?: STecVehicleList
    public unusedVehicles!: STecVehicleListItem[]
    public usedVehicles!: STecVehicleListItem[]
    public loadingVehicles = false;
    public showDetails = false;

    constructor(
        private readonly sTecService: STecAccountService,
        private readonly toastService: ToastrService,
        private readonly sanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.updateVehicleList();
    }

    get account(): STecAccount | undefined {
        return this.accounts.find(a => a.id === this.connection.accountId);
    }

    get accountId(): number {
        return this.connection.accountId;
    }

    set accountId(id: number) {
        this.connection.accountId = id;
        this.updateVehicleList();
    }

    get vehicle(): STecVehicleListItem | undefined {
        return this.vehicles?.list.find(v => v.troId === this.connection.troId);
    }

    private updateVehicleList() {
        if(this.connection.accountId !== -1) {
            this.loadingVehicles = true;
            this.sTecService.getVehicleList(this.connection.accountId)
            .then(l => {
                this.vehicles = l;
                this.unusedVehicles = l?.list.filter(value => !value.used).sort((a, b) => {
                   return Number(a.troId) - Number(b.troId);
                });
                this.usedVehicles = l?.list.filter(value => value.used).sort((a, b) => {
                    return Number(a.troId) - Number(b.troId);
                });
            })
            .catch(() => this.toastService.warning('Liste der Fahrzeuge konnte nicht geladen werden.'))
            .finally(() => this.loadingVehicles = false);
        }
    }


}
