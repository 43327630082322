import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api-handlers/api.service';
import {CacheManager, CacheUpdater} from '../../../services/api-handlers/cacheManager';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";
import {HealthStatusHistory, HealthStatusHistoryEntry} from '@io-elon-common/frontend-api/lib/model/models';
import {BehaviorSubject} from 'rxjs';
import {EvseHealthHistoryRequest} from '@io-elon-common/frontend-api/lib/model/evseHealthHistoryRequest';

@Injectable({
    providedIn: 'root'
})
export class EvseHealthService extends CacheManager {
    private evseHealthHistoryCache: CacheUpdater<HealthStatusHistoryEntry[], { id: number, start: number, end: number }>

    public constructor(
        private readonly apiService: ApiService
    ) {
        super(POLL_INTERVALS.charging_sessions);
        this.evseHealthHistoryCache = this.createManagedCache(
            (obj, id) => obj.id.start === id.start && obj.id.end === id.end && obj.id.id === id.id);
    }

    public getEvsesHealthHistoryData(start: number, end: number, showAlerts = true): Promise<HealthStatusHistory> {
        return this.apiService.getEvsesHealthStatusHistoryInTimeRange(showAlerts, start, end, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public getEvseHealthStatusFromEvse(evseId: number, start: number, end: number): BehaviorSubject<HealthStatusHistoryEntry[] | undefined> {
        return this.evseHealthHistoryCache.getOrCreateGet(
            {
                id: evseId,
                start,
                end
            },
            ()=>this.apiService.getEvseHealthStatusHistoryInTimeRange(true, evseId, start, end, undefined, undefined, ApiHandler.customerId).toPromise().then(list => list.list)
        ).data;
    }

    public getEvseHealthStatusFromEvseForState(evseId: number, start: number, end: number, evseHealthRequest: EvseHealthHistoryRequest): BehaviorSubject<HealthStatusHistoryEntry[] | undefined> {
        return this.evseHealthHistoryCache.getOrCreateGet(
            {
                id: evseId,
                start,
                end,
            },
            ()=>this.apiService.getEvseHealthStatusHistoryForState(true, evseId, start, end, undefined, undefined, ApiHandler.customerId, evseHealthRequest).toPromise().then(list => list.list)
        ).data;
    }
}
