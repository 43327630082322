import { Component, Input, OnInit } from '@angular/core';
import {
    ExecuteEvseAddOrUpdate,
    PowerSupply,
    SystemInfoActorArg,
    SystemInfoActorInfo,
    SystemInfoEvseType
} from '@io-elon-common/frontend-api';
import { SystemService } from '../../../../services/api-handlers/system.service';
import { IEditForm } from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {FormBuilder} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {isNumeric} from 'rxjs/internal-compatibility';
import {EvseActorArgs} from '@io-elon-common/frontend-api/lib/model/evseActorArgs';

interface StateGroupManufacturer {
    //For Autocomplete
    manufacturer: string;
    evses: Array<SystemInfoEvseType>
}

@Component({
    selector: 'app-edit-evse-dialog',
    templateUrl: './edit-evse-dialog.component.html',
    styleUrls: ['./edit-evse-dialog.component.scss']
})
export class EditEvseDialogComponent implements OnInit, IEditForm<ExecuteEvseAddOrUpdate> {
    @Input()
    public possiblePowerSupplies!: PowerSupply[];
    @Input()
    public data!: ExecuteEvseAddOrUpdate;
    @Input()
    public edit!: boolean;

    public types!: Array<SystemInfoEvseType>;
    public evseType!: SystemInfoEvseType;
    public actorType!: SystemInfoActorInfo;
    public actorArgs!: Array<SystemInfoActorArg & { value?: string | number }>;

    private oldType: SystemInfoEvseType | null = null;
    private oldActorArguments: EvseActorArgs[] = [];

    public evseNamesList: StateGroupManufacturer[] = [];
    public stateForm = this.formBuilder.group({
        stateGroup: "",
    });
    public manufacturerGroupOptions: Observable<StateGroupManufacturer[]> | undefined;

    constructor(
        private readonly systemService: SystemService,
        private readonly formBuilder: FormBuilder
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.types = (await this.systemService.getSystemInfo()).supportedEvses
        this.evseType = this.types.filter(t => t.key === this.data.type)[0];
        this.actorType = this.evseType?.actors.filter(a => a.key === this.data.actorKey)[0];
        this.actorArgs = this.actorType?.args;
        if (this.edit) {
            this.oldType = this.evseType;
        }
        if (this.evseType) {
            this.stateForm.setValue({"stateGroup": this.evseType.name})
        }

        this.types.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer))

        const uniqueManufacturer = Array.from(
            new Set(
                this.types.map((evse) => {
                    return evse.manufacturer;
                })
            )
        );

        for (const manufacturer of uniqueManufacturer) {
            this.evseNamesList.push({
                manufacturer: manufacturer,
                evses: this.types.filter(vObj => vObj.manufacturer === manufacturer).sort((a, b) => a.name.localeCompare(b.name))
            });
        }

        this.manufacturerGroupOptions = this.stateForm
            .get("stateGroup")!
            .valueChanges.pipe(
                startWith(""),
                map((value) => this.filterGroup(value || ""))
            );
        this.oldActorArguments = [...this.data.actorArgs];
    }

    private filterGroup(value: string): StateGroupManufacturer[] {
        if (value) {
            this.evseType = this.types.filter(t => t.name === value)?.[0];
            if (this.evseType) {
                this.updateActorFields();
            }
            this.data.actorArgs.forEach(arg => {
                const oldActorArgument = this.oldActorArguments.find(oldArg => oldArg.key === arg.key);
                if (oldActorArgument) {
                    arg.value = oldActorArgument.value;
                }
            });
            return this.evseNamesList.map(group => ({
                manufacturer: group.manufacturer,
                evses: group.evses.filter(e => e.name.toLowerCase().includes(value.toLowerCase()) || e.manufacturer.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
            })).filter((group) => group.evses.length > 0);
        }
        return this.evseNamesList;
    }

    public updateActorFields() {
        if (this.oldType === this.evseType) {
            // Do not clear args if it is not necessary
            return;
        }
        this.data.type = this.evseType.key;
        this.actorType = this.evseType.actors[0];
        this.updateActorArgs();
        this.oldType = this.evseType;
    }

    public updateActorArgs() {
        this.data.actorKey = this.actorType?.key;
        this.data.actorArgs = this.actorType?.args.map(arg => {
            return {
                key: arg.key,
                value: ""
            }
        }) || [];
    }

    public updateMaintenanceReason() {
        if(!this.data.maintenance) {
            this.data.maintenanceReason = undefined;
        }
    }

    validate(): string[] {
        let msgs: string[] = []
        if (!this.data.name || /^\s*$/.test(this.data.name)) {
            msgs.push("- Name.<br>");
        }
        if (!this.data.type) {
            msgs.push("- Typ.<br>")
        }
        if (!this.actorType) {
            msgs.push("- Anschlussart.<br>")
        } else {
            let i = 0;
            for (const a of this.actorType.args) {
                const arg = this.actorType?.args.find(arg => arg.key === this.data.actorArgs[i].key);
                if ((arg && arg.isRequired) && !this.data.actorArgs[i].value) {
                    msgs.push("- " + a.name + ".<br>")
                }
                if ((this.data.actorArgs[i].key === "meterPollIntervallSeconds" || this.data.actorArgs[i].key === "statusPollIntervallSeconds")) {
                    if (isNumeric(this.data.actorArgs[i].value) && Number(this.data.actorArgs[i].value) < 0) {
                        msgs.push("- " + a.name + " Der Wert muss eine positive Zahl sein.<br>")
                    }
                }
                ++i;
            }
        }
        if (!this.data.maxI) {
            msgs.push("- Max Amps.<br>")
        }

        // noinspection SuspiciousTypeOfGuard
        if (typeof this.data.powerSupplyId !== "number") {
            msgs.push("- Unterverteilung. <br>")
        }
        if (msgs.length > 0) {
            msgs = ["Nicht ausgefüllt:<br>"].concat(msgs);
        }
        if (this.data.maxI && this.data.minGuestI && this.data.maxI < this.data.minGuestI) {
            msgs.push("<br>Max amps smaller then Min Guest amps.<br>");
        }
        if (this.data.maintenance && this.data.maintenanceReason === undefined) {
            msgs.push("- Maintenance reason must be given.<br>");
        }
        return msgs;
    }

    public rotationImage(): string {
        switch (this.data.phaseRotation) {
            case 'NO_ROTATION':
                return "/assets/img/phase_rotation/no_rotate.png"
            case 'LEFT':
                return "/assets/img/phase_rotation/left.png"
            case 'RIGHT':
                return "/assets/img/phase_rotation/right.png"
            case 'SWITCH_1_2':
                return "/assets/img/phase_rotation/switch12.png"
            case 'SWITCH_1_3':
                return "/assets/img/phase_rotation/switch13.png"
            case 'SWITCH_2_3':
                return "/assets/img/phase_rotation/switch23.png"
            default:
                return "/assets/img/phase_rotation/empty.png"
        }
    }
}
