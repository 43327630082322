<h2>
    {{load.name}}
    <button *ngIf="!loading" (click)="refreshHistory()" mat-icon-button mat-button matTooltip="Verlauf neu aus Rohdaten berechnen. Diese Operation kann mehrere Minuten ben&ouml;tigen und sollte nur benutzt werden, wenn es Probleme gibt."><mat-icon>memory</mat-icon></button>
    <button *ngIf="loading" mat-icon-button mat-button matTooltip="Bitte warten, neuberechnung läuft noch."><i class="fa fa-circle-o-notch fa-spin"></i></button>
</h2>
<div fxLayoutAlign="end end">
    <app-graph-help-icon [options]="['controlZoom', 'leftClickMove', 'shiftLeftClickZoom', 'doubleLeftClickReset']" [vAlign]="'bottom'"></app-graph-help-icon>
</div>
<app-simple-graph *ngIf="!loading && points; else loadTemplate" [labels]="labels" [graphData]="points"></app-simple-graph>
<ng-template #loadTemplate>
    Daten werden geladen. Bitte warten.
</ng-template>
