<mat-form-field appearance="fill" class="server-uri">
    <mat-label>Server URI</mat-label>
    <input matInput type="text" [(ngModel)]="uri">
</mat-form-field>
<button
    mat-raised-button color="primary"
    type="submit"
    (click)="connect()"
    [disabled]="connected"
>
    Verbinden
</button>
<button
    mat-raised-button color="primary"
    type="submit"
    (click)="close()"
    [disabled]="!connected"
>
    Trennen
</button>
<hr>
<div>
    <mat-form-field appearance="fill">
        <mat-label>chargePointIdentity</mat-label>
        <input matInput type="text" [(ngModel)]="callCmd.chargePointIdentity">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Action</mat-label>
        <mat-select [(ngModel)]="callCmd.action">
            <mat-option *ngFor="let action of actions" [value]="action" >{{action}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Nachricht</mat-label>
        <input matInput type="text" [(ngModel)]="callCmd.body">
        <button [disabled]="!callCmd.action" matSuffix mat-icon-button aria-label="Clear" (click)="showWizard = !showWizard">
            <mat-icon>mode_edit</mat-icon>
        </button>
    </mat-form-field>
    <br/>
    <app-wizard *ngIf="showWizard" [structure]="structure[callCmd.action]" (textChanged)="callCmd.body = $event"></app-wizard>
    <br/>
    <button
        mat-raised-button color="primary"
        type="submit"
        (click)="sendCmd()"
        [disabled]="!connected"
    >
        Senden
    </button>
    <button
        mat-raised-button color="primary"
        type="submit"
        (click)="requestList()"
        [disabled]="!connected"
    >
        Verbindungen auflisten
    </button>
</div>

<hr>
<mat-form-field appearance="fill">
    <mat-label>grep</mat-label>
    <input matInput type="text" [(ngModel)]="grep">
</mat-form-field>
<mat-form-field appearance="fill">
    <mat-label>tail</mat-label>
    <input matInput type="number" [(ngModel)]="tail">
</mat-form-field>
<mat-checkbox [(ngModel)]="autoScroll">Automatisch scrollen</mat-checkbox>
<button mat-raised-button color="primary" type="submit" (click)="download()">
    Protokoll Herunterladen
</button>
<button mat-raised-button color="primary" type="submit" (click)="clear()">
    Löschen
</button>
<pre id="scroll-pre">{{filterLog()}}</pre>

