import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    Basis,
    BasisOverview,
    Box,
    ChargingSessionList, EvseTeaser,
    Fleet, UnknownOcppClient,
    Vehicle
} from '@io-elon-common/frontend-api';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {DialogParamsWrapperType, EvseService} from '../../service/evse.service';
import {BasisService} from '../../../basis/service/basis.service';
import {ToastrService} from 'ngx-toastr';
import {VehicleService} from 'src/app/modules/vehicle/service/vehicle.service';
import {UnknownCpiService} from "../../../unknown-cpis/service/unknown-cpi.service";
import {AuthService} from '../../../../shared/guards/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {
    BulkEvseConfigValidationComponent
} from '../../dialogs/bulk-evse-config-validation/bulk-evse-config-validation.component';


@Component({
    selector: 'app-evse-list-view',
    templateUrl: './evse-list-view.component.html',
    styleUrls: ['./evse-list-view.component.scss']
})
export class EvseListViewComponent implements OnInit, OnDestroy {
    private selectedFleetSubsciption!: Subscription;
    private fleetIdSubscription?: Subscription;

    private basisSubscription?: Subscription;
    private startTst: number | undefined;
    @Input() public basisId = 1;
    public boxes!: Box[];
    public evses!: EvseTeaser[];
    public fleetId!: number
    public error = '';
    public basisOverview!: BehaviorSubject<BasisOverview | undefined>;

    public fleet!: BehaviorSubject<Fleet | undefined>

    public vehicles!: BehaviorSubject<Vehicle[] | undefined>
    public activeChargingSessions!: BehaviorSubject<ChargingSessionList | undefined>
    public basis: BehaviorSubject<Basis | undefined> = new BehaviorSubject<Basis | undefined>(undefined);
    public unknownCpis: BehaviorSubject<UnknownOcppClient[] | undefined> = new BehaviorSubject<UnknownOcppClient[] | undefined>(undefined);
    public isDev!: boolean
    constructor(
        private readonly basisService: BasisService,
        private readonly evseService: EvseService,
        private readonly toastr: ToastrService,
        private readonly fleetService: FleetService,
        private readonly route: ActivatedRoute,
        private readonly vehicleService: VehicleService,
        private readonly unknownCpiService: UnknownCpiService,
        private readonly authService: AuthService,
        private readonly dialog: MatDialog,
    ) {
    }

    public async ngOnInit() {
        this.unknownCpis = this.unknownCpiService.getAll();
        const fleetId = parseInt(this.route.snapshot.paramMap.get('fleetId') as string, 10);
        if (!Number.isNaN(fleetId)) {
            this.fleetService.setSelectedFleet(fleetId);
        }

        this.selectedFleetSubsciption = this.fleetService.selectedFleet.subscribe(id => {
            if (id !== undefined && !isNaN(id)) {
                this.fleetId = id;
                this.error = '';
                this.updateFleetId(id);
            } else {
                this.error = 'Es wurde keine Flotte ausgewählt.';
            }
        });


        this.fleetIdSubscription = this.fleetService.selectedFleet.subscribe(sf => {
            if(sf !== undefined) {
                this.fleet = this.fleetService.get(sf);
            }
        });

        this.vehicles = this.vehicleService.getAll();
        this.isDev = this.authService.isDeveloper();
    }

    ngOnDestroy() {
        this.selectedFleetSubsciption.unsubscribe();
        this.basisSubscription?.unsubscribe();
    }

    public async newEvse(): Promise<void> {
        try{
            const powerSupplies =  this.fleet.getValue()?.base.powerSupplies;
            if(powerSupplies === undefined){
                this.toastr.warning("Keine Basis ausgewählt");
                return;
            }
            await this.evseService.showNewDialog(new DialogParamsWrapperType(this.basisId, powerSupplies));
        } catch (err: any) {
            this.toastr.warning(err, "Fehler");
            console.log(err);
        }
    }

    public async showBulkValidationDialog(): Promise<void> {
        this.dialog.open(BulkEvseConfigValidationComponent, {
            data: this.basisId,
            maxHeight: '70vh',
            width: '40vw',
            minHeight: '70vh',
        });
    }

    private async updateFleetId(fleetId: number) {
        this.activeChargingSessions = this.fleetService.getActivChargingSessions(fleetId);
        if (this.basisSubscription) {
            this.basisSubscription.unsubscribe();
        }

        this.basisSubscription = this.fleetService.get(fleetId).subscribe(fleet => {
            if (fleet) {
                // noinspection JSIgnoredPromiseFromCall
                this.basisId = fleet.base.id;
                this.update(fleet.base);
                this.basis.next(fleet.base);
            }
        });
    }

    private async update(basis: Basis) {
        this.basisOverview = this.basisService.getOverview(basis.id);
    }
}
