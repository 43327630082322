<div class="io-row">
    <button *ngIf="!notification.ack" mat-icon-button (click)="ignore($event)" matTooltip="Ignorieren">
        <mat-icon>notifications_off</mat-icon>
    </button>
    <span class="date">{{notification?.tst | age}}</span><br>
    <app-notification-icon [notification]="notification"></app-notification-icon>
    <div class="message">
        <span [class]="{'weak':notification.ack}">{{notification?.message}}</span><br/>
        <ng-container *ngIf="(evse | async) as evse">
            <app-evse-link [evse]="evse" [showAlerts]="false"></app-evse-link>
            <br/>
        </ng-container>

        <ng-container *ngIf="(vehicle | async) as vehicle">
            <app-vehicle-link [vehicle]="vehicle" [showAlerts]="false"></app-vehicle-link>
            <br/>
        </ng-container>

        <app-meter-link *ngIf="(meter | async) as meter" [meter]="meter" [showAlerts]="false"></app-meter-link>
    </div>
    <hr>
</div>
